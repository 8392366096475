<template>
    <div class="search-form">
        <div class="tab-title form-group">
            <div class="row align-items-center">
                <div class="col-auto pe-0">
                    <p class="mb-0">Find {{ isProjectSearch ? 'Projects' : 'Properties'}} By Your Destiny Profile <i class="fa fa-search fa-flip-horizontal filterSearchIcon"></i></p>
                </div>
                <div class="col-auto ms-md-auto">
                    <div class="row justify-content-md-end">
                        <div class="col">
                            <select class="form-control" v-model="formData.gender">
                                <option value="" disabled selected hidden>Gender</option>
                                <option>Male</option>
                                <option>Female</option>
                                <option>Other</option>
                            </select>
                        </div>
                        <div class="col">
                            <input type="date" placeholder="Date Of Birth" class="form-control" v-model="formData.dateOfBirth">
                        </div>
                        <div class="col-auto">
                            <a href="#" @click.prevent="resetForm" class="comman-btn-5">
                                <img src="frontend/images/refresh-icon.svg">
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="form-group">
            <div class="row align-items-center">
                <div class="col">
                    <input type="text" class="form-control" name="emailid" v-model="formData.keywords" placeholder="Search by Address, School, City, Zip" >
                </div>
                <div class="col-auto">
                    <button type="submit" class="w-100 btn comman-btn-2" @click="submitForm">
                        <i class="me-2"><img src="frontend/images/search-ico.svg"></i>Search
                    </button>
                </div>
            </div>
        </div>
        <div class="row align-items-center justify-content-between">
            <div :class="{
                'col-6': !isProjectSearch,
                'col-4': isProjectSearch
            }">
                <select class="form-control text-center" title="Listing Type" v-model="formData.listingType">
                    <option value="" selected>All Listing Types</option>
                    <option v-for="(option, index) in mappedListingTypes" :key="index" :value="option.id">
                        {{ option.name }}
                    </option>
                </select>
            </div>
            <div class="col-4" v-if="isProjectSearch">
                <select class="form-control text-center" title="Listing Type" v-model="formData.locationType">
                    <option value="" selected>All Locations</option>
                    <option value="local">Local</option>
                    <option value="x-border">X-Border</option>
                </select>
            </div>
            <div :class="{
                'col-6': !isProjectSearch,
                'col-4': isProjectSearch
            }">
                <div :class="dropdownClass" >
                    <div class="dropdown">
                        <button class="form-control btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton" aria-expanded="false">
                            {{ showSelectedType(formData.selectedType) }}
                        </button>
                        <div class="dropdown-menu  p-4" aria-labelledby="dropdownMenuButton">
                            <!-- Radio buttons for selecting the main category -->
                            <div class="form-check" v-for="(child, parentType) in options.propertyTypes" :key="parentType">
                                <input class="form-check-input" type="radio" :id="parentType" :value="parentType" v-model="formData.selectedType">
                                <label class="form-check-label" :for="parentType">{{ parentType }}</label>
                            </div>

                            <!-- Display categories based on the selected type -->
                            <div v-if="formData.selectedType" class="mt-3">
                                <div v-for="items in options.propertyTypes[formData.selectedType]" :key="items.name">
                                    <div class="form-check">
                                        <input class="form-check-input" type="checkbox" :id="items.name" :checked="isCategorySelected(items)" @change="selectAll(items, $event.target.checked)">
                                        <label class="form-check-label" :for="items.name">{{ items.name }}</label>
                                    </div>
                                    <div style="padding-left: 20px;">
                                        <div class="form-check" v-for="item in items.children" :key="item">
                                            <input class="form-check-input" type="checkbox" :id="item.name" :name="items.name" :checked="isItemSelected(item)" @change="selectItem(item, $event.target.checked)">
                                            <label class="form-check-label" :for="item">{{ item.name }}</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { getConstListingTypes, getConstPropertyTypes } from "@/utils/apiHelpers";
export default {
    name: 'ExploreMoreSearch',

    props: {
        type: {
            type: String,
            Required: true
        },
        params: {
            type: Object
        },
        queryData: {
            type: Object,
            required: false
        },
        isProjectSearch: {
            type: Boolean,
            required: false,
            default: false
        },
    },

    data() {
        return {
            formData: this.queryData || {},
            isDropdownOpen: false,
            selectedType: null,
            showCategories: [],
            options: {
                propertyTypes: [],
                listingTypes: [],
            }
        }
    },

    mounted() {
        document.addEventListener('click', this.handleOutsideClick);
        this.getOptions();
    },
       
    computed: {
        mappedListingTypes() {
            let options = [];
            // Map property types based on the parent prop
            if (this.type === 'Buy') {
                options = this.options.listingTypes.filter(event =>
                    event.isForSale === 1
                )
            } else {
                options = this.options.listingTypes.filter(event =>
                    event.isForRent === 1
                )
            }
            return options;
        },

        dropdownClass() {
            // Add logic to adjust the class based on selected options
            if (this.selectedType) {
                return 'extended-dropdown'; // Class to adjust dropdown size
            }
            return '';
        }
    },

    methods: {
        async getOptions() {
            const listingTypeOptions = await getConstListingTypes();
            this.options.listingTypes = listingTypeOptions || [];

            const propertyTypeOptions = await getConstPropertyTypes();
            this.options.propertyTypes = propertyTypeOptions || {};

            this.$emit('update-options', this.options);
        },

        resetSelectedCategories() {
            this.formData.selectedCategories = {};
        },

        showSelectedType(selectedType) {
            if (selectedType) {
                return selectedType.charAt(0).toUpperCase() + selectedType.slice(1);
            }

            return 'Select Type';
        },

        resetForm() {
            // should have all ExploreMore getFilters formData params
            Object.assign(this.formData, {
                gender: '',
                dateOfBirth: '',
                keywords: '',
                listingType: '',
                locationType: '',
                selectedCategories: {},
                selectedType: null,
                selectedCountry: null,
                is_featured: null,
                is_FSM_linked: null,
                is_widget_property: null,
                is_curated_property: null,
            });
            this.submitForm();
        },

        isCategorySelected(parentItem) {
            let categoryAllSelected = true;
            const allItems = parentItem.children || [];
            if (!this.formData.selectedCategories[this.formData.selectedType]) {
                this.formData.selectedCategories[this.formData.selectedType] = [];
            }
            allItems.forEach(element => {
                if (!this.formData.selectedCategories[this.formData.selectedType].includes(element.id)) {
                    categoryAllSelected = false;
                }
            });
            return categoryAllSelected;
        },

        isItemSelected(item) {
            if (!this.formData.selectedCategories[this.formData.selectedType]) {
                return false;
            }
            return this.formData.selectedCategories[this.formData.selectedType].includes(item.id);
        },

        selectAll(parentItem, isChecked) {
            const allItems = parentItem.children || [];
            if (!this.formData.selectedCategories[this.formData.selectedType]) {
                this.formData.selectedCategories[this.formData.selectedType] = [];
            }
            if (isChecked) {
                // push all
                allItems.forEach(item => {
                    this.formData.selectedCategories[this.formData.selectedType].push(item.id);
                });
            } else {
                // remove all
                allItems.forEach(item => {
                    if (this.formData.selectedCategories[this.formData.selectedType].includes(item.id)) {
                        const index = this.formData.selectedCategories[this.formData.selectedType].indexOf(item.id);
                        if (index > -1) {
                            this.formData.selectedCategories[this.formData.selectedType].splice(index, 1);
                        }
                    }
                });
            }
            this.formData.selectedCategories[this.formData.selectedType] = [...new Set(this.formData.selectedCategories[this.formData.selectedType])];
        },

        selectItem(item, isChecked) {
            if (!this.formData.selectedCategories[this.formData.selectedType]) {
                this.formData.selectedCategories[this.formData.selectedType] = [];
            }

            if (isChecked && !this.formData.selectedCategories[this.formData.selectedType].includes(item.id)) {
                this.formData.selectedCategories[this.formData.selectedType].push(item.id);
            }

            if (!isChecked && this.formData.selectedCategories[this.formData.selectedType].includes(item.id)) {
                const index = this.formData.selectedCategories[this.formData.selectedType].indexOf(item.id);
                if (index > -1) {
                    this.formData.selectedCategories[this.formData.selectedType].splice(index, 1);
                }
            }
        },

        toggleDropdown() {
            this.isDropdownOpen = !this.isDropdownOpen;
        },

        handleOutsideClick(e) {
            if (!this.$el.contains(e.target) && this.isDropdownOpen) {
                this.isDropdownOpen = false;
            }
        },

        submitForm() {
            this.$emit('submit', this.formData);
        }
    },
    watch: {
        queryData() {
            this.formData = this.queryData;
            this.submitForm();
        }
    }
}
</script>

<style scoped>
.dropdown-menu {
  position: absolute;
  z-index: 999;
  background: white;
  border: 1px solid #ccc;
  max-height: 300px;
  overflow-y: auto;
}

.btn-dropdown {
    background-color: #f2f2f2; /* Light grey background */
    color: #333; /* Dark grey text color */
    border: none; /* No border */
    padding: 8px 16px; /* Top and bottom padding 8px, left and right 16px */
    font-size: 16px; /* Text size */
    border-radius: 5px; /* Rounded corners */
    display: inline-block; /* Ensures the dropdown behaves as an inline block */
    width: 100%; /* Full width */
    text-align: left; /* Aligns text to the left */
}

.btn-dropdown:hover {
    background-color: #e6e6e6; /* Slightly darker grey on hover */
    cursor: pointer; /* Changes cursor to pointer on hover */
}

.btn-dropdown:focus {
    background-color: #d9d9d9; /* Even darker grey on focus */
    outline: none; /* Removes outline on focus */
    box-shadow: 0 0 3px #666; /* Adds focus shadow */
}

/* Placeholder style for the property type button */
button#dropdownMenuButton {
    color: #999; /* Placeholder color */
    background-color: #f2f2f2; /* Light background */
    border: none; /* Remove border */
    outline: none; /* Remove the default outline */
    box-shadow: none; /* Remove any focus box-shadow */
}

/* When a property type is selected, change the color */
button#dropdownMenuButton.selected {
    color: #333; /* Regular text color when selected */
}

/* Ensure the placeholder color and border stay consistent on focus */
button#dropdownMenuButton:focus {
    color: #999; /* Keep placeholder color on focus */
    border: none; /* Prevent border on focus */
    outline: none; /* Prevent outline on focus */
    box-shadow: none; /* Remove the focus box-shadow */
}
</style>
<template>
    <div class="row">
        <div class="col-md-12">
            <div v-if="selectedPropertyId" class="d-flex justify-content-end align-items-end mt-3 me-2">
                <a @click="resetButton" role="button" class="comman-btn-2">
                    <i class="fa fa-angle-double-left"></i> Back </a>
            </div>
        </div>
        <div class="col-md-6 border-end">
            <ComparedProperty :itemId="itemId"></ComparedProperty>
        </div>
        <div class="col-md-6 border-end">
            <LoaderCust :isLoading="loading" />
            <ComparedProperty v-if="selectedPropertyId" :itemId="selectedPropertyId"></ComparedProperty>
            <div v-else>
                <div  style="height:100vh; overflow-y:scroll">
                    <div class="filter-bar-se mt-5 mb-3 flex" :class="['container', loading ? 'd-none' : '']">
                        <div class="comman-title">
                            <h3>{{ filters.sort_by_field }}</h3>
                        </div>
                        <div class="filter-right-bar align-items-center">
                            <a @click="resetButton" role="button">
                                <i class="fa fa-rotate-right"></i> Reset </a>
                            <div class="dropdown mx-2">
                                <a href="#" id="Dropdown-sort-by" role="button" data-bs-toggle="dropdown"
                                    aria-expanded="true">
                                    <img src="frontend/images/filter-ico.svg"> Filter By
                                </a>
                                <ul class="dropdown-menu" aria-labelledby="Dropdown-sort-by">
                                    <li>
                                        <a class="dropdown-item" href="#"
                                            :class="{ 'selected': filters.sort_by_field === 'All' }"
                                            @click.prevent="sortProperties('All')">All</a>
                                        <a class="dropdown-item" href="#"
                                            :class="{ 'selected': filters.sort_by_field === 'Property Price' }"
                                            @click.prevent="sortProperties('Property Price')">Property Price</a>
                                        <a class="dropdown-item" href="#"
                                            :class="{ 'selected': filters.sort_by_field === 'Location' }"
                                            @click.prevent="sortProperties('Location')">Location</a>
                                        <a class="dropdown-item" href="#"
                                            :class="{ 'selected': filters.sort_by_field === 'Features' }"
                                            @click.prevent="sortProperties('Features')">Features</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="row px-3 g-3">
                        <template v-if="!loading && properties.length > 0">
                            <div class="col-lg-4 col-md-6 d-flex" v-for="property in properties" :key="property.id">
                                <div class="card flex-grow-1 d-flex flex-column" @click="setSelectedProperty(property)">
                                    <div class="card-img-top position-relative property-comparison-card-img">
                                        <img :src="getPropertyImage(property?.actual_unit_photo[0]?.photo_name)"
                                            :alt="property?.property_name" class="img-fluid w-100 object-fit-cover" style="height: 200px;" />
                                        <div class="mark-logo position-absolute bottom-0 end-0 me-2 mb-2">
                                            <img src="frontend/images/logo.png" alt="logo" class="w-12 h-12">
                                        </div>
                                    </div>
                                    <div class="card-body property-comparison flex-grow-1 d-flex flex-column">
                                        <h5>{{ property.property_name }}</h5>
                                        <p class="loct-info">
                                            <img src="frontend/images/map-gol-ico.svg">
                                            {{ property.street }}, {{ property.district }}
                                        </p>
                                        <div class="price-pro"><span>S$ {{ formatPrice(property.asking_price_sale_rent) }}
                                                <small>(${{computedSQF(property)}}/SQF)</small></span></div>
                                        <div class="pro-specify">
                                            <span><i><img src="frontend/images/spec-ico1.svg"></i>{{property.no_of_rooms }}</span>
                                            <span><i><img src="frontend/images/spec-ico2.svg"></i>{{property.no_of_baths }}</span>
                                            <span><i><img src="frontend/images/spec-ico3.svg"></i>{{property.no_car_park_lots }}</span>
                                            <span><i><img src="frontend/images/spec-ico4.svg"></i>{{ property.floor_area }}{{ property.land_area_unit }}</span>
                                        </div>
                                        <div class="pro-tag">
                                            <a v-if="property.property_type_details.parent_id_top_level_1_name">{{property.property_type_details.parent_id_top_level_1_code }}</a>
                                            <a v-if="property.property_type_details.name && property.property_type_details.parent_id_top_level_1_name">{{property.property_type_details.code }}</a>
                                            <a v-if="property.land_title_type">{{property.land_title_type }}</a>
                                        </div>
                                        <ul>
                                            <li v-if="property.is_in_wishlist">
                                                <figure>
                                                    <img src="frontend/images/heart-ico.svg">
                                                </figure>
                                                <small>favorite</small>
                                            </li>
                                            <li v-if="property.is_direct_owner">
                                                <figure>
                                                    <img src="frontend/images/specify-ico1.png">
                                                </figure>
                                                <small>Direct<br>Owner</small>
                                            </li>
                                            <li v-if="property.is_sing_pass || property.is_corp_pass ">
                                                <figure>
                                                    <img src="frontend/images/specify-ico2.png">
                                                </figure>
                                                <small>SingPass<br>Verified </small>
                                            </li>
                                            <li v-if="property.is_document_approved">
                                                <figure>
                                                    <img src="frontend/images/specify-ico3.png">
                                                </figure>
                                                <small>Documents<br>Verified</small>
                                            </li>
                                            <li v-if="property.is_below_mean_value">
                                                <figure>
                                                    <img src="frontend/images/Down-arrow.svg">
                                                </figure>
                                                <small>BMV</small>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </template>
                    </div>

                    <div class="pagination-se mt-3" v-if="!loading && properties.length > 0">
                        <nav aria-label="...">
                            <ul class="pagination justify-content-center">
                                <li class="page-item" :class="{ disabled: filters.page === 1 }"
                                    @click="filters.page > 1 && changePage(filters.page - 1)" v-if="filters.page > 1">
                                    <a class="page-link"><img src="frontend/images/left-arrow-icon.svg"
                                            alt="Previous"></a>
                                </li>
                                <li v-for="pageNumber in paginatedPages(filters.page, totalPages)" :key="pageNumber"
                                    class="page-item" :class="{ active: pageNumber === filters.page }">
                                    <a class="page-link"
                                        @click.prevent="pageNumber !== filters.page && changePage(pageNumber)">
                                        {{ pageNumber }}
                                    </a>
                                </li>
                                <li class="page-item" :class="{ disabled: filters.page === totalPages }"
                                    @click="filters.page < totalPages && changePage(filters.page + 1)"
                                    v-if="filters.page < totalPages">
                                    <a class="page-link"><img src="frontend/images/right-arrow-icon.svg" alt="Next"></a>
                                </li>
                            </ul>
                        </nav>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import LoaderCust from "@/components/LoaderCust.vue";
    import { CONSTANT } from "@/constants/constants";
    import { mapState } from "vuex";
    import { formatPrice, getPropertyImage, getUserProfileImage, paginatedPages } from "@/utils/helpers";
    import ComparedProperty from "@/views/tool/ComparedProperty.vue";

    export default {
        name: 'PropertyComparison',
        components: {
            LoaderCust,
            ComparedProperty,
        },
        data() {
            return {
                loading: false,
                filters: {
                    page: 1,
                    sort_by_field: 'All',
                },
                properties: [],
                totalPages: 0,
                totalRecords: 0,
                selectedPropertyId: null,
                propertyDetail: [],
                featuresList: [],
            }
        },
        created() {
            this.fetchPropertyData().then(() => {
                this.fetchComparedOptions();
            });

        },
        computed: {
            CONSTANT() {
                return CONSTANT
            },
            ...mapState(['isAuthenticated', 'user']),
            itemId() {
                return this.$route.params.id;
            },
        },
        methods: {
            formatPrice,
            getPropertyImage,
            getUserProfileImage,
            paginatedPages,
            setSelectedProperty(property) {
                if (property) {
                    this.selectedPropertyId = property.id;
                }
            },
            sortProperties(sortBy) {
                this.filters.sort_by_field = sortBy;
                this.filters.page = 1;

                this.fetchComparedOptions();
            },
            async fetchPropertyData() {
                const token = localStorage.getItem('userToken');
                try {
                    const response = await this.$axios.post('api/property-detail',
                        {
                            user_id: this.user.id,
                            api_token: token,
                            property_listing_id: this.itemId,
                        });
                    this.agent_id = response.data.output[0].user_id;
                    this.userCredit = response.data.output[0].available_credit_points;
                    this.propertyDetail = response.data.output[0];
                    this.featuresList = [
                        ...response.data.output[0].features,
                        ...response.data.output[0].features_additional,
                    ];

                    try {
                        this.mode_of_payment = JSON.parse(response.data.output[0].mode_of_payment);
                    } catch (e) {
                        this.mode_of_payment = response.data.output[0].mode_of_payment;
                    }
                } catch (error) {
                    console.error('Error fetching options:', error);
                }
            },
            async fetchComparedOptions() {
                const token = localStorage.getItem('userToken');
                try {
                    this.loading = true;

                    const response = await this.$axios.post('api/compared-property', {
                        api_token: token,
                        sort_by_field: this.filters.sort_by_field,
                        user_id: this.user.id,
                        page_number: this.filters.page,
                        price: this.propertyDetail.asking_price_sale_rent,
                        location: this.propertyDetail.district,
                        features: this.featuresList.map(feature => feature.pr_master_feature_id),
                    });
                    this.properties = response.data.output.property_list ?? [];
                    this.totalPages = response.data.output.no_of_total_pages ?? 0;
                    this.totalRecords = response.data.output.no_of_total_records ?? 0;

                    this.$nextTick(() => {
                        this.initializeOwlCarousel();
                    });
                } catch (error) {
                    console.error('Error fetching properties:', error);
                } finally {
                    this.loading = false;
                }
            },
            changePage(page) {
                if (page > 0) {
                    const totalPages = this.totalPages;
                    if (page <= totalPages) {
                        this.filters.page = page;

                        this.fetchComparedOptions();
                    }
                }
            },
            initializeOwlCarousel() {
                window.$('.property-carousel').owlCarousel({
                    loop: false,
                    margin: 0,
                    nav: true,
                    dots: true,
                    navText: ['<i class="fa fa-angle-left"></i>', '<i class="fa fa-angle-right"></i>'],
                    responsive: {
                        0: {
                            items: 1,
                            margin: 0
                        },
                        600: {
                            items: 1
                        },
                        1000: {
                            items: 1
                        }
                    }
                });
            },
            computedSQF(property) {
                return property.asking_price_sale_rent > 0 ? Math.ceil(property.asking_price_sale_rent / property.floor_area) : 0;
            },
            resetButton() {
                this.selectedPropertyId = null;
                this.filters.sort_by_field = 'All';

                this.fetchComparedOptions();
            },
        }
    }
</script>

<style scoped>
    .card-img-top img {
        height: 250px;
        object-fit: cover;
        width: 100%;
        position: relative;
    }

    .mark-logo img {
        width: 130px;
        height: auto;
    }

    .dropdown-item:hover,
    .dropdown-item.selected {
        background-color: #ffe6b9;
    }
</style>
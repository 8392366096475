<template>
    <div v-if="properties !== undefined && properties.length > 0">
        <div class="accordion custom-accordion" id="accordion-one">
            <div class="accordion-item">
                <div class="accordion-header" id="heading1">
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                        data-bs-target="#property-gua" aria-expanded="true" aria-controls="property-gua">
                        Property Compatibility
                    </button>
                        
                </div>
                <div id="property-gua" class="accordion">
                    <div class="accordion-body">
                        <div class="d-flex align-items-center mb-3">
                            <a @click.prevent="navigateToProperty(activeProperty - 1)" :class="{ 'text-muted': activeProperty === 0 }">
                                <i class="fa fa-angle-left"></i>
                            </a>
                        
                            <a class="mx-3" @click="redirectToPropertyDetails(currentProperty)">{{ currentProperty.unit_layout_name || currentProperty.id }}</a>
                        
                            <a @click.prevent="navigateToProperty(activeProperty + 1)"
                                :class="{ 'text-muted': activeProperty === properties.length - 1 }">
                                <i class="fa fa-angle-right"></i>
                            </a>
                        </div>

                        <div class="row">
                            <div class="col-6 table property-gua-data-table">
                                <table>
                                    <tbody>
                                        <tr class="border border-0">
                                            <td class="pe-4"><b>Built-Up</b></td>
                                            <td class="px-4"><b>Total Units</b></td>
                                        </tr>
                                        <tr class="border border-0">
                                            <td class="pe-4"><span>{{ currentProperty.unit_size }} sqft</span></td>
                                            <td class="px-4"><span>{{ currentProperty.total_units }}</span></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        
                        
                        <div v-if="!isAuthenticated" class="login-signup-overlay text-center">
                            <a href="/sign-in" class="comman-btn-2">Login / Sign Up</a>
                        </div>

                        <div :class="{ blurred: !isAuthenticated }">
                            <div class="property-slider">
                                <div class="property-slider-left">
                                    <div class="owl-carousel owl-theme property-slider-f-slider"
                                        v-if="currentProperty.actual_unit_photos?.length > 0">
                                        <div class="item" v-for="(pimage, index) in currentProperty.actual_unit_photos"
                                            :key="pimage.id">

                                            <div class="property-slider-images">
                                                <img :src="`${CONSTANT.IMAGE_URL}/imagesPropertyDocumentAndOtherPhotos/${pimage.photo_name}`"
                                                    :alt="pimage.photo_name">
                                            </div>
                                            <div class="text-center">{{ index + 1 }} / {{
                                                currentProperty.actual_unit_photos.length }} photos</div>
                                        </div>
                                    </div>
                                    <div v-else>
                                        <div class="item">
                                            <div class="property-slider-images">
                                                <img :src="CONSTANT.DEFAULT_NO_IMAGE">
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="property-slider-right">
                                    <div class="row">
                                        <div class="col">
                                            <div class="light-info-box mt-0">
                                                <span>Property Facing</span>
                                                <h5>{{ currentProperty.FSM_property_facing_orientation_building_id?.sub_cardinal || '-' }}</h5>
                                            </div>
                                        </div>
                                        <div class="col">
                                            <div class="light-info-box mt-0">
                                            <span>Main Door Facing</span>
                                                <h5>{{ currentProperty.FSM_space_sectors_main_door_entrance_sector_id?.sub_cardinal || '-' }}</h5>
                                            </div>
                                        </div>
                                    </div>

                                    <div>
                                        <div class="list-box">
                                            <ul>
                                                <li><b>Property Gua</b> <span><b>Sectors/Direction</b></span></li>
                                                <template
                                                    v-if="currentProperty.is_FSM_linked && property_gua[currentProperty.id] && Object.keys(property_gua[currentProperty.id]).length > 0">
                                                    <li v-for="(guaDirection, guaName) in property_gua[currentProperty.id]"
                                                        :key="guaName">
                                                        <span>{{ formatTitleCase(guaName) }}</span><span>{{ guaDirection
                                                            }}</span>
                                                    </li>
                                                </template>
                                                <template v-else>
                                                    <li>
                                                        <span v-if="!currentProperty.is_FSM_linked">Feng Shui Map not
                                                            linked for this property.</span>
                                                        <span v-else>No property gua found.</span>
                                                    </li>
                                                </template>
                                            </ul>
                                        </div>
                                        <a @click="redirectToPropertyDetails(currentProperty)" class="comman-btn-2 mt-3">View More Compatibility</a>
                                    </div>
                                </div>
                            </div>
                        </div>

                        
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div v-else>
        <span>No unit layouts found for this project.</span>
    </div>
</template>

<script>
    import { CONSTANT } from '@/constants/constants';
    import { mapState } from 'vuex';
    import { formatTitleCase } from "@/utils/helpers";

    export default {
        name: 'ProjectFengShui',
        props: {
            projectDetail: {
                type: Object,
                required: true,
            },
        },
        data() {
            return {
                property_gua: {},
                properties: this.projectDetail.unit_layout,
                activeProperty: 0,
                CONSTANT
            }
        },
        computed: {
            ...mapState(['isAuthenticated']),
            currentProperty() {
                // Returns the currently active property details
                return this.properties[this.activeProperty] || {};
            }
        },
        methods: {
            formatTitleCase,
            navigateToProperty(index) {
                if (index >= 0 && index < this.properties.length) {
                    this.activeProperty = index;
                    
                    // destroy and reinitialize owlcarousal
                    const sliderClass = `.property-slider-f-slider`;
                    if (window.$(sliderClass).data('owl.carousel')) {
                        window.$(sliderClass).trigger('destroy.owl.carousel');
                    }

                    setTimeout(() => {
                        const newSliderClass = `.property-slider-f-slider`;
                        window.$(newSliderClass).owlCarousel({
                            loop: false,
                            margin: 0,
                            nav: true,
                            dots: false,
                            navText: ['<i class="fa fa-angle-left"></i>', '<i class="fa fa-angle-right"></i>'],
                            responsive: {
                                0: {
                                    items: 1,
                                },
                                600: {
                                    items: 1,
                                },
                                1000: {
                                    items: 1,
                                },
                            },
                        });
                    }, 1000);
                }
            },
            getAllPropertyGua() {
                this.properties = this.projectDetail?.unit_layout || [];
                if (this.properties == undefined) {
                    return;
                }
                this.properties.forEach(propertyDetail => {
                    this.getPropertyGua(propertyDetail);
                });
            },
            async getPropertyGua(property) {
                try {
                    if (property.is_FSM_linked) {
                        const response = await this.$axios.post(`api/properties/${property.id}/property-gua`, {
                            api_token: localStorage.getItem('userToken'),
                        });
                        Object.assign(this.property_gua, { [property.id]: response.data.output });
                    }
                } catch (error) {
                    console.error("Error retrieving property gua:", error);
                    throw error;
                }
            },
            redirectToPropertyDetails(property) {
                window.location.href = window.FRONTEND_URL + `/property-detail/${property.id}`;
            },
        },

        mounted() {
            if (this.properties.length > 0) {
                this.navigateToProperty(0); // Initialize for the first property
            }
        },

        watch: {
            projectDetail: {
                immediate: true,
                deep: true,
                handler(newVal) {
                    if (newVal && this.isAuthenticated) {
                        this.getAllPropertyGua();
                    }
                },
            },
        }

    }
</script>

<style scoped>
    .blurred {
        filter: blur(5px);
    }

    .accordion-body {
        position: relative;
    }

    .login-signup-overlay {
        position: absolute;
        top: 50%;
        /* Center vertically */
        left: 50%;
        /* Center horizontally */
        transform: translate(-50%, -50%);
        z-index: 10;
    }
</style>
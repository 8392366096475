<template>    
    <section class="property-se">
        <div class="container">
            <div class="property-title-bar">
                <div class="comman-title mb-0">
                    <h3 v-if="this.itemId>0"><a href="#"><i class="fa fa-angle-left"></i></a> Edit Property Listing</h3>
                    <h3 v-if="this.itemId==0"><a href="#"><i class="fa fa-angle-left"></i></a> Create Property Listing</h3>
                </div>
                <div class="progress-bar-box">
                    <div>1/5</div>
                    <div class="progress">
                        <div class="progress-bar" role="progressbar" style="width: 20%" aria-valuenow="15" aria-valuemin="0" aria-valuemax="100"></div>							 
                        <!--<div class="progress-bar" role="progressbar" style="width: 20%" aria-valuenow="15" aria-valuemin="0" aria-valuemax="100"></div>							
                        <div class="progress-bar" role="progressbar" style="width: 20%" aria-valuenow="15" aria-valuemin="0" aria-valuemax="100"></div>							
                        <div class="progress-bar" role="progressbar" style="width: 20%" aria-valuenow="15" aria-valuemin="0" aria-valuemax="100"></div>							
                        <div class="progress-bar" role="progressbar" style="width: 20%" aria-valuenow="15" aria-valuemin="0" aria-valuemax="100"></div>							
                        <div class="progress-bar" role="progressbar" style="width: 20%" aria-valuenow="15" aria-valuemin="0" aria-valuemax="100"></div>-->
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-md-2">
                    <div class="property-sidebar">
                        <ul>
                            <li><a class="active" href="/post-property-listing">Step 1</a></li>
                            <li :class="isDisabledStep2"><router-link :to="`/post-property-listing-2/${itemId}`">Step 2</router-link></li>
                            <li :class="isDisabledStep3"><router-link :to="`/post-property-listing-3/${itemId}`">Step 3</router-link></li>
                            <li :class="isDisabledStep4"><router-link :to="`/post-property-listing-4/${itemId}`">Step 4</router-link></li>
                            <li :class="isDisabledStep5"><router-link :to="`/post-property-listing-5/${itemId}`">Step 5</router-link></li>								
                        </ul>
                    </div>
                </div>
                <div class="col-md-10">
                    <form @submit.prevent="submitPost">
                        <div class="property-box">
                            <div class="property-inner-box">
                                <div class="comman-title">
                                    <h3>Key Information</h3>
                                </div>

                                <div v-if="user.is_corporate" class="form-group">
                                    <label>Project</label>
                                    <select 
                                        id="projectSelect" 
                                        class="form-control"
                                        v-model="form.projectId" 
                                    >
                                        <option value="" disabled>Select Project</option>
                                        <option 
                                            v-for="project in projects" 
                                            :key="project.id" 
                                            :value="project.id"
                                        >
                                            {{ project.project_name }}
                                        </option>
                                    </select>
                                    <span class="error" v-if="errors.projectId">{{ errors.projectId }}</span>
                                </div>

                                <div class="form-group">
                                    <label>Listing Type*</label>
                                    <select 
                                        id="group1Select" 
                                        class="form-control"
                                        v-model="form.listingType" 
                                    >
                                        <option value="" disabled>Listing Type</option>
                                        <option 
                                        v-for="option in optionsGroup1" 
                                        :key="option.value" 
                                        :value="option.value"
                                        >
                                        {{ option.label }}
                                        </option>
                                    </select>
                                    <span  class="error" v-if="errors.listingType">{{ errors.listingType }}</span>
                                </div>
                                <div class="form-check mb-2">
                                    <input class="form-check-input" type="checkbox" v-model="form.qualify_for_foreigner" id="qualify_for_foreigner">
                                    <label class="form-check-label w-100" for="qualify_for_foreigner">
                                        Qualify For Foreigner
                                    </label>
                                </div>	
                            </div>
                            <div class="property-inner-box">
                                <div class="comman-title">
                                    <h3>Property Address / Location</h3>
                                </div>
                                <div class="form-group">
                                    <label>Property/Place/Project Name*</label>
                                    <input
                                        type="text"
                                        class="form-control"
                                        v-model="form.property_name"
                                        placeholder="Property/Place/Project Name"
                                    />
                                    <span class="error" v-if="errors.property_name">{{ errors.property_name }}</span>
                                </div>

                                <div class="row row-cols-1 row-cols-md-3">
                                    <div class="col">
                                        <div class="form-group">
                                            <label>Street*</label>
                                                <!-- <input type="text" class="form-control" v-model="form.street" placeholder="Street">                                            -->

                                                <input
                                                class="form-control"
                                                type="text"
                                                v-model="form.street"
                                                @input="fetchSuggestions"
                                                placeholder="Type to Street..."
                                                />
                                                <span  class="error" v-if="errors.street">{{ errors.street }}</span>
                                                <ul v-if="suggestions.length" class="sugesstion_ul">
                                                    <li v-for="(suggestion, index) in suggestions" :key="index" @click="selectSuggestion(suggestion)">
                                                        {{ suggestion }}
                                                    </li>
                                                </ul>
                                                
                                        </div>
                                    </div>
                                    <div class="col">
                                        <div class="form-group">
                                            <label>Area / HDB Estate*</label>
                                            <!-- <input type="text" class="form-control"  v-model="form.area_hdb_estate" placeholder="Area / HDB Estate"> -->
                                            <input
                                            class="form-control"
                                            type="text"
                                            v-model="form.area_hdb_estate"
                                            @input="fetchSuggestionsArea"
                                            placeholder="Type to Area..."
                                            />
                                            <span  class="error" v-if="errors.area_hdb_estate">{{ errors.area_hdb_estate }}</span>
                                            <ul v-if="suggestionsArea.length" class="sugesstion_ul">
                                                <li v-for="(suggestion, index) in suggestionsArea" :key="index" @click="selectSuggestionArea(suggestion)">
                                                    {{ suggestion }}
                                                </li>
                                            </ul>
                                            <span  class="error" v-if="errors.area_hdb_estate">{{ errors.area_hdb_estate }}</span>
                                        </div>
                                    </div>

                                    <div class="col" v-if="this.user.is_corporate && this.user.package != null">
                                        <div class="form-group">
                                            <label>District *</label>
                                            <!-- Country Selection -->
                                            <div>
                                                <select v-model="form.country" @change="fetchDistricts" class="form-control">
                                                    <option value="" disabled selected>Select Country</option>
                                                    <option value="singapore">Singapore</option>
                                                    <option value="malaysia">Malaysia</option>
                                                </select>
                                                <span class="error" v-if="errors.country">{{ errors.country }}</span>

                                                <!-- Add gap after country selection -->
                                                <div style="margin-bottom: 15px;"></div> <!-- Adds gap between country and district dropdown -->

                                                <!-- District Selection -->
                                                <select id="district_id" v-model="form.district_id" class="form-control"  @change="changeDistrict" :disabled="!form.country">
                                                    <option value="" disabled selected>Select District</option>
                                                    <option v-for="option in optionsDistrictList" :key="option.value" :value="option.value">
                                                        {{ option.label }}
                                                    </option>
                                                </select>
                                                <span class="error" v-if="errors.district">{{ errors.district }}</span>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col" v-if="!this.user.is_corporate || (this.user.is_corporate && !this.user.package)">
                                        <div class="form-group">                                      
                                            <label>District *</label>
                                            
                                            <!-- District Selection -->
                                            <select id="district_id" v-model="form.district_id" class="form-control" @change="changeDistrict">
                                                <option value="" disabled selected>Select District</option>
                                                <option v-for="option in optionsDistrictList" :key="option.value" :value="option.value">
                                                    {{ option.label }}
                                                </option>
                                            </select>
                                            <span class="error" v-if="errors.district">{{ errors.district }}</span>                                                                      
                                        </div>
                                    </div>

                                    <div class="col">
                                        <div class="form-group">
                                            <label>Block No</label>
                                            <input type="string" class="form-control"  v-model="form.block_no" placeholder="Block No">
                                            <span  class="error" v-if="errors.block_no">{{ errors.block_no }}</span>
                                        </div>
                                    </div>
                                    <div class="col">
                                        <div class="form-group">
                                            <label>Level No.</label>
                                            <input type="string" class="form-control" v-model="form.level_no" placeholder="Level No.">
                                            <span  class="error" v-if="errors.level_no">{{ errors.level_no }}</span>
                                        </div>
                                    </div>
                                    <div class="col">
                                        <div class="form-group">
                                            <label>Unit No.</label>
                                            <input type="string" class="form-control"  v-model="form.unit_no" placeholder="Unit No.">
                                            <span  class="error" v-if="errors.unit_no">{{ errors.unit_no }}</span>
                                        </div>
                                    </div>
                                </div>								
                            </div>
                            <div class="property-inner-box">
                                <div class="comman-title">
                                    <h3>Basic Information</h3>
                                </div>
                                <div class="row row-cols-1 row-cols-md-2">
                                    <div class="col">
                                        <div class="form-group">
                                            <label>Property Type</label>
                                            <select class="form-control" v-model="form.selectedPropertyType">
                                                <option value="" disabled>Select Property Type</option>
                                                <RecursiveDropdown :options="hierarchicalOptions" />
                                            </select>
                                            <span  class="error" v-if="errors.selectedPropertyType">{{ errors.selectedPropertyType }}</span>
                                        </div>
                                    </div>
                                    <div class="col">
                                        <div class="form-group">
                                            <label>Land Tenure</label>
                                            <select id="land_tenure" v-model="form.land_tenure" class="form-control">
                                                <option value="" disabled selected>Land Tenure</option>
                                                <option v-for="option in optionsLandTenuresList" :key="option.value" :value="option.value">
                                                    {{ option.label }}
                                                </option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="col">
                                        <div class="form-group">
                                            <div class="row row-cols-1 row-cols-md-2">
                                                <div class="col">
                                                    <label>Floor Area *</label>
                                                    <input type="number" min="0"  class="form-control col" v-model="form.floor_area" placeholder="Floor Area">
                                                    <span  class="error" v-if="errors.floor_area">{{ errors.floor_area }}</span>
                                                </div>
                                                <div class="col">
                                                    <label>Floor Area (SQF/SQM)*</label>
                                                    <select class="form-control col" v-model="form.floor_area_unit">
                                                        <option value="SQF">SQF</option>
                                                        <option value="SQM">SQM</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col">
                                        <div class="form-group">
                                            <div class="row row-cols-1 row-cols-md-2">
                                                <div class="col">
                                                    <label>Land Area (For Landed ONLY)</label>
                                                    <input type="number" min="0" class="form-control col" v-model="form.land_area" placeholder="Land Area">
                                                </div>
                                                <div class="col">
                                                    <label>Land Area (SQF/SQM)</label>
                                                    <select class="form-control col" v-model="form.land_area_unit">
                                                        <option value="SQF">SQF</option>
                                                        <option value="SQM">SQM</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                
                                    <!-- <div class="col">
                                        <div class="form-group">
                                            <label>Land Area (For Landed ONLY)</label>
                                            <input type="text" class="form-control"  placeholder="Land Area (For Landed ONLY)">
                                        </div>
                                    </div> -->

                                        
                                    <div class="col">
                                        <div class="form-group">
                                            <label>Land Title Type*</label>
                                            <select class="form-control col" v-model="form.land_title_type">
                                                <option value="" selected> Land Title Type</option>
                                                <option value="HDB">HDB</option>
                                                <option value="Strata">Strata</option>
                                                <option value="Landed">Landed</option>
                                            </select>
                                            <span  class="error" v-if="errors.land_title_type">{{ errors.land_title_type }}</span>
                                        </div>
                                    </div>
                                    <div class="col">
                                        <div class="form-group">
                                            <label>Developer</label>
                                            <input type="text" class="form-control" v-model="form.developer_name" placeholder="Developer">
                                        </div>
                                    </div>
                                    <div class="col">
                                        <div class="form-group">
                                            <label>Total Blocks</label>
                                            <input type="number" min="0" class="form-control" v-model="form.total_blocks" placeholder="Total Blocks">
                                        </div>
                                    </div>
                                    <div class="col">
                                        <div class="form-group">
                                            <label>Total Units</label>
                                            <input type="number" min="0" class="form-control" v-model="form.total_units" placeholder="Total Units">
                                        </div>
                                    </div>
                                    <div class="col">
                                        <div class="form-group">
                                            <label>Total carpark Lots</label>
                                            <input type="number" min="0" class="form-control" v-model="form.total_car_parks" placeholder="Total carpark Lots">
                                        </div>
                                    </div>
                                    <div class="col">
                                        <div class="form-group">
                                            <label>Year of Completion</label>
                                            <input type="number" min="0" class="form-control"   v-model="form.year_of_competion_TOP" placeholder="Year of Completion">
                                        </div>
                                    </div>
                                </div>								
                            </div>
                            <div class="property-inner-box">
                                <div class="comman-title">
                                    <h3>Configuration</h3>
                                </div>
                                <div class="row row-cols-1 row-cols-md-3">
                                    <div class="col">
                                        <div class="form-group">
                                            <label>Rooms*</label>
                                            <input type="number" min="0" class="form-control"   v-model="form.no_of_rooms"  placeholder="Rooms">											 
                                            <span  class="error" v-if="errors.no_of_rooms">{{ errors.no_of_rooms }}</span>
                                        </div>
                                    </div>
                                    <div class="col">
                                        <div class="form-group">
                                            <label>Baths*</label>
                                            <input type="number" min="0" class="form-control"   v-model="form.no_of_baths"  placeholder="Baths">	
                                            <span  class="error" v-if="errors.no_of_baths">{{ errors.no_of_baths }}</span>	 
                                        </div>
                                    </div>
                                    <div class="col">
                                        <div class="form-group">
                                            <label>Car Park Lots*</label>
                                            <input type="number" min="0" class="form-control"   v-model="form.no_car_park_lots"  placeholder="Car Park Lots">
                                            <span  class="error" v-if="errors.no_car_park_lots">{{ errors.no_car_park_lots }}</span>
                                        </div>
                                    </div>
                                    <div class="col">
                                        <div class="form-group">
                                            <label>Layout Type*</label>
                                            <select id="layout_type_id" v-model="form.layout_type_id" class="form-control">
                                                <option value="" disabled selected>Layout Type</option>
                                                <option v-for="option in optionsLayoutTypeList" :key="option.value" :value="option.value">
                                                    {{ option.label }}
                                                </option>
                                            </select>
                                            <span  class="error" v-if="errors.layout_type_id">{{ errors.layout_type_id }}</span>
                                        </div>
                                    </div> 
                                    <div class="col">
                                        <div class="form-group">
                                            <label>Floor Level*</label>
                                            <select id="floor_level_id" v-model="form.floor_level_id" class="form-control">
                                                <option value="" disabled selected>Floor Level</option>
                                                <option v-for="option in optionsFloorLevelList" :key="option.value" :value="option.value">
                                                    {{ option.label }}
                                                </option>
                                            </select>
                                            <span  class="error" v-if="errors.floor_level_id">{{ errors.floor_level_id }}</span>
                                        </div>
                                    </div>
                                    <div class="col">
                                        <div class="form-group">
                                            <label>Main View*</label>
                                            <select id="main_view_id" v-model="form.main_view_id" class="form-control">
                                                <option value="" disabled selected>Main View</option>
                                                <option v-for="option in optionsMainList" :key="option.value" :value="option.value">
                                                    {{ option.label }}
                                                </option>
                                            </select>
                                            <span  class="error" v-if="errors.main_view_id">{{ errors.main_view_id }}</span>
                                        </div>
                                    </div>
                                </div>								
                            </div>
                            <div class="d-flex justify-content-md-end">
                                <button class="btn comman-btn-6">Cancel</button>
                                <button v-if="this.itemId == 0" class="btn comman-btn-2 ms-3" type="submit">Next</button>
                                <button v-else class="btn comman-btn-2 ms-3" type="submit">Save</button>
                            </div>
                            <div class="progress-bar-box">
                                <div>Form Completion: {{ completionPercentage }}%</div>
                            <div class="progress">
                                <div 
                                    class="progress-bar" 
                                    role="progressbar" 
                                    :style="{ width: completionPercentage + '%' }" 
                                    :aria-valuenow="completionPercentage" 
                                    aria-valuemin="0" 
                                    aria-valuemax="100">
                                </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </section>
</template>
  
  <script>
  import { mapState } from 'vuex';
  import RecursiveDropdown from '@/components/RecursiveDropdown.vue';
  import { OPTIONS_GROUP_1,PROPERTY_TYPE } from '@/constants/constantsProperty';
  import toastr from 'toastr';
  import 'toastr/build/toastr.min.css';
import { CONSTANT } from '@/constants/constants';
  export default {
    name: 'PostPropertyListing', 
    components: {
      RecursiveDropdown
    },  
    data() {
        return {
            currentPage: 'step1',
            isDisabledStep2 :'disabled',
            isDisabledStep3 :'disabled',
            isDisabledStep4 :'disabled',
            isDisabledStep5 :'disabled',
            optionsGroup1: OPTIONS_GROUP_1,
            optionsLandTenuresList: [],
            optionsDistrictList: [],
            optionsLayoutTypeList: [],
            optionsFloorLevelList: [],
            optionsMainList: [],
            suggestions: [],
            suggestionsArea: [],
            suggestionsDistrict: [],
            editData: '',
            projects: [],
            
            form: {
                property_name: '',
                street: '',
                qualify_for_foreigner: '0',
                projectId: '',
                listingType: '',
                district_id: '',
                land_tenure: '',
                layout_type_id: '',
                floor_level_id: '',
                land_title_type: '',
                main_view_id: '',
                selectedPropertyType: '',
                floor_area_unit: 'SQF',
                land_area_unit: 'SQF',
            },
            options: this.transformData(PROPERTY_TYPE),
            // options: this.transformData([

            //     { id: 1,depth:'', name: 'Residential', parent_id: 0 },

            //         { id: 3,depth:'-', name: 'All HDB', parent_id: 1 },

            //             { id: 6,depth:'--', name: 'HDB Flat', parent_id: 3 },
            //             { id: 7,depth:'--', name: 'HDB Executive', parent_id: 3 },

            //         { id: 4,depth:'-', name: 'All Non-Landed (Private)', parent_id: 1 },
            //             { id: 8,depth:'--', name: 'Apartment', parent_id: 4 },
            //             { id: 9,depth:'--', name: 'Walk-Up Apartment', parent_id: 4 },
            //             { id: 10,depth:'--', name: 'Condominium', parent_id: 4 },
            //             { id: 11,depth:'--', name: 'Executive Condominium', parent_id: 4 },

            //         { id: 5,depth:'-', name: 'All Landed', parent_id: 1 },
            //             { id: 12,depth:'--', name: 'Terrace / Link House', parent_id: 5 },
            //             { id: 13,depth:'--', name: 'Town House', parent_id: 5 },
            //             { id: 14,depth:'--', name: 'Cluster House', parent_id: 5 },
            //             { id: 15,depth:'--', name: 'Conservative House', parent_id: 5 },
            //             { id: 16,depth:'--', name: 'Semi-Detached/Zero-Lot', parent_id: 5 },
            //             { id: 17,depth:'--', name: 'Detached/Bungalow', parent_id: 5 },
            //             { id: 18,depth:'--', name: 'Good Class Bungalow (GCB)', parent_id: 5 },
            //             { id: 19,depth:'--', name: 'Vacant Land', parent_id: 5 },

            //     { id: 2,depth:'', name: 'Commercial & Industrial', parent_id: 0 },

            //         { id: 20,depth:'-', name: 'All Commercial', parent_id: 2 },
            //             { id: 22,depth:'--', name: 'Retail', parent_id: 20 },
            //             { id: 23,depth:'--', name: 'Office', parent_id: 20 },
            //             { id: 24,depth:'--', name: 'Hotel / Hostel', parent_id: 20 },
            //             { id: 25,depth:'--', name: 'Shopping Mall', parent_id: 20 },
            //             { id: 26,depth:'--', name: 'Medical Suite', parent_id: 20 },
            //             { id: 27,depth:'--', name: 'ShopHouse', parent_id: 20 },
            //             { id: 28,depth:'--', name: 'HDB ShopHouse', parent_id: 20 },
            //             { id: 29,depth:'--', name: 'Vacant Land', parent_id: 20 },
            //         { id: 21,depth:'-', name: 'All Industrial', parent_id: 2 },
            //             { id: 30,depth:'--', name: 'Office', parent_id: 21 },
            //             { id: 31,depth:'--', name: 'Warehouse (B1)', parent_id: 21 },
            //             { id: 32,depth:'--', name: 'Factory (B2)', parent_id: 21 },
            //             { id: 33,depth:'--', name: 'Vacant Land', parent_id: 21 }
            // ]),
            errors: {}
        }
    },
    computed: {
      ...mapState(['isAuthenticated', 'user']),
      hierarchicalOptions() {        
        return this.createHierarchicalOptions(this.options);
      },
      itemId() {
        return this.$route.params.id;
      },
      completionPercentage() {
        const fields = [
            // this.form.projectId,
            this.form.listingType,
            this.form.property_name,
            this.form.street,
            this.form.area_hdb_estate,
            this.form.district,
            this.form.block_no,
            this.form.level_no,
            this.form.unit_no,
            this.form.land_tenure,
            this.form.floor_area,
            this.form.land_area,
            this.form.land_title_type,
            this.form.developer_name,
            this.form.total_blocks,
            this.form.total_units,
            this.form.total_car_parks,
            this.form.year_of_competion_TOP,
            this.form.no_of_rooms,
            this.form.no_of_baths,
            this.form.no_car_park_lots,
            this.form.layout_type_id,
            this.form.floor_level_id,
            this.form.main_view_id,
        ];
        const filledFields = fields.filter(field => field != null && field !== "").length;
        return Math.round((filledFields / fields.length) * 100);
      }
    },
    created() {
        this.fetchOptions();
        if(this.itemId > 0){
            this.isDisabledStep2 = '';
            this.isDisabledStep3 = '';
            this.isDisabledStep4 = '';
            this.isDisabledStep5 = '';
        }
    },
    watch: {
        'form.property_name': function (newValue) {
            if (newValue.length >= 3) {
                this.fetchPropertyDetails(newValue);
            }
        },
        // Automatically save the completion percentage whenever it changes
        completionPercentage() {
            this.saveCompletion();
        },
    },
    methods: {      
        savePageCompletion(page, percentage) {
            // Save the page completion percentage to local storage
            const completionData = JSON.parse(localStorage.getItem('formCompletionData')) || {};
            completionData[page] = percentage;
            localStorage.setItem('formCompletionData', JSON.stringify(completionData));
        },

        saveCompletion() {
            // Save the current page's completion percentage
            const percentage = this.completionPercentage;
            this.savePageCompletion('page1', percentage);
        },

        validateForm() {
            this.errors = {};

            if (this.user.is_corporate) { 
                if (!this.form.projectId) {
                    this.errors.projectId = 'Project is required.';
                }
            }

            if (!this.form.listingType ) {
                this.errors.listingType = 'Listing Type is required.';
            }
            if (!this.form.property_name ) {
                this.errors.property_name = 'Property Name is required.';
            }
            if (!this.form.street ) {
                this.errors.street = 'Street is required.';
            }
            if (!this.form.area_hdb_estate ) {
                this.errors.area_hdb_estate = 'Area / HDB Estate.';
            }
            if (!this.form.district_id ) {
                this.errors.district_id = 'District is required.';
            }
            if (!this.form.district ) {
                this.errors.district = 'District is required.';
            }
            // if (!this.form.block_no ) {
            //     this.errors.block_no = 'Block no is required.';
            // }
            if (!this.form.floor_area ) {
                this.errors.floor_area = 'Floor Area is required.';
            }
            if (!this.form.level_no ) {
                this.errors.level_no = 'Level No is required.';
            }
            if (!this.form.land_title_type ) {
                this.errors.land_title_type = 'Land Title Type is required.';
            }
            
            if (!this.form.no_of_baths ) {
                this.errors.no_of_baths = 'Baths is required.';
            }
            if (!this.form.no_of_rooms ) {
                this.errors.no_of_rooms = 'Rooms is required.';
            }
            if (!this.form.no_car_park_lots ) {
                this.errors.no_car_park_lots = 'Car Park Lots is required.';
            }
            if (!this.form.floor_level_id ) {
                this.errors.floor_level_id = 'Floor Level is required.';
            }
            if (!this.form.main_view_id ) {
                this.errors.main_view_id = 'Main View is required.';
            }
            if (!this.form.layout_type_id ) {
                this.errors.layout_type_id = 'Layout Type is required.';
            }

            if (!this.form.unit_no ) {
                this.errors.unit_no = 'Unit No is required.';
            }
            if (!this.form.selectedPropertyType ) {
                this.errors.selectedPropertyType = 'Property type is required.';
            }
            return Object.keys(this.errors).length === 0;
        },        
        async submitPost() {
            
            if (this.validateForm()) {
                var qualify_for_foreigner = this.form.qualify_for_foreigner == true ? 1 : 0
                const token = localStorage.getItem('userToken');
                if (this.loading) return;
                this.loading = true;
                try {
                    var property_listing_id_edit = 0;
                    if(this.itemId > 0){
                        property_listing_id_edit = this.itemId;
                    }
                    const response = await this.$axios.post('api/add-update-property', {
                        user_id :this.user.id,
                        api_token: token,
                        property_listing_id:property_listing_id_edit,
                        country_id: 93, 
                        project_listing_id: this.form.projectId ?? 0,
                        listing_type: this.form.listingType,
                        qualify_for_foreigner:qualify_for_foreigner,
                        property_name:this.form.property_name,
                        street:this.form.street,
                        area_hdb_estate:this.form.area_hdb_estate,
                        district:this.form.district,
                        district_id:this.form.district_id,
                        block_no:this.form.block_no,
                        level_no:this.form.level_no,
                        unit_no:this.form.unit_no,
                        property_type:this.form.selectedPropertyType,
                        land_tenure:this.form.land_tenure,
                        floor_area:this.form.floor_area,
                        floor_area_unit:this.form.floor_area_unit,
                        land_area:this.form.land_area ? this.form.land_area : 0,
                        land_area_unit:this.form.land_area_unit,
                        land_title_type:this.form.land_title_type,
                        developer_name:this.form.developer_name ? this.form.developer_name : '',
                        total_blocks:this.form.total_blocks ? this.form.total_blocks : 0,
                        total_units:this.form.total_units ? this.form.total_units : 0,
                        total_car_parks:this.form.total_car_parks ? this.form.total_car_parks : 0,
                        year_of_competion_TOP:this.form.year_of_competion_TOP ? this.form.year_of_competion_TOP : 0,
                        no_of_rooms:this.form.no_of_rooms,
                        no_of_baths:this.form.no_of_baths,
                        no_car_park_lots:this.form.no_car_park_lots,
                        layout_type_id:this.form.layout_type_id,
                        floor_level_id:this.form.floor_level_id,
                        main_view_id:this.form.main_view_id
                    });
                    
                    toastr.success(response.data.message, 'Success', { timeOut: 3000 });

                    const property_listing_id = response.data.output.id
                    this.$router.push('/post-property-listing-2/' + property_listing_id);

                } catch (error) {
                    
                    if (error.response && error.response.data) {
                        this.errors = this.extractErrors(error.response.data.errors || {});
                    } else {
                        console.error('An unexpected error occurred:', error);
                    }
                } finally {
                    this.loading = false;
                }
            }
        },

        async fetchPropertyDetails(propertyName) {
            try {
                const token = localStorage.getItem('userToken');
                
                // Make an API request using axios
                const response = await this.$axios.post('/api/property-details', {
                    user_id :this.user.id,
                    api_token: token,
                    property_name: propertyName,
                }, {
                });

                // Handle the response
                if (response.data.success) {
                    this.form.street = response.data.data.street || '';
                    this.form.area_hdb_estate = response.data.data.area_hdb_estate || '';
                    this.form.district_id = response.data.data.district_id || '';
                    this.form.district = response.data.data.district || '';
                } else {
                    this.clearAddressFields();
                }
            } catch (error) {
                // Handle errors
                this.clearAddressFields();
                console.error(error.response ? error.response.data.message : error.message);
            }
        },

        clearAddressFields() {
            this.form.street = '';
            this.form.area_hdb_estate = '';
            this.form.district_id = '';
            this.form.district = '';
        },

        async fetchSuggestions() {
            if (this.form.street.length < 2) {
                this.suggestions = []; // Clear suggestions if query is too short
                return;
            }
            try {
                const response = await this.$axios.post('api/location',
                {                    
                    type : "Locality",
                    keyword : this.street, 
                    parent_id: CONSTANT.COUNTRY_CODE
                });
                this.suggestions = response.data.output.map(item => item.name).filter(name => name.toLowerCase().includes(this.form.street.toLowerCase()));

            } catch (error) {
                console.error('Error fetching suggestions:', error);
            }
        },
        
        async fetchSuggestionsArea() {
            if (this.form.area_hdb_estate.length < 2) {
                this.suggestionsArea = []; // Clear suggestions if query is too short
                return;
            }
            try {
                const response = await this.$axios.post('api/location',
                {                    
                    type : "Locality",
                    keyword : this.area_hdb_estate, 
                    parent_id: CONSTANT.COUNTRY_CODE
                });
                this.suggestionsArea = response.data.output.map(item => item.name).filter(name => name.toLowerCase().includes(this.form.area_hdb_estate.toLowerCase()));

            } catch (error) {
                console.error('Error fetching suggestions:', error);
            }
        },
        async fetchSuggestionsDistrict() {
            if (this.form.district.length < 2) {
                this.suggestionsDistrict = []; // Clear suggestions if query is too short
                return;
            }
            try {
                const response = await this.$axios.post('api/location',
                {                    
                    type : "City",
                    keyword : this.district, 
                    parent_id: CONSTANT.COUNTRY_CODE
                });
                // this.suggestionsDistrict = response.data.output.map(item => item.name).filter(name => name.toLowerCase().includes(this.form.district.toLowerCase()));
                this.suggestionsDistrict = response.data.output.map(item => ({
                value: item.id,
                label: item.name
                }));

            } catch (error) {
                console.error('Error fetching suggestions:', error);
            }
        },
        selectSuggestion(suggestion) {
            this.form.street = suggestion;
            this.suggestions = [];
        },
        selectSuggestionArea(suggestion) {
            this.form.area_hdb_estate = suggestion;
            this.suggestionsArea = [];
        },
        selectSuggestionDistrict(suggestion) {
            
            this.form.district = suggestion.label;
            this.form.district_id = suggestion.value;
            this.suggestionsDistrict = [];
        },

        extractErrors(errors) {
            // Extract the first error message from each field's errors array
            const formattedErrors = {};
            for (const [field, messages] of Object.entries(errors)) {
                if (Array.isArray(messages) && messages.length > 0) {
                formattedErrors[field] = messages[0]; // Get the first error message
                }
            }
            return formattedErrors;
        },  

        transformData(data) {
            const map = {};
            const result = [];
    
            data.forEach(item => {
            map[item.id] = { ...item, children: [] };
            });
    
            data.forEach(item => {
            if (item.parent_id === 0) {
                result.push(map[item.id]);
            } else {
                map[item.parent_id].children.push(map[item.id]);
            }
            });
    
            return result;
        },

        createHierarchicalOptions(data) {
            return data.map(option => this.createOptionGroup(option));
        },

        createOptionGroup(option) {

            if (!option.children || option.children.length === 0) {
                return {
                    type: 'option',
                    id: option.id,
                    label: option.name,
                    depth: option.depth,
                };
            }
            return {
                type: 'optgroup',
                label: option.name,
                depth: option.depth,
                children: option.children.map(child => this.createOptionGroup(child))
            };

        },

        async fetchDistricts() {
            if (!this.form.country) {
                return;
            }

            // Set country code based on the selected country
            if (this.form.country === 'singapore') {
                this.form.countryCode = '93'; // Singapore location_id
            } else if (this.form.country === 'malaysia') {
                this.form.countryCode = '7'; // Malaysia location_id
            }

            // Fetch the districts after country selection
            try {
                const response = await this.$axios.post('api/location', {
                type: 'City', 
                parent_id: this.form.countryCode,
                });

                this.optionsDistrictList = response.data.output.map(item => ({
                    value: item.id,
                    label: item.name,
                }));

            } catch (error) {
                console.error('Error fetching districts:', error);
            }

        },

        changeDistrict() {
            const selectedValue = this.form.district_id; // Get the value from v-model
            const selectedOption = this.optionsDistrictList.find(option => option.value === selectedValue);
            
            if (selectedOption) {
                const selectedLabel = selectedOption.label;
                this.form.district = selectedLabel; // Set the district name to the form field
            }
        },

        async fetchOptions() {

            const token = localStorage.getItem('userToken');

             // Get District list
             try {
                const response = await this.$axios.post('api/location',
                {                    
                    type : "City",
                    keyword : this.street, 
                    parent_id: CONSTANT.COUNTRY_CODE                   
                });
                this.optionsDistrictList = response.data.output.map(item => ({
                    value: item.id,
                    label: item.name
                }));
            } catch (error) {
                console.error('Error fetching options:', error);
            }

            // Get Land Tenure list
            try {
                const response = await this.$axios.post('api/pr-land-tenures-list',
                {                    
                    parent_id : "0",
                    keyword : "", 
                    show_in_project: 0,
                    show_in_property:1,
                    last_record_number : 0                     
                });
                this.optionsLandTenuresList = response.data.output.map(item => ({
                value: item.id,
                label: item.name
                }));
            } catch (error) {
                console.error('Error fetching options:', error);
            }
            
            // Get Layout type list
            try {
                const response = await this.$axios.post('api/pr-layout-features-list',
                {                    
                    parent_id : "0",
                    keyword : "", 
                    show_in_project: 0,
                    show_in_property:1,
                    last_record_number : 0                     
                });
                this.optionsLayoutTypeList = response.data.output.map(item => ({
                value: item.id,
                label: item.name
                }));
            } catch (error) {
                console.error('Error fetching options:', error);
            }
            
            // Get Floor level
            try {
                const response = await this.$axios.post('api/pr-floor-levels-list',
                {                    
                    parent_id : "0",
                    keyword : "", 
                    show_in_project: 1,
                    show_in_property:0,
                    last_record_number : 0                     
                });
                this.optionsFloorLevelList = response.data.output.map(item => ({
                value: item.id,
                label: item.name
                }));
            } catch (error) {
                console.error('Error fetching options:', error);
            }
            
            // Get Main View
            try {
                const response = await this.$axios.post('api/pr-main-view-list',
                {                    
                    parent_id : "0",
                    keyword : "", 
                    show_in_project: 0,
                    show_in_property:1,
                    last_record_number : 0                     
                });
                this.optionsMainList = response.data.output.map(item => ({
                    value: item.id,
                    label: item.name
                }));
            } catch (error) {
                console.error('Error fetching options:', error);
            }

            try {
                const response = await this.$axios.get('api/projects', {
                    params: {
                        user_id: this.user.id,
                        api_token: token,
                    }
                });
                this.projects = response.data.output;
            } catch (error) {
                console.error('Error fetching projects:', error);
            }

            if(this.itemId > 0){
                
                try {
                    const response = await this.$axios.post('api/property-detail',
                    {                    
                        user_id :this.user.id,
                        api_token: token,
                        property_listing_id:this.itemId,                    
                    });
                    this.editData = response.data.output[0];
                    this.form.projectId = this.editData.project_listing_id;
                    this.form.listingType = this.editData.listing_type;
                    this.form.qualify_for_foreigner = this.editData.qualify_for_foreigner == 1 ? true : false;
                    this.form.property_name = this.editData.property_name;
                    this.form.street = this.editData.street;
                    this.form.selectedPropertyType = this.editData.property_type;
                    this.form.area_hdb_estate = this.editData.area_hdb_estate;
                    this.form.district = this.editData.district;
                    this.form.district_id = this.editData.district_id;
                    this.form.block_no = this.editData.block_no;
                    this.form.level_no = this.editData.level_no;
                    this.form.unit_no = this.editData.unit_no;
                    this.form.land_tenure = this.editData.land_tenure == null ? '' : this.editData.land_tenure ;
                    this.form.floor_area = this.editData.floor_area;
                    this.form.land_area = this.editData.land_area;
                    this.form.land_area_unit = this.editData.land_area_unit;
                    this.form.land_title_type = this.editData.land_title_type;
                    this.form.developer_name = this.editData.developer_name;
                    this.form.total_blocks = this.editData.total_blocks;
                    this.form.total_units = this.editData.total_units;
                    this.form.total_car_parks = this.editData.total_car_parks;
                    this.form.year_of_competion_TOP = this.editData.year_of_competion_TOP;
                    this.form.no_of_rooms = this.editData.no_of_rooms;
                    this.form.no_of_baths = this.editData.no_of_baths;
                    this.form.no_car_park_lots = this.editData.no_car_park_lots;
                    this.form.layout_type_id = this.editData.layout_type_id;
                    this.form.floor_level_id = this.editData.floor_level_id;
                    this.form.main_view_id = this.editData.main_view_id;
                } catch (error) {
                    console.error('Error fetching options:', error);
                }
            }
        }
    }
  }
  </script>
  
  <style scoped>
    .sugesstion_ul {
        list-style-type: none;
        padding: 0;
        margin: 0;
        border: 1px solid #ddd;
        position: absolute;
        background: #fff;
    }
    .sugesstion_ul li {
        cursor: pointer;
        padding: 8px;
    }
    .sugesstion_ul li:hover {
        background-color: #f0f0f0;
    }
  </style>
  
<template>
    <section class="signin-sec">
        <div class="container">
            <div class="step-process-outer">
                <ol class="d-flex justify-content-center">
                    <li>
                        <figure>
                            <img src="frontend/images/signup-step-ico1.svg">
                        </figure>	
                        <p class="mb-0">Just register for FREE Sign-Up & start listing your properties.</p>
                    </li>
                    <li>
                        <figure>
                            <img src="frontend/images/signup-step-ico2.svg">
                        </figure>	
                        <p class="mb-0">Decide on options to upgrade & select packages according to your needs.</p>
                    </li>
                    <li>
                        <figure>
                            <img src="frontend/images/signup-step-ico3.svg">
                        </figure>	
                        <p class="mb-0">Complete your profile & payment to access exclusive tools & privileges.</p>
                    </li>
                </ol>
            </div>
            <div class="row">
                <div class="col-lg-8 col-md-6 d-md-flex align-items-stretch">
                    <div class="private-why-outr">
                        <div class="comman-title">
                            <h3>Why Sign Up As Private User?</h3>
                            <p>Private User (Property Owner-Landlord / Buyer & Renter-Tenant)</p>
                        </div>
                        <ol>
                            <li>
                                <div class="">
                                    <h4>Own Your Listing, Amplify Its Reach;</h4>
                                    <p>You list the property, own it like your story, take advantage of our features to connect at a deeper level with
                                        potential buyers or renters, or empower estate agents to reach a wider audience.</p>
                                </div>
                            </li>
                            <li>
                                <div class="">
                                    <h4>Maintain Control & Flexibility;</h4>
                                    <p>View the listing updates yourself, decide on the level of involvement that suits you- DIY to save on
                                        commissions but let estate agents handle all the heavy-lifting in a supporting role.</p>
                                </div>
                            </li>
                            <li>
                                <div class="">
                                    <h4>Expert Marketing, Local Insights;</h4>
                                    <p>Partner with qualified estate agents to benefit from their client network, marketing expertise, negotiation
                                        skills, local knowledge and maximize prospective offers.</p>
                                </div>
                            </li>
                        </ol>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 d-md-flex align-items-stretch">
                    <div class="signin-bx full-width">
                        <h1>Sign up</h1>
                        <form @submit.prevent="register">
                            <div class="form-group">
                                <input v-model="form.name" id="name" type="text" class="form-control" placeholder="Name"/>
                                <span class="error" v-if="errors.name">{{ errors.name }}</span>
                            </div>
                            <div class="form-group">
                                <input v-model="form.email" id="email" type="text" class="form-control" placeholder="Email address"/>
                                <span class="error" v-if="errors.email">{{ errors.email }}</span>
                            </div>
                            <div class="form-group">
                                <input v-model="form.mobile_number" id="mobile_number" type="text" class="form-control" placeholder="Phone number with country code (No Zero)"/>
                                <span class="error" v-if="errors.mobile_number">{{ errors.mobile_number }}</span>
                            </div>
                            <div class="form-check form-group">
                                <input class="form-check-input" type="checkbox" value="" id="otr-foen-ta-1">
                                <label class="form-check-label w-100" for="otr-foen-ta-1">
                                    By continuing, I agree to PROPFACE's <br><a href="/terms-and-conditions">Terms of Use</a> & <a href="/privacy-policy">Privacy Policy.</a>
                                </label>
                            </div>
                            <div class="form-group">
                                <button type="submit" class="btn comman-btn-2 w-100">Sign Up</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import { mapActions } from 'vuex';
import toastr from "toastr";

export default {
    name: 'SignUp',
    data() {
        return {
            form: {
                name: '',
                email: '',
                mobile_number: '',
                password: '',
                confirmPassword: '',
            },
            errors: {},
            googleIdToken: localStorage.getItem('googleIdToken'),
            facebookAccessToken: localStorage.getItem('facebookAccessToken'),
        };
    },
    computed: {
        ...mapActions(['setAuthenticated', 'setUser']),
        isFormValid() {
            return !this.emailError && !this.passwordError && this.email && this.password;
        },
    },
    methods: {
        validateForm() {
            this.errors = {};

            if (!this.form.name) {
                this.errors.name = 'Name is required.';
            }

            if (!this.form.mobile_number) {
                this.errors.mobile_number = 'Phone number is required.';
            }

            if (!this.form.email) {
                this.errors.email = 'Email is required.';
            } else if (!this.validEmail(this.form.email)) {
                this.errors.email = 'Invalid email address.';
            }

            return Object.keys(this.errors).length === 0;
        },
        validEmail(email) {
            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            return emailRegex.test(email);
        },
        extractErrors(errors) {
            const formattedErrors = {};
            for (const [field, messages] of Object.entries(errors)) {
                if (Array.isArray(messages) && messages.length > 0) {
                    formattedErrors[field] = messages[0]; // Get the first error message
                }
            }
            return formattedErrors;
        },
        async register() {
            if (this.validateForm()) {
                if (this.emailError) {
                    return;
                }

                try {
                    let registerAPI = '';
                    let loginAPI = '';

                    if (this.googleIdToken) {
                        registerAPI = 'api/register-google';
                        loginAPI = 'api/login-google';
                    }

                    if (this.facebookAccessToken) {
                        registerAPI = 'api/register-facebook';
                        loginAPI = 'api/login-facebook';
                    }

                    if (!registerAPI) {
                        return;
                    }

                    const response = await this.$axios.post(registerAPI, {
                        facebook_access_token: this.facebookAccessToken,
                        google_id_token: this.googleIdToken,
                        name: this.form.name,
                        email: this.form.email,
                        mobile_number: this.form.mobile_number,
                        is_private_user: '1',
                    });

                    toastr.success(response.data.message, 'Success', { timeOut: 3000 });

                    const response_login = await this.$axios.post(loginAPI, {
                        facebook_access_token: this.facebookAccessToken,
                        google_id_token: this.googleIdToken,
                    });

                    await this.$store.dispatch('login', response_login.data);
                    await this.sendCode();
                    this.$router.push('/verify-number');
                } catch (error) {
                    if (error.response && error.response.data) {
                        this.errors = this.extractErrors(error.response.data.errors || {});
                    } else {
                        console.error('An unexpected error occurred:', error);
                    }
                } finally {
                    this.loading = false;
                }
            }
        },
        async sendCode() {
            try {
                await this.$axios.post('api/code/send', {
                    mobile_number: this.form.mobile_number,
                });
            } catch (error) {
                console.error('Error sending code:', error);
            }
        },
    }
}
</script>

import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';  // Import your Vuex store
import axios from './axios'; // Import your configured  Axios instance
import JsonExcel from 'vue-json-excel3';

import Swal from 'sweetalert2';
import {createHead, VueHeadMixin} from "@unhead/vue";
window.Swal = Swal;
window.FRONTEND_URL = process.env.VUE_APP_FRONTEND_URL || 'https://pv-frontend.tess.gg';
window.STRIPE_KEY = process.env.VUE_APP_STRIPE_KEY || '';
window.STRIPE_SECRET_KEY = process.env.VUE_APP_STRIPE_SECRET_KEY || '';
// import ToastContainer from 'vue3-toastify'
// import 'vue3-toastify/dist/index.css' // Import the default styles

// Use the Vuex store
// app.use(store);

// // Use the router
// app.use(router);

// // Mount the application
// app.mount('#app');


//import { createPinia } from 'pinia';


axios.defaults.baseURL = process.env.VUE_APP_BACKEND_URL || 'https://pv-staging.tess.gg/public';
axios.defaults.headers.common['Accept'] = `application/json`;
axios.defaults.headers.common['Content-Type'] = `application/json`;

// Function to get the token
async function getToken() {
    const isAuthenticated = localStorage.getItem('userToken');
    const storedToken = localStorage.getItem('access_token');
    const tokenExpiration = localStorage.getItem('token_expiration');
    const now = new Date().getTime();

    // Check if the token exists and hasn't expired
    if (isAuthenticated && storedToken && tokenExpiration && now < tokenExpiration) {
        // Token is still valid, return it
        return storedToken;
    } else {
        // Token is expired or doesn't exist, request a new one
        const tokenData = {   
            grant_type: 'client_credentials',
            client_id: '1',
            client_secret: 'czI1IMgcxFLjD0JzwJWzIutDC8FB3NBnA0OVrORA',
        };

        try {
            const response = await axios.post('/oauth/token', tokenData);
            const accessToken = response.data.access_token;
            const expiresIn = response.data.expires_in;

            // Calculate the expiration time (current time + expires_in in seconds)
            const expirationTime = now + expiresIn * 1000;

            // Store the new token and expiration time
            localStorage.setItem('access_token', accessToken);
            localStorage.setItem('token_expiration', expirationTime);

            return accessToken;
        } catch (error) {
            console.error('Error obtaining token:', error);
            return null; // Handle this as needed (e.g., redirect to login)
        }
    }
}

// Initialize the app
async function initApp() {
    const accessToken = await getToken();

    if (accessToken) {
        axios.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;
        
    } else {
        console.error('Failed to set authorization header');
        // Handle error (e.g., redirect to login)
    }

    const app = createApp(App);
    const head =createHead();
    app.mixin(VueHeadMixin)
    app.use(head);

    // Make Axios available globally
    app.config.globalProperties.$axios = axios;

    app.component("downloadExcel", JsonExcel);
    //app.use(createPinia());
    app.use(store);
    app.use(router);
    // app.use(ToastContainer,{
    //     "theme": "auto", //colored
    //   })

    app.mount('#app');
}

initApp();


// Toast Example
// toast.success('This is a success message!', {
//     position: toast.POSITION.TOP_RIGHT,
//     autoClose: 5000,
//     hideProgressBar: false,
//     closeOnClick: true,
//     pauseOnHover: true,
//     draggable: true,
//     progress: undefined,
// })
// toast.loading('This is a success message!', {
//     position: toast.POSITION.TOP_RIGHT,
//     autoClose: 5000,
//     hideProgressBar: false,
//     closeOnClick: true,
//     pauseOnHover: true,
//     draggable: true,
//     progress: undefined,
// })

// toastr.success('Your action was successful!', 'Success', {
//     closeButton: true,
//     debug: false,
//     progressBar: true,
//     positionClass: 'toast-top-right',
//     onclick: null,
//     showDuration: '300',
//     hideDuration: '1000',
//     timeOut: '5000',
//     extendedTimeOut: '1000',
//     showEasing: 'swing',
//     hideEasing: 'linear',
//     showMethod: 'fadeIn',
//     hideMethod: 'fadeOut'
// });
<template>
    <div class="accordion custom-accordion" id="accordion-one">
        <div class="accordion-item">
            <div class="accordion-header" id="heading1">
                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                    data-bs-target="#key-information" aria-expanded="true" aria-controls="key-information">
                    Key Information
                </button>
            </div>
            <div id="key-information" class="accordion-collapse collapse" aria-labelledby="heading1">
                <div class="accordion-body">
                    <div class="row row-cols-1 row-cols-sm-4">
                        <div class="col">
                            <div class="light-info-box">
                                <span>Listing type</span>
                                <h5>{{ propertyDetail?.listing_type_details?.name || '-' }}</h5>
                            </div>
                        </div>
                        <div class="col">
                            <div class="light-info-box">
                                <span>Property Address</span>
                                <h5>{{propertyDetail.district}}</h5>
                            </div>
                        </div>
                        <div class="col">
                            <div class="light-info-box">
                                <span>Street</span>
                                <h5>{{propertyDetail.street}}</h5>
                            </div>
                        </div>
                        <div class="col">
                            <div class="light-info-box">
                                <span>Area / HDB Estate</span>
                                <h5>{{propertyDetail.area_hdb_estate}} SQF</h5>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="accordion-item">
            <div class="accordion-header" id="heading2">
                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                    data-bs-target="#basic-information" aria-expanded="true" aria-controls="basic-information">
                    Basic Information
                </button>
            </div>
            <div id="basic-information" class="accordion-collapse collapse" aria-labelledby="heading2">
                <div class="accordion-body">
                    <div class="row row-cols-1 row-cols-sm-4">
                        <div class="col">
                            <div class="light-info-box">
                                <span>Property Type</span>
                                <h5>{{ propertyDetail?.property_type_details?.name || '-' }}</h5>
                            </div>
                        </div>
                        <div class="col">
                            <div class="light-info-box">
                                <span>Land Tenure</span>
                                <h5>{{propertyDetail.land_tenure}}</h5>
                            </div>
                        </div>
                        <div class="col">
                            <div class="light-info-box">
                                <span>Floor Area ({{propertyDetail.floor_area_unit}})</span>
                                <h5>{{propertyDetail.floor_area}}</h5>
                            </div>
                        </div>
                        <div class="col">
                            <div class="light-info-box">
                                <span>Land Area</span>
                                <h5>{{propertyDetail.land_area}} {{propertyDetail.land_area_unit}}
                                </h5>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="accordion-item">
            <div class="accordion-header" id="heading3">
                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                    data-bs-target="#configuration" aria-expanded="true" aria-controls="configuration">
                    Configuration
                </button>
            </div>
            <div id="configuration" class="accordion-collapse collapse" aria-labelledby="heading3">
                <div class="accordion-body">
                    <div class="row row-cols-1 row-cols-sm-4">
                        <div class="col">
                            <div class="light-info-box text-center">
                                <img src="frontend/images/room-icon.svg" alt="">
                                <span class="d-inline-block w-100">Rooms</span>
                                <h5>{{propertyDetail.no_of_rooms}}</h5>
                            </div>
                        </div>
                        <div class="col">
                            <div class="light-info-box text-center">
                                <img src="frontend/images/bathrooms-icon.svg" alt="">
                                <span class="d-inline-block w-100">Bathrooms</span>
                                <h5>{{propertyDetail.no_of_baths}}</h5>
                            </div>
                        </div>
                        <div class="col">
                            <div class="light-info-box text-center">
                                <img src="frontend/images/carpark-lots-icon.svg" alt="">
                                <span class="d-inline-block w-100">Carpark lots</span>
                                <h5>{{propertyDetail.no_car_park_lots}}</h5>
                            </div>
                        </div>
                        <div class="col">
                            <div class="light-info-box text-center">
                                <img src="frontend/images/unit-type-icon.svg" alt="">
                                <span class="d-inline-block w-100">Unit Type</span>
                                <h5>{{propertyDetail.unit_no || '-' }}</h5>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="accordion-item">
            <div class="accordion-header" id="heading4">
                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                    data-bs-target="#property-details" aria-expanded="true" aria-controls="property-details">
                    Property Details
                </button>
            </div>
            <div id="property-details" class="accordion-collapse collapse" aria-labelledby="heading4">
                <div class="accordion-body">
                    <div class="accordion custom-accordion-2" id="accordion-two">
                        <div class="accordion-item">
                            <div class="accordion-header" id="heading-1">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#key-features" aria-expanded="true" aria-controls="key-features">
                                    Key features
                                </button>
                            </div>
                            <div id="key-features" class="accordion-collapse collapse show" aria-labelledby="heading-1"
                                data-bs-parent="#accordion-two">
                                <div class="accordion-body">
                                    <ul class="key-features-green-check">
                                        <li v-for="option in featuresList" :key="option.pr_master_feature_id"
                                            :value="option.pr_master_feature_id">
                                            <img src="frontend/images/green-check.svg" alt="">
                                            {{option.additional_feature_name}}
                                        </li>
                                        <li v-for="option in add_featuresList" :key="option.pr_master_feature_id"
                                            :value="option.pr_master_feature_id">
                                            <img src="frontend/images/green-check.svg" alt="">
                                            {{option.additional_feature_name}}
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="accordion-item">
                            <div class="accordion-header" id="heading-1">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#facilities" aria-expanded="true" aria-controls="facilities">
                                    Facilities
                                </button>
                            </div>
                            <div id="facilities" class="accordion-collapse collapse" aria-labelledby="heading-1"
                                data-bs-parent="#accordion-two">
                                <div class="accordion-body">
                                    <div class="row row-cols-1 row-cols-sm-4">

                                        <div class="col" v-for="option in facilitiyList"
                                            :key="option.pr_master_facilty_id" :value="option.pr_master_facilty_id">
                                            <div class="light-info-box text-center">
                                                <img src="frontend/images/ac-icon.svg" alt="">
                                                <h5>{{option.additional_facility_name}}</h5>
                                            </div>
                                        </div>
                                        <div class="col" v-for="option in add_facilitiyList"
                                            :key="option.pr_master_feature_id" :value="option.pr_master_feature_id">
                                            <div class="light-info-box text-center">
                                                <img src="frontend/images/ac-icon.svg" alt="">
                                                <h5>{{option.additional_facility_name}}</h5>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="accordion-item">
            <div class="accordion-header" id="heading5">
                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                    data-bs-target="#property-status" aria-expanded="true" aria-controls="property-status">
                    Property Status
                </button>
            </div>
            <div id="property-status" class="accordion-collapse collapse" aria-labelledby="heading5">
                <div class="accordion-body">
                    <div class="row row-cols-1 row-cols-sm-4 flex">
                        <div class="col mb-3">
                            <div class="light-info-box">
                                <span class="d-inline-block w-100">Current Occupancy</span>
                                <h5>{{propertyDetail.current_occupancy || '-'}}</h5>
                            </div>
                        </div>
                        <div class="col mb-3">
                            <div class="light-info-box">
                                <span class="d-inline-block w-100">Current Mthly Rental Rate</span>
                                <h5>{{propertyDetail.current_tenancy_rental_rate || '-'}}</h5>
                            </div>
                        </div>
                        <div class="col mb-3">
                            <div class="light-info-box">
                                <span class="d-inline-block w-100">Current Tenancy Expiry</span>
                                <h5>{{propertyDetail.current_tenancy_expiration_date || '-'}}</h5>
                            </div>
                        </div>
                        <div class="col mb-3">
                            <div class="light-info-box">
                                <span class="d-inline-block w-100">Availability Date</span>
                                <h5>{{formatDate(propertyDetail.availability_date)}}</h5>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="accordion-item">
            <div class="accordion-header" id="heading6">
                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                    data-bs-target="#pricing-terms" aria-expanded="true" aria-controls="pricing-terms">
                    Pricing Terms & Payment Options
                </button>
            </div>
            <div id="pricing-terms" class="accordion-collapse collapse" aria-labelledby="heading6">
                <div class="accordion-body">
                    <div class="light-info-box">
                        <span class="d-inline-block w-100">Listing Price</span>
                        <h5>S${{ formatPrice(propertyDetail.asking_price_sale_rent) }} 
                            <small>($ {{computedSQF(propertyDetail)}}/SQF)</small>
                        </h5>
                    </div>
                    <div class="comman-title mt-4">
                        <h4>Payment Mode Acceptance</h4>
                    </div>
                    <div class="property-meta-se mb-0">
                        <ul v-if="Array.isArray(mode_of_payment)">
                            <li v-for="option in mode_of_payment" :key="option">
                                <span>{{option}}</span>
                            </li>
                        </ul>
                        <span v-else>{{ mode_of_payment.replace(/\|/g, ', ') }}</span>
                    </div>
                    <div id="property-payment-mode" class="row row-cols-1 row-cols-sm-4 flex">
                        <div class="col mb-3">
                            <div class="light-info-box">
                                <span class="d-inline-block w-100">Rental Period / Preferred Tenancy
                                    (If Applicable)</span>
                                <h5>{{ rentalPeriodLabel }}
                                </h5>
                            </div>
                        </div>
                        <div class="col mb-3">
                            <div class="light-info-box">
                                <span class="d-inline-block w-100">Security Deposit</span>
                                <h5>{{formatPrice(propertyDetail.rental_security_deposit)}}</h5>
                            </div>
                        </div>
                        <div class="col mb-3">
                            <div class="light-info-box">
                                <span class="d-inline-block w-100">Utility Deposit</span>
                                <h5>{{formatPrice(propertyDetail.rental_utility_deposit)}}</h5>
                            </div>
                        </div>
                        <div class="col mb-3">
                            <div class="light-info-box">
                                <span class="d-inline-block w-100">Lump-Sum Pre-Pay Rent</span>
                                <h5>{{formatPrice(propertyDetail.rental_lumpsum_pre_pay_in_months_rent)}}</h5>
                            </div>
                        </div>
                        <div class="col mb-3">
                            <div class="light-info-box">
                                <span class="d-inline-block w-100">Proposed Pre-Pay Rebate</span>
                                <h5>{{propertyDetail.rental_proposed_pre_pay_rebate_in_percentage}}
                                </h5>
                            </div>
                        </div>
                        <div class="col mb-3">
                            <div class="light-info-box">
                                <span class="d-inline-block w-100">Diversity Friendly</span>
                                <h5 v-if="propertyDetail.rental_diversity_friendly">All renters are
                                    welcome regardless of race, ethnicity, religion, age, gender
                                    identity, sexual orientation or physical ability.</h5>
                                <h5 v-if="!propertyDetail.rental_diversity_friendly">NO</h5>
                            </div>
                        </div>
                        <div class="col mb-3">
                            <div class="light-info-box">
                                <span class="d-inline-block w-100">Pet Policy</span>
                                <h5 v-if="propertyDetail.rental_pet_allowed == 1">Pets Allowed</h5>
                                <h5 v-else-if="propertyDetail.rental_pet_allowed == 2">Pets Not-Allowed </h5>
                                <h5 v-else>-</h5>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="accordion-item">
            <div class="accordion-header" id="heading7">
                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                    data-bs-target="#Hybrid-Listing" aria-expanded="true" aria-controls="Hybrid-Listing">
                    Hybrid-Listing
                </button>
            </div>
            <div id="Hybrid-Listing" class="accordion-collapse collapse" aria-labelledby="heading7">
                <div class="accordion-body">
                    <div class="row align-items-center">
                        <div class="col-4">
                            <div class="light-info-box ">
                                <span class="d-inline-block w-100 mt-3"
                                    v-if="propertyDetail.hybrid_listing_type==1">Attend To Inquiry
                                    By Myself</span>
                                <span class="d-inline-block w-100 mt-3"
                                    v-if="propertyDetail.hybrid_listing_type==2">Assign To
                                    Recommended Estate Agent</span>
                                <span class="d-inline-block w-100 mt-3"
                                    v-if="propertyDetail.hybrid_listing_type==3">Find My
                                    Own/Co-Broking Estate Agent</span>
                            </div>
                        </div>
                        <div class="col-8">
                            <div class="light-info-box">
                                <span class="d-inline-block w-100">Contact Information</span>
                                <div class="d-flex" v-if="propertyDetail.display_my_contact_information">
                                    <h5 class="me-2"><img src="frontend/images/email-icon.svg" alt="">
                                        {{propertyUserDetail?.email}}</h5>
                                    <h5 class="me-2"><img src="frontend/images/phone-icon.svg" alt="">
                                        {{propertyUserDetail?.mobile_number}}</h5>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="accordion-item">
            <div class="accordion-header" id="heading8">
                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                    data-bs-target="#Auto-Map" aria-expanded="true" aria-controls="Auto-Map">
                    Nearby Amenities (Auto-Map)
                </button>
            </div>
            <div id="Auto-Map" class="accordion-collapse collapse" aria-labelledby="heading8">
                <div class="accordion-body">
                    <div class="row row-cols-1 row-cols-sm-3">
                        <div class="col">
                            <div class="light-info-box text-center">
                                <img src="frontend/images/Nearest-MRT-icon.svg" alt="">
                                <h5>500M to</h5>
                                <span class="d-inline-block w-100">Nearest MRT</span>
                            </div>
                        </div>
                        <div class="col">
                            <div class="light-info-box text-center">
                                <img src="frontend/images/Nearest-Bus-Station-icon.svg" alt="">
                                <h5>100M to</h5>
                                <span class="d-inline-block w-100">Nearest Bus Station</span>
                            </div>
                        </div>
                        <div class="col">
                            <div class="light-info-box text-center">
                                <img src="frontend/images/Nearest-School-icon.svg" alt="">
                                <h5>3KM to</h5>
                                <span class="d-inline-block w-100">Nearest School</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="accordion-item">
            <div class="accordion-header" id="heading9">
                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                    data-bs-target="#Additional-Links" aria-expanded="true" aria-controls="Additional-Links">
                    Additional Links/Attachments
                </button>
            </div>
            <div id="Additional-Links" class="accordion-collapse collapse" aria-labelledby="heading9">
                <div class="accordion-body">
                    <div class="row row-cols-1 row-cols-sm-3" v-if="otherDocList.length>0">
                        <div class="col form-group" v-for="option in otherDocList" :key="option.id" :value="option.id">
                            <a :href="`${CONSTANT.IMAGE_URL}/imagesPropertyDocumentAndOtherPhotos/${option.photo_name}`"
                                class="comman-btn-6" target="_blank">
                                {{option.photo_original_name}}
                                <img src="frontend/images/upload-orange.svg" alt=""></a>
                        </div>
                    </div>
                    <div v-else><span>No Additional Attachment Found</span></div>
                    <div class="row row-cols-1 row-cols-sm-3">
                        <div class="col form-group" v-for="option in mediaLinksList" :key="option.id"
                            :value="option.id">
                            <a :href="`${option.link_url}`" class="comman-btn-6" target="_blank">
                                {{option.link_name}} ( {{option.link_type}} )
                                <img src="frontend/images/upload-orange.svg" alt=""></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="accordion-item">
            <div class="accordion-header" id="heading10">
                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                    data-bs-target="#Descriptions" aria-expanded="true" aria-controls="Descriptions">
                    Description
                </button>
            </div>
            <div id="Descriptions" class="accordion-collapse collapse" aria-labelledby="heading10">
                <div class="accordion-body">
                    {{propertyDetail.additional_description || 'No Description Found'}}
                </div>
            </div>
        </div>
        <div class="accordion-item">
            <div class="accordion-header" id="heading11">
                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                    data-bs-target="#Reviews" aria-expanded="true" aria-controls="Reviews">
                    Reviews
                </button>
            </div>
            <div id="Reviews" class="accordion-collapse collapse" aria-labelledby="heading11">
                <div class="accordion-body">
                    <div v-if="propertyDetail.reviews && propertyDetail.reviews.length > 0">
                        <div class="row row-cols-1" v-for="(review, key) in propertyDetail?.reviews"
                            :key="'review-'+key">
                            <div class="col">
                                <div class="light-info-box">
                                    <label class="w-auto me-3">{{ review.user?.name }}<i class="fa fa-star mx-1"
                                            v-for="(rating, index) in review.ratings" :key="'rating-star-'+index"
                                            style="color: #FFD600;"></i></label>
                                    <h5>{{ review.feedback }}</h5>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-else>
                        <span>No reviews found.</span>
                    </div>
                </div>
            </div>
        </div>
        <div class="review-form px-1" v-if="isAuthenticated && user && user.id !== propertyDetail.user_id">
            <div class="comman-title">
                <h3>Leave a review</h3>
            </div>
            <div class="review-form-box">
                <form>
                    <div class="form-group d-flex align-items-center">
                        <label class="w-auto me-3"><b>Your Rating</b></label>
                        <div class="rating">
                            <input type="radio" name="rating" value="5" id="5" v-model="reviewForm.ratings">
                            <label for="5"><i class="fa fa-star-o"></i></label>

                            <input type="radio" name="rating" value="4" id="4" v-model="reviewForm.ratings">
                            <label for="4"><i class="fa fa-star-o"></i></label>

                            <input type="radio" name="rating" value="3" id="3" v-model="reviewForm.ratings">
                            <label for="3"><i class="fa fa-star-o"></i></label>

                            <input type="radio" name="rating" value="2" id="2" v-model="reviewForm.ratings">
                            <label for="2"><i class="fa fa-star-o"></i></label>

                            <input type="radio" name="rating" value="1" id="1" v-model="reviewForm.ratings">
                            <label for="1"><i class="fa fa-star-o"></i></label>
                        </div>
                    </div>
                    <div class="form-group">
                        <textarea class="form-control" rows="5" placeholder="Writer message here"
                            v-model="reviewForm.feedback"></textarea>
                    </div>
                    <button class="comman-btn-2 w-100" @click.prevent="submitReview">Submit</button>
                </form>
            </div>
        </div>
        <div class="accordion-item">
            <div class="accordion-header" id="heading13">
                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#map-section"
                    aria-expanded="true" aria-controls="map-section">
                    Map
                </button>
            </div>
            <div id="map-section" class="accordion-collapse collapse" aria-labelledby="heading13">
                <div class="accordion-body">
                    <div v-if="!mapError" id="map" class="map-se" style="width: 100%; height: 325px;"></div>
                    <div v-else>Location cannot be found on map.</div>
                </div>
            </div>
        </div>
        <div class="accordion-item">
            <div class="accordion-header" id="heading13">
                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                    data-bs-target="#Transaction-history" aria-expanded="true" aria-controls="Transaction-history">
                    Transaction History
                </button>
            </div>
            <div id="Transaction-history" class="accordion-collapse collapse" aria-labelledby="heading13">
                <div class="accordion-body">
                    <div class="table-responsive transaction-history-table">
                        <table>
                            <thead>
                            <tr>
                                <th>Id</th>
                                <th>Unit Area</th>
                                <th>Transaction Type</th>
                                <th>Rent Tenure (months)</th>
                                <th>Date</th>
                                <th>Price</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr v-for="listing in property_transaction_histories" :key="listing.id">
                                <td>{{ listing.id }}</td>
                                <td>{{ listing.floor_area }}/ {{ listing.floor_area_unit }}</td>
                                <td>{{ listing.transaction_type }}</td>
                                <td>{{ listing.rent_tenure  ?? '-' }}</td>
                                <td>{{ listing.rent_date ?? listing.sold_date}}</td>
                                <td>{{ listing.rent_price ?? listing.sold_price ?? 'No detail Provided'}}</td>
                            </tr>
                            <tr v-if="property_transaction_histories && property_transaction_histories.length == 0">
                                <td colspan="6" class="text-center">No transaction found</td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import toastr from 'toastr';
    import { CONSTANT } from '@/constants/constants';
    import { mapState } from 'vuex';
    import { RENTAL_PERIOD } from '@/constants/constantsProperty';
    import { formatDate, formatPrice } from "@/utils/helpers";

    export default {
        name: 'PropertyBasicInformation',
        props: {
            propertyDetail: {
                type: Object,
                required: true,
            },
            propertyUserDetail: {
                type: Object,
                required: true,
            },
            otherDocList: {
                type: Array,
                required: true,
            },
            mode_of_payment: {
                type: [Array, String],
                required: true,
            },
            mediaLinksList: {
                type: Array,
                required: true,
            },
            add_facilitiyList: {
                type: Array,
                required: true,
            },
            facilitiyList: {
                type: Array,
                required: true,
            },
            featuresList: {
                type: Array,
                required: true,
            },
            add_featuresList: {
                type: Array,
                required: true,
            },
            property_transaction_histories: {
                type: Array,
                required: true,
            }
        },
        data() {
            return {
                CONSTANT,
                rentalPeriodList: RENTAL_PERIOD,
                reviewForm: {
                    ratings: 0,
                    feedback: null
                },
                mapError: false
            }
        },
        computed: {
            ...mapState(['isAuthenticated', 'user']),
            rentalPeriodLabel() {
                const item = this.rentalPeriodList.find(option => option.value === this.propertyDetail.rental_period_preferred_tenancy);
                return item ? item.label : 'Not found';
            },
        },
        methods: {
            formatDate,
            formatPrice,
            computedSQF(property) {
                return property.asking_price_sale_rent > 0 ? Math.ceil(property.asking_price_sale_rent / property.floor_area) : 0;
            },
            async initializeMap() {
                try {
                    const google = await this.loadGoogleMapsApi();
                    const geocoder = new google.maps.Geocoder();
                    const geocodeAddress = async (address) => {
                        return new Promise((resolve, reject) => {
                            geocoder.geocode({ address }, (results, status) => {
                                if (status === 'OK' && results && results.length > 0) {
                                    resolve(results);
                                } else {
                                    reject(status);
                                }
                            });
                        });
                    };

                    let addressParts = [
                        this.propertyDetail.unit_no,
                        this.propertyDetail.street,
                        this.propertyDetail.area_hdb_estate,
                        this.propertyDetail.district
                    ];
                    let address = addressParts.filter(part => part !== null && part !== undefined).join(', ');
                    const results = await geocodeAddress(address).catch(async (status) => {
                        if (status === 'ZERO_RESULTS') {
                            address = `${this.propertyDetail.area_hdb_estate}, ${this.propertyDetail.district}`;
                            return geocodeAddress(address);
                        } else {
                            this.mapError = true;
                            throw new Error('Geocode failed: ' + status);
                        }
                    });

                    // Ensure results were returned
                    if (results && results.length > 0) {
                        const location = results[0].geometry.location;
                        const map = new google.maps.Map(document.getElementById('map'), {
                            zoom: 13,
                            center: location,
                        });
                        new google.maps.Marker({
                            map: map,
                            position: location,
                        });
                        this.mapError = false;
                    } else {
                        this.mapError = true;
                        console.error('No valid results found for the address.');
                    }
                } catch (error) {
                    this.mapError = true;
                    console.error('Error initializing map:', error);
                }
            },
            async loadGoogleMapsApi() {
                return new Promise((resolve, reject) => {
                    if (!process.env?.VUE_APP_GOOGLE_API_KEY) {
                        return reject(new Error('API Key is missing'));
                    }

                    const script = document.createElement('script');
                    script.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.VUE_APP_GOOGLE_API_KEY}`;
                    script.async = true;
                    script.defer = true;

                    script.onload = () => resolve(window.google);
                    script.onerror = () => reject(new Error('Google Maps API failed to load.'));

                    document.head.appendChild(script);
                });
            },

            async submitReview() {
                try {
                    if (!this.isAuthenticated || !this.user) {
                        toastr.error('Unauthenticated', 'error', { timeOut: 3000 });
                        return;
                    }

                    if (this.user.id === this.propertyDetail.user_id) {
                        toastr.error('Property owner is not allowed to leave review', 'error', { timeOut: 3000 });
                        return;
                    }

                    if (!this.reviewForm.feedback) {
                        toastr.error('Feedback is required.', 'error', { timeOut: 3000 });
                        return;
                    }
                    this.reviewForm.property_listing_id = this.propertyDetail.id;
                    this.reviewForm.user_id = this.user.id;
                    this.reviewForm.api_token = localStorage.getItem('userToken')
                    const reviewResponse = await this.$axios.post(`api/properties/${this.propertyDetail.id}/review`, this.reviewForm);

                    if (reviewResponse.status === 200) {
                        toastr.success(reviewResponse.data.message, 'Success', {
                            progressBar: true,
                            closeButton: true,
                            timeOut: 1500,
                            onHidden: () => {
                                this.$emit('refetch-property');
                                this.resetReviewForm();
                            }
                        });
                    }
                } catch (error) {
                    console.error('Error submitting review:', error);
                }
            },

            resetReviewForm() {
                this.reviewForm = {
                    ratings: 0,
                    feedback: null
                }
            }
        },
        watch: {
            propertyDetail: {
                handler() {
                    this.initializeMap();
                },
                deep: true
            },
        },
    }
</script>

<style scoped>
#property-status .light-info-box, #property-payment-mode .light-info-box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
}
</style>
// src/constants/constants.js

export const CONSTANT = {
    IMAGE_URL: process.env.VUE_APP_BACKEND_URL + '/storage',
    DEFAULT_IMAGE: "frontend/images/no_profile.jpg",
    DEFAULT_NO_IMAGE: "frontend/images/no_image_available.jpg",
    COUNTRY_CODE: "93",
    GOOGLE: {
        SITE_KEY: "6Lcg9j0qAAAAAKBi7MtKgoX4IfH99kjUK5URgYKW",
    },
    APPOINTMENT_TYPES: {
        EXCLUSIVE: 0,
        NON_EXCLUSIVE: 1,
    },
    STATUS_TYPES: {
        PENDING: 0,
        APPROVED: 1,
        REJECTED: 10,
    },
    CREDIT_STATUS: {
        BEFORE: 0,
        AFTER: 1,
    },
    WIDGET: {
        TITLE: '',
        DESCRIPTION: '',
        IMAGE_URL: '',
    }
};



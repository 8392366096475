<template>    
    
	<section class="w-2-property-lsi">
		<div class="container">
			<div class="progress">
				<div class="progress-bar" role="progressbar" style="width: 50%;" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100"></div>
			</div>
			<div class="step-container d-flex justify-content-between">
				<div class="step-circle"><span>1</span> Step 1</div>
				<div class="step-circle"><span>2</span> Step 2</div>
				<div class="step-circle"><span>3</span> Step 3</div>
			</div>
			
			<form @submit.prevent="submitPost" id="multi-step-form">
				<div class="step step-1">										
					<div class="form-group">
						<label>Asking Rent/Sale Price</label>
						<input type="number" placeholder="Asking Sale Price" class="form-control" v-model="form.asking_price_sale_rent">
						<span  class="error" v-if="errors.asking_price_sale_rent">{{ errors.asking_price_sale_rent }}</span>
					</div>					
					<div class="form-group">
                        <label for="">Current Occupancy</label>
                        <select class="form-control" v-model="form.current_occupancy" >
                            <option value="">Current Occupancy</option>
                            <option>Occupied By Owner</option>
                            <option>Rented To Tenant</option>
                            <option>Vacant</option>
                            <option>Renovation / Make-Over In Progress</option>
                        </select>
                        <span  class="error" v-if="errors.current_occupancy">{{ errors.current_occupancy }}</span>
                    </div>	
                    <div class="form-group">
                        <label>Availability Date</label>
						<input type="date" class="form-control" v-model="form.availability_date" placeholder="Availability Date" >
                        <span  class="error" v-if="errors.availability_date">{{ errors.availability_date }}</span>
					</div>
                    <div class="comman-title mt-3">
						<h4 class="mb-2">Hybrid-Listing Option</h4>
						<p>You may assign this listing to multiple Estate Agents with our digital-brokerage widget, enhancing the reach and accelerating speed-to-market via the entire or co-agency network with leveraged resources, amplifying your chances of success while maintaining control of the original listing.</p>
						<a href="#">"Choice Of Either 1 Selection, Not Allowed To Have Multiple Choices."</a>
					</div>

                    <div class="form-group">
                        <div class="form-check">
                            <input class="form-check-input" type="radio" v-model="form.hybrid_listing_type" value="2" id="Assign-To-Recommended-Estate-Agent">
                            <label class="form-check-label w-100" for="Assign-To-Recommended-Estate-Agent">
                                Assign To Recommended Estate Agent
                            </label>
                        </div>
                        <div class="form-check">
                            <input class="form-check-input" type="radio" v-model="form.hybrid_listing_type" value="3" id="Find-My-Own">
                            <label class="form-check-label w-100" for="Find-My-Own">
                                Find My Own/Co-Broking Estate Agent
                            </label>
                        </div>
                        <div class="form-check">
                            <input class="form-check-input" type="radio" v-model="form.hybrid_listing_type" value="1" id="Attend-To-Inquiry">
                            <label class="form-check-label w-100" for="Attend-To-Inquiry">
                                Attend To Inquiry By Myself
                            </label>
                        </div>
                        <span  class="error" v-if="errors.hybrid_listing_type">{{ errors.hybrid_listing_type }}</span>
                    </div>


                    <div class="comman-title mt-3">
						<h4>FengShui Map - Enriched Contents</h4>
					</div>
                    <div class="form-group">
						<label>Date Of Property Completed/1st Occupied</label>
						<input type="number" placeholder="Property Identification" class="form-control" v-model="form.FSM_property_completion_occupation_date">
                        <span  class="error" v-if="errors.FSM_property_completion_occupation_date">{{ errors.FSM_property_completion_occupation_date }}</span>
					</div>

                    <div class="comman-title mt-3">
						<h4>Building Facing Direction</h4>
					</div>
                    
                    <div class="form-group">
                        <label for="">Main Block/Building Facing</label>
                        <select class="form-control" v-model="form.FSM_property_facing_orientation_building_id">
                            <option value="0" disabled>Main Block/Building Facing</option>
                            <RecursiveDropdown :options="hierarchicalOptions" />
                        </select>
                        <span  class="error" v-if="errors.FSM_property_facing_orientation_building_id">{{ errors.FSM_property_facing_orientation_building_id }}</span>
                    </div>	

                    <div class="form-group">
                        <label for="">Main Door Facing</label>
                        <select class="form-control" v-model="form.FSM_property_facing_orientation_main_entrance_id">
                            <option value="0" disabled>Main Door Facing</option>
                            <RecursiveDropdown :options="hierarchicalOptions" />
                        </select>
                        <span  class="error" v-if="errors.FSM_property_facing_orientation_main_entrance_id">{{ errors.FSM_property_facing_orientation_main_entrance_id }}</span>
                    </div>	

                    <div class="form-group">
                        <label for="">Main Balcony/Window Facing</label>
                        <select class="form-control" v-model="form.FSM_property_facing_orientation_main_balcony_window_id">
                            <option value="0" disabled>Main Balcony/Window Facing</option>
                            <RecursiveDropdown :options="hierarchicalOptions" />
                        </select>
                        <span  class="error" v-if="errors.FSM_property_facing_orientation_main_balcony_window_id">{{ errors.FSM_property_facing_orientation_main_balcony_window_id }}</span>                                            
                    </div>	
                    
                    <div class="comman-title mt-3">
						<h4>Space Sector</h4>
					</div>

                    
                    
                    <div class="form-group">
                        <label for="">Main Door/Entrance Sector</label>
                        <select class="form-control" v-model="form.FSM_space_sectors_main_door_entrance_sector_id">
                            <option value="0" disabled>Main Door/Entrance Sector</option>
                            <RecursiveDropdown :options="hierarchicalOptions" />
                        </select>
                        <span  class="error" v-if="errors.FSM_space_sectors_main_door_entrance_sector_id">{{ errors.FSM_space_sectors_main_door_entrance_sector_id }}</span>
                    </div>	
                
                    <div class="form-group">
                        <label for="">Main Living-Hall Sector</label>
                        <select class="form-control" v-model="form.FSM_space_sectors_main_living_hall_hall_sector_id">
                            <option value="0" disabled>Main Living-Hall Sector</option>
                            <RecursiveDropdown :options="hierarchicalOptions" />
                        </select>
                        <span  class="error" v-if="errors.FSM_space_sectors_main_living_hall_hall_sector_id">{{ errors.FSM_space_sectors_main_living_hall_hall_sector_id }}</span>
                    </div>	
                
                    <div class="form-group">
                        <label for="">Kitchen Sector</label>
                        <select class="form-control" v-model="form.FSM_space_sectors_kitchen_sector_id">
                            <option value="0" disabled>Kitchen Sector</option>
                            <RecursiveDropdown :options="hierarchicalOptions" />
                        </select>
                        <span  class="error" v-if="errors.FSM_space_sectors_kitchen_sector_id">{{ errors.FSM_space_sectors_kitchen_sector_id }}</span>
                    </div>	
                
                    <div class="form-group">
                        <label for="">Main/Master Room Sector</label>
                        <select class="form-control" v-model="form.FSM_space_sectors_main_master_bedroom_sector_id">
                            <option value="0" disabled>Main/Master Room Sector</option>
                            <RecursiveDropdown :options="hierarchicalOptions" />
                        </select>
                        <span  class="error" v-if="errors.FSM_space_sectors_main_master_bedroom_sector_id">{{ errors.FSM_space_sectors_main_master_bedroom_sector_id }}</span>
                    </div>	
                    
                

					<div class="pull-right">
						<button type="submit" class="comman-btn-2 next-step">Next</button>
					</div>
				</div>
			</form>
		</div>
	</section>

</template>
  
<script> 
  
  import { mapState } from 'vuex';
  import toastr from 'toastr';
  import 'toastr/build/toastr.min.css';
  import { CONSTANT } from '@/constants/constants';
  import RecursiveDropdown from '@/components/RecursiveDropdown.vue';
  import { FUNG_SHUI_CARDINAL } from '@/constants/constantsProperty';
  export default {
    name: 'Widget2Page', 
    data() {
        return {
			editData: '',
            options: this.transformData(FUNG_SHUI_CARDINAL),
			form: {
                current_occupancy: '',
                hybrid_listing_type: '',
                FSM_property_facing_orientation_building_id: 0,
                FSM_property_facing_orientation_main_entrance_id: 0,
                FSM_property_facing_orientation_main_balcony_window_id: 0,
                FSM_space_sectors_main_door_entrance_sector_id: 0,
                FSM_space_sectors_main_living_hall_hall_sector_id: 0,
                FSM_space_sectors_kitchen_sector_id: 0,
                FSM_space_sectors_main_master_bedroom_sector_id: 0,

                AI_generative_language_Ids: [],
                AI_generative_target_audience_Ids: [],
                AI_generative_pr_look_and_feel_detail_Ids: [],
            },
			errors: {}
        }
    },
	components: {
      RecursiveDropdown
    }, 
	created() {
        this.fetchOptions();
	},
	computed: {
        ...mapState(['isAuthenticated', 'user']),
        hierarchicalOptions() {        
            return this.createHierarchicalOptions(this.options);
        },
        id() {
			return this.$route.params.id;
		},
		idType() {
			return this.$route.params.id_type;
		},   
		postListingId() {
			return this.$route.params.post_listing_id;
		},   
    },
	methods: {
		async fetchOptions() {
			// Get District list
			try {
				const response = await this.$axios.post('api/location',
				{                    
					type : "City",
					keyword : this.street, 
					parent_id: CONSTANT.COUNTRY_CODE                   
				});
				this.optionsDistrictList = response.data.output.map(item => ({
					value: item.id,
					label: item.name
				}));
			} catch (error) {
				console.error('Error fetching options:', error);
			}

			// Furnishing & Fittings
            try {
                const response = await this.$axios.post('api/pr-master-furnishing-fittings-list',
                {                    
                    parent_id : "0",
                    keyword : "", 
                    show_in_project: 0,
                    show_in_checkbox: 1,
                    show_in_property:1,
                    show_in_included : 0,                     
                    last_record_number : 0                     
                });
                this.checkboxFFlist = response.data.output.map(item => ({
                    id: item.id,
                    name: item.name
                }));
                console.log(this.checkboxFFlist);
            } catch (error) {
                console.error('Error fetching options:', error);
            }
		},
        validateForm() {
            this.errors = {};
            if (!this.form.asking_price_sale_rent ) {
                this.errors.asking_price_sale_rent = 'Asking Rent/Sale Price is required.';
            }
            
            if (!this.form.current_occupancy ) {
                this.errors.current_occupancy = 'Current Occupancy is required.';
            }
            
            if (!this.form.availability_date ) {
                this.errors.availability_date = 'Current Occupancy is required.';
            }
            
            if (!this.form.FSM_property_completion_occupation_date ) {
                this.errors.FSM_property_completion_occupation_date = 'Date Of Property Completed.';
            }

            if (this.form.hybrid_listing_type.length == 0) {
                 this.errors.hybrid_listing_type = 'Choice Of Either 1 Selection, Not Allowed To Have Multiple Choices.';
            } 
            

                     
            return Object.keys(this.errors).length === 0;
        },        
        async submitPost() {
            
            if (this.validateForm()) {
                
                const token = localStorage.getItem('userToken');
    
                if (this.loading) return;
                this.loading = true;
                try {
                    var loginted_user_id = 0;
                    if(this.user.id > 0){
                        loginted_user_id = this.user.id;
                    }
                    const response = await this.$axios.post('api/update-widget-property', {
                        user_id :loginted_user_id,
                        api_token: token,
                        property_listing_id: this.postListingId,
						
                        asking_price_sale_rent: this.form.asking_price_sale_rent, 
                        current_occupancy: this.form.current_occupancy, 
                        hybrid_listing_type: this.form.hybrid_listing_type, 
                        availability_date: this.form.availability_date, 

                        FSM_property_completion_occupation_date: this.form.FSM_property_completion_occupation_date ? this.form.FSM_property_completion_occupation_date : 0, 
                        FSM_property_facing_orientation_building_id:this.form.FSM_property_facing_orientation_building_id ? this.form.FSM_property_facing_orientation_building_id : 0,
                        FSM_property_facing_orientation_main_entrance_id : this.form.FSM_property_facing_orientation_main_entrance_id ? this.form.FSM_property_facing_orientation_main_entrance_id : 0,
                        FSM_property_facing_orientation_main_balcony_window_id : this.form.FSM_property_facing_orientation_main_balcony_window_id ? this.form.FSM_property_facing_orientation_main_balcony_window_id : 0,
                        FSM_space_sectors_main_door_entrance_sector_id : this.form.FSM_space_sectors_main_door_entrance_sector_id ? this.form.FSM_space_sectors_main_door_entrance_sector_id : 0,
                        FSM_space_sectors_main_living_hall_hall_sector_id : this.form.FSM_space_sectors_main_living_hall_hall_sector_id ? this.form.FSM_space_sectors_main_living_hall_hall_sector_id : 0,
                        FSM_space_sectors_kitchen_sector_id : this.form.FSM_space_sectors_kitchen_sector_id ? this.form.FSM_space_sectors_kitchen_sector_id : 0,
                        FSM_space_sectors_main_master_bedroom_sector_id : this.form.FSM_space_sectors_main_master_bedroom_sector_id ? this.form.FSM_space_sectors_main_master_bedroom_sector_id : 0,

						referral_user_id:this.id,
						referral_user_type:this.idType
                    });

                    localStorage.setItem('property_listing_id', this.postListingId);
                    localStorage.setItem('hybrid_listing_type', this.form.hybrid_listing_type);

                    toastr.success(response.data.message, 'Success', { timeOut: 3000 });
                    this.$router.push('/widget-property-post-3/'+this.id+'/'+this.idType+'/'+this.postListingId);       

                } catch (error) {
                    
                    if (error.response && error.response.data) {
                        this.errors = this.extractErrors(error.response.data.errors || {});
                    } else {
                        console.error('An unexpected error occurred:', error);
                    }
                } finally {
                    this.loading = false;
                }
            }
        },		
        extractErrors(errors) {
            // Extract the first error message from each field's errors array
            const formattedErrors = {};
            for (const [field, messages] of Object.entries(errors)) {
                if (Array.isArray(messages) && messages.length > 0) {
                formattedErrors[field] = messages[0]; // Get the first error message
                }
            }
            return formattedErrors;
        },
        transformData(data) {
            const map = {};
            const result = [];
    
            data.forEach(item => {
            map[item.id] = { ...item, children: [] };
            });
    
            data.forEach(item => {
            if (item.parent_id === 0) {
                result.push(map[item.id]);
            } else {
                map[item.parent_id].children.push(map[item.id]);
            }
            });
    
            return result;
        },
        createHierarchicalOptions(data) {
            return data.map(option => this.createOptionGroup(option));
        },
        createOptionGroup(option) {
                if (!option.children || option.children.length === 0) {
                    return {
                        type: 'option',
                        id: option.id,
                        label: option.name,
                        depth: option.depth,
                    };
                }
                return {
                    type: 'optgroup',
                    label: option.name,
                    depth: option.depth,
                    children: option.children.map(child => this.createOptionGroup(child))
                };
        },
		

	}
  }
</script>
  
<style scoped>

</style>
  
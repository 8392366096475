<template>    
    
	<section class="w-2-property-lsi">
		<div class="container">
			<div class="progress">
				<div class="progress-bar" role="progressbar" style="width: 100%;" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100"></div>
			</div>
			<div class="step-container d-flex justify-content-between">
				<div class="step-circle"><span>1</span> Step 1</div>
				<div class="step-circle"><span>2</span> Step 2</div>
				<div class="step-circle"><span>3</span> Step 3</div>
			</div>
			
			<form @submit.prevent="submitPost" id="multi-step-form">
				<div class="step step-3">	
                    
                    <div class="comman-title">
                        <h4>Upload File/Photo</h4>
                    </div>

                    <div class="comman-title">
                        <h4>Insert Links:</h4>
                    </div>
                    
                    <div class="drop-zone-box">
                        <div class="drop-zone">
                            <span class="drop-zone__prompt openBrowsePopup"> <img src="frontend/images/upload-icon.svg" alt=""> <br> Please Upload Your Property Photo</span>
                            <input type="file" name="myFile" class="drop-zone__input">
                        </div>
                    </div>
                    <span v-for="option in otherDocListUL" :key="option.id" :value="option.id" 
                        :class="{'badge': true, 'bg-secondary': true, 'rounded-pill': true, [`addi_${option.id}`]: true, 'position-relative': true, 'featurelist-badge': true}"
                        >
                        <a :href="`${CONSTANT.IMAGE_URL}/imagesPropertyActualUnit/${option.photo_name}`" target="_blank" class="text-white "> {{option.photo_original_name}} &nbsp;</a>
                        <span class="fa fa-trash text-white  deleteUploadACtualUnitPhoto" data-deleteType="mb" :id="option.id">
                            <span class="visually-hidden">X</span>
                        </span>
                    </span>
                    
					<div class="form-group">
                        <label>Virtual Tour</label>
                        <input type="text" placeholder="Paste Link" class="form-control" v-model="form.link_url1">
                        <span  class="error" v-if="errors.link_url1">{{ errors.link_url1 }}</span>
                    </div>
                    <div class="form-group">
                        <label>Video Walkthrough</label>
                        <input type="text" placeholder="Paste Link" class="form-control"  v-model="form.link_url2">
                        <span  class="error" v-if="errors.link_url2">{{ errors.link_url2 }}</span>
                    </div>

					<div class="pull-right">
						<button type="submit" class="comman-btn-2 next-step">Next</button>
					</div>
				</div>
			</form>
		</div>
	</section>


    <!-- upload photos modal -->
    <div class="modal fade" id="upload-photos-modal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">			
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"><img src="frontend/images/close.svg" alt=""></button>			
            <div class="modal-body text-center">
                <form id="file-upload-form" class="uploader">
                    <input id="file-upload" type="file" @change="onFileChange" name="fileUploadImg" multiple accept="image/*" />
                        <input type="hidden" name="type_photos"  id="type_photos" value="1">
                        <label for="file-upload" id="file-drag">
                            <img id="file-image" src="#" alt="Preview" class="hidden">
                            <div id="start">
                                <img src="frontend/images/big-upload-icom.svg" alt="">
                                <div>
                                    <h5 class="title_photos_upload">Please Upload Your Site Plan</h5>
                                    <p class="mb-0">&lt;Types Of File Formats&gt; JPG, PDF, SVG <br>
                                    &lt;Max Size Per File&gt; 05 - 10 PX <br>
                                    &lt;Max No. Of Files&gt; 4 </p>
                                </div>	
                                <div id="notimage" class="hidden">Please select an image</div>
                                <!-- <span id="file-upload-btn" class="btn btn-primary">Select a file</span> -->
                            </div>
                            <div id="response" class="hidden">
                                <div id="messages"></div>
                                <progress class="progress" id="file-progress" value="0">
                                    <span>0</span>%
                                </progress>
                            </div>
                        </label>
                    </form>
                </div>
            </div>
        </div>
    </div>  
</template>
  
<script> 
  import { mapState } from 'vuex';
  import toastr from 'toastr';
  import 'toastr/build/toastr.min.css';
  import { CONSTANT } from '@/constants/constants';
  export default {
    name: 'Widget2Page', 
    data() {
        return {
            CONSTANT,
			editData: '',
            otherDocListUL : [],
			form: {
                hybrid_listing_type: '',
                link_url1: '',
                link_url2: ''
            },
			errors: {}
        }
    },
	components: {
    }, 
    mounted() {
        this.setupEventListeners();
    }, 
	created() {
        this.fetchOptions();
	},
	computed: {
        ...mapState(['isAuthenticated', 'user']),
        id() {
			return this.$route.params.id;
		},
		idType() {
			return this.$route.params.id_type;
		}, 
        postListingId() {
			return this.$route.params.post_listing_id;
		},   
    },
	methods: {
		async fetchOptions() {
            
			// Get District list
			try {
				const response = await this.$axios.post('api/location',
				{                    
					type : "City",
					keyword : this.street, 
					parent_id: CONSTANT.COUNTRY_CODE                   
				});
				this.optionsDistrictList = response.data.output.map(item => ({
					value: item.id,
					label: item.name
				}));
			} catch (error) {
				console.error('Error fetching options:', error);
			}

			// Furnishing & Fittings
            try {
                const response = await this.$axios.post('api/pr-master-furnishing-fittings-list',
                {                    
                    parent_id : "0",
                    keyword : "", 
                    show_in_project: 0,
                    show_in_checkbox: 1,
                    show_in_property:1,
                    show_in_included : 0,                     
                    last_record_number : 0                     
                });
                this.checkboxFFlist = response.data.output.map(item => ({
                    id: item.id,
                    name: item.name
                }));
                console.log(this.checkboxFFlist);
            } catch (error) {
                console.error('Error fetching options:', error);
            }
		},
        validateForm() {
            this.errors = {};
            if (!this.form.link_url1 ) {
                this.errors.link_url1 = 'Virtual Tour is required.';
            }                    
            if (!this.form.link_url2 ) {
                this.errors.link_url2 = 'Video Walkthrough is required.';
            }                    
            return Object.keys(this.errors).length === 0;
        },        
        async submitPost() {
            
            if (this.validateForm()) {
                this.$router.push('/sign-in/');
                const token = localStorage.getItem('userToken');
                // const property_listing_id = localStorage.getItem('property_listing_id');
                const agentId = localStorage.getItem('agentId');
                const hybrid_listing_type = localStorage.getItem('hybrid_listing_type');

                console.log(agentId);
                console.log(hybrid_listing_type);
               
                if (this.loading) return;
                this.loading = true;
                try {
                    var loginted_user_id = 0;
                    if(this.user.id > 0){
                        loginted_user_id = this.user.id;
                    }
                    await this.$axios.post('api/add-property-listing-media-link', {
                        user_id :loginted_user_id,
                        api_token: token,
                        // property_listing_id: property_listing_id,
						link_type:'OutsideLink',
						link_name:'Virtual Tour',
						link_url:this.form.link_url2 ? this.form.link_url2 : ''
                    });
                  
                    const response = await this.$axios.post('api/add-property-listing-media-link', {
                        user_id :loginted_user_id,
                        api_token: token,
                        // property_listing_id: this.postListingId,
						link_type:'OutsideLink',
						link_name:'Video Walkthrough',
						link_url:this.form.link_url1 ? this.form.link_url1 : ''
                    });
                    toastr.success(response.data.message, 'Success', { timeOut: 3000 });

                    console.log(this.router);
                    if (loginted_user_id > 0) {
                        if (hybrid_listing_type == "2") { 
                            this.$router.push({ name: 'AgentDetail', params: { id: agentId } });
                        } else {
                            this.$router.push('/my-property/'); 
                        }      
                    } else {
                        if (hybrid_listing_type == "2") {
                            // Redirect to sign-in and store the intended redirect URL
                            localStorage.setItem('redirectAfterLogin', `/agents/${agentId}`);
                            this.$router.push('/sign-in/'); 
                        } else {
                            // Store fallback redirect URL
                            localStorage.setItem('redirectAfterLogin', '/my-property/');
                            this.$router.push('/sign-in/');  
                        }     
                    }

                    // var property_listing_id = response.data.output.id

                } catch (error) {
                    
                    if (error.response && error.response.data) {
                        this.errors = this.extractErrors(error.response.data.errors || {});
                    } else {
                        console.error('An unexpected error occurred:', error);
                    }
                } finally {
                    this.loading = false;
                }
            }
        },	

        extractErrors(errors) {
            // Extract the first error message from each field's errors array
            const formattedErrors = {};
            for (const [field, messages] of Object.entries(errors)) {
                if (Array.isArray(messages) && messages.length > 0) {
                formattedErrors[field] = messages[0]; // Get the first error message
                }
            }
            return formattedErrors;
        },
        async onFileChange(event) {
            const files = event.target.files;
            this.selectedFiles = Array.from(files);
            if (this.selectedFiles) {
                
                const token = localStorage.getItem('userToken');
                const formData = new FormData();

                // Append files to FormData
                this.selectedFiles
                .forEach(file => {
                    formData.append('actual_unit_photos[]', file);
                });
                formData.append('dataJson', JSON.stringify({
                user_id: this.user.id,
                api_token: token,
                property_listing_id: this.postListingId
                }));
                try {
                    const response = await this.$axios.post('api/add-actual-unit-photos', formData, {
                        headers: {
                        'Content-Type': 'multipart/form-data',
                        },
                    });

                    if(response.data.status == 1){
                        this.otherDocListUL = response.data.output;
                        toastr.success(response.data.message, 'Success', { timeOut: 3000 });
                        setTimeout(function(){
                            window.$(".btn-close").trigger('click');
                        },500);
                    }else{
                        toastr.error(response.data.message, 'Error11', { timeOut: 3000 });
                    }
                } catch (error) {                
                    if (error.response && error.response.data) {
                        this.errors = this.extractErrors(error.response.data.errors || {});
                    } else {
                        console.error('An unexpected error occurred:', error);
                    }
                } finally {
                this.loading = false;
                }
            }
        },  
        async deleteDocUnitPhoto(id) {
            const token = localStorage.getItem('userToken');
            try {
                await this.$axios.post('api/delete-actual-unit-photo',
                {                    
                    api_token: token,
                    user_id:this.user.id,
                    photo_id:id,


                });
                window.$(".addi_"+id).remove();
                
            } catch (error) {
                toastr.error('Error fetching options:')
            }
        },      
        setupEventListeners() {
            const self = this; // Save reference to `this`
            
            window.$(document).on('click', '.deleteUploadACtualUnitPhoto', function() {        
                const id = window.$(this).attr('id');
                window.Swal.fire({
                title: 'Are you sure?',
                text: `Delete`,
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, delete it!'
                }).then((result) => {
                if (result.isConfirmed) {
                    self.deleteDocUnitPhoto(id);
                }
                });
            });            
        }
       
		

	}
  }


  window.$(document).on("click",".openBrowsePopup",function(){    
    var typeOfPhotos = window.window.$(this).data("id");
    window.$("#type_photos").val(typeOfPhotos);
    
    window.$("input[name='fileUploadImg']").attr('accept',"image/*,application/pdf");
    if(typeOfPhotos == 'up'){
        window.$("input[name='fileUploadImg']").attr('accept',"image/*");
        window.$(".title_photos_upload").html("Please Upload Unit Photos");
    }else if(typeOfPhotos == 'ul'){
        window.$(".title_photos_upload").html("Please Upload  Unit Layout & Site Plan");
    }else if(typeOfPhotos == 'mb'){
        window.$(".title_photos_upload").html("Please Upload  Marketing Brochure ");
    }else{
        window.$(".title_photos_upload").html("Please Upload  Verify Authentic Badge ");
    }
    window.$('#upload-photos-modal').modal('show');
    // const myModal = new Modal(document.getElementById('upload-photos-modal'));
    // myModal.show();

  })

 
</script>
  
<style scoped>

</style>
  
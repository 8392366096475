<template>
    <div class="page-wrap">
        <section class="mrp-search-box-se">
            <div class="container">
                <div class="row">
                    <div class="col-md-8 offset-md-2">
                        <ul class="nav nav-tabs" id="myTab" role="tablist">
                            <li class="nav-item" role="presentation"><a class="nav-link active" href="javascript:;">Property</a></li>
                            <li class="nav-item" role="presentation"><a class="nav-link" href="/explore-more-project">Project</a></li>
                        </ul>
                        <ul class="nav nav-tabs" id="myTab" role="tablist">
                            <li class="nav-item" role="presentation">
                                <button class="nav-link" :class="{ active: activeTab === 'Buy' }" id="Buy-tab"
                                    data-bs-toggle="tab" data-bs-target="#Buy" type="button" role="tab"
                                    aria-controls="Buy" aria-selected="true" @click="setActiveTab('Buy')">Buy</button>
                            </li>
                            <li class="nav-item" role="presentation">
                                <button class="nav-link" :class="{ active: activeTab === 'Rent' }" id="Rent-tab"
                                    data-bs-toggle="tab" data-bs-target="#Rent" type="button" role="tab"
                                    aria-controls="Rent" aria-selected="false"
                                    @click="setActiveTab('Rent')">Rent</button>
                            </li>
                        </ul>
                        <div class="tab-content" id="myTabContent">
                            <div class="tab-pane fade show active" id="Buy" role="tabpanel" aria-labelledby="Buy-tab">
                                <ExploreMoreSearch @submit="submitSearch" type="Buy" :queryData="formData" @update-options="updateOptions">
                                </ExploreMoreSearch>
                            </div>
                            <div class="tab-pane fade" id="Rent" role="tabpanel" aria-labelledby="Rent-tab">
                                <ExploreMoreSearch @submit="submitSearch" type="Rent" :queryData="formData" @update-options="updateOptions">
                                </ExploreMoreSearch>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section class="mrp-search-se">
            <div class="container">
                <div class="filter-bar-se mb-0">
                    <div class="comman-title mb-0">
                        <h3 class="mb-2">Most Recent Featured Posting</h3>
                        <span>Showing {{ featuredTotalRecords }} properties</span>
                    </div>
                    <div class="filter-right-bar">
                        <!-- <div class="dropdown">
							<a href="#" id="Dropdown-sort-by" role="button" data-bs-toggle="dropdown" aria-expanded="true">
								<img src="frontend/images/filter-ico.svg"> Filter By
							</a>
							<ul class="dropdown-menu" aria-labelledby="Dropdown-sort-by">
								<li><a class="dropdown-item" href="#">Recommended </a></li>
								<li><a class="dropdown-item" href="#">111</a></li>								
							</ul>
						</div> -->
                        <div class="dropdown">
                            <a href="#" id="Dropdown-sort-by" role="button" data-bs-toggle="dropdown"
                                aria-expanded="true">
                                <img src="frontend/images/Sort-By.svg"> Sort By
                            </a>
                            <ul class="dropdown-menu" aria-labelledby="Dropdown-sort-by">
                                <li><a class="dropdown-item" href="#"
                                        :class="{ 'selected': filters.featured_sort_by_field === 'Newest' }"
                                        @click.prevent="sortProperties(true, 'Newest')">Newest</a></li>
                                <li><a class="dropdown-item" href="#"
                                        :class="{ 'selected': filters.featured_sort_by_field === 'Lowest Price' }"
                                        @click.prevent="sortProperties(true, 'Lowest Price')">Lowest Price</a></li>
                                <li><a class="dropdown-item" href="#"
                                        :class="{ 'selected': filters.featured_sort_by_field === 'Highest Price' }"
                                        @click.prevent="sortProperties(true, 'Highest Price')">Highest Price</a></li>
                                <li><a class="dropdown-item" href="#"
                                        :class="{ 'selected': filters.featured_sort_by_field === 'Lowest Floor Area' }"
                                        @click.prevent="sortProperties(true, 'Lowest Floor Area')">Lowest Floor Area</a></li>
                                <li><a class="dropdown-item" href="#"
                                        :class="{ 'selected': filters.featured_sort_by_field === 'Highest Floor Area' }"
                                        @click.prevent="sortProperties(true, 'Highest Floor Area')">Highest Floor Area</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>

                <div class="property-listing">
                    <template v-if="!loading && featuredProperties.length > 0">
                        <div class="property-listing-box" v-for="property in featuredProperties" :key="property.id">
                            <div class="property-listing-img">
                                <div class="property-carousel explore-owl owl-carousel owl-theme">
                                    <picture style="position: relative;">
                                        <a class="wishlist-icon-in-pic" @click="wishlistProperty(property)" title="Wishlist Property">
                                            <img v-if="property.is_in_wishlist" src="frontend/images/heart-filled-ico.svg" alt="Remove"/>
                                            <img v-else src="frontend/images/heart-ico.svg" alt="Remove"/>
                                        </a>
                                        <img v-for="(image, index) in property.actual_unit_photo" :key="index"
                                         :src="getPropertyImage(image.photo_name)" :alt="property.property_name" />
                                    </picture>
                                </div>
                                <div class="mark-logo">
                                    <img src="frontend/images/logo.png" alt="logo">
                                </div>
                            </div>
                            <div class="property-listing-contant">
                                <div class="project-info">
                                    <h3><a @click="redirectToPropertyDetails(property)">{{ property.property_name }}</a></h3>
                                    <p class="loct-info">
                                        <img src="frontend/images/map-gol-ico.svg">
                                        {{ property.street }}, {{ property.district }}
                                    </p>
                                    <div class="price-pro ms-md-auto">
                                        <span>S$ {{ formatPrice(property.asking_price_sale_rent) }} <small>($
                                                {{computedSQF(property)}}/SQF)</small></span>
                                    </div>
                                    <div class="pro-specify">
                                        <span><i><img src="frontend/images/spec-ico1.svg"></i>{{ property.no_of_rooms
                                            }}</span>
                                        <span><i><img src="frontend/images/spec-ico2.svg"></i>{{ property.no_of_baths
                                            }}</span>
                                        <span><i><img src="frontend/images/spec-ico3.svg"></i>{{
                                            property.no_car_park_lots }}</span>
                                        <span><i><img src="frontend/images/spec-ico4.svg"></i>{{ property.floor_area }}
                                            {{ property.land_area_unit }}</span>
                                    </div>
                                    <div class="pro-tag">
                                        <a v-if="property.property_type_details.parent_id_top_level_1_name" @click="searchByPropertyType(property.property_type_details.parent_id_top_level_1_name)">{{
                                            property.property_type_details.parent_id_top_level_1_code }}</a>
                                        <a v-if="property.property_type_details.name && property.property_type_details.parent_id_top_level_1_name" @click="searchByPropertyType(property.property_type_details.parent_id_top_level_1_name, property.property_type_details.name)">{{
                                            property.property_type_details.code }}</a>
                                        <a v-if="property.land_title_type" @click=searchByLandTitleType(property.land_title_type)>{{ property.land_title_type }}</a>
                                    </div>
                                    <ul>
                                        <li v-if="property.is_in_wishlist">
                                            <figure>
                                                <img src="frontend/images/heart-ico.svg">
                                            </figure>
                                            <small>favorite</small>
                                        </li>
                                        <li v-if="property.is_direct_owner">
                                            <figure>
                                                <img src="frontend/images/specify-ico1.png">
                                            </figure>
                                            <small>Direct<br>Owner</small>
                                        </li>
                                        <li v-if="property.is_sing_pass || property.is_corp_pass ">
                                            <figure>
                                                <img src="frontend/images/specify-ico2.png">
                                            </figure>
                                            <small>SingPass<br>Verified </small>
                                        </li>
                                        <li v-if="property.is_document_approved">
                                            <figure>
                                                <img src="frontend/images/specify-ico3.png">
                                            </figure>
                                            <small>Documents<br>Verified</small>
                                        </li>
                                        <!--<li v-if="property.is_FSM_linked">
                                            <figure>
                                                <img src="frontend/images/specify-ico4.png">
                                            </figure>
                                            <small>Fengshui<br>Map</small>
                                        </li>
                                        <li v-if="property.is_assigned_to_the_agent">
                                            <figure>
                                                <img src="frontend/images/specify-ico5.png">
                                            </figure>
                                            <small>Hybrid-Listing<br>Owners </small>
                                        </li>-->
                                        <li v-if="property.is_below_mean_value">
                                            <figure>
                                                <img src="frontend/images/Down-arrow.svg">
                                            </figure>
                                            <small>BMV</small>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div class="property-listing-profile">
                                <div class="property-l-profile-box">
                                    <div class="profile-box">
                                        <img :src="getUserProfileImage(property.user_id_details?.profile_photo_name)" />
                                        <div>
                                            <span>Posted By</span>
                                            <h5>{{ property.user_id_details?.name }} <img
                                                    src="frontend/images/varify-user.svg" alt=""></h5>
                                        </div>
                                    </div>
                                    <div class="agency-reg-no"> Agency Reg No:<span>
                                            {{property.user_id_details?.user_service_details?.agency_registration_no
                                            }}</span></div>
                                    <div class="property-l-profile-btn">
                                        <a :href="'https://api.whatsapp.com/send?phone=' + property.user_id_details?.mobile_number"
                                            class="comman-btn-2 whatsapp-btn w-100"><b class="mb-0">WhatsApp</b></a>
                                        <a :href="'mailto:' + property.user_id_details?.contact_email"
                                            class="comman-btn-6 w-100"><b>Email</b></a>
                                    </div>
                                </div>
                                <div class="property-l-profile-box">
                                    <p>Partner With Me to Market Your Property</p>
                                    <a :href="'widget-property-post-1/' + property.user_id + '/' + property.property_type"
                                        class="comman-btn-2">
                                        <span>
                                            <b>Create A Hybrid Listing</b><br>
                                            Own Your Story, Amplity its Reach!
                                        </span>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </template>
                    <template v-else>
                        <LoaderCust :isLoading="loading"/>
                        <div v-if="!loading" class="text-center">No records found</div>
                    </template>
                    <div class="pagination-se" v-if="!loading && featuredProperties.length > 0">
                        <nav aria-label="...">
                            <ul class="pagination justify-content-center">
                                <li class="page-item" :class="{ disabled: filters.featured_page === 1 }"
                                    @click="filters.featured_page > 1 && changePage(filters.featured_page - 1)" v-if="filters.featured_page > 1">
                                    <a class="page-link"><img src="frontend/images/left-arrow-icon.svg"
                                            alt="Previous"></a>
                                </li>
                                <li v-for="pageNumber in paginatedPages(filters.featured_page, featuredTotalPages)" :key="pageNumber"
                                    class="page-item" :class="{ active: pageNumber === filters.featured_page }">
                                    <a class="page-link"
                                        @click.prevent="pageNumber !== filters.featured_page && changePage(pageNumber)">
                                        {{ pageNumber }}
                                    </a>
                                </li>
                                <li class="page-item" :class="{ disabled: filters.featured_page === featuredTotalPages }"
                                    @click="filters.featured_page < featuredTotalPages && changePage(filters.featured_page + 1)"
                                    v-if="filters.featured_page < featuredTotalPages">
                                    <a class="page-link"><img src="frontend/images/right-arrow-icon.svg" alt="Next"></a>
                                </li>
                            </ul>
                        </nav>
                    </div>
                </div>
            </div>
            <div class="container">
                <div class="filter-bar-se mb-0">
                    <div class="comman-title mb-0">
                        <h3 class="mb-2">Most Recent Posting</h3>
                        <span>Showing {{ normalTotalRecords }} properties</span>
                    </div>
                    <div class="filter-right-bar">
                        <!-- <div class="dropdown">
							<a href="#" id="Dropdown-sort-by" role="button" data-bs-toggle="dropdown" aria-expanded="true">
								<img src="frontend/images/filter-ico.svg"> Filter By
							</a>
							<ul class="dropdown-menu" aria-labelledby="Dropdown-sort-by">
								<li><a class="dropdown-item" href="#">Recommended </a></li>
								<li><a class="dropdown-item" href="#">111</a></li>
							</ul>
						</div> -->
                        <div class="dropdown">
                            <a href="#" id="Dropdown-sort-by" role="button" data-bs-toggle="dropdown"
                                aria-expanded="true">
                                <img src="frontend/images/Sort-By.svg"> Sort By
                            </a>
                            <ul class="dropdown-menu" aria-labelledby="Dropdown-sort-by">
                                <li><a class="dropdown-item" href="#"
                                        :class="{ 'selected': filters.sort_by_field === 'Newest' }"
                                        @click.prevent="sortProperties(false, 'Newest')">Newest</a></li>
                                <li><a class="dropdown-item" href="#"
                                        :class="{ 'selected': filters.sort_by_field === 'Lowest Price' }"
                                        @click.prevent="sortProperties(false, 'Lowest Price')">Lowest Price</a></li>
                                <li><a class="dropdown-item" href="#"
                                        :class="{ 'selected': filters.sort_by_field === 'Highest Price' }"
                                        @click.prevent="sortProperties(false, 'Highest Price')">Highest Price</a></li>
                                <li><a class="dropdown-item" href="#"
                                        :class="{ 'selected': filters.sort_by_field === 'Lowest Floor Area' }"
                                        @click.prevent="sortProperties(false, 'Lowest Floor Area')">Lowest Floor Area</a></li>
                                <li><a class="dropdown-item" href="#"
                                        :class="{ 'selected': filters.sort_by_field === 'Highest Floor Area' }"
                                        @click.prevent="sortProperties(false, 'Highest Floor Area')">Highest Floor Area</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>

                <div class="property-listing">
                    <template v-if="!loading && normalProperties.length > 0">
                        <div class="property-listing-box" v-for="property in normalProperties" :key="property.id">
                            <div class="property-listing-img">
                                <div class="property-carousel explore-owl owl-carousel owl-theme">
                                    <picture style="position: relative;">
                                        <a class="wishlist-icon-in-pic" @click="wishlistProperty(property)" title="Wishlist Property">
                                            <img v-if="property.is_in_wishlist" src="frontend/images/heart-filled-ico.svg" alt="Remove"/>
                                            <img v-else src="frontend/images/heart-ico.svg" alt="Remove"/>
                                        </a>
                                        <img v-for="(image, index) in property.actual_unit_photo" :key="index"
                                         :src="getPropertyImage(image.photo_name)" :alt="property.property_name" />
                                    </picture>
                                </div>
                                <div class="mark-logo">
                                    <img src="frontend/images/logo.png" alt="logo">
                                </div>
                            </div>
                            <div class="property-listing-contant">
                                <div class="project-info">
                                    <h3><a @click="redirectToPropertyDetails(property)">{{ property.property_name }}</a></h3>
                                    <p class="loct-info">
                                        <img src="frontend/images/map-gol-ico.svg">
                                        {{ property.street }}, {{ property.district }}
                                    </p>
                                    <div class="price-pro ms-md-auto">
                                        <span>S$ {{ formatPrice(property.asking_price_sale_rent) }} <small>($
                                                {{computedSQF(property)}}/SQF)</small></span>
                                    </div>
                                    <div class="pro-specify">
                                        <span><i><img src="frontend/images/spec-ico1.svg"></i>{{ property.no_of_rooms }}</span>
                                        <span><i><img src="frontend/images/spec-ico2.svg"></i>{{ property.no_of_baths }}</span>
                                        <span><i><img src="frontend/images/spec-ico3.svg"></i>{{ property.no_car_park_lots }}</span>
                                        <span><i><img src="frontend/images/spec-ico4.svg"></i>{{ property.floor_area }} {{ property.land_area_unit }}</span>
                                    </div>
                                    <div class="pro-tag">
                                        <a v-if="property.property_type_details.parent_id_top_level_1_name" @click="searchByPropertyType(property.property_type_details.parent_id_top_level_1_name)">{{
                                            property.property_type_details.parent_id_top_level_1_code }}</a>
                                        <a v-if="property.property_type_details.name && property.property_type_details.parent_id_top_level_1_name" @click="searchByPropertyType(property.property_type_details.parent_id_top_level_1_name, property.property_type_details.name)">{{
                                            property.property_type_details.code }}</a>
                                        <a v-if="property.land_title_type" @click=searchByLandTitleType(property.land_title_type)>{{ property.land_title_type }}</a>
                                    </div>
                                    <ul>
                                        <li v-if="property.is_in_wishlist">
                                            <figure>
                                                <img src="frontend/images/heart-ico.svg">
                                            </figure>
                                            <small>favorite</small>
                                        </li>
                                        <li v-if="property.is_direct_owner">
                                            <figure>
                                                <img src="frontend/images/specify-ico1.png">
                                            </figure>
                                            <small>Direct<br>Owner</small>
                                        </li>
                                        <li v-if="property.is_sing_pass || property.is_corp_pass ">
                                            <figure>
                                                <img src="frontend/images/specify-ico2.png">
                                            </figure>
                                            <small>SingPass<br>Verified </small>
                                        </li>
                                        <li v-if="property.is_document_approved">
                                            <figure>
                                                <img src="frontend/images/specify-ico3.png">
                                            </figure>
                                            <small>Documents<br>Verified</small>
                                        </li>
                                        <!--<li v-if="property.is_FSM_linked">
                                            <figure>
                                                <img src="frontend/images/specify-ico4.png">
                                            </figure>
                                            <small>Fengshui<br>Map</small>
                                        </li>
                                        <li v-if="property.is_assigned_to_the_agent">
                                            <figure>
                                                <img src="frontend/images/specify-ico5.png">
                                            </figure>
                                            <small>Hybrid-Listing<br>Owners </small>
                                        </li>-->
                                        <li v-if="property.is_below_mean_value">
                                            <figure>
                                                <img src="frontend/images/Down-arrow.svg">
                                            </figure>
                                            <small>BMV</small>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div class="property-listing-profile">
                                <div class="property-l-profile-box">
                                    <div class="profile-box">
                                        <img :src="getUserProfileImage(property.user_id_details?.profile_photo_name)" />
                                        <div>
                                            <span>Posted By</span>
                                            <h5>{{ property.user_id_details?.name }} <img
                                                    src="frontend/images/varify-user.svg" alt=""></h5>
                                        </div>
                                    </div>
                                    <div class="agency-reg-no"> Agency Reg No:<span>
                                            {{property.user_id_details?.user_service_details?.agency_registration_no
                                            }}</span></div>
                                    <div class="property-l-profile-btn">
                                        <a :href="'https://api.whatsapp.com/send?phone=' + property.user_id_details?.mobile_number"
                                            class="comman-btn-2 whatsapp-btn w-100"><b class="mb-0">WhatsApp</b></a>
                                        <a :href="'mailto:' + property.user_id_details?.contact_email"
                                            class="comman-btn-6 w-100"><b>Email</b></a>
                                    </div>
                                </div>
                                <div class="property-l-profile-box">
                                    <p>Partner With Me to Market Your Property</p>
                                    <a :href="'widget-property-post-1/' + property.user_id + '/' + property.property_type"
                                        class="comman-btn-2">
                                        <span>
                                            <b>Create A Hybrid Listing</b><br>
                                            Own Your Story, Amplity its Reach!
                                        </span>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </template>
                    <template v-else-if="loading">
                        <LoaderCust :isLoading="loading"/>
                    </template>
                    <template v-else>
                        <div class="text-center">No records found</div>
                    </template>
                    <div class="pagination-se" v-if="!loading && normalProperties.length > 0">
                        <nav aria-label="...">
                            <ul class="pagination justify-content-center">
                                <li class="page-item" :class="{ disabled: filters.page === 1 }"
                                    @click="filters.page > 1 && changePage(filters.page - 1)" v-if="filters.page > 1">
                                    <a class="page-link"><img src="frontend/images/left-arrow-icon.svg"
                                            alt="Previous"></a>
                                </li>
                                <li v-for="pageNumber in paginatedPages(filters.page, normalTotalPages)" :key="pageNumber"
                                    class="page-item" :class="{ active: pageNumber === filters.page }">
                                    <a class="page-link"
                                        @click.prevent="pageNumber !== filters.page && changePage(pageNumber)">
                                        {{ pageNumber }}
                                    </a>
                                </li>
                                <li class="page-item" :class="{ disabled: filters.page === normalTotalPages }"
                                    @click="filters.page < normalTotalPages && changePage(filters.page + 1)"
                                    v-if="filters.page < normalTotalPages">
                                    <a class="page-link"><img src="frontend/images/right-arrow-icon.svg" alt="Next"></a>
                                </li>
                            </ul>
                        </nav>
                    </div>
                </div>
            </div>
        </section>

        <section class="offer-pro-sec">
            <div class="container">
                <div class="comman-title d-md-flex align-items-center justify-content-between">
                    <div class="offer-heading">
                        <span>Browse more offers</span>
                        <h3>recently browsed properties</h3>
                    </div>
                </div>
                <LoaderCust :isLoading="loading"/>
                <div v-if="!loading && normalProperties && normalProperties.length > 0" class="property-carousel owl-carousel owl-theme">
                    <div v-for="property in normalProperties" :key="property.id" class="item">
                        <div class="project-bx">
                            <!--							<div class="heart-pro">-->
                            <!--								<a href=""><i class="fa fa-heart-o"></i></a>-->
                            <!--							</div>-->
                            <a :href="''">
                                <picture>
                                    <img src="frontend/images/project-img1.jpg">
                                </picture>
                            </a>
                            <div class="project-info">
                                <div class="d-md-flex mb-2 align-items-center">
                                    <h3><a @click="redirectToPropertyDetails(property)">{{ property.property_name }}</a></h3>
                                    <div class="price-pro ms-md-auto">
                                        <span><small>s$</small> {{ formatPrice(property.asking_price_sale_rent) }}</span>
                                    </div>
                                </div>
                                <p class="loct-info"><i><img src="frontend/images/map-gol-ico.svg"></i>{{
                                    property.street }}, {{ property.district }}</p>
                                <div class="pro-specify">
                                    <span><i><img src="frontend/images/spec-ico1.svg"></i>{{ property.no_of_rooms
                                        }}</span>
                                    <span><i><img src="frontend/images/spec-ico2.svg"></i>{{ property.no_of_baths
                                        }}</span>
                                    <span><i><img src="frontend/images/spec-ico3.svg"></i>{{ property.no_car_park_lots
                                        }}</span>
                                    <span><i><img src="frontend/images/spec-ico4.svg"></i>{{ property.floor_area }} {{
                                        property.land_area_unit }}</span>
                                </div>
                                <div class="pro-tag">
                                    <a style="cursor:default" href="javascript:;">{{
                                        property.property_type_details.parent_id_top_level_1_code }}</a>
                                    <a style="cursor:default" href="javascript:;">{{ property.property_type_details.code
                                        }}</a>
                                    <a style="cursor:default" href="javascript:;">{{ property.land_title_type }}</a>
                                </div>
                                <ul>
                                    <li>
                                        <figure>
                                            <img src="frontend/images/specify-ico1.png">
                                        </figure>
                                        <small>Direct<br>Owner</small>
                                    </li>
                                    <li>
                                        <figure>
                                            <img src="frontend/images/specify-ico2.png">
                                        </figure>
                                        <small>SingPass<br>Verified </small>
                                    </li>
                                    <li>
                                        <figure>
                                            <img src="frontend/images/specify-ico3.png">
                                        </figure>
                                        <small>Documents<br>Verified</small>
                                    </li>
                                    <!--<li>
                                        <figure>
                                            <img src="frontend/images/specify-ico4.png">
                                        </figure>
                                        <small>Fengshui<br>Map</small>
                                    </li>
                                    <li>
                                        <figure>
                                            <img src="frontend/images/specify-ico5.png">
                                        </figure>
                                        <small>Hybrid-Listing<br>Owners </small>
                                    </li>-->
                                    <li>
                                        <figure>
                                            <img src="frontend/images/Down-arrow.svg">
                                        </figure>
                                        <small>BMV</small>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
    import ExploreMoreSearch from './ExploreMoreSearch.vue';
    import LoaderCust from '@/components/LoaderCust.vue';
    import toastr from 'toastr';
    import { formatPrice, getUserProfileImage, paginatedPages, getPropertyImage } from '@/utils/helpers';
    import { mapState } from 'vuex';

    export default {
        name: 'ExploreMore',

        components: { ExploreMoreSearch, LoaderCust },

        data() {
            return {
                activeTab: 'Buy',
                featuredProperties:[],
                normalProperties:[],
                featuredTotalPages: 0,
                normalTotalPages: 0,
                featuredTotalRecords: 0,
                normalTotalRecords: 0,
                loading: false,
                filters: {
                    page: 1,
                    sort_by_field: 'Newest',
                    featured_page: 1,
                    featured_sort_by_field: 'Newest',
                },
                formData: {},
                options: {},
                isFeatured: null,
            }
        },

        created() {
            if (this.$route.query.formData) {
                // Replace the route with the same path but without query parameters (during F5 remove params)
                this.$router.replace({ path: this.$route.path });
            }
        },

        mounted() {
            window.$(document).ready(function () {
                window.$('.property-image-carousel').owlCarousel({
                    loop: false,
                    margin: 20,
                    nav: true,
                    dots: false,
                    navText: ['<i class="fa fa-angle-left"></i>', '<i class="fa fa-angle-right"></i>'],
                    responsive: {
                        0: {
                            items: 1,
                            margin: 10
                        },
                        600: {
                            items: 2
                        },
                        1000: {
                            items: 3
                        }
                    }
                })
                window.$('.testimonail-carousel').owlCarousel({
                    loop: false,
                    margin: 25,
                    nav: false,
                    dots: true,
                    responsive: {
                        0: {
                            items: 1
                        },
                        600: {
                            items: 2
                        },
                        1000: {
                            items: 3
                        }
                    }
                })
            });

            this.getFilters();
        },

        computed: {
            ...mapState(['user']),
        },

        methods: {
            formatPrice,
            getUserProfileImage,
            getPropertyImage,
            paginatedPages,

            updateOptions(newOptions) {
                this.options = newOptions;
            },

            searchByPropertyType(parentCategoryName, childCategoryName) {
                let categoryIds = [];
                let params = {
                    selectedType: "",
                    selectedCategories: {},
                    property_type: null,
                    listingType: "",
                    gender: ""
                };
                params.selectedType = parentCategoryName;
                const categoryArray = this.options.propertyTypes[parentCategoryName];
                for (const category of categoryArray) {
                    // if no child, push parent category
                    if (!childCategoryName) {
                        categoryIds.push(category.id);
                    }
                    // Check within children
                    if (category.children && category.children.length > 0) {
                        if (childCategoryName) {
                            // push parent category and child category
                            const childMatch = category.children.find((child) => child.name === childCategoryName);
                            if (childMatch) {
                                categoryIds.push(category.id);
                                categoryIds.push(childMatch.id);
                            }
                        } else {
                            category.children.forEach(childCategory => {
                                categoryIds.push(childCategory.id);
                            });
                        }
                    }
                }
                if (!params.selectedCategories) {
                    params.selectedCategories = [];
                }
                params.selectedCategories[parentCategoryName] = categoryIds;
                params.property_type = [...new Set(categoryIds)].join(',');

                this.formData = params;
                this.fetchAllProperties(params);
            },

            searchByLandTitleType(landTitleName) {
                let params = {
                    land_title_type: landTitleName,
                };

                this.fetchAllProperties(params);
            },

            async wishlistProperty(property) {
                try {
                    if (!this.user?.id) {
                        toastr.error('Please log in to wishlist', 'error', {
                            progressBar: true,
                            closeButton: true,
                            timeOut: 1500,
                        });
                        return;
                    }
                    const wishlistResponse = await this.$axios.post(`api/users/${this.user.id}/wishlist/properties/${property.id}`, {
                        api_token: localStorage.getItem('userToken'),
                    });

                    if (wishlistResponse.status === 200) {
                        toastr.success(wishlistResponse.data.message, 'Success', {
                            progressBar: true,
                            closeButton: true,
                            timeOut: 1500,
                            onHidden: () => {
                                this.fetchAllProperties();
                            }
                        });
                    }
                } catch (error) {
                    console.error('Error wishlisting property:', error);
                }
            },

            getFilters() {
                const formDataString = this.$route.query.formData;
                const parsedFormData = formDataString ? JSON.parse(formDataString) : {};
                this.formData = {
                    gender: parsedFormData.gender || '',
                    dateOfBirth: parsedFormData.dateOfBirth || '',
                    keywords: parsedFormData.keywords || '',
                    listingType: parsedFormData.listingType || '',
                    selectedCategories: parsedFormData.selectedCategories || {},
                    selectedType: parsedFormData.selectedType || null,
                    selectedCountry: parsedFormData?.selectedCountry || null,
                    is_featured: parsedFormData?.is_featured || null,
                    is_FSM_linked: parsedFormData?.is_FSM_linked || null,
                    is_widget_property: parsedFormData?.is_widget_property || null,
                    is_curated_property: parsedFormData?.is_curated_property || null,
                };

                if (Object.prototype.hasOwnProperty.call(parsedFormData, 'sort_by_field')) {
                    this.filters.sort_by_field = parsedFormData.sort_by_field;
                }
                this.activeTab = this.$route.query.currentTab || 'Buy';
                this.fetchAllProperties();
            },
            setActiveTab(tab) {
                if (tab !== this.activeTab) {
                    this.formData.listingType = '';
                }
                this.activeTab = tab;
                this.fetchAllProperties();
            },

            computedSQF(property) {
                return property.asking_price_sale_rent > 0 ? Math.ceil(property.asking_price_sale_rent / property.floor_area) : 0;
            },

            async fetchAllProperties(params = null) {
                await this.fetchProperties(true, params);
                await this.fetchProperties(false, params);
            },

            async fetchProperties(isFeatured, params = null) {
                this.loading = true;
                try {
                    let parameters = {
                        is_for_sale: this.activeTab == "Buy" ? 1 : 0,
                        is_for_rent: this.activeTab == "Rent" ? 1 : 0,
                        sort_by_field: isFeatured ? this.filters.featured_sort_by_field : this.filters.sort_by_field,
                        page_number: isFeatured ? this.filters.featured_page : this.filters.page,
                        user_id: this.user?.id,
                        listing_type: this.formData?.listingType,
                        is_featured: isFeatured,
                        selected_country: this.formData?.selectedCountry,
                    };
                    if (params) {
                        parameters = { ...parameters, ...params };
                    } else {
                        let otherParams = {
                            property_type: this.formData?.selectedType
                                ? this.formData?.selectedCategories[this.formData.selectedType]?.join(',')
                                : '',
                            keywords: this.formData?.keywords,
                            is_FSM_linked: this.formData?.is_FSM_linked,
                            is_widget_property: this.formData?.is_widget_property,
                            is_curated_property: this.formData?.is_curated_property,
                            land_title_type: this.formData?.land_title_type,
                            is_featured: isFeatured,
                            selected_country: this.formData?.selectedCountry,
                        };
                        parameters = { ...parameters, ...otherParams };
                    }

                    const propertiesResponse = await this.$axios.post('api/property-search-browse', parameters);

                    if (isFeatured) {
                        this.featuredProperties = propertiesResponse.data.output.property_list ?? [];
                        this.featuredTotalPages = propertiesResponse.data.output.no_of_total_pages ?? 0;
                        this.featuredTotalRecords = propertiesResponse.data.output.no_of_total_records ?? 0;
                        this.isFeatured = propertiesResponse.data.output.is_featured;
                    } else {
                        this.normalProperties = propertiesResponse.data.output.property_list ?? [];
                        this.normalTotalPages = propertiesResponse.data.output.no_of_total_pages ?? 0;
                        this.normalTotalRecords = propertiesResponse.data.output.no_of_total_records ?? 0;
                        this.isFeatured = propertiesResponse.data.output.is_featured;
                    }

                    this.$nextTick(() => {
                        this.initializeOwlCarousel();
                    });
                } catch (error) {
                    console.error('Error fetching properties:', error);
                } finally {
                    this.loading = false;
                }
            },
            sortProperties(isFeatured, sortBy) {
                if (isFeatured) {
                    this.filters.featured_sort_by_field = sortBy;
                    this.filters.featured_page = 1;
                } else {
                    this.filters.sort_by_field = sortBy;
                    this.filters.page = 1;
                }

                this.fetchProperties(isFeatured);
            },

            changePage(page, isFeatured) {
                if (page > 0) {
                    const totalPages = isFeatured ? this.featuredTotalPages : this.normalTotalPages;
                    if (page <= totalPages) {
                        if (isFeatured) {
                            this.filters.featured_page = page;
                        } else {
                            this.filters.page = page;
                        }
                        this.fetchProperties(isFeatured);
                    }
                }
            },

            submitSearch(data) {
                this.formData = data;
                this.filters.page = 1;
                this.filters.featured_page = 1;
                this.fetchAllProperties();
            },

            initializeOwlCarousel() {
                window.$('.property-carousel').owlCarousel({
                    loop: false,
                    margin: 0,
                    nav: true,
                    dots: true,
                    navText: ['<i class="fa fa-angle-left"></i>', '<i class="fa fa-angle-right"></i>'],
                    responsive: {
                        0: {
                            items: 1,
                            margin: 0
                        },
                        600: {
                            items: 1
                        },
                        1000: {
                            items: 1
                        }
                    }
                });
            },
            redirectToPropertyDetails(property) {
                window.location.href = window.FRONTEND_URL + `/property-detail/${property.id}`;
            }
        }
    }
</script>

<style scoped>
    .dropdown-item:hover,
    .dropdown-item.selected {
        background-color: #ffe6b9;
    }

    .loader {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 20px;
        margin-top: 20px;
    }
</style>
<template>    
    <section class="property-slider-se">
        <div class="container">
            <div class="property-slider">
                <div class="property-slider-left">
                    <div class="property-slider-f-slider owl-carousel owl-theme">
                        <div class="item" v-for="pimage in propertyImages" :key="pimage.project_listing_id">
                            <div class="property-slider-images">
                                <img :src="`${CONSTANT.IMAGE_URL}/imagesProjectListing/${pimage?.photo_name}`" :alt="pimage?.photo_name" >
                            </div>
                        </div>
                    </div>
                </div>
                <div class="property-slider-right">
                    <div class="property-slider-thumb">
                        <img :src="`${CONSTANT.IMAGE_URL}/imagesProjectListing/${firstImage?.photo_name}`" :alt="firstImage?.photo_name">
                    </div>
                    <div class="property-slider-thumb mb-0">
                        <a  v-for="(image, index) in propertyImages" :key="index" :class="{'d-none': index != 0}" :href="`${CONSTANT.IMAGE_URL}/imagesProjectListing/${image?.photo_name}`" data-lightbox="gallery" :data-title="image?.photo_name">
                            <img :src="`${CONSTANT.IMAGE_URL}/imagesProjectListing/${image?.photo_name}`" :alt="image?.photo_name" class="img-fluid">
                            <span class="overlay">{{morePhotocount}} photos </span>
                        </a>
                    </div>
                </div>
            </div>


            <div class="property-details-se">
                <div class="row">
                    <div class="col-md-8">
                        <div class="property-title-se">
                            <h3> {{ projectDetail.project_name }}
                                <img src="frontend/images/varify-user.svg" alt="">
                                <img src="frontend/images/file-check.svg" alt=""> </h3>
                            <span class="property-price">S$ {{ formatPrice(projectDetail.listing_price_from) }} - S$ {{ formatPrice(projectDetail.listing_price_to) }}.00</span>
                        </div>
                        <div class="property-meta-se row">
                            <ul>
                                <li><i class="fa fa-map-pin"></i><span><b>{{ formattedAddress }}</b></span></li>
                                <li><i class="fa fa-home"></i><span><b>listing reference ID: {{this.itemId }}</b></span></li>
                                <li><i class="fa fa-calendar"></i><span><b>listing Status: {{ publishedTillDate }}</b></span></li>
                            </ul>
                            <ul class="d-flex justify-content-end align-content-end">
                                <li>
                                    <button type="button" class="btn" data-bs-toggle="modal" data-bs-target="#shareProjectModal">
                                        <img src="frontend/images/share.svg" alt=""/>
                                    </button>
                                </li>
                            </ul>
                        </div>
                        <div class="modal fade" id="shareProjectModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
                             aria-hidden="true">
                            <div class="modal-dialog modal-lg modal-dialog-centered">
                                <div class="modal-content">
                                    <div class="modal-header">
                                        <h1 class="modal-title fs-5">Share Project</h1>
                                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"><img
                                            src="frontend/images/close.svg" alt=""></button>
                                    </div>
                                    <div class="modal-body">
                                        <div class="row row-cols-1 row-cols-sm-4 share-property-sec">
                                            <div class="col mb-3">
                                                <div class="card bpt-box" @click="handleShare('copy')" style="cursor: pointer;">
                                                    <picture>
                                                        <img src="frontend/images/copy-link.png" class="card-img-top"
                                                             alt="Copy Link Icon">
                                                    </picture>
                                                    <div class="card-body align-items-center d-flex justify-content-center">
                                                        <span class="card-title mb-0">Copy Link</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col mb-3">
                                                <div class="card bpt-box" @click="handleShare('email')" style="cursor: pointer;">
                                                    <picture>
                                                        <img src="frontend/images/email-icon.svg" class="card-img-top"
                                                             alt="Email Icon">
                                                    </picture>
                                                    <div class="card-body align-items-center d-flex justify-content-center">
                                                        <span class="card-title mb-0">Email</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <!--<div class="col mb-3">
                                                <div class="card bpt-box" @click="handleShare('message')" style="cursor: pointer;">
                                                    <picture>
                                                        <img src="frontend/images/message.png" class="card-img-top"
                                                            alt="Message Icon">
                                                    </picture>
                                                    <div class="card-body align-items-center d-flex justify-content-center">
                                                        <span class="card-title mb-0">Message</span>
                                                    </div>
                                                </div>
                                            </div>-->
                                            <div class="col mb-3">
                                                <div class="card bpt-box" @click="handleShare('whatsapp')" style="cursor: pointer;">
                                                    <picture>
                                                        <img src="frontend/images/whatsapp.png" class="card-img-top"
                                                             alt="Whatsapp Icon">
                                                    </picture>
                                                    <div class="card-body align-items-center d-flex justify-content-center">
                                                        <span class="card-title mb-0">Whatsapp</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col mb-3">
                                                <div class="card bpt-box" @click="handleShare('messenger')" style="cursor: pointer;">
                                                    <picture>
                                                        <img src="frontend/images/messenger.png" class="card-img-top"
                                                             alt="Whatsapp Icon">
                                                    </picture>
                                                    <div class="card-body align-items-center d-flex justify-content-center">
                                                        <span class="card-title mb-0">Messenger</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col mb-3">
                                                <div class="card bpt-box" @click="handleShare('twitter')" style="cursor: pointer;">
                                                    <picture>
                                                        <img src="frontend/images/twitter.png" class="card-img-top"
                                                             alt="Twitter Icon">
                                                    </picture>
                                                    <div class="card-body align-items-center d-flex justify-content-center">
                                                        <span class="card-title mb-0">Twitter (X)</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col mb-3">
                                                <div class="card bpt-box" @click="handleShare('facebook')" style="cursor: pointer;">
                                                    <picture>
                                                        <img src="frontend/images/fb-ico.svg" class="card-img-top"
                                                             alt="Facebook Icon">
                                                    </picture>
                                                    <div class="card-body align-items-center d-flex justify-content-center">
                                                        <span class="card-title mb-0">Facebook</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col mb-3">
                                                <div class="card bpt-box" @click="handleShare('embed')" style="cursor: pointer;">
                                                    <picture>
                                                        <img src="frontend/images/embed.png" class="card-img-top"
                                                             alt="Embed Icon">
                                                    </picture>
                                                    <div class="card-body align-items-center d-flex justify-content-center">
                                                        <span class="card-title mb-0">Embed</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="property-details-btn-se justify-content-between">
                            <ul class="flex align-items-center">
                                <li><a :class="{ active: selectedTab === 'projectDetails' }" @click="selectedTab = 'projectDetails'">Project details</a></li>
                                <li><a :class="{ active: selectedTab === 'fengShui' }" @click="selectedTab = 'fengShui'">Feng Shui</a></li>
                            </ul>
                            <button v-if="this.coop_id === user.id && projectDetail.is_active === 1" class="comman-btn-2" data-bs-toggle="modal" data-bs-target="#feature_project">
                                <b>Feature Your Project</b>
                            </button>
                        </div>
                        <ProjectBasicInformation v-if="selectedTab === 'projectDetails'" @refetch-project="fetchOptions" :project-detail="projectDetail" :project-user-detail="projectUserDetail"
                        :featuresList="featuresList" :add_featuresList="add_featuresList" :facilitiyList="facilitiyList" :add_facilitiyList="add_facilitiyList"
                        :unit_layouts="unit_layouts" :mode_of_payment="mode_of_payment" :otherDocList="otherDocList" :mediaLinksList="mediaLinksList"
                        :propertyType="propertyType" :rentalPriodLIst="rentalPriodLIst">
                        </ProjectBasicInformation>
                        <ProjectFengShui v-if="selectedTab === 'fengShui'" :project-detail="projectDetail">
                        </ProjectFengShui>
                        <div class="feature-project-se">
                            <div class="modal fade" id="feature_project" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                                <div class="modal-dialog modal-dialog-centered">
                                    <div class="modal-content">
                                        <div class="modal-header">
                                            <h5 class="modal-title text-warning" id="feature_projectLabel">Feature Your Project Now</h5>
                                        </div>
                                        <div v-if="isFeatured" class="modal-body">
                                            <h4><b>Congrats, this project is being featured</b></h4>
                                            <br>
                                            <label><b>Feature From:</b> {{ featuredFrom }}</label>
                                            <label><b>Feature To:</b> {{ featuredTo }}</label>
                                            <div class=" d-flex justify-content-end">
                                                <button type="button" class="comman-btn-2-danger" data-bs-dismiss="modal">Close</button>
                                            </div>
                                        </div>
                                        <div v-else>
                                            <div class="d-flex justify-content-start mt-3 ps-3">
                                                <h4>Propface now offer featuring service</h4>
                                            </div>
                                            <div class="px-3">
                                                <p>With just <b>10 credits</b>, you can get your property featured up to <b>8 days</b></p>
                                                <label>Current Credit Balance</label>
                                                <p><b>{{ userCredit ?? '0' }} Credits</b></p>
                                                <div class="modal-footer">
                                                    <button type="button" class="comman-btn-2-danger" data-bs-dismiss="modal">Close</button>
                                                    <a v-if="userCredit < 10" class="comman-btn-2" href="/top-up" onclick="$('#feature_project').modal('hide')">Top-up</a>
                                                    <button v-else type="button" class="comman-btn-2" data-bs-toggle="modal" data-bs-dismiss="modal" data-bs-target="#confirmationFeature">Feature Now</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="modal fade" id="confirmationFeature" data-bs-backdrop="static" data-bs-keyboard="false" aria-hidden="true" aria-labelledby="exampleModalToggleLabel2" tabindex="-1">
                                <div class="modal-dialog modal-dialog-centered">
                                    <div class="modal-content">
                                        <div class="modal-header">
                                            <h5 class="modal-title" id="confirmationFeatureLabel">Confirmation</h5>
                                        </div>
                                        <div class="modal-body">
                                            <p class="mb-3">10 Credits will be deducted from your Account.</p>
                                            <label>Credit Balance after deduction</label>
                                            <p><b>{{ userCredit - 10 ?? '0' }} Credits</b></p>
                                        </div>
                                        <div class="modal-footer">
                                            <button type="button" class="comman-btn-2-danger" data-bs-dismiss="modal">Close</button>
                                            <button class="comman-btn-2" data-bs-dismiss="modal" @click="featureProject">Yes, I'm Sure</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="property-siderbar-boxs">
                            <div class="profile-box">
                                <img :src="computedProfileImgSrc" >
                                <div>
                                    <h5>{{projectUserDetail.name}} <img src="frontend/images/varify-user.svg" alt=""></h5>
                                    <span>Singapore</span>
                                </div>
                            </div>
                            <div class="list-box">
                              <ul>
                                <li v-if="user_service_details">Position: <span><b>{{user_service_details.position_held}}</b></span></li>
                                <li v-if="user_service_details">CEA Registration No. <span><b>{{ user_service_details.agency_registration_no }}</b></span></li>
                                <li v-if="user_service_details">Agency Name <span><b>{{user_service_details.agency_name}}</b></span></li>
                                <li v-if="user_service_details">Agency License no. <span><b>{{user_service_details.agency_license}}</b></span></li>
                                <li>Mobile Number:<span><b>{{ maskedNumber(projectUserDetail.mobile_number) }}</b></span></li>
                                <!-- <li>Email:<span><b>{{projectUserDetail.email}}</b></span></li> -->
                              </ul>
                            </div>
                            <div class="property-siderbar-2-btn">
                              <a :href="'/agents/' +  projectUserDetail?.id" class="comman-btn-2 w-100"><b>View my properties</b></a>
                              <a v-if="this.user.id !== projectUserDetail?.id" :href="'https://api.whatsapp.com/send?phone=' + projectUserDetail.mobile_number" class="comman-btn-2 w-100" @click="requestWhatsapp">
                                  <b>Request now</b>
                              </a>
                            </div>
                        </div>
                        <PersonalAffinityStars></PersonalAffinityStars>
                        <div class="property-siderbar-boxs">
                            <div class="comman-title">
                                <h3>Auspicious dates </h3>
                            </div>
                            <div class="calendar">
                                <header>
                                    <pre class="left"><i class="fa fa-angle-left"></i></pre>
                                    <div class="header-display">
                                        <p class="display mb-0">""</p>
                                    </div>
                                    <pre class="right"><i class="fa fa-angle-right"></i></pre>
                                </header>
                        
                                <div class="week">
                                    <div>S</div>
                                    <div>M</div>
                                    <div>T</div>
                                    <div>W</div>
                                    <div>T</div>
                                    <div>F</div>
                                    <div>S</div>
                                </div>
                                <div class="days"></div>
                            </div>
                            <div class="display-selected">
                                <p class="selected"></p>
                            </div>
                            <div class="lrm-czh-se">
                                <div class="">
                                    Legend
                                    <button type="button" :class="'btn ' + (auspicious.type === 'renovation' ? 'comman-btn-3' : 'comman-btn-2')" @click="auspicious.type = 'renovation'">Renovation</button>
                                    <button type="button" :class="'btn ' + (auspicious.type === 'move_in' ? 'comman-btn-3' : 'comman-btn-2')" @click="auspicious.type = 'move_in'">Move In</button>
                                </div>
                                <div class="">
                                    Clashes
                                    <select v-model="auspicious.zodiac" class="form-control">
                                        <option value="">Zodiac Sign</option>
                                        <option v-for="(zodOpt, index) in options.zodiac" :key="'zod'+index" :value="zodOpt">
                                            {{ zodOpt }}
                                        </option>
                                    </select>
                                    <select v-model="auspicious.house_direction" class="form-control">
                                        <option value="">House Direction</option>
                                        <option v-for="(houseDirOpt, ind) in options.house_direction" :key="'houseDir'+ind"
                                            :value="houseDirOpt">
                                            {{ houseDirOpt }}
                                        </option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div class="property-siderbar-boxs">
                            <div class="comman-title">
                                <h3>Schedule tour </h3>
                            </div>
                            <form>
                                <div class="form-group">
                                    <input type="text" placeholder="Full name" class="form-control">
                                </div>
                                <div class="form-group">
                                    <input type="text" placeholder="Email address" class="form-control">
                                </div>
                                <div class="form-group">
                                    <input type="text" placeholder="Phone number" class="form-control">
                                </div>
                                <div class="form-group">
                                    <textarea placeholder="Writer message here" class="form-control"></textarea>
                                </div>				
                                <div class="form-group">
                                    <div class="form-check">
                                        <input type="checkbox" class="form-check-input" value="" id="Are-you-agree">
                                        <label class="form-check-label" for="Are-you-agree"><b>Do you agree?</b></label>
                                    </div>
                                </div>
                                <button class="comman-btn-2 w-100"><b>Interested</b></button>
                            </form>
                        </div>
                        <div class="property-siderbar-boxs">
                            <div class="comman-title">
                                <h3>Mortgage calculator</h3>
                            </div>
                            <p>{{ formattedAddress }}</p>
                            <div class="calculator-mortgage">
                                <div class="form-inline">
                                    <div class="form-group">
                                        <label><b>Property value</b></label>
                                        <div class="input-group">
                                            <div class="input-group-prepend">
                                                <div class="input-group-text">S$</div>
                                            </div>
                                            <input type="text" class="form-control" placeholder="Property value" v-model="mortgage.property_value">
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <label class=""><b>Loan Amount (%)</b></label>
                                    <div class="range-item">
                                        <div class="range-input d-flex position-relative">
                                            <input type="range" min="0" max="75" class="form-range" name="dataShared"
                                                   v-model="mortgage.loan_percentage" />
                                            <div class="range-line">
                                                <span class="active-line"
                                                      :style="{ width: mortgage.loan_percentage >= 75 ? '95%' : (mortgage.loan_percentage / 75 * 95) + '%' }">
                                                </span>
                                            </div>
                                            <div class="dot-line">
                                                <span class="active-dot"
                                                      :style="{ left: mortgage.loan_percentage >= 75 ? '95%' : (mortgage.loan_percentage / 75 * 95) + '%' }">
                                                </span>
                                            </div>
                                        </div>
                                        <ul class="list-inline list-unstyled">
                                            <li class="list-inline-item">
                                                <span>0</span>
                                            </li>
                                            <li class="list-inline-item">
                                                <span>15</span>
                                            </li>
                                            <li class="list-inline-item">
                                                <span>30</span>
                                            </li>
                                            <li class="list-inline-item">
                                                <span>45</span>
                                            </li>
                                            <li class="list-inline-item">
                                                <span></span>
                                            </li>
                                            <li class="list-inline-item">
                                                <span>75</span>
                                            </li>
                                        </ul>
                                    </div>
                                    <input type="text" class="form-control text-center" placeholder="Loan Amount"
                                           v-model="mortgage.loan_percentage">
                                </div>
                                <div class="form-group">
                                    <label class=""><b>Tenure (years)</b></label>
                                    <div class="range-item">
                                        <div class="range-input d-flex position-relative">
                                            <input type="range" min="0" max="35" class="form-range" name="dataShared"
                                                   v-model="mortgage.tenure" />
                                            <div class="range-line">
                                                <span class="active-line"
                                                      :style="{ width: mortgage.tenure >= 35 ? '95%' : (mortgage.tenure / 35 * 95) + '%' }">
                                                </span>
                                            </div>
                                            <div class="dot-line">
                                                <span class="active-dot"
                                                      :style="{ left: mortgage.tenure >= 35 ? '93%' : (mortgage.tenure / 38 * 93) + '%' }">
                                                </span>
                                            </div>
                                        </div>
                                        <ul class="list-inline list-unstyled">
                                            <li class="list-inline-item">
                                                <span>0</span>
                                            </li>
                                            <li class="list-inline-item">
                                                <span>10</span>
                                            </li>
                                            <li class="list-inline-item">
                                                <span>20</span>
                                            </li>
                                            <li class="list-inline-item">
                                                <span></span>
                                            </li>
                                            <li class="list-inline-item">
                                                <span>35</span>
                                            </li>
                                        </ul>
                                    </div>
                                    <input type="text" class="form-control text-center" placeholder="Tenure"
                                           v-model="mortgage.tenure">
                                </div>
                                <div class="form-group">
                                    <label class=""><b>Interest Rate (%)</b></label>
                                    <div class="range-item">
                                        <div class="range-input d-flex position-relative">
                                            <input type="range" min="0" max="6" class="form-range" name="dataShared"
                                                   v-model="mortgage.interest_rate_percentage" />
                                            <div class="range-line">
                                                <span class="active-line"
                                                      :style="{ width: mortgage.interest_rate_percentage >= 6 ? '95%' : (mortgage.interest_rate_percentage / 7 * 93) + '%' }">
                                                </span>
                                            </div>
                                            <div class="dot-line">
                                                <span class="active-dot"
                                                      :style="{ left: mortgage.interest_rate_percentage >= 6 ? '95%' : (mortgage.interest_rate_percentage / 7 * 93) + '%' }">
                                                </span>
                                            </div>
                                        </div>
                                        <ul class="list-inline list-unstyled">
                                            <li class="list-inline-item">
                                                <span>0</span>
                                            </li>
                                            <li class="list-inline-item">
                                                <span>2</span>
                                            </li>
                                            <li class="list-inline-item">
                                                <span></span>
                                            </li>
                                            <li class="list-inline-item">
                                                <span></span>
                                            </li>
                                            <li class="list-inline-item">
                                                <span>6</span>
                                            </li>
                                        </ul>
                                    </div>
                                    <input type="text" class="form-control text-center" placeholder="Interest Rate"
                                           v-model="mortgage.interest_rate_percentage">
                                </div>
                            </div>
                            <div class="card">
                                <div class="card-header">
                                    <h5>your mortgage breakdown</h5>
                                </div>
                                <div class="card-body">
                                    <div class="row row-cols-md-2">
                                        <div class="col">
                                            <div class="chart-box">
                                                <span class="mb-0">Loan amount ({{ mortgage.loan_percentage }}%)</span>
                                                <p class="mb-2">S$ {{ loan_amount.toFixed(2) }}</p>
                                                <div class="circal-chart">
                                                    <img src="frontend/images/downpayment-circal.svg" alt="">
                                                    <p>S$ {{ down_payment.toFixed(2) }}</p>
                                                    <span>Downpayment</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col">
                                            <div class="chart-box">
                                                <p class="mb-0">Monthly Repayment</p>
                                                <span class="mb-2">
                                                    <img src="frontend/images/yellow-dot.png" alt="">
                                                    S$ {{ monthly_repayment_principal.toFixed(2) }} Principal <br>
                                                    <img src="frontend/images/blue-dot.png" alt="">
                                                    S$ {{ monthly_repayment_interest.toFixed(2) }} Int</span>
                                                <div class="circal-chart">
                                                    <img src="frontend/images/month-circal.svg" alt="">
                                                    <p>S$ {{ monthly_repayment_amount.toFixed(2) }}</p>
                                                    <span>/month</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <section class="recnet-list-sec" v-if="response.sameLocationListings && response.sameLocationListings.length > 0">
        <LoaderCust :isLoading="loading"/>
        <div class="container" v-if="!loading">
            <div class="comman-title d-md-flex align-items-center justify-content-between">
                <div class="offer-heading">
                    <h3>Nearby Listings</h3>
                </div>
            </div>
            <div class="row row-cols-lg-4 row-cols-md-2 row-cols-sm-2">
                <div class="col" v-for="item in response.sameLocationListings.slice(0,4)" :key="item.id">
                    <ProjectBox :item="item"></ProjectBox>
                </div>
            </div>
        </div>
    </section>


    <section class="offer-pro-sec" v-if="response.sameOwnerListings && response.sameOwnerListings.length > 0">
        <LoaderCust :isLoading="loading"/>
        <div class="container" v-if="!loading">
            <div class="comman-title d-md-flex align-items-center justify-content-between">
                <div class="offer-heading">
                    <h3>Listings By Same Agent/Advertiser</h3>
                </div>
            </div>
            <div class="row row-cols-lg-4 row-cols-md-2 row-cols-sm-2">
                <div class="col" v-for="item in response.sameOwnerListings.slice(0,4)" :key="item.id">
                    <ProjectBox :item="item"></ProjectBox>
                </div>
            </div>
        </div>
    </section>


    <section class="recnet-list-sec" v-if="response.samePriceListings && response.samePriceListings.length > 0">
        <LoaderCust :isLoading="loading"/>
        <div class="container" v-if="!loading">
            <div class="comman-title d-md-flex align-items-center justify-content-between">
                <div class="offer-heading">
                    <h3>Similar Property Price</h3>
                </div>
            </div>
            <div class="row row-cols-lg-4 row-cols-md-2 row-cols-sm-2">
                <div class="col" v-for="item in response.samePriceListings.slice(0,4)" :key="item.id">
                    <ProjectBox :item="item"></ProjectBox>
                </div>
            </div>
        </div>
    </section>

</template>
  
<script>
import { CONSTANT } from '@/constants/constants';
import { OPTIONS_GROUP_1,PROPERTY_TYPE,RENTAL_PERIOD } from '@/constants/constantsProperty';
import { mapState } from 'vuex';
// Import Lightbox2 CSS and JS
import 'lightbox2/dist/css/lightbox.min.css';
import 'lightbox2/dist/js/lightbox-plus-jquery.min.js';
import ProjectBasicInformation from "@/views/project/ProjectBasicInformation.vue";
import ProjectFengShui from "@/views/project/ProjectFengShui.vue";
import PersonalAffinityStars from "@/views/fengshui/PersonalAffinityStars.vue";
import toastr from "toastr";
import LoaderCust from "@/components/LoaderCust.vue";
import ProjectBox from "@/views/home/ProjectBox.vue";
import { formatPrice } from "@/utils/helpers";

export default {
    name: 'ProjectDetail',
    components: {ProjectBox, LoaderCust, ProjectBasicInformation, PersonalAffinityStars, ProjectFengShui},
    data() {
        return {
            selectedTab: 'projectDetails',
            loading: false,
            user_service_details: [],
            otherDocList: [],
            mediaLinksList: [],
            rentalPriodLIst: RENTAL_PERIOD,
            CONSTANT,
            listingType: OPTIONS_GROUP_1,
            propertyType: PROPERTY_TYPE,
            projectDetail : [''],
            projectUserDetail : [],
            propertyImages : [],
            morePhotocount : 0,
            firstImage : [],
            secondImage : [],
            featuresList : [],
            add_featuresList : [],
            facilitiyList : [],
            add_facilitiyList : [],
            mode_of_payment:[],
            listing_type_details:[],
            unit_layouts: [],
            coop_id: null,
            isFeatured: null,
            featuredTo: null,
            featuredFrom: null,
            userCredit: null,
            publishedTillDate: null,
            mortgage: {
                property_value: 0,
                loan_percentage: 75,
                tenure: 10,
                interest_rate_percentage: 2,
            },
            loan_amount: 0,
            down_payment: 0,
            monthly_repayment_amount: 0,
            monthly_repayment_interest: 0,
            monthly_repayment_principal: 0,
            response : {},
            auspicious: {
                type: 'renovation',
                zodiac: null,
                house_direction: null,
                date_month: null
            },
            good_dates: [],
            bad_dates: [],
            options: {
                zodiac: [
                    'Boar',
                    'Dog',
                    'Dragon',
                    'Goat',
                    'Horse',
                    'Monkey',
                    'Ox',
                    'Rabbit',
                    'Rat',
                    'Rooster',
                    'Snake',
                    'Tiger'
                ],
                house_direction: [
                    'N', 'E', 'S', 'W'
                ]
            },
            firstLoadDetail: true,
        }
    },
    mounted() {
        let display = document.querySelector(".display");
        let days = document.querySelector(".days");
        let previous = document.querySelector(".left");
        let next = document.querySelector(".right");
        //let selected = document.querySelector(".selected");

        let date = new Date();

        let year = date.getFullYear();
        let month = date.getMonth();
        this.auspicious.date_month = month + 1;

        function displayCalendar() {
            const firstDay = new Date(year, month, 1);
            const lastDay = new Date(year, month + 1, 0);

            const firstDayIndex = firstDay.getDay();
            const numberOfDays = lastDay.getDate();

            let formattedDate = date.toLocaleString("en-US", {
                month: "long",
                year: "numeric"
            });

            display.innerHTML = `${formattedDate}`;

            days.innerHTML = ''; // Clear days before repopulating

            for (let x = 0; x < firstDayIndex; x++) {
                const div = document.createElement("div");
                days.appendChild(div);
            }

            for (let i = 1; i <= numberOfDays; i++) {
                let div = document.createElement("div");
                let currentDate = new Date(year, month, i);

                div.dataset.date = currentDate.toDateString();
                div.innerHTML = i;
                div.classList.add("day-" + i);

                days.appendChild(div);
            }
        }


        // Call the function to display the calendar
        displayCalendar();

        previous.addEventListener("click", () => {
            month--;
            if (month < 0) {
                month = 11;
                year--;
            }
            date.setFullYear(year);
            date.setMonth(month);
            this.auspicious.date_month = month + 1;
            displayCalendar();
            this.displaySelected();
        });

        next.addEventListener("click", () => {
            month++;
            if (month > 11) {
                month = 0;
                year++;
            }
            date.setFullYear(year);
            date.setMonth(month);
            this.auspicious.date_month = month + 1;
            displayCalendar();
            this.displaySelected();
        });

        // Initialize the calendar and selected date display
        this.displaySelected();
    },
    created() {
        this.fetchOptions();
        this.fetchRecOffProject();
    },
    computed: {
        ...mapState(['isAuthenticated', 'user']),
        itemId() {
            return this.$route.params.id;
        },
        computedProfileImgSrc() {
            return this.projectUserDetail.profile_photo_name ? `${CONSTANT.IMAGE_URL}/imagesProfile/${this.projectUserDetail.profile_photo_name}` : CONSTANT.DEFAULT_IMAGE;
        },
        formattedAddress() {
            let addressParts = [
                this.projectDetail.street,
                this.projectDetail.area_hdb_estate,
                this.projectDetail.district
            ];
            let address = addressParts.filter(part => part !== null && part !== undefined).join(', ');
            return address || '-';
        }
    },
    watch: {
        mortgage: {
            handler() {
                this.updateMortgageValue();
             },
            deep: true
        },
        auspicious: {
            handler() {
                this.getAuspiciousDates();
            },
            deep: true
        },
    },
    methods: {
        formatPrice,
        displaySelected() {
            const dayElements = document.querySelectorAll(".days div");
            const goodDates = this.good_dates || [];
            const badDates = this.bad_dates || [];

            dayElements.forEach(dayElement => {
                dayElement.classList.remove('bad-date', 'good-date');
                // Extract the day number from the class (e.g., day-1, day-2)
                const dayNumber = dayElement.classList.length > 0 ? parseInt(dayElement.classList[0].split('-')[1]) : null;
                if (dayNumber) {
                    if (goodDates.includes(dayNumber)) {
                        dayElement.classList.add('good-date');
                    }

                    if (badDates.includes(dayNumber)) {
                        dayElement.classList.add('bad-date');
                    }
                }
            });
        },
        async getAuspiciousDates() {
            try {
                let params = {
                    renovation: this.auspicious.type === 'renovation',
                    move_in: this.auspicious.type === 'move_in',
                    clash_zodiac: this.auspicious.zodiac,
                    clash_house_direction: this.auspicious.house_direction,
                    month: this.auspicious.date_month,
                    api_token: localStorage.getItem('userToken')
                }

                // Return if any param is null or undefined
                if (Object.values(params).some(value => value === null || value === undefined)) {
                    return;
                }

                const response = await this.$axios.post(`api/auspicious-clash-dates/dates`, params);

                if (response.status === 200) {
                    this.good_dates = response.data.output.good_dates;
                    this.bad_dates = response.data.output.bad_dates;
                    this.displaySelected();
                }
            } catch (error) {
                console.error('Error retrieving auspicious clash dates:', error);
            }
        },
        handleShare(actionType) {
            const currentLink = window.location.href;

            switch (actionType) {
                case "copy":
                    navigator.clipboard.writeText(currentLink);
                    toastr.info("Link copied to clipboard!", "info", { timeOut: 3000 });
                    break;

                case "email":
                    window.location.href = `mailto:?subject=Check this out&body=${encodeURIComponent(
                        currentLink
                    )}`;
                    break;

                case "whatsapp":
                    window.open(
                        `https://wa.me/?text=${encodeURIComponent(currentLink)}`,
                        "_blank"
                    );
                    break;

                case "messenger":
                    window.open(
                        `https://www.messenger.com/t?link=${encodeURIComponent(currentLink)}`,
                        "_blank"
                    );
                    break;

                case "twitter":
                    window.open(
                        `https://twitter.com/intent/tweet?url=${encodeURIComponent(currentLink)}&text=Check this out!`,
                        "_blank"
                    );
                    break;

                case "facebook":
                    window.open(
                        `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(currentLink)}`,
                        "_blank"
                    );
                    break;

                case "embed": {
                    let embedCode = `<iframe src="${currentLink}" frameborder="0"></iframe>`;
                    navigator.clipboard.writeText(embedCode);
                    toastr.info("Embed code copied to clipboard!", "info", { timeOut: 3000 });
                    break;
                }
                default:
                    console.error("Unknown action type");
            }
        },
        async fetchOptions() {
            this.loading = true;
            const token = localStorage.getItem('userToken');
            try {
                const response = await this.$axios.post('api/project-detail',
                    {
                        user_id: this.user.id,
                        api_token: token,
                        project_listing_id: this.itemId,
                    });
                this.coop_id = response.data.output[0].user_id_details.id;
                this.user_service_details = response.data.output[0].user_id_details.user_service_details;
                this.projectDetail = response.data.output[0];
                this.listing_type_details = response.data.output[0].listing_type_details;
                this.projectUserDetail = response.data.output[0].user_id_details;
                this.propertyImages = response.data.output[0].listing_photo;
                this.morePhotocount = response.data.output[0].listing_photo.length;
                this.firstImage = response.data.output[0].listing_photo[1];
                this.secondImage = response.data.output[0].listing_photo[2];
                this.featuresList = response.data.output[0].features;
                this.add_featuresList = response.data.output[0].features_additional;
                this.facilitiyList = response.data.output[0].facilities;
                this.add_facilitiyList = response.data.output[0].facility_additional;
                this.otherDocList = response.data.output[0].doc_other_photos;
                this.mediaLinksList = response.data.output[0].media_links;
                this.unit_layouts = response.data.output[0].unit_layout;
                this.publishedTillDate = response.data.output[0].published_till_date;
                this.featuredFrom = response.data.output[0].featured_from;
                this.featuredTo = response.data.output[0].featured_to;
                this.isFeatured = response.data.output[0].is_featured;
                this.userCredit = response.data.output[0].user_id_details.available_credit_points;

                setTimeout(function () {

                    window.$('.property-slider-f-slider').owlCarousel({
                        loop: false,
                        margin: 0,
                        nav: true,
                        dots: false,
                        navText: ['<i class="fa fa-angle-left"></i>', '<i class="fa fa-angle-right"></i>'],
                        responsive: {
                            0: {
                                items: 1,
                            },
                            600: {
                                items: 1
                            },
                            1000: {
                                items: 1
                            }
                        }
                    })

                }, 1000);
                try {
                    this.mode_of_payment = JSON.parse(response.data.output[0].mode_of_payment);
                } catch {
                    this.mode_of_payment =response.data.output[0].mode_of_payment;
                }
            } catch (error) {
                this.loading = false;
                console.error('Error fetching options:', error);
            } finally {
                this.loading = false;
                this.updateViewCount();
            }
        },
        maskedNumber(value) {
            if (value) {
                const start = value.slice(0, 6); // +6016
                const end = value.slice(-3); // 108
                return `${start}****${end}`; // Combine parts with masking
            }
        },
        async featureProject() {
            const token = localStorage.getItem('userToken');
            try {
                const response = await this.$axios.post('/api/feature-project', {
                    project_id: this.itemId,
                    api_token: token,
                });

                if (response.data.status === 1) {
                    this.userCredit = response.data.available_credit_points;

                    alert('Property has been successfully featured!');
                } else {
                    alert(response.data.message);
                }
            } catch (error) {
                console.error("Error featuring property:", error);
                alert('Something went wrong. Please try again.');
            }
        },
        updateMortgageValue() {
            this.loan_amount = parseFloat((this.mortgage.property_value * (this.mortgage.loan_percentage / 100)).toFixed(2));
            this.down_payment = parseFloat((this.mortgage.property_value * ((100 - this.mortgage.loan_percentage) / 100)).toFixed(2));

            const monthlyInterestRate = this.mortgage.interest_rate_percentage / 100 / 12; // Convert annual rate to monthly rate
            const totalPayments = this.mortgage.tenure * 12; // Total number of payments

            if (monthlyInterestRate > 0) {
                this.monthly_repayment_amount = parseFloat((
                    this.loan_amount * (monthlyInterestRate * Math.pow(1 + monthlyInterestRate, totalPayments)) /
                    (Math.pow(1 + monthlyInterestRate, totalPayments) - 1)
                ).toFixed(2));
            } else {
                // If interest rate is 0, repayment is simply loan amount divided by months
                this.monthly_repayment_amount = parseFloat((this.loan_amount / totalPayments).toFixed(2));
            }

            this.monthly_repayment_interest = parseFloat((monthlyInterestRate * this.loan_amount).toFixed(2));
            this.monthly_repayment_principal = parseFloat((this.monthly_repayment_amount - this.monthly_repayment_interest).toFixed(2));
        },
        async fetchRecOffProject(){
            const token = localStorage.getItem('userToken');
            try {
                const response = await this.$axios.post('/api/fetch-recent-offer-project', {
                    project_id: this.itemId,
                    user_id: this.user.id,
                    api_token: token,
                });

                if (response.data.status === 1) {
                    this.response = response.data.output;
                } else {
                    alert(response.data.message);
                }
            } catch (error) {
                console.error("Error fetch recent and offer projects:", error);
            }
        },
        async updateViewCount() {
            // increment property analytic views count on first mounted load only
            if (this.firstLoadDetail) {
                await this.$axios.post(`api/projects/${this.projectDetail.id}/analytics/views`);
            }

            this.firstLoadDetail = false;
        },
        async requestWhatsapp() {
            if (!this.projectUserDetail?.mobile_number) {
                toastr.error("Mobile number is missing!");
                return;
            }

            // increment property analytic inquiries count
            await this.$axios.post(`api/properties/${this.projectUserDetail.id}/analytics/inquiries`);

            // Construct the WhatsApp URL
            const whatsappUrl = `https://api.whatsapp.com/send?phone=${this.projectUserDetail.mobile_number}`;

            // Open in a new tab
            window.open(whatsappUrl, '_blank');
        },
    }
}
</script>

<style scoped>
.opacity-0{
opacity:0
}
/* Add styles specific to the About Us page here */
</style>

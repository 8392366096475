<template>    
   <section class="listing-se">
        <div class="container">
            <div class="filter-bar-se mb-4">
                <div class="comman-title mb-0">
                    <h3 class="mb-2">My Property</h3>
                </div>
                <div class="filter-right-bar">
                    <div class="dropdown">
                        <a href="#" id="Dropdown-sort-by" role="button" data-bs-toggle="dropdown" aria-expanded="true">
                            <img src="frontend/images/Sort-By.svg"> Sort By : {{ selectedSortOption }}
                        </a>
                        <ul class="dropdown-menu" aria-labelledby="Dropdown-sort-by">
                            <li v-for="option in sortOptions" :key="option.value">
                                <a 
                                class="dropdown-item sortBy" 
                                href="#" 
                                @click.prevent="sortData(option.value)"
                                >
                                {{ option.label }}
                                </a>
                            </li>							
                        </ul>
                    </div>
                </div>
            </div>
            <div class="top-tabs-design">
                <ul>
                    <li><a href="javascript:;" class="active">My Active Property</a></li>
                    <li><a href="/property-history">Property History</a></li>
                </ul>
            </div>
            <LoaderCust :isLoading="isLoading" />
            <h5 class="mt-5">Your Active Listings</h5>
            <div v-if="!isLoading && !items.length" class="alert alert-warning">No Active Listing Found.</div>
            <div class="row row-cols-lg-3 row-cols-sm-2">
                <div class="col" v-for="item in items" :key="item.id">
                    <router-link :to="`/property-detail/${item.id}`">
                        <div class="project-bx">
                            <div class="edit-pro">
                                <router-link :to="`/post-property-listing/${item.id}`">
                                    <i class="fa fa-pencil"></i>
                                </router-link>
                            </div>

                            <picture v-if="item.actual_unit_photo && item.actual_unit_photo.length > 0">
                                <img :src="getImageUrl(item.actual_unit_photo[0].photo_name)" @error="handleImageError" alt="Property Image" />
                            </picture>
                            <picture v-else>
                                <img :src="CONSTANT.DEFAULT_NO_IMAGE"  @error="handleImageError" alt="Property Image" />
                            </picture>
                            <div class="project-info">
                                <div class="d-md-flex mb-2 align-items-center">
                                    <h3><a href="">{{ item.property_name }}</a></h3>
                                    <div class="price-pro ms-md-auto">
                                        <span><small>S$</small> {{ formatPrice(item.asking_price_sale_rent) }}</span>
                                    </div>
                                </div>
                                <p class="loct-info"><i><img src="frontend/images/map-gol-ico.svg"></i>{{ item.street }},{{ item.area_hdb_estate }},{{ item.district }}</p>
                                <div class="pro-specify">
                                    <span><i><img src="frontend/images/spec-ico1.svg"></i>{{item.no_of_rooms}}</span>
                                    <span><i><img src="frontend/images/spec-ico2.svg"></i>2-4</span>
                                    <span><i><img src="frontend/images/spec-ico3.svg"></i>{{item.no_car_park_lots}}</span>
                                    <span><i><img src="frontend/images/spec-ico4.svg"></i>{{item.land_area}} {{item.land_area_unit}}</span>
                                </div>
                                <div class="pro-tag">
                                    <a v-if="item.property_type_details.parent_id_top_level_1_name">{{
                                            item.property_type_details.parent_id_top_level_1_name }}</a>
                                    <a v-if="item.property_type_details.code">{{
                                            item.property_type_details.code }}</a>
                                    <a v-if="item.land_title_type">{{ item.land_title_type }}</a>
                                </div>
                                <ul>
                                    <li v-if="item.is_direct_owner">
                                        <figure>
                                            <img src="frontend/images/specify-ico1.png">
                                        </figure>
                                        <small>Direct<br>Owner</small>
                                    </li>
                                    <li  v-if="item.is_sing_pass || item.is_corp_pass ">
                                        <figure>
                                            <img src="frontend/images/specify-ico2.png">
                                        </figure>
                                        <small>SingPass<br>Verified </small>
                                    </li>
                                    <li v-if="item.is_document_approved">
                                        <figure>
                                            <img src="frontend/images/specify-ico3.png">
                                        </figure>
                                        <small>Documents<br>Verified</small>
                                    </li>
                                    <!--<li v-if="item.is_FSM_linked">
                                        <figure>
                                            <img src="frontend/images/specify-ico4.png">
                                        </figure>
                                        <small>Fengshui<br>Map</small>
                                    </li>
                                    <li v-if="item.is_assigned_to_the_agent">
                                        <figure>
                                            <img src="frontend/images/specify-ico5.png">
                                        </figure>
                                        <small>Hybrid-Listing<br>Owners </small>
                                    </li>-->
                                    <li v-if="item.is_below_mean_value">
                                        <figure>
                                            <img src="frontend/images/Down-arrow.svg">
                                        </figure>
                                        <small>BMV</small>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </router-link>
                    <div class="d-flex justify-content-between mt-2">
                        <div class="d-flex flex-grow-1">
                            <button v-if="item.sold" class="comman-btn-2 flex-fill mx-1" data-bs-toggle="modal" data-bs-target="#changePropertyStatus" @click="setSelectedProperty(item)">Mark As Sold</button>
                            <button v-if="item.rent" class="comman-btn-2 flex-fill mx-1" data-bs-toggle="modal" data-bs-target="#changePropertyStatus" @click="setSelectedProperty(item)">Mark As Rented</button>
                        </div>
                    </div>
                </div>
            </div>
            <h5 class="mt-5">Pending Approval</h5>
            <div v-if="!isLoading && !pendingListings.length" class="alert alert-warning">No Pending Approval Listing Found.</div>
            <div class="row row-cols-lg-3 row-cols-sm-2">
                <div class="col" v-for="item in pendingListings" :key="item.id">
                    <router-link :to="`/property-detail/${item.id}`">
                        <div class="project-bx">
                            <div class="edit-pro d-flex justify-content-between">
                                <router-link :to="`/post-property-listing/${item.id}`">
                                    <i class="fa fa-pencil"></i>
                                </router-link>
                                <a type="button" data-bs-toggle="modal" data-bs-target="#deleteProperty" @click="setSelectedProperty(item)">
                                    <i class="fa fa-trash"></i>
                                </a>
                            </div>
                            <picture v-if="item.actual_unit_photo && item.actual_unit_photo.length > 0">
                                <img :src="getImageUrl(item.actual_unit_photo[0].photo_name)" @error="handleImageError" alt="Property Image" />
                            </picture>
                            <picture v-else>
                                <img :src="CONSTANT.DEFAULT_NO_IMAGE"  @error="handleImageError" alt="Property Image" />
                            </picture>
                            <div class="project-info">
                                <div class="d-md-flex mb-2 align-items-center">
                                    <h3><a href="">{{ item.property_name }}</a></h3>
                                    <div class="price-pro ms-md-auto">
                                        <span><small>S$</small> {{ formatPrice(item.asking_price_sale_rent) }}</span>
                                    </div>
                                </div>
                                <p class="loct-info"><i><img src="frontend/images/map-gol-ico.svg"></i>{{ item.street }},{{ item.area_hdb_estate }},{{ item.district }}</p>
                                <div class="pro-specify">
                                    <span><i><img src="frontend/images/spec-ico1.svg"></i>{{item.no_of_rooms}}</span>
                                    <span><i><img src="frontend/images/spec-ico2.svg"></i>2-4</span>
                                    <span><i><img src="frontend/images/spec-ico3.svg"></i>{{item.no_car_park_lots}}</span>
                                    <span><i><img src="frontend/images/spec-ico4.svg"></i>{{item.land_area}} {{item.land_area_unit}}</span>
                                </div>
                                <div class="pro-tag">
                                    <a v-if="item.property_type_details.parent_id_top_level_1_name">{{
                                            item.property_type_details.parent_id_top_level_1_name }}</a>
                                    <a v-if="item.property_type_details.code">{{
                                            item.property_type_details.code }}</a>
                                    <a v-if="item.land_title_type">{{ item.land_title_type }}</a>
                                </div>
                                <ul>
                                    <li v-if="item.is_direct_owner">
                                        <figure>
                                            <img src="frontend/images/specify-ico1.png">
                                        </figure>
                                        <small>Direct<br>Owner</small>
                                    </li>
                                    <li  v-if="item.is_sing_pass || item.is_corp_pass ">
                                        <figure>
                                            <img src="frontend/images/specify-ico2.png">
                                        </figure>
                                        <small>SingPass<br>Verified </small>
                                    </li>
                                    <li v-if="item.is_document_approved">
                                        <figure>
                                            <img src="frontend/images/specify-ico3.png">
                                        </figure>
                                        <small>Documents<br>Verified</small>
                                    </li>
                                    <!--<li v-if="item.is_FSM_linked">
                                        <figure>
                                            <img src="frontend/images/specify-ico4.png">
                                        </figure>
                                        <small>Fengshui<br>Map</small>
                                    </li>
                                    <li v-if="item.is_assigned_to_the_agent">
                                        <figure>
                                            <img src="frontend/images/specify-ico5.png">
                                        </figure>
                                        <small>Hybrid-Listing<br>Owners </small>
                                    </li>-->
                                    <li v-if="item.is_below_mean_value">
                                        <figure>
                                            <img src="frontend/images/Down-arrow.svg">
                                        </figure>
                                        <small>BMV</small>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </router-link>
                    <div class="d-flex justify-content-between mt-2">
                        <div class="d-flex flex-grow-1">
                            <button v-if="item.sold" class="comman-btn-2 flex-fill mx-1" data-bs-toggle="modal" data-bs-target="#changePropertyStatus" @click="setSelectedProperty(item)">Mark As Sold</button>
                            <button v-if="item.rent" class="comman-btn-2 flex-fill mx-1" data-bs-toggle="modal" data-bs-target="#changePropertyStatus" @click="setSelectedProperty(item)">Mark As Rented</button>
                        </div>
                    </div>
                </div>
            </div>
            <h5 class="mt-5">Expired Listings</h5>
            <div v-if="!isLoading && !expiredListings.length" class="alert alert-warning">No Expired Listing Found.</div>
            <div class="row row-cols-lg-3 row-cols-sm-2">
                <div class="col" v-for="item in expiredListings" :key="item.id">
                    <router-link :to="`/property-detail/${item.id}`">
                        <div class="project-bx">
                            <div class="edit-pro d-flex justify-content-between">
                                <router-link :to="`/post-property-listing/${item.id}`">
                                    <i class="fa fa-pencil"></i>
                                </router-link>
                                <a type="button" data-bs-toggle="modal" data-bs-target="#deleteProperty" @click="setSelectedProperty(item)">
                                    <i class="fa fa-trash"></i>
                                </a>
                            </div>
                            <picture v-if="item.actual_unit_photo && item.actual_unit_photo.length > 0">
                                <img :src="getImageUrl(item.actual_unit_photo[0].photo_name)" @error="handleImageError" alt="Property Image" />
                            </picture>
                            <picture v-else>
                                <img :src="CONSTANT.DEFAULT_NO_IMAGE"  @error="handleImageError" alt="Property Image" />
                            </picture>
                            <div class="project-info">
                                <div class="d-md-flex mb-2 align-items-center">
                                    <h3><a href="">{{ item.property_name }}</a></h3>
                                    <div class="price-pro ms-md-auto">
                                        <span><small>S$</small> {{ formatPrice(item.asking_price_sale_rent) }}</span>
                                    </div>
                                </div>
                                <p class="loct-info"><i><img src="frontend/images/map-gol-ico.svg"></i>{{ item.street }},{{ item.area_hdb_estate }},{{ item.district }}</p>
                                <div class="pro-specify">
                                    <span><i><img src="frontend/images/spec-ico1.svg"></i>{{item.no_of_rooms}}</span>
                                    <span><i><img src="frontend/images/spec-ico2.svg"></i>2-4</span>
                                    <span><i><img src="frontend/images/spec-ico3.svg"></i>{{item.no_car_park_lots}}</span>
                                    <span><i><img src="frontend/images/spec-ico4.svg"></i>{{item.land_area}} {{item.land_area_unit}}</span>
                                </div>
                                <div class="pro-tag">
                                    <a v-if="item.property_type_details.parent_id_top_level_1_name">{{
                                            item.property_type_details.parent_id_top_level_1_name }}</a>
                                    <a v-if="item.property_type_details.code">{{
                                            item.property_type_details.code }}</a>
                                    <a v-if="item.land_title_type">{{ item.land_title_type }}</a>
                                </div>
                                <ul>
                                    <li v-if="item.is_direct_owner">
                                        <figure>
                                            <img src="frontend/images/specify-ico1.png">
                                        </figure>
                                        <small>Direct<br>Owner</small>
                                    </li>
                                    <li  v-if="item.is_sing_pass || item.is_corp_pass ">
                                        <figure>
                                            <img src="frontend/images/specify-ico2.png">
                                        </figure>
                                        <small>SingPass<br>Verified </small>
                                    </li>
                                    <li v-if="item.is_document_approved">
                                        <figure>
                                            <img src="frontend/images/specify-ico3.png">
                                        </figure>
                                        <small>Documents<br>Verified</small>
                                    </li>
                                    <!--<li v-if="item.is_FSM_linked">
                                        <figure>
                                            <img src="frontend/images/specify-ico4.png">
                                        </figure>
                                        <small>Fengshui<br>Map</small>
                                    </li>
                                    <li v-if="item.is_assigned_to_the_agent">
                                        <figure>
                                            <img src="frontend/images/specify-ico5.png">
                                        </figure>
                                        <small>Hybrid-Listing<br>Owners </small>
                                    </li>-->
                                    <li v-if="item.is_below_mean_value">
                                        <figure>
                                            <img src="frontend/images/Down-arrow.svg">
                                        </figure>
                                        <small>BMV</small>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </router-link>
                    <div class="d-flex justify-content-between mt-2">
                        <div class="d-flex flex-grow-1">
                            <button v-if="item.sold" class="comman-btn-2 flex-fill mx-1" data-bs-toggle="modal" data-bs-target="#changePropertyStatus" @click="setSelectedProperty(item)">Mark As Sold</button>
                            <button v-if="item.rent" class="comman-btn-2 flex-fill mx-1" data-bs-toggle="modal" data-bs-target="#changePropertyStatus" @click="setSelectedProperty(item)">Mark As Rented</button>
                            <button class="comman-btn-2 flex-fill mx-1" data-bs-toggle="modal" data-bs-target="#renewProperty" @click="setSelectedProperty(item)">
                                Republish Property
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <h5 class="mt-5">Your Draft Listings</h5>
            <div v-if="!isLoading && !draftListings.length" class="alert alert-warning">No Draft Listing Found.</div>
            <div class="row row-cols-lg-3 row-cols-sm-2">
                <div class="col" v-for="item in draftListings" :key="item.id">
                    <router-link :to="`/property-detail/${item.id}`">
                        <div class="project-bx">
                            <div class="edit-pro d-flex justify-content-between">
                                <router-link :to="`/post-property-listing/${item.id}`">
                                    <i class="fa fa-pencil"></i>
                                </router-link>
                                <a type="button" data-bs-toggle="modal" data-bs-target="#deleteProperty" @click="setSelectedProperty(item)">
                                    <i class="fa fa-trash"></i>
                                </a>
                            </div>

                            <picture v-if="item.actual_unit_photo && item.actual_unit_photo.length > 0">
                                <img :src="getImageUrl(item.actual_unit_photo[0].photo_name)" @error="handleImageError" alt="Property Image" />
                            </picture>
                            <picture v-else>
                                <img :src="CONSTANT.DEFAULT_NO_IMAGE"  @error="handleImageError" alt="Property Image" />
                            </picture>
                            <div class="project-info">
                                <div class="d-md-flex mb-2 align-items-center">
                                    <h3><a href="">{{ item.property_name }}</a></h3>
                                    <div class="price-pro ms-md-auto">
                                        <span><small>S$</small> {{ formatPrice(item.asking_price_sale_rent) }}</span>
                                    </div>
                                </div>
                                <p class="loct-info"><i><img src="frontend/images/map-gol-ico.svg"></i>{{ item.street }},{{ item.area_hdb_estate }},{{ item.district }}</p>
                                <div class="pro-specify">
                                    <span><i><img src="frontend/images/spec-ico1.svg"></i>{{item.no_of_rooms}}</span>
                                    <span><i><img src="frontend/images/spec-ico2.svg"></i>2-4</span>
                                    <span><i><img src="frontend/images/spec-ico3.svg"></i>{{item.no_car_park_lots}}</span>
                                    <span><i><img src="frontend/images/spec-ico4.svg"></i>{{item.land_area}} {{item.land_area_unit}}</span>
                                </div>
                                <div class="pro-tag">
                                    <a v-if="item.property_type_details.parent_id_top_level_1_name">{{
                                            item.property_type_details.parent_id_top_level_1_name}}</a>
                                    <a v-if="item.property_type_details.code">{{
                                            item.property_type_details.code }}</a>
                                    <a v-if="item.land_title_type">{{ item.land_title_type }}</a>
                                </div>
                                <ul>
                                    <li v-if="item.is_direct_owner">
                                        <figure>
                                            <img src="frontend/images/specify-ico1.png">
                                        </figure>
                                        <small>Direct<br>Owner</small>
                                    </li>
                                    <li  v-if="item.is_sing_pass || item.is_corp_pass ">
                                        <figure>
                                            <img src="frontend/images/specify-ico2.png">
                                        </figure>
                                        <small>SingPass<br>Verified </small>
                                    </li>
                                    <li v-if="item.is_document_approved">
                                        <figure>
                                            <img src="frontend/images/specify-ico3.png">
                                        </figure>
                                        <small>Documents<br>Verified</small>
                                    </li>
                                    <!--<li v-if="item.is_FSM_linked">
                                        <figure>
                                            <img src="frontend/images/specify-ico4.png">
                                        </figure>
                                        <small>Fengshui<br>Map</small>
                                    </li>
                                    <li v-if="item.is_assigned_to_the_agent">
                                        <figure>
                                            <img src="frontend/images/specify-ico5.png">
                                        </figure>
                                        <small>Hybrid-Listing<br>Owners </small>
                                    </li>-->
                                    <li v-if="item.is_below_mean_value">
                                        <figure>
                                            <img src="frontend/images/Down-arrow.svg">
                                        </figure>
                                        <small>BMV</small>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </router-link>
                </div>
            </div>
        </div>

        <!--modal section-->
       <div class="delete-property-modal">
           <div class="modal fade" id="deleteProperty" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
               <div class="modal-dialog">
                   <div class="modal-content">
                       <div class="modal-header">
                           <h5 class="modal-title" id="staticBackdropLabel">Are you sure?</h5>
                       </div>
                       <div class="modal-body">
                           Clicking <b>Confirm</b> will delete this current listing. <br>
                           Please reconsider your action.
                       </div>
                       <div class="modal-footer">
                           <button type="button" class="comman-btn-2-danger" data-bs-dismiss="modal">Close</button>
                           <button type="button" class="comman-btn-2" @click="deleteProperty()" data-bs-dismiss="modal">Confirm</button>
                       </div>
                   </div>
               </div>
           </div>
       </div>
       <div class="renew-property-modal">
           <div class="modal fade" id="renewProperty" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
               <div class="modal-dialog">
                   <div class="modal-content">
                       <div v-if="userCredit < 10">
                           <div class="modal-header">
                               <h5 class="modal-title" id="staticBackdropLabel">Insufficient Credits</h5>
                           </div>
                           <div class="modal-body">
                               <b>Republishing property requires 10 credits</b><br>
                               Your Current Credit: <b>{{ userCredit }}</b>
                           </div>
                           <div class="modal-footer">
                               <button type="button" class="comman-btn-2-danger" data-bs-dismiss="modal">Close</button>
                               <a class="comman-btn-2" href="/top-up" onclick="$('#renewProperty').modal('hide')">
                                   <img src="/frontend/images/Choose-plan.svg" alt="topUp">
                                   Top up
                               </a>
                           </div>
                       </div>
                       <div v-else>
                           <div class="modal-header">
                               <h5 class="modal-title" id="staticBackdropLabel">Republish Property Listing</h5>
                           </div>
                           <div class="modal-body">
                               Clicking <b>Confirm</b> will request to republish this current listing. <br>
                               This action will cost you <b>10</b> credits if approved by admin. <br>
                               Your Current Credit: <b>{{ userCredit }}</b>
                           </div>
                           <div class="modal-footer">
                               <button type="button" class="comman-btn-2-danger" data-bs-dismiss="modal">Close</button>
                               <button type="button" class="comman-btn-2"  @click="renewProperty()" data-bs-dismiss="modal">Confirm</button>
                           </div>
                       </div>
                   </div>
               </div>
           </div>
       </div>
       <div class="change-propety-status-modal">
           <div class="modal fade" id="changePropertyStatus" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
               <div class="modal-dialog">
                   <div class="modal-content">
                       <div class="modal-header">
                           <h5 class="modal-title" id="staticBackdropLabel" v-if="this.selectedProperty?.sold">Mark this Property as <b>Sold</b></h5>
                           <h5 class="modal-title" id="staticBackdropLabel" v-if="this.selectedProperty?.rent">Mark this Property as <b>Rented</b></h5>
                       </div>
                       <div class="modal-body">
                           <form @submit.prevent="changeProperty">
                               <div class="mb-3" v-if="this.selectedProperty?.rent">
                                   <label for="rent_price" class="form-label">Rent Price / month</label>
                                   <input type="number" class="form-control" id="rent_price" v-model="form.rent_price" placeholder="Enter rent price">
                               </div>

                               <div class="mb-3" v-if="this.selectedProperty?.rent">
                                   <label for="rent_tenure" class="form-label">Rent Tenure (Months)</label>
                                   <input type="number" class="form-control" id="rent_price" v-model="form.rent_tenure" placeholder="Enter rent tenure">
                               </div>

                               <div class="mb-3" v-if="this.selectedProperty?.rent">
                                   <label for="rent_start_date" class="form-label">Rent Start Date</label>
                                   <input type="date" class="form-control" id="rent_start_date" placeholder="We will use this data as reference" v-model="form.rent_start_date" required>
                               </div>

<!--                               <div class="mb-3" v-if="this.selectedProperty?.rent">-->
<!--                                   <label for="rent_end_date" class="form-label">Rent End Date</label>-->
<!--                                   <input type="date" class="form-control" id="rent_end_date" v-model="form.rent_end_date">-->
<!--                               </div>-->

                               <div class="mb-3" v-if="this.selectedProperty?.sold">
                                   <label for="sold_date" class="form-label">Sold on Date</label>
                                   <input type="date" class="form-control" id="rent_end_date" placeholder="We will use this data as reference" v-model="form.sold_date" required>
                               </div>

                               <div class="mb-3" v-if="this.selectedProperty?.sold">
                                   <label for="sold_price" class="form-label">Sold Price</label>
                                   <input type="number" class="form-control" id="sold_price" v-model="form.sold_price" placeholder="Enter sold price">
                               </div>

                               <p>Please reconsider your decision, <b>once confirmed can't be undone</b></p>
                               <div class="mb-3 form-check">
                                   <input type="checkbox" class="form-check-input" id="agreement" v-model="checkboxChecked" required>
                                   <label class="form-check-label" for="agreement">I confirm that all information is accurate.</label>
                               </div>
                               <button type="submit" class="btn btn-warning col-md-12" data-bs-dismiss="modal" >Confirm</button>
                           </form>
                       </div>
                       <div class="modal-footer">
                           <button type="button" class="comman-btn-2-danger" data-bs-dismiss="modal">Close</button>
                       </div>
                   </div>
               </div>
           </div>
       </div>
    </section>
</template>

<script>
    import LoaderCust from '@/components/LoaderCust.vue';
    import { CONSTANT } from '@/constants/constants';
    import { mapState } from 'vuex';
    import { formatPrice } from '@/utils/helpers';
    import toastr from "toastr";

    export default {
    name: 'MyProperty', 
    components: {
        LoaderCust,
    },
    data() {
        return {    
            isLoading: true,   
            CONSTANT, 
            items: [],
            userCredit: null,
            draftListings: [],
            pendingListings: [],
            expiredListings: [],
            optionsLandTenuresList: [],
            selectedSortOption: 'Newest',
            sortOptions: [
                { label: 'Newest', value: 'Newest' },
                { label: 'Lowest Price', value: 'Lowest Price' },
                { label: 'Highest Price', value: 'Highest Price' },
                { label: 'Lowest Floor Area', value: 'Lowest Floor Area' },
                { label: 'Highest Floor Area', value: 'Highest Floor Area' },
            ],
            selectedProperty: null,
            selectedPropertyId: null,
            form: {
                rent_price: '',
                rent_tenure: '',
                rent_start_date: '',
                rent_end_date: '',
                sold_price: '',
                sold_date: '',
            },
            checkboxChecked: false,
        }
    },
    computed: {
      ...mapState(['isAuthenticated', 'user']),
    },
    created() {
       this.fetchOptions();

    },
    methods: {
        formatPrice,
        async changeProperty() {
            const token = localStorage.getItem('userToken');
            try {
                const response = await this.$axios.post('api/change-property-listing-status',{
                    api_token : token,
                    user_id: this.user.id,
                    is_rent: this.selectedProperty.rent,
                    is_sold: this.selectedProperty.sold,
                    property_listing_id : this.selectedPropertyId,
                    property_rent_price: this.form.rent_price,
                    property_rent_tenure: this.form.rent_tenure,
                    property_rent_start_date: this.form.rent_start_date,
                    property_rent_end_date: this.form.rent_end_date,
                    property_sold_price: this.form.sold_price,
                    property_sold_date: this.form.sold_date,
                });

                if (response.data.status === 1) {
                    toastr.success('Property status change successfully');

                    await this.fetchOptions();
                }
            } catch (error){
                console.error('Error changing property status', error);
            }

        },
        async fetchOptions() {
            // Get Land Tenure list
            try {
                const response = await this.$axios.post('api/pr-land-tenures-list',
                {
                    parent_id : "0",
                    keyword : "",
                    show_in_project: 0,
                    show_in_property:1,
                    last_record_number : 0
                });
                this.optionsLandTenuresList = response.data.output.map(item => ({
                value: item.id,
                label: item.name
                }));
            } catch (error) {
                console.error('Error fetching options:', error);
            }
            const token = localStorage.getItem('userToken');
            try {
                const response = await this.$axios.post('api/property-list',
                {
                    user_id : this.user.id,
                    api_token : token,
                    sort_by_field : 'Newest',
                });
                this.items  = response.data.output.myArray;
                this.draftListings = response.data.output.unpublishedArray;
                this.pendingListings = response.data.output.publishedArray;
                this.expiredListings = response.data.output.expiredArray;
                this.userCredit = response.data.output.available_credit_points;
            } catch (error) {
                console.error('Error fetching options:', error);
            } finally {
                this.isLoading = false;
            }

        },
        getImageUrl(photoName) {
            return photoName ? `${CONSTANT.IMAGE_URL}/imagesPropertyActualUnit/${photoName}` : CONSTANT.DEFAULT_IMAGE;
        },

        handleImageError(event) {
            event.target.src = CONSTANT.DEFAULT_IMAGE;
        },

        getlandTenue(value) {
            const item = this.optionsLandTenuresList.find(option => option.value === value);
            return item ? item.label : 'Not found';
        },
        
        async sortData(sortBy) {
            this.isLoading = true;
            this.items = [];
            this.selectedSortOption = sortBy; 
            const token = localStorage.getItem('userToken');
            try {
                const response = await this.$axios.post('api/property-list',
                {                    
                    user_id : this.user.id,
                    api_token : token, 
                    sort_by_field : sortBy, 
                });
                this.items  = response.data.output.myArray;
                this.draftListings = response.data.output.unpublishedArray;
                this.pendingListings = response.data.output.publishedArray;
                this.expiredListings = response.data.output.expiredArray;
            } catch (error) {
                console.error('Error fetching options:', error);
            }finally {
                this.isLoading = false;
            }
        },
        setSelectedProperty(property) {
            if (!property) {
                console.error('No request provided');
                return;
            }
            this.selectedProperty = property;
            this.selectedPropertyId = property.id
        },

        async deleteProperty() {
            const token = localStorage.getItem('userToken');
            try {
                const response = await this.$axios.post('api/delete-property-listing',{
                    api_token : token,
                    property_listing_id : this.selectedPropertyId,
                });

                if (response.data.status === 1) {
                    toastr.success('Listing Deleted Successfully');

                    await this.fetchOptions();
                }

            } catch (error){
                console.error('Error fetching options:', error);
            }
        },
        async renewProperty() {
            const token = localStorage.getItem('userToken');
            try {
                const response = await this.$axios.post('api/renew-property-listing', {
                    api_token : token,
                    property_listing_id : this.selectedPropertyId,
                });

                if (response.data.status === 1) {
                    toastr.success('Successful Request for Republish');

                    await this.fetchOptions();
                } else {
                    toastr.error('Republish Fail!')
                }
            } catch (error) {
                console.error('Error Renew Property:', error);
            }
        }
    }
    
  }
  </script>
  
  <style scoped>
  /* Add styles specific to the About Us page here */
  </style>
  
<template>
    <div class="project-bx h-100">
        <picture style="position: relative;">
            <a class="wishlist-icon-in-pic" @click="wishlistProperty(item)" title="Wishlist Property">
                <img v-if="item.is_in_wishlist" src="frontend/images/heart-filled-ico.svg" alt="Remove"/>
                <img v-else src="frontend/images/heart-ico.svg" alt="Remove"/>
            </a>
            <img :src="getPropertyImage(item.actual_unit_photo?.[0]?.photo_name || null)" @error="handleImageError"
                alt="Property Image" />
        </picture>
        <div class="project-info">
            <a :href="'/property-detail/' + item.id" class="text-decoration-none">
                <div class="mb-2 align-items-center">
                    <h3 style="color: black;">{{ item.property_name }}</h3>
                    <div class="price-pro">
                        <span><small>S$</small> {{ formatPrice(item.asking_price_sale_rent) }}</span>
                    </div>
                </div>
            </a>
            <p class="loct-info"><i><img src="frontend/images/map-gol-ico.svg"></i>{{ item.street }},{{ item.area_hdb_estate }},{{ item.district }}</p>

            <div class="d-flex flex-column">
                <div class="mt-auto">
                    <div class="pro-specify">
                        <span><i><img src="frontend/images/spec-ico1.svg"></i>{{item.no_of_rooms}}</span>
                        <span><i><img src="frontend/images/spec-ico2.svg"></i>{{ item.no_of_baths }}</span>
                        <span><i><img src="frontend/images/spec-ico3.svg"></i>{{item.no_car_park_lots}}</span>
                        <span><i><img src="frontend/images/spec-ico4.svg"></i>{{item.floor_area}} {{item.floor_area_unit}}</span>
                    </div>
                    <div class="pro-tag">
                        <a style="cursor:default">{{ item.property_type_details.parent_id_top_level_1_code }}</a>
                        <a style="cursor:default">{{ item.property_type_details.code }}</a>
                        <a style="cursor:default">{{ item.land_title_type }}</a>
                    </div>
                    <ul>
                        <li v-if="item.is_direct_owner">
                            <figure>
                                <img src="frontend/images/specify-ico1.png">
                            </figure>
                            <small>Direct<br>Owner</small>
                        </li>
                        <li  v-if="item.is_sing_pass || item.is_corp_pass ">
                            <figure>
                                <img src="frontend/images/specify-ico2.png">
                            </figure>
                            <small>SingPass<br>Verified </small>
                        </li>
                        <li v-if="item.is_document_approved">
                            <figure>
                                <img src="frontend/images/specify-ico3.png">
                            </figure>
                            <small>Documents<br>Verified</small>
                        </li>
                        <!--<li v-if="item.is_FSM_linked">
                            <figure>
                                <img src="frontend/images/specify-ico4.png">
                            </figure>
                            <small>Fengshui<br>Map</small>
                        </li>
                        <li v-if="item.is_assigned_to_the_agent">
                            <figure>
                                <img src="frontend/images/specify-ico5.png">
                            </figure>
                            <small>Hybrid-Listing<br>Owners </small>
                        </li>-->
                        <li v-if="item.is_below_mean_value">
                            <figure>
                                <img src="frontend/images/Down-arrow.svg">
                            </figure>
                            <small>BMV</small>
                        </li>
                    </ul>
                </div>
            </div>


        </div>
    </div>
</template>

<script>
import { formatPrice, getPropertyImage } from '@/utils/helpers';
import { mapState } from 'vuex';
import toastr from 'toastr';

export default {
    name: 'PropertyBox',

    props: {
        item: {
            type: Object,
            Required: true
        },
    },

    data() {
        return {     
        }
    },

    computed: {
        ...mapState(['user']),
    },

    methods: {
        formatPrice,
        getPropertyImage,
        async wishlistProperty(property) {
            try {
                if (!this.user?.id) {
                    toastr.error('Please log in to wishlist', 'error', {
                        progressBar: true,
                        closeButton: true,
                        timeOut: 1500,
                    });
                    return;
                }
                const wishlistResponse = await this.$axios.post(`api/users/${this.user.id}/wishlist/properties/${property.id}`, {
                    api_token: localStorage.getItem('userToken'),
                });

                if (wishlistResponse.status === 200) {
                    toastr.success(wishlistResponse.data.message, 'Success', {
                        progressBar: true,
                        closeButton: true,
                        timeOut: 1500,
                        onHidden: () => {
                            window.location.reload();
                        }
                    });
                }
            } catch (error) {
                console.error('Error wishlisting property:', error);
            }
        },
    }
}
</script>
<template>    
    
    <section class="property-se">
        <div class="container">
            <div class="property-title-bar">
                <div class="comman-title mb-0">
                    <h3><a href="#"><i class="fa fa-angle-left"></i></a> Create Project Listing</h3>
                </div>
                <div class="progress-bar-box">
                    <div>5/5</div>
                    <div class="progress">
                        <div class="progress-bar" role="progressbar" style="width: 20%" aria-valuenow="15" aria-valuemin="0" aria-valuemax="100"></div>							 
                        <div class="progress-bar" role="progressbar" style="width: 20%" aria-valuenow="15" aria-valuemin="0" aria-valuemax="100"></div>							
                        <div class="progress-bar" role="progressbar" style="width: 20%" aria-valuenow="15" aria-valuemin="0" aria-valuemax="100"></div>							
                        <div class="progress-bar" role="progressbar" style="width: 20%" aria-valuenow="15" aria-valuemin="0" aria-valuemax="100"></div>							
                        <div class="progress-bar" role="progressbar" style="width: 20%" aria-valuenow="15" aria-valuemin="0" aria-valuemax="100"></div>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-md-2">
                    <div class="property-sidebar">
                        <ul>
                            <li><a id="step1" href="/post-property-listing">Step 1</a></li>
                            <li><a id="step2" href="/post-property-listing-2">Step 2</a></li>
                            <li><a id="step3" href="/post-property-listing-3">Step 3</a></li>
                            <li><a id="step4" href="/post-property-listing-4">Step 4</a></li>
                            <li><a class="active" id="step5">Step 5</a></li> 
                        </ul>
                    </div>
                </div>
                <div class="col-md-10">
                    <div class="property-box mb-4">							 
                        <div>
                            <h4 style="color: blue; font-weight: bold;">Overall Completion Status: {{ overallCompletionValue }}%</h4>
                        </div>
                        <div class="d-flex justify-content-md-end">
                            <button @click="resetCompletionData" class="btn comman-btn-2 ms-3">
                                My Project
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

</template>

  <script>
  import { mapState } from 'vuex';
  import toastr from 'toastr';
  import 'toastr/build/toastr.min.css';

  export default {
    name: 'PostPropertyListingStep5',
    data() {
        return {
            overallCompletionValue: 0,
        }
    },
    computed: {
        ...mapState(['isAuthenticated', 'user']),
        itemId() {
            return this.$route.params.id;
        },
    },
    mounted() {
        this.overallCompletion();
        this.disableSteps();
    },
    methods: {
        disableStep(step) {
        const stepElement = document.getElementById(`step${step}`);
        if (stepElement) {
            stepElement.style.pointerEvents = 'none';  // Disable clicks
            stepElement.style.color = 'gray';  // Gray out the text
        }
    },

        disableSteps() {
            // You can call this method to disable steps based on the condition
            this.disableStep(1); // Disable Step 1
            this.disableStep(2); // Disable Step 2
            this.disableStep(3); // Disable Step 3
            this.disableStep(4); // Disable Step 4
        },

        overallCompletion() {
            const completionData = JSON.parse(localStorage.getItem('projectFormCompletionData')) || {};
            const percentages = Object.values(completionData);

            const totalPercentage = percentages.reduce((sum, current) => sum + current, 0);

            const overallCompletionValue = Math.round(totalPercentage / 4); // Calculate the overall completion value

            console.log(completionData);
            console.log(percentages);
            console.log(totalPercentage);
            console.log(overallCompletionValue);

            // Now set the overallCompletionValue to the data property
            this.overallCompletionValue = overallCompletionValue;

            // Optionally, send the completion data to the backend
            this.sendCompletionData(overallCompletionValue);

            return overallCompletionValue; // Return the calculated value (though now not necessary)
        },

        async sendCompletionData(completionValue) {
            const token = localStorage.getItem('userToken');
            const storedProjectListingId = localStorage.getItem('project_listing_id');
            try {
                const response = await this.$axios.post('api/update-project-document-completion', {
                    user_id :this.user.id,
                    api_token: token,
                    project_listing_id: storedProjectListingId,
                    completion: completionValue
                });                    
                toastr.success(response.data.message, 'Success', { timeOut: 3000 });
            } catch (error) {  
                if (error.response && error.response.data) {
                    this.errors = this.extractErrors(error.response.data.errors || {});
                } else {
                    console.error('An unexpected error occurred:', error);
                }
            } finally {
                this.loading = false;
            }
        },

        resetCompletionData() {
            const resetData = {
                projectPage1: 0,
                projectPage2: 0,
                projectPage3: 0,
                projectPage4: 0
            };

            // Save the reset data to localStorage
            localStorage.setItem('projectFormCompletionData', JSON.stringify(resetData));

            console.log('Completion data has been reset:', resetData);
            this.$router.push('/my-project');
        },

        async save(params) {
            
            const token = localStorage.getItem('userToken');
            try {
                    
                const response = await this.$axios.post('api/update-property-publish-status', {
                    user_id :this.user.id,
                    api_token: token,
                    property_listing_id:this.itemId,
                    publish_status:params
                });                    
                toastr.success(response.data.message, 'Success', { timeOut: 3000 });
                this.$router.push('/my-property');         

            } catch (error) {
                
                if (error.response && error.response.data) {
                    this.errors = this.extractErrors(error.response.data.errors || {});
                } else {
                    console.error('An unexpected error occurred:', error);
                }
            } finally {
                this.loading = false;
            }

        },
        extractErrors(errors) {
            // Extract the first error message from each field's errors array
            const formattedErrors = {};
            for (const [field, messages] of Object.entries(errors)) {
                if (Array.isArray(messages) && messages.length > 0) {
                formattedErrors[field] = messages[0]; // Get the first error message
                }
            }
            return formattedErrors;
        }, 
     
    }
  }
  </script>
  
  <style scoped>
  /* Add styles specific to the About Us page here */
  </style>


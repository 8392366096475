<template>
    <section class="property-data-analytic-se">
        <div class="container">
            <div class="top-tabs-design">
                <ul>
                    <li><a href="/profile">My Profile</a></li>
                    <li v-if="!user.is_private_user">
                        <a href="/verify-service-detail-page">Verify Service Details</a>
                    </li>
                    <li><a href="javascript:;"  class="active">Analytics and Report</a></li>
                    <li><a href="/wishlist">My Wishlist</a></li>
                    <li><a href="/requested-service">Requested Service</a></li>
                    <li><a href="/messages">Messages</a></li>
                </ul>
            </div>
            <div class="top-tabs-design">
                <ul>
                    <li><a href="/property-data-analytic">Property</a></li>
                    <li><a href="javascript:;" class="active">Project</a></li>
                </ul>
            </div>
            <div class="dashboard">
                <div class="container py-4">
                    <div class="row text-center mb-4">
                        <div class="col-md-6">
                            <div class="card shadow-sm">
                                <div class="card-body">
                                    <h5 class="card-title">Total Views</h5>
                                    <p>{{ totalViews }}</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="card shadow-sm">
                                <div class="card-body">
                                    <h5 class="card-title">Total Inquiries</h5>
                                    <p>{{ totalInquiries }}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row text-center mb-4">
                        <div class="col-md-6">
                            <div class="card shadow-sm">
                                <div class="card-body">
                                    <h5 class="card-title">Total Active Project Listing</h5>
                                    <p>{{  }}</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="card shadow-sm">
                                <div class="card-body">
                                    <h5 class="card-title">Total Lead</h5>
                                    <p>{{  }}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <template v-if="projectsData.length > 0">
                    <div class="table-responsive custom-table-design">
                        <h4 class="mb-3">Projects Data Insights</h4>
                        <div class="filter-bar-se my-4">
                            <div class="text-center w-25" id="app">
                                <downloadexcel
                                    class="comman-btn-2"
                                    :fetch="exportProjectsToExcel"
                                    :fields="json_fields"
                                    :before-generate="startDownload"
                                    :before-finish="finishDownload"
                                >
                                    Download Excel
                                </downloadexcel>
                            </div>
                            <div class="filter-right-bar">
                                <div class="dropdown">
                                    <a href="#" id="Dropdown-sort-by" role="button" data-bs-toggle="dropdown" aria-expanded="true">
                                        <img src="frontend/images/Sort-By.svg"> Sort By
                                    </a>
                                    <ul class="dropdown-menu" aria-labelledby="Dropdown-sort-by">
                                        <li><a class="dropdown-item" href="#" :class="{ 'selected': dataFilters.sort_by_field === 'Oldest' }"
                                               @click.prevent="sort('Oldest')">Oldest</a></li>
                                        <li><a class="dropdown-item" href="#" :class="{ 'selected': dataFilters.sort_by_field === 'Newest' }"
                                               @click.prevent="sort('Newest')">Newest</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <table class="text-center table-bordered align-middle" >
                            <thead>
                            <tr>
                                <th>Project Name</th>
                                <th>Views</th>
                                <th>Inquiries</th>
                                <th>Created At</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr v-for="project in projectsData" :key="project.id">
                                <td @click="redirectToProjectDetails(project.property_listing_id)" style="cursor: pointer;">{{ '-' }}</td>
                                <td>{{  }}</td>
                                <td>{{  }}</td>
                                <td>{{  }}</td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </template>
                <template v-else>
                    <div class="text-center">No records found</div>
                </template>
                <div class="pagination-se mt-3" v-if="projectsData.length > 0">
                    <nav aria-label="...">
                        <ul class="pagination justify-content-center">
                            <li class="page-item" :class="{ disabled: dataFilters.page === 1 }"
                                @click="dataFilters.page > 1 && changePage(dataFilters.page - 1)" v-if="dataFilters.page > 1">
                                <a class="page-link"><img src="frontend/images/left-arrow-icon.svg"
                                                          alt="Previous"></a>
                            </li>
                            <li v-for="pageNumber in paginatedPages(dataFilters.page, totalPages)" :key="pageNumber"
                                class="page-item" :class="{ active: pageNumber === dataFilters.page }">
                                <a class="page-link"
                                   @click.prevent="pageNumber !== dataFilters.page && changePage(pageNumber)">
                                    {{ pageNumber }}
                                </a>
                            </li>
                            <li class="page-item" :class="{ disabled: dataFilters.page === totalPages }"
                                @click="dataFilters.page < totalPages && changePage(dataFilters.page + 1)"
                                v-if="dataFilters.page < totalPages">
                                <a class="page-link"><img src="frontend/images/right-arrow-icon.svg" alt="Next"></a>
                            </li>
                        </ul>
                    </nav>
                </div>

                <template>
                    <div class="table-responsive custom-table-design mt-4">
                        <div class="filter-bar-se my-4">
                            <h4 class="mb-3">Active Leads</h4>
                            <div class="filter-right-bar">
                                <div class="dropdown">
                                    <a href="#" id="Dropdown-sort-by" role="button" data-bs-toggle="dropdown" aria-expanded="true">
                                        <img src="frontend/images/Sort-By.svg"> Sort By
                                    </a>
                                    <ul class="dropdown-menu" aria-labelledby="Dropdown-sort-by">
                                        <li><a class="dropdown-item" href="#" :class="{ 'selected': dataFilters.sort_by_field === 'Oldest' }"
                                               @click.prevent="sort('Oldest')">Oldest</a></li>
                                        <li><a class="dropdown-item" href="#" :class="{ 'selected': dataFilters.sort_by_field === 'Newest' }"
                                               @click.prevent="sort('Newest')">Newest</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <table class="text-center table-bordered align-middle" >
                            <thead>
                            <tr>
                                <th>Customer Name</th>
                                <th>Project Listing</th>
                                <th>Created At</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <td>{{  }}</td>
                                <td>{{  }}</td>
                                <td>{{  }}</td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </template>
                <template>
                    <div class="text-center mt-4">No records found</div>
                </template>
                <!--                <div class="pagination-se mt-3" v-if="propertiesData.length > 0">-->
                <!--                    <nav aria-label="...">-->
                <!--                        <ul class="pagination justify-content-center">-->
                <!--                            <li class="page-item" :class="{ disabled: dataFilters.page === 1 }"-->
                <!--                                @click="dataFilters.page > 1 && changePage(dataFilters.page - 1)" v-if="dataFilters.page > 1">-->
                <!--                                <a class="page-link"><img src="frontend/images/left-arrow-icon.svg"-->
                <!--                                                          alt="Previous"></a>-->
                <!--                            </li>-->
                <!--                            <li v-for="pageNumber in paginatedPages(dataFilters.page, totalPages)" :key="pageNumber"-->
                <!--                                class="page-item" :class="{ active: pageNumber === dataFilters.page }">-->
                <!--                                <a class="page-link"-->
                <!--                                   @click.prevent="pageNumber !== dataFilters.page && changePage(pageNumber)">-->
                <!--                                    {{ pageNumber }}-->
                <!--                                </a>-->
                <!--                            </li>-->
                <!--                            <li class="page-item" :class="{ disabled: dataFilters.page === totalPages }"-->
                <!--                                @click="dataFilters.page < totalPages && changePage(dataFilters.page + 1)"-->
                <!--                                v-if="dataFilters.page < totalPages">-->
                <!--                                <a class="page-link"><img src="frontend/images/right-arrow-icon.svg" alt="Next"></a>-->
                <!--                            </li>-->
                <!--                        </ul>-->
                <!--                    </nav>-->
                <!--                </div>-->
            </div>
        </div>
    </section>
</template>

<script>
    import {mapState} from "vuex";
    import downloadexcel from "vue-json-excel3";
    import toastr from "toastr";
    import {formatDate} from "@/utils/helpers";

    export default {
        name: 'ProjectDataAnalytic',
        computed: {
            ...mapState(['isAuthenticated', 'user', 'userProfile']),
        },
        components: {
            downloadexcel,
        },
        data() {
            return {
                projectsData: [],
                totalViews: '',
                totalInquiries: '',
                totalPages: 0,
                totalRecords: 0,
                dataFilters: {
                    page: 1,
                    sort_by_field: 'Newest',
                },
                json_fields: {
                    "Property Name": "property.property_name",
                    "Views": "views",
                    "Inquiries": "inquiries",
                    "Created At": {
                        field: "created_at",
                        callback: (value) => formatDate(value), // Format the date
                    },
                },
            }
        },
        created() {
            this.fetchData();
        },
        methods: {
            redirectToProjectDetails(projectId) {
                this.$router.push({ name: 'projectDetail', params: { id: projectId } });
            },
            startDownload() {
                toastr.info('Preparing the download...');
            },
            finishDownload() {
                toastr.success('Download completed!');
            },
            async fetchData() {
                const token = localStorage.getItem('userToken');
                try {
                    const response = await this.$axios.post('api/get-project-analytic-detail',{
                        api_token: token,
                        user_id: this.user.id,
                        sort_by_field: this.dataFilters.sort_by_field,
                        page_number: this.dataFilters.page,
                    });

                    // this.totalViews = response.data.output.total_views;
                    // this.totalInquiries = response.data.output.total_inquiries;
                    this.projectsData = response.data.output.data.data;
                    this.totalRecords = response.data.output.total_records;
                    this.totalPages = response.data.output.total_pages;

                } catch (error) {
                    console.error('Unable to fetch data, error:', error.message);
                }
            },
            sort(sortBy) {
                this.dataFilters.sort_by_field = sortBy;
                this.dataFilters.page = 1;

                this.fetchData();
            },
            changePage(page) {
                if (page > 0) {
                    const totalPages = this.totalPages;
                    if (page <= totalPages) {
                        this.dataFilters.page = page;
                        this.fetchData();
                    }
                }
            },
            async exportProjectsToExcel() {
                // Return the array of objects to be exported
                return this.projectsData;
            },
        }
    }


</script>




<template>
  <div class="footer-sec">
    <div class="footer-menu-outer">
      <div class="container">
        <div class="row">
          <div class="col-md-3">					
            <div class="footer-privacy-logo">
              <figure>
                <img src="frontend/images/logo.png">
              </figure>
              <p>Empowers Property Owners To Partner With Estate Agents, Connecting To Buyers & Renters For Seamless Property Search Experience.</p>
            </div>
          </div>
          <div class="col-md-9">
            <div class="row">				
<!--              <div class="col-md-3">-->
<!--                <div class="footer-menu footer-nav">-->
<!--                  <h3>Discover</h3>-->
<!--                  <ul>-->
<!--                        <li><a href="/most-viewed">Most Viewed & Popular Searches</a></li>-->
<!--                    <li><a href="/hybrid-listing">Hybrid Listing</a></li>-->
<!--                    <li><a href="/feng-shui-mapping">Feng Shui Mapping</a></li>-->
<!--                    <li><a href="/ai-assist-creatives">AI-Assist & Creatives</a></li>-->
<!--                    <li><a href="/x-border-properties">X-Border Properties</a></li>-->
<!--                  </ul>-->
<!--                </div>-->
<!--              </div>-->
              <div class="col-md-4 col-6">
                <div class="footer-menu footer-nav">
                  <h3>Quick links</h3>
                  <ul>
                    <li><a href="/about-us">About</a></li>
                    <li><a href="/faq">FAQs</a></li>
                    <li><a href="/privacy-policy">Privacy policy</a></li>
                    <li><a href="/terms-and-conditions">Terms and conditions</a></li>
                  </ul>
                </div>
              </div>
              <div class="col-md-4 col-6">
                <div class="footer-menu footer-nav">
                  <h3>Contact Us</h3>
                  <ul>
                    <li>
                      <div>
                        <img class="pt-1" src="/frontend/images/email-footer-ico.png">
                        <a class="pt-1 text-lowercase" href="mailto: support@propface.com">  support@propface.com</a>
                      </div>
                    </li>
                    <li>
                      <div>
                        <img class="pt-1" src="/frontend/images/phone-footer-ico.png">
                        <span class="pt-1">  +65-12341234</span>
                      </div>
                    </li>
                    <li>
                      <div>
                        <img class="pt-1" src="/frontend/images/map-footer-ico.png">
                        <span class="pt-1">  Singapore</span>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="col-md-4">
                <div class="news-quick">
                  <h3>Get news & Update</h3>
                  <form>
                    <div class="form-group">
                      <input type="text" name="email" class="form-control" placeholder="Email address">
                    </div>
                    <div class="">
                      <button type="submit" class="btn comman-btn-3 w-100">Submit</button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="copyright-outer">
      <div class="container">
        <div class="row align-items-center">
          <div class="col-md-8">
            <p>2024 All Rights Reserved | <b>PROPFACE</b> Pte Ltd</p>
          </div>
          <div class="col-md-4">
            <div class="social">
              <a href="https://www.x.com/login">
                <img src="frontend/images/tweet-ico.svg">
              </a>
              <a href="https://www.facebook.com/login">
                <img src="frontend/images/fb-ico.svg">
              </a>
              <a href="https://www.linkedin.com/login">
                <img src="frontend/images/linkedin-ico.svg">
              </a>
              <a href="https://www.youtube.com/accounts/login">
                <img src="frontend/images/youtube-ico.svg">
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>    
</template>
  
  <script>
  export default {
    name: 'FooterPart'
  }
  </script>
  
  <style scoped>
  footer {
    background: #333;
    color: white;
    padding: 1rem;
    text-align: center;
  }
  </style>
  
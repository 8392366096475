<template>
    <div>
        <div class="container mt-5">
            <h1>Forgot your password?</h1>
            <h3>Just let us know your email address and we will email you a password reset link.</h3>
        </div>

        <form class="container" method="POST">
            <div>
                <label for="email" class="text-warning mt-4"><h5>Email:</h5></label>
                <input id="email" v-model="email" type="email" class="form-control w-50" required autofocus/>
            </div>

            <button type="button" class="mt-5 comman-btn-2" @click="submitForm">
                Email Password Reset Link
            </button>
        </form>
    </div>
</template>

<script>
export default {
    name: 'ForgetPassword',
    data() {
        return {
            email: '',
        };
    },
    methods: {
        async submitForm() {
            try {
                const response = await this.$axios.post('api/forgot-password', {
                    email: this.email,
                });

                if(response.status == 200) {
                    alert('Email has been sent to you');
                }

            } catch (error) {
                console.error('An unexpected error occurred:', error);
            }
        }
    },
};
</script>

<style scoped>
</style>

<template>
    <section class="signin-sec">
        <div class="container">
            <div class="signin-bx m-auto">
                <h1>Login</h1>
                <div v-if="errorMessage" class="alert alert-danger">{{ errorMessage }}</div>
                <form @submit.prevent="login">
                    <div class="form-group">
                        <input type="email" placeholder="Email address" class="form-control" v-model="email"
                            @blur="validateEmail" :class="{ 'is-invalid': emailError }" />
                        <div v-if="emailError" class="error">{{ emailError }}</div>
                    </div>
                    <div class="form-group">
                        <input type="password" placeholder="Password" class="form-control" v-model="password"
                            @blur="validatePassword" :class="{ 'is-invalid': passwordError }" />
                        <div v-if="passwordError" class="error">{{ passwordError }}</div>
                    </div>
                    <div class="d-flex justify-content-between form-group">
                        <div class="form-check">
                            <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault">
                            <label class="form-check-label" for="flexCheckDefault">
                                Remember me
                            </label>
                        </div>
                        <span class="forgot-link ml-auto"><a href="/forget-password">Forgot Password?</a></span>
                    </div>
                    <div class="form-group">
                        <button type="submit" class="btn comman-btn-2 w-100" :disabled="loading">Login</button>
                    </div>
                    <div class="or-text"><span>Or continue with</span></div>
                    <div class="form-group">
                        <div class="row">
                            <div class="col-md-6 col-6">
                                <div class="login-link">
                                    <a href="" @click.prevent="loginWithGoogle"><i><img
                                    src="frontend/images/google-login.png"></i>Google</a>
                                </div>
                            </div>
                            <div class="col-md-6 col-6">
                                <div class="login-link">
                                    <a href="" @click.prevent="loginWithFacebook"><i><img
                                    src="frontend/images/fb-login.png"></i>Facebook</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <small class="no-login">
                        By continuing, I agree to PROPFACE's <br><a href="/terms-and-conditions">Terms of Use</a> & <a
                            href="/privacy-policy">Privacy Policy.</a>
                    </small>
                    <small class="no-login mb-0">
                        Not a member? <a href="/sign-up"><b>Create account</b></a>
                    </small>
                </form>
            </div>
        </div>
    </section>
</template>

<script>
    import { mapActions } from 'vuex';
    export default {
        name: 'SignIn',
        data() {
            return {
                email: '',
                password: '',
                emailError: '',
                passwordError: '',
                errorMessage: '',
                loading: false,
                form: {
                    resetEmail: null,
                },
                failedAttempts: 0,
                lockoutTime: null, 
            };
        },
        computed: {
            ...mapActions(['setAuthenticated', 'setUser']),
            isFormValid() {
                return !this.emailError && !this.passwordError && this.email && this.password;
            },
        },
        methods: {
            async loginWithGoogle() {
                try {
                    // Request the Google redirect URL from the API
                    const response = await this.$axios.get('api/google');
                    window.location.href = response.data.redirect_url; // Redirect to Google login
                } catch (error) {
                    console.error('Error during Google login:', error);
                }
            },

            async loginWithFacebook() {
                try {
                    // Request the Facebook redirect URL from the API
                    const response = await this.$axios.get('api/facebook/redirect');
                    window.location.href = response.data.redirect_url; // Redirect to Facebook login
                } catch (error) {
                    console.error('Error during Facebook login:', error);
                }
            },

            validateEmail() {
                this.emailError = this.email
                    ? /\S+@\S+\.\S+/.test(this.email)
                        ? ''
                        : 'Invalid email format'
                    : 'Email is required';
            },
            validatePassword() {
                this.passwordError = this.password
                    ? this.password.length >= 6
                        ? ''
                        : 'Password must be at least 6 characters long'
                    : 'Password is required';
            },
            
            async login() {
                this.validateEmail();
                this.validatePassword();

                // Prevent login if there are validation errors
                if (this.emailError || this.passwordError) {
                    return;
                }

                if (this.loading) return; // Prevent multiple submissions
                this.loading = true;

                // Check if the user is locked out
                const now = new Date().getTime();
                if (this.lockoutTime && now < this.lockoutTime) {
                    const remainingTime = Math.ceil((this.lockoutTime - now) / 1000 / 60);
                    this.errorMessage = `Too many failed attempts. Try again in ${remainingTime} minute(s).`;
                    return;
                }

                this.loading = true;

                try {
                    // Make the login API call
                    const response = await this.$axios.post('api/login', {
                        email: this.email,
                        password: this.password,
                    });

                    // Reset failed attempts on successful login
                    this.failedAttempts = 0;
                    this.lockoutTime = null;

                    console.log('Login successful:', response.data);
                    localStorage.setItem('userId', response.data.user.id);

                    // Dispatch login action to store user data
                    await this.$store.dispatch('login', response.data);

                    // Handle post-login actions
                    await this.handlePostLoginActions(response.data);

                } catch (error) {
                    this.failedAttempts += 1;

                    if (this.failedAttempts >= 4) {
                        // Set lockout time for 10 minutes
                        this.lockoutTime = new Date().getTime() + 10 * 60 * 1000;
                        this.errorMessage = 'Too many failed attempts. Try again in 10 minutes.';
                    } else {
                        this.errorMessage = `Login failed. Please check your credentials. (${4 - this.failedAttempts} attempts remaining)`;
                    }

                    console.error('Login error:', error);
                    
                } finally {
                    this.loading = false; // Reset loading state
                }
            },

            async handlePostLoginActions(userData) {
                const redirectAfterLogin = localStorage.getItem('hybrid_listing_type');
                const agentId = localStorage.getItem('agentId');
                const widgetPropertyId = localStorage.getItem('widget_propertyId');
                const propertyListingId = localStorage.getItem('property_listing_id');
                const userId = localStorage.getItem('userId');

                // Define the function to update the user for a property
                const updateUserForProperty = async (propertyListingId, userId) => {
                    try {
                        const response = await this.$axios.post('/api/update-user-property', {
                            property_listing_id: propertyListingId,
                            user_id: userId,
                        });

                        if (response.data.status === 1) {
                            console.log('Success:', response.data.message);
                            // Update the UI or notify the user
                        } else {
                            console.error('Error:', response.data.message);
                        }
                    } catch (error) {
                        console.error(
                            'Error:',
                            error.response?.data?.message || 'An error occurred while updating the property user.'
                        );
                    }
                };

                try {
                    // Handle hybrid listing type redirection
                    if (redirectAfterLogin) {
                        if (redirectAfterLogin === "2" && agentId) {
                            if (propertyListingId && userId) {
                                await updateUserForProperty(propertyListingId, userId);
                            }
                            this.$router.push({ name: 'AgentDetail', params: { id: agentId } });
                            return; // Ensure no further redirection
                        } else {
                            this.$router.push('/my-property/');
                            return; // Ensure no further redirection
                        }
                    }

                    // Handle widget property ID logic
                    if (widgetPropertyId) {
                        await this.widgetPropertyIdSet(widgetPropertyId, userData.user.id);
                    }

                    // Default redirection if no specific condition matches
                    this.$router.push('/');
                } catch (error) {
                    console.error('Error during login actions:', error.message || error);
                }
            },

            async widgetPropertyIdSet(propertyId, userId) {
                try {
                    const response = await this.$axios.post('api/widget-property', {
                        propertyId,
                        userId,
                    });

                    console.log('Widget property set successfully:', response.data);
                } catch (error) {
                    console.error('Failed to set widget property:', error);
                }
            },

            async googleLogin() {
                // TODO: Google Login
                const today = new Date();
                const googleIdToken = 'GT-' + today.getHours();
                localStorage.removeItem('facebookAccessToken');
                if (this.loading) {
                    return;
                }

                this.loading = true;

                try {
                    const response = await this.$axios.post('api/login-google', {
                        google_id_token: googleIdToken,
                    });

                    if (response.data && response.data.token) {
                        await this.$store.dispatch('login', response.data);
                        console.log('Login successful:', response.data);
                    }

                    localStorage.setItem('googleIdToken', googleIdToken);
                    this.$router.push('/sign-up-social');
                } catch (error) {
                    // Handle login errors
                    this.errorMessage = 'Login failed. Please check your credentials.';
                    console.error('Login error:', error);
                } finally {
                    this.loading = false;
                }
            },
            async facebookLogin() {
                // TODO: FB Login
                const today = new Date();
                const facebookAccessToken = 'FB-' + today.getHours();
                localStorage.removeItem('googleIdToken');
                if (this.loading) {
                    return;
                }

                this.loading = true;

                try {
                    const response = await this.$axios.post('api/login-facebook', {
                        facebook_access_token: facebookAccessToken,
                    });

                    if (response.data && response.data.token) {
                        await this.$store.dispatch('login', response.data);
                        console.log('Login successful:', response.data);
                    }

                    localStorage.setItem('facebookAccessToken', facebookAccessToken);
                    this.$router.push('/sign-up-social');
                } catch (error) {
                    // Handle login errors
                    this.errorMessage = 'Login failed. Please check your credentials.';
                    console.error('Login error:', error);
                } finally {
                    this.loading = false;
                }
            },
            async widgetPropetyIdSet(property_listing_id, userId) {
                const token = localStorage.getItem('userToken');
                this.loading = true;
                try {
                    const response = await this.$axios.post('api/widget-property-set-user-id-after-login', {
                        user_id: userId,
                        api_token: token,
                        property_listing_id: property_listing_id,
                    });
                    if (response.data) {
                        localStorage.removeItem('widget_propertyId');
                        this.$router.push('/my-property/');
                    }
                } catch (error) {
                    this.errorMessage = 'Login failed. Please check your credentials.';
                    console.error('Login error:', error);
                } finally {
                    this.loading = false;
                }
            },
            async forgetPassword() {
                if (!this.validateResetEmail(this.form.resetEmail)) {
                    alert("Please enter a valid email address.");
                    return;
                }

                this.loading = true;

                try {
                    const response = await this.$axios.get('/auth/forgot-password', {
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({ email: this.form.resetEmail }),
                    });

                    if (!response) {
                        throw new Error("Something went wrong. Please try again.");
                    }

                    const result = await response.json();

                    alert(result.message || "Password reset email sent successfully.");
                    this.resetForm();
                } catch (error) {
                    console.error("Error:", error);
                    alert(error.message || "Unable to send password reset email. Please try again.");
                } finally {
                    this.loading = false;
                }
            },
            validateResetEmail(email) {
                const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                return regex.test(email);
            },
            resetForm() {
                this.form.resetEmail = "";
            },
        }
    }
</script>
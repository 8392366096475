<template>
    <section class="verify-service-detail-page">
        <div class="container">
            <div class="top-tabs-design">
                <ul>
                    <li><a href="/profile">My Profile  <span v-if="userProfile.is_user_verified">
                        <img src="/frontend/images/verified_logo.png" alt="Verified" style="width: 16px; height: 16px; margin-left: 5px; margin-top: 5px;">
                    </span></a></li>
                    <li><a href="javascript:;" class="active">Verify Service Details</a></li>
                    <li><a href="/property-data-analytic">Analytics and Report</a></li>
                    <li><a href="/wishlist">My Wishlist</a></li>
                    <li><a href="/requested-service">Requested Service</a></li>
                    <li><a href="/messages">Messages</a></li>
                </ul>
            </div>
            <div class="comman-title">
                <h3>Fill up to complete your service profile to be verified</h3><br>
                <label>
                    Verification Status:
                    <span v-if="isVerified">
                        <img src="/frontend/images/verified_logo.png" alt="Verified" style="width: 16px; height: 16px; margin-left: 5px; margin-top: 3px;">
                        <span style="font-size: 10px; vertical-align: middle;"> Verified</span>
                    </span>
                    <span v-else>
                        <img src="/frontend/images/unverified_logo.png" alt="Unverified" style="width: 16px; height: 16px; margin-left: 5px; margin-top: 3px;">
                        <span style="font-size: 10px; vertical-align: middle; color: red;"> Unverified</span>
                    </span>
                </label>
            </div>
            <form @submit.prevent="updateServicedData">
                <div class="profile-card-edit">
                    <div class="comman-title">
                        <h3>Agency/Company </h3>
                    </div>
                    <div class="row">
                        <div class="form-group col-md-3">
                            <label>Agency Name</label>
                            <input type="text" placeholder="Agency Name" class="form-control"
                                   v-model="form.agency_name" required>
                            <span class="error" v-if="errors.agency_name">{{ errors.agency_name }}</span>
                        </div>
                        <div class="form-group col-md-3">
                            <label>Agency License</label>
                            <input type="text" placeholder="Agency License" class="form-control"
                                   v-model="form.agency_license" required>
                        </div>
                        <div class="form-group col-md-3">
                            <label>Agency Registration No</label>
                            <input type="text" placeholder="Agency Registration No" class="form-control"
                                   v-model="form.agency_registration_no" required>
                        </div>
                        <div class="form-group col-md-3">
                            <label>Position Held</label>
                            <input type="text" placeholder="Position Held" class="form-control"
                                   v-model="form.position_held">
                            <span class="error" v-if="errors.position_held">{{ errors.position_held }}</span>
                        </div>
                        <div class="form-group mb-0 col-md-12">
                            <div class="form-check">
                                <input class="form-check-input" type="checkbox" value="" id="show_as_agent"
                                       v-model="form.show_as_agent">
                                <label class="form-check-label w-100" for="show_as_agent">
                                    Estate Agent<a href="#"> Sign-Up / Upgrade</a>
                                </label>
                            </div>
                        </div>
                    </div>
                </div>


                <div class="profile-card-edit">
                    <div class="comman-title">
                        <h3>Your Services</h3>
                    </div>
                    <div class="row">
                        <div class="form-group col-md-4">
                            <label>My Services</label>
                            <select id="service_type_id" v-model="form.service_type_id" class="form-control">
                                <!-- <option value="" disabled selected>Please Facilities</option> -->
                                <option value="0" disabled>Select Service</option>
                                <option v-for="option in service_typeList" :key="option.value"
                                        :value="option.value">
                                    {{ option.label }}
                                </option>
                            </select>
                            <span class="error" v-if="errors.service_type_id">{{ errors.service_type_id }}</span>
                        </div>
                        <div class="form-group col-md-4">
                            <label>Company Name</label>
                            <input type="text" placeholder="Company Name" class="form-control"
                                   v-model="form.company_name">
                        </div>

                        <div class="form-group col-md-4">
                            <label>Working Hours</label>
                            <input type="text" placeholder="Working Hours" class="form-control"
                                   v-model="form.working_hours">
                        </div>
                    </div>
                    <div class="row">
                        <div class="form-group">
                            <div class="mb-3">
                                <label>Availability Days</label>
                                <ul class="d-flex flex-row gap-3 list-unstyled">
                                    <li v-if="form.is_available_mon">
                                        <span class="badge comman-btn-2 text-dark px-3 py-2">Mon</span>
                                    </li>
                                    <li v-if="form.is_available_tue">
                                        <span class="badge comman-btn-2 text-dark px-3 py-2">Tue</span>
                                    </li>
                                    <li v-if="form.is_available_wed">
                                        <span class="badge comman-btn-2 text-dark px-3 py-2">Wed</span>
                                    </li>
                                    <li v-if="form.is_available_thu">
                                        <span class="badge comman-btn-2 text-dark px-3 py-2">Thu</span>
                                    </li>
                                    <li v-if="form.is_available_fri">
                                        <span class="badge comman-btn-2 text-dark px-3 py-2">Fri</span>
                                    </li>
                                    <li v-if="form.is_available_sat">
                                        <span class="badge comman-btn-2 text-dark px-3 py-2">Sat</span>
                                    </li>
                                    <li v-if="form.is_available_sun">
                                        <span class="badge comman-btn-2 text-dark px-3 py-2">Sun</span>
                                    </li>
                                </ul>
                            </div>
<!--                            <input type="text" placeholder="Availability Days" class="form-control"-->
<!--                                   v-model="form.availability_days">-->
                            <div class="form-group">
                                <div class="form-check form-check-inline">
                                    <label class="form-check-label w-100" for="is_available_mon">Monday</label>
                                    <input class="form-check-input" type="checkbox"
                                           v-model="form.is_available_mon" id="is_available_mon">
                                </div>
                                <div class="form-check form-check-inline">

                                    <label class="form-check-label w-100" for="is_available_tue">Tuesday</label>
                                    <input class="form-check-input" type="checkbox"
                                           v-model="form.is_available_tue" id="is_available_tue">
                                </div>
                                <div class="form-check form-check-inline">
                                    <label class="form-check-label w-100"
                                           for="is_available_wed">Wednesday</label>
                                    <input class="form-check-input" type="checkbox"
                                           v-model="form.is_available_wed" id="is_available_wed">
                                </div>
                                <div class="form-check form-check-inline">
                                    <label class="form-check-label w-100"
                                           for="is_available_thu">Thursday</label>
                                    <input class="form-check-input" type="checkbox"
                                           v-model="form.is_available_thu" id="is_available_thu">
                                </div>
                                <div class="form-check form-check-inline">
                                    <label class="form-check-label w-100" for="is_available_fri">Friday</label>
                                    <input class="form-check-input" type="checkbox"
                                           v-model="form.is_available_fri" id="is_available_fri">
                                </div>
                                <div class="form-check form-check-inline">
                                    <label class="form-check-label w-100"
                                           for="is_available_sat">Saturday</label>
                                    <input class="form-check-input" type="checkbox"
                                           v-model="form.is_available_sat" id="is_available_sat">
                                </div>
                                <div class="form-check form-check-inline">
                                    <label class="form-check-label w-100" for="is_available_sun">Sunday</label>
                                    <input class="form-check-input" type="checkbox"
                                           v-model="form.is_available_sun" id="is_available_sun">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="profile-card-edit">
                    <div class="comman-title">
                        <h3>Your Experience</h3>
                    </div>
                    <div class="row">
                        <div class="form-group col-md-6 disabled">
                            <label>Service</label>
                            <select id="personal_service_type_id" v-model="form.personal_service_type_id"
                                    class="form-control">
                                <option value="0">Select Service</option>
                                <option v-for="option in service_typeList" :key="option.value"
                                        :value="option.value">
                                    {{ option.label }}
                                </option>
                            </select>
                        </div>
                        <div class="form-group col-md-6">
                            <label>Working Since</label>
                            <select v-model="this.form.personal_working_since" id="year-select"
                                    class="form-control">
                                <option value="">Working Since</option>
                                <option value="0">Select Working Since</option>
                                <option v-for="year in years" :key="year" :value="year">{{ year }}</option>
                            </select>
                        </div>
                    </div>
                </div>

                <div class="profile-card-edit d-flex">
                    <button class="btn comman-btn-2" type="submit">Save</button>
                    <button class="btn comman-btn-4 ms-2">Cancel</button>
                </div>
            </form>
            <div class="profile-card-edit" v-if="userProfile.is_business && profilServicesData?.agency_name">
                <div class="comman-title">
                    <h3>Your Widget Content</h3>
                </div>

                <form>
                    <div class="form-group">
                        <label for="title">Title</label>
                        <input type="text" id="title" v-model="widget.title" class="form-control" placeholder="Enter the title for your widget"/>
                    </div>

                    <div class="form-group">
                        <label for="description">Description</label>
                        <textarea id="description" v-model="widget.description" class="form-control" placeholder="Enter a brief description" rows="3"></textarea>
                    </div>

                    <div class="form-group">
                        <label for="image">Image URL</label>
                        <input type="file" id="image" @change="onImageUpload" class="form-control" accept="image/*" />
                        <div v-if="widget.image" class="form-group">
                            <label>Image Preview:</label>
                            <img :src="widget.image" alt="Uploaded Image" class="img-fluid" style="max-width: 100%; height: auto;" />
                        </div>
                    </div>

                    <div class="mt-4">
                        <button @click="submitWidget" type="button" class="comman-btn-2">Save Widget</button>
                    </div>
                </form>
                <div class="d-flex">
                    <div class="url-container">
                        <small>Share link to post properties to owner or colleague</small>
                        <div class="url-box">
                            <div>{{ referal_current_Url }}</div>
                            <button @click="copyToClipboard" type="button" class="btn btn-warning btn-sm">Copy URL</button>
                        </div>
                        <p v-if="copySuccess" class="copy-success">URL copied to clipboard!</p>
                    </div>
                </div>

                <div class="form-group mt-4">
                    <label>Share your widget:</label>
                    <div class="d-flex gap-3">
                        <a :href="'https://wa.me/?text=' + encodeURIComponent(referal_current_Url)" target="_blank" class="social-icon">
                            <img src="/frontend/images/whatsapp.png" alt="WhatsApp" style="width: 40px; height: 40px;" />
                        </a>

                        <a :href="'https://t.me/share/url?url=' + encodeURIComponent(referal_current_Url)" target="_blank" class="social-icon">
                            <img src="/frontend/images/telegramicon.png" alt="Telegram" style="width: 40px; height: 40px;" />
                        </a>

                        <a href="https://www.facebook.com" target="_blank" class="social-icon">
                            <img src="/frontend/images/messenger.png" alt="Facebook" style="width: 40px; height: 40px;" />
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
    import toastr from "toastr";
    import {mapState} from "vuex";

    export default {
        name: 'VerifyServiceDetailPage',
        data() {
            return {
                isVerified: '',
                errors: {},
                isLoading: false,
                copySuccess: false,
                referal_current_Url: '',
                form: {
                    name: '',
                    first_name: '',
                    last_name: '',
                    gender: '',
                    date_of_birth: '',
                    mobile_phone: '',
                    email: '',
                    is_mobile_verified: '',
                    is_email_verified: '',
                    contact_phone: '',
                    contact_email: '',
                    facebook_url: '',
                    linkedin_url: '',
                    twitter_url: '',
                    instagram_url: '',
                    tiktok_url: '',
                    youtube_url: '',
                    about_you: '',
                    agency_name: '',
                    agency_license: '',
                    agency_registration_no: '',
                    position_held: '',
                    personal_service_type_id: 0,
                    personal_working_since: '',
                    service_type_id: 0,
                    show_as_agent: 0,
                    is_available_mon: 0,
                    is_available_tue: 0,
                    is_available_wed: 0,
                    is_available_thu: 0,
                    is_available_fri: 0,
                    is_available_sat: 0,
                    is_available_sun: 0,
                },
                package_details: [],
                myprofileData: [],
                service_typeList: [],
                profilServicesData: [],
                widget: {
                    title: '',
                    description: '',
                    image: '',
                    url: '',
                },
                years: [],
                isAgent: null,
                isFeatured: null,
                featuredTo: null,
                featuredFrom: null,
            }
        },
        computed:{
            ...mapState(['user','isAuthenticated', 'userProfile'])
        },
        async created() {
            await this.fetchOptions();
            this.generateYears();
        },
        methods: {
            generateYears() {
                const currentYear = new Date().getFullYear();
                for (let year = 1970; year <= currentYear; year++) {
                    this.years.push(year);
                }
            },
            validateForm() {
                this.errors = {};
                if (!this.form.agency_name) {
                    this.errors.agency_name = 'Agency Name is required.';
                }
                if (!this.form.position_held) {
                    this.errors.position_held = 'Position Held is required.';
                }
                if (!this.form.service_type_id) {
                    this.errors.service_type_id = 'My Services is required.';
                }
                //   if (!this.form.availability_days ) {
                //       this.errors.availability_days = 'Availability Days is required.';
                //   }

                return Object.keys(this.errors).length === 0;
            },
            async updateServicedData() {
                if (this.validateForm()) {
                    this.isLoading = true;
                    var show_as_agent = this.form.show_as_agent == true ? 1 : 0
                    const token = localStorage.getItem('userToken');
                    try {
                        const response = await this.$axios.post('api/add-update-user-services', {
                            user_id: this.user.id,
                            api_token: token,
                            user_service_detail_id: 0,
                            agency_name: this.form.agency_name ? this.form.agency_name : '',
                            agency_license: this.form.agency_license ? this.form.agency_license : '',
                            agency_registration_no: this.form.agency_registration_no ? this.form.agency_registration_no : '',
                            position_held: this.form.position_held ? this.form.position_held : '',
                            service_type_id: this.form.service_type_id ? this.form.service_type_id : '',
                            company_name: this.form.company_name ? this.form.company_name : '',
                            availability_days: this.form.availability_days ? this.form.availability_days : 0,
                            is_available_mon: this.form.is_available_mon,
                            is_available_tue: this.form.is_available_tue,
                            is_available_wed: this.form.is_available_wed,
                            is_available_thu: this.form.is_available_thu,
                            is_available_fri: this.form.is_available_fri,
                            is_available_sat: this.form.is_available_sat,
                            is_available_sun: this.form.is_available_sun,
                            working_hours: this.form.working_hours ? this.form.working_hours : 0,
                            show_as_agent: show_as_agent,
                            personal_service_type_id: this.form.personal_service_type_id,
                            personal_working_since: this.form.personal_working_since ? this.form.personal_working_since : 0,

                        });

                        this.profilServicesData = response.data.output;
                        toastr.success(response.data.message, 'Success', { timeOut: 3000 });

                    } catch (error) {

                        if (error.response && error.response.data) {
                            this.errors = this.extractErrors(error.response.data.errors || {});
                            toastr.error('Something went wrong', 'error', { timeOut: 3000 });
                        } else {
                            // console.error('An unexpected error occurred:', error);
                            toastr.error('Something went wrong', 'error', { timeOut: 3000 });
                        }
                    } finally {
                        this.isLoading = false;
                    }
                }
            },
            async fetchOptions() {
                const token = localStorage.getItem('userToken');
                // Get Features list
                try {
                    const response = await this.$axios.post('api/services-type-list',
                        {
                            user_id: this.user.id,
                            api_token: token
                        });
                    this.service_typeList = response.data.output.map(item => ({
                        value: item.id,
                        label: item.name
                    }));

                    if (this.service_typeList.length > 0) {
                        this.form.personal_service_type_id = this.service_typeList[0].value;
                    }

                } catch (error) {
                    console.error('Error fetching options:', error);
                }

                if (this.isAuthenticated) {
                    try {
                        const response = await this.$axios.post('/api/my-profile-detail', {
                            api_token: token,
                            user_id: this.user.id
                        });

                        var userData = response.data.output;
                        await this.$store.dispatch('profileUpdate', response.data.output);
                        var userServiceData = userData.service_details[0];
                        this.isVerified = userServiceData?.is_verified;
                        this.package_details = userData.package_details;
                        this.isFeatured = userServiceData?.is_featured;
                        this.featuredTo = userServiceData?.featured_to;
                        this.featuredFrom = userServiceData?.featured_from;
                        this.profilServicesData = userServiceData;
                        this.referal_current_Url = `${window.location.protocol}//${window.location.host}/` + 'widget-property-post/' + userData.id + '/' + userData.referral_user_type;
                        this.form = userData,
                        this.form.agency_name = userServiceData?.agency_name,
                        this.form.agency_license = userServiceData?.agency_license,
                        this.form.agency_registration_no = userServiceData?.agency_registration_no,
                        this.form.position_held = userServiceData?.position_held,
                        this.form.service_type_id = userServiceData?.service_type_id ?? 0,
                        this.form.company_name = userServiceData?.company_name,
                        this.form.availability_days = userServiceData?.availability_days,
                        this.form.is_available_mon = userServiceData?.is_available_mon == 1 ? true : false,
                        this.form.is_available_tue = userServiceData?.is_available_tue == 1 ? true : false,
                        this.form.is_available_wed = userServiceData?.is_available_wed == 1 ? true : false,
                        this.form.is_available_thu = userServiceData?.is_available_thu == 1 ? true : false,
                        this.form.is_available_fri = userServiceData?.is_available_fri == 1 ? true : false,
                        this.form.is_available_sat = userServiceData?.is_available_sat == 1 ? true : false,
                        this.form.is_available_sun = userServiceData?.is_available_sun == 1 ? true : false,
                        this.form.working_hours = userServiceData?.working_hours,
                        this.form.show_as_agent = userServiceData?.show_as_agent == 1 ? true : false,
                        this.form.personal_service_type_id = userServiceData?.personal_service_type_id ?? this.service_typeList[0].value,
                        this.form.personal_working_since = userServiceData?.personal_working_since ?? '',
                        // This is not executed, as some error has thrown before
                        await this.$store.dispatch('profileUpdate', response.data.output);
                    } catch (error) {
                        console.error('Failed to fetch profile:', error);
                    }

                } else {
                    console.log('User is not authenticated');
                }
            },

            //widget functions
            async submitWidget() {
                try {
                    // Create a new FormData object to send the image as a file
                    const formData = new FormData();

                    // Append all data to the FormData object, including the image as a file

                    formData.append('api_token', localStorage.getItem('userToken'));
                    formData.append('user_id', this.user.id);
                    formData.append('title', this.widget.title);
                    formData.append('description', this.widget.description);
                    formData.append('url', this.referal_current_Url);

                    // Check if there is an image and append it to the formData
                    if (this.widget.image) {
                        formData.append('image', this.widget.image);
                    }

                    // Send the form data with axios
                    const response = await this.$axios.post('api/widgets', formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                        }
                    });

                    console.log('Request submitted successfully', response);
                } catch (error) {
                    console.error('Error submitting widget:', error);
                }
            },
            onImageUpload(event) {
                const file = event.target.files[0];
                if (file) {
                    const validImageTypes = ['image/jpeg', 'image/png', 'image/gif'];
                    if (validImageTypes.includes(file.type)) {
                        this.widget.image = file;  // Send the file directly
                    } else {
                        alert("Please upload a valid image.");
                    }
                }
            },
            copyToClipboard() {
                navigator.clipboard.writeText(this.referal_current_Url)
                    .then(() => {
                        this.copySuccess = true;
                        setTimeout(() => {
                            this.copySuccess = false; // Reset success message after 2 seconds
                        }, 2000);
                    })
                    .catch(err => {
                        console.error('Failed to copy: ', err);
                    });
            },
        }

    }
</script>

<style scoped>
    .container {
        margin-top: 20px;
    }

    .url-container {
        margin: 10px 0px;
        width: 100%;
    }

    .url-box {
        border: 1px solid #ddd;
        padding: 7px 7px;
        border-radius: 4px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        background: #ddd;
        text-transform: lowercase;
    }

    .copy-success {
        color: green;
        margin-top: 10px;
    }
</style>
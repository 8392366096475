<template>    
    
    <section class="property-se">
        <div class="container">
            <div class="property-title-bar">
                <div class="comman-title mb-0">
                    <h3 v-if="this.itemId>0"><a href="#"><i class="fa fa-angle-left"></i></a> Edit Property Listing</h3>
                    <h3 v-if="this.itemId==0"><a href="#"><i class="fa fa-angle-left"></i></a> Create Property Listing</h3>
                </div>
                <div class="progress-bar-box">
                    <div>3/5</div>
                    <div class="progress">
                        <div class="progress-bar" role="progressbar" style="width: 20%" aria-valuenow="15" aria-valuemin="0" aria-valuemax="100"></div>							 
                        <div class="progress-bar" role="progressbar" style="width: 20%" aria-valuenow="15" aria-valuemin="0" aria-valuemax="100"></div>							
                        <div class="progress-bar" role="progressbar" style="width: 20%" aria-valuenow="15" aria-valuemin="0" aria-valuemax="100"></div>							
                        <div class="progress-bar" role="progressbar" style="width: 20%" aria-valuenow="15" aria-valuemin="0" aria-valuemax="100"></div>
                        <!--<div class="progress-bar" role="progressbar" style="width: 20%" aria-valuenow="15" aria-valuemin="0" aria-valuemax="100"></div>							
                        <div class="progress-bar" role="progressbar" style="width: 20%" aria-valuenow="15" aria-valuemin="0" aria-valuemax="100"></div>-->
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-md-2">
                    <div class="property-sidebar">
                        <ul>
                            
                            <li><router-link :to="`/post-property-listing/${itemId}`">Step 1</router-link></li>
                            <li><router-link :to="`/post-property-listing-2/${itemId}`">Step 2</router-link></li>
                            <li><a class="active" href="javascript:;">Step 3</a></li>                            
                            <li><router-link :to="`/post-property-listing-4/${itemId}`">Step 4</router-link></li>
                            <li><router-link :to="`/post-property-listing-5/${itemId}`">Step 5</router-link></li>
                        </ul>
                    </div>
                </div>
                <div class="col-md-10">
                    <form @submit.prevent="submitPost">  
                        <div class="property-box">
                                
                            <div class="property-inner-box">
                                <div class="comman-title">
                                    <h3>Hybrid-Listing</h3>
                                </div>				
                                <p>You may assign this listing to multiple Estate Agents with our digital-brokerage widget, enhancing the reach and accelerating speed-to-market via the entire or co-agency network with leveraged resources, amplifying your chances of success while maintaining control of the original listing.</p>				
                                <div class="form-group">
                                    <label><a href="javascript:;">"Choice Of Either 1 Selection, Not Allowed To Have Multiple Choices."</a></label>
                                    <!-- <label for="">"Choice Of Either 1 Selection, Not Allowed To Have Multiple Choices."</label> -->
                                    <div class="form-check">
                                        <input class="form-check-input" type="radio" v-model="form.hybrid_listing_type" value="2" id="Assign-To-Recommended-Estate-Agent">
                                        <label class="form-check-label w-100" for="Assign-To-Recommended-Estate-Agent">
                                            Assign To Recommended Estate Agent
                                        </label>
                                    </div>
                                    <div class="form-check">
                                        <input class="form-check-input" type="radio" v-model="form.hybrid_listing_type" value="3" id="Find-My-Own">
                                        <label class="form-check-label w-100" for="Find-My-Own">
                                            Find My Own/Co-Broking Estate Agent
                                        </label>
                                    </div>
                                    <div class="form-check">
                                        <input class="form-check-input" type="radio" v-model="form.hybrid_listing_type" value="1" id="Attend-To-Inquiry">
                                        <label class="form-check-label w-100" for="Attend-To-Inquiry">
                                            Attend To Inquiry By Myself
                                        </label>
                                    </div>
                                    <span  class="error" v-if="errors.hybrid_listing_type">{{ errors.hybrid_listing_type }}</span>
                                    <div class="d-flex">
                                        <div class="notify-box">
                                            <span>Prefers To Display Contact Information</span> 
                                            <!-- <a href="#" class="yes">
                                                <img src="images/yes.svg" alt=""> YES</a> 
                                            <a href="#" class="no">
                                                <img src="images/no.svg" alt=""> No
                                            </a> -->
                                            <input type="radio" class="form-check-input" v-model="form.display_my_contact_information" value="1" id="yes1"> <span for="yes1" class="yes">Yes</span>
                                            <input type="radio" class="form-check-input" v-model="form.display_my_contact_information" value="0" id="no1"><span for="no1" class="no">No</span>
                                        </div>
                                    </div>
                                </div>
                            </div> 
                            <div class="property-inner-box">
                                <div class="comman-title">
                                    <h3>Enriched Contents - FengShui Mapping</h3>
                                </div>				
                                <div class="row row-cols-md-2 row-cols-1">
                                    <div class="col">
                                        <div class="form-group">
                                            <label for="">Property Identification</label>											
                                            <input type="number" class="form-control" placeholder="Year Completed/1st Occupied" v-model="form.FSM_property_completion_occupation_date">
                                        </div>	
                                    </div>									
                                    <div class="col">
                                        <!-- <div class="form-group">
                                            <label for=""></label>
                                            <div class="form-check form-check-inline">
                                                <input class="form-check-input" type="checkbox" value="" id="Landed">
                                                <label class="form-check-label w-100" for="Landed">
                                                    Landed
                                                </label>
                                            </div>	
                                            <div class="form-check form-check-inline">
                                                <input class="form-check-input" type="checkbox" value="" id="High-Rise">
                                                <label class="form-check-label w-100" for="High-Rise">
                                                    High-Rise
                                                </label>
                                            </div>
                                        </div> -->
                                    </div>
                                </div>	
                                <br>
                                <div class="comman-title">
                                    <h4>Property Facing</h4>
                                </div>		
                                <div class="row row-cols-md-3 row-cols-1">
                                    <div class="col">
                                        <div class="form-group">
                                            <label for="">Main Block/Building Facing</label>
                                            <select class="form-control" v-model="form.FSM_property_facing_orientation_building_id">
                                                <option value="0" disabled>Main Block/Building Facing</option>
                                                <RecursiveDropdown :options="hierarchicalOptions" />
                                            </select>
                                            <span  class="error" v-if="errors.FSM_property_facing_orientation_building_id">{{ errors.FSM_property_facing_orientation_building_id }}</span>
                                        </div>	
                                    </div>			
                                    <div class="col">
                                        <div class="form-group">
                                            <label for="">Main Door Facing</label>
                                            <select class="form-control" v-model="form.FSM_property_facing_orientation_main_entrance_id">
                                                <option value="0" disabled>Main Door Facing</option>
                                                <RecursiveDropdown :options="hierarchicalOptions" />
                                            </select>
                                            <span  class="error" v-if="errors.FSM_property_facing_orientation_main_entrance_id">{{ errors.FSM_property_facing_orientation_main_entrance_id }}</span>
                                        </div>	
                                    </div>			
                                    <div class="col">
                                        <div class="form-group">
                                            <label for="">Main Balcony/Window Facing</label>
                                            <select class="form-control" v-model="form.FSM_property_facing_orientation_main_balcony_window_id">
                                                <option value="0" disabled>Main Balcony/Window Facing</option>
                                                <RecursiveDropdown :options="hierarchicalOptions" />
                                            </select>
                                            <span  class="error" v-if="errors.FSM_property_facing_orientation_main_balcony_window_id">{{ errors.FSM_property_facing_orientation_main_balcony_window_id }}</span>                                            
                                        </div>	
                                    </div>			
                                </div>	
                                <br>										
                                <div class="comman-title">
                                    <h4>Space Sector</h4>
                                </div>		
                                <div class="row row-cols-md-2 row-cols-1">
                                    <div class="col">
                                        <div class="form-group">
                                            <label for="">Main Door/Entrance Sector</label>
                                            <select class="form-control" v-model="form.FSM_space_sectors_main_door_entrance_sector_id">
                                                <option value="0" disabled>Main Door/Entrance Sector</option>
                                                <RecursiveDropdown :options="hierarchicalOptions" />
                                            </select>
                                            <span  class="error" v-if="errors.FSM_space_sectors_main_door_entrance_sector_id">{{ errors.FSM_space_sectors_main_door_entrance_sector_id }}</span>
                                        </div>	
                                    </div>			
                                    <div class="col">
                                        <div class="form-group">
                                            <label for="">Main Living-Hall Sector</label>
                                            <select class="form-control" v-model="form.FSM_space_sectors_main_living_hall_hall_sector_id">
                                                <option value="0" disabled>Main Living-Hall Sector</option>
                                                <RecursiveDropdown :options="hierarchicalOptions" />
                                            </select>
                                            <span  class="error" v-if="errors.FSM_space_sectors_main_living_hall_hall_sector_id">{{ errors.FSM_space_sectors_main_living_hall_hall_sector_id }}</span>
                                        </div>	
                                    </div>			
                                    <div class="col">
                                        <div class="form-group">
                                            <label for="">Kitchen Sector</label>
                                            <select class="form-control" v-model="form.FSM_space_sectors_kitchen_sector_id">
                                                <option value="0" disabled>Kitchen Sector</option>
                                                <RecursiveDropdown :options="hierarchicalOptions" />
                                            </select>
                                            <span  class="error" v-if="errors.FSM_space_sectors_kitchen_sector_id">{{ errors.FSM_space_sectors_kitchen_sector_id }}</span>
                                        </div>	
                                    </div>			
                                    <div class="col">
                                        <div class="form-group">
                                            <label for="">Main/Master Room Sector</label>
                                            <select class="form-control" v-model="form.FSM_space_sectors_main_master_bedroom_sector_id">
                                                <option value="0" disabled>Main/Master Room Sector</option>
                                                <RecursiveDropdown :options="hierarchicalOptions" />
                                            </select>
                                            <span  class="error" v-if="errors.FSM_space_sectors_main_master_bedroom_sector_id">{{ errors.FSM_space_sectors_main_master_bedroom_sector_id }}</span>
                                        </div>	
                                    </div>			
                                </div>											
                            </div> 

                            <div class="property-inner-box">
                                <div class="comman-title">
                                    <h3>AI-Generative <img src="images/check-icon.svg" alt="" class="mt-1"></h3>
                                </div>
                                <div class="card">
                                    <div class="card-header">
                                        <div class="comman-title mb-0">
                                            <h4>Language</h4>
                                        </div>
                                    </div>
                                    <div class="card-body">
                                        <div class="custom-check-btn">
                                            <!-- <input type="checkbox" class="btn-check" id="btn-check-1" checked autocomplete="off">
                                            <label class="btn" for="btn-check-1">English</label> -->
                                            <div v-for="item in checkboxLanguagelist" :key="item.id">
                                                <input class="btn-check" type="checkbox" 
                                                :id="item.id"
                                                :value="item.id"
                                                v-model="form.AI_generative_language_Ids">
                                                <label class="btn" :for="item.id">
                                                    {{ item.name }}
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="card">
                                    <div class="card-header">
                                        <div class="comman-title mb-0">
                                            <h4>Audience</h4>
                                        </div>
                                    </div>
                                    <div class="card-body">
                                        <div class="custom-check-btn">

                                            <div v-for="item in checkboxTAlist" :key="item.id">
                                                <input class="btn-check" type="checkbox" 
                                                :id="'chk_aud' + item.id"
                                                :value="item.id"
                                                v-model="form.AI_generative_target_audience_Ids">
                                                <label class="btn" :for="'chk_aud' + item.id">
                                                    {{ item.name }}
                                                </label>
                                            </div>

                                            <!-- <input type="checkbox" class="btn-check" id="btn-check-14" checked autocomplete="off">
                                            <label class="btn" for="btn-check-14">Overseas Property Investors (Migrants/Expatriates)</label> -->
                                            
                                            
                                        </div>
                                    </div>
                                </div>
                                <div class="card">
                                    <div class="card-header">
                                        <div class="comman-title mb-0">
                                            <h4>Look & Feel</h4>
                                        </div>
                                    </div>
                                    <div class="card-body">
                                        <div class="custom-check-btn">
                                            <!-- <input type="checkbox" class="btn-check" id="btn-check-20" checked autocomplete="off">
                                            <label class="btn" for="btn-check-20">Luxurious</label> -->

                                            <div v-for="item in checkboxLFlist" :key="item.id">
                                                <input class="btn-check" type="checkbox" 
                                                :id="'chk_look' + item.id"
                                                :value="item.id"
                                                v-model="form.AI_generative_pr_look_and_feel_detail_Ids">
                                                <label class="btn" :for="'chk_look' + item.id">
                                                    {{ item.name }}
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="card">
                                    <div class="card-header">
                                        <div class="comman-title mb-0">
                                            <h4>Platform</h4>
                                        </div>
                                    </div>
                                    <div class="card-body">
                                        <div class="custom-check-btn">
                                            <input type="checkbox" class="btn-check" id="btn-check-26" v-model="form.AI_generative_target_platform_youtube"  autocomplete="off">
                                            <label class="btn" for="btn-check-26">YouTube</label>
                                            
                                            <input type="checkbox" class="btn-check" id="btn-check-27" v-model="form.AI_generative_target_platform_facebook" autocomplete="off">
                                            <label class="btn" for="btn-check-27">FaceBook</label>

                                            <input type="checkbox" class="btn-check" id="btn-check-28" v-model="form.AI_generative_target_platform_tiktok" autocomplete="off">
                                            <label class="btn" for="btn-check-28">TikTok</label>

                                            <input type="checkbox" class="btn-check" id="btn-check-29" v-model="form.AI_generative_target_platform_instagram" autocomplete="off">
                                            <label class="btn" for="btn-check-29">Instagram</label>
                                            
                                            <input type="checkbox" class="btn-check" id="btn-check-30" v-model="form.AI_generative_target_platform_twitter" autocomplete="off">
                                            <label class="btn" for="btn-check-30">Twitter</label>
                                            
                                            <input type="checkbox" class="btn-check" id="btn-check-31" v-model="form.AI_generative_target_platform_linkedin" autocomplete="off">
                                            <label class="btn" for="btn-check-31">LinkedIn</label>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="d-flex justify-content-md-end">
                                <!-- <button class="btn comman-btn-6">Previous</button> -->
                                <router-link :to="`/post-property-listing-2/${itemId}`" class="btn comman-btn-6">Previous</router-link>
                                <button v-if="this.itemId == 0" class="btn comman-btn-2 ms-3" type="submit">Next</button>
                                <button v-else class="btn comman-btn-2 ms-3" type="submit">Save</button>
                            </div>

                            <div class="progress-bar-box">
                                <div>Form Completion: {{ completionPercentage }}%</div>
                            <div class="progress">
                                <div 
                                    class="progress-bar" 
                                    role="progressbar" 
                                    :style="{ width: completionPercentage + '%' }" 
                                    :aria-valuenow="completionPercentage" 
                                    aria-valuemin="0" 
                                    aria-valuemax="100">
                                </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </section>

</template>
  
  <script>
  import { mapState } from 'vuex';
  import RecursiveDropdown from '@/components/RecursiveDropdown.vue';
  import { FUNG_SHUI_CARDINAL } from '@/constants/constantsProperty';
  import toastr from 'toastr';
  import 'toastr/build/toastr.min.css';
  export default {
    name: 'PostPropertyListingStep3',
    components: {
      RecursiveDropdown
    }, 
    data() {
        return {
            checkboxTAlist: [],
            checkboxLanguagelist: [],
            checkboxLFlist: [],
            options: this.transformData(FUNG_SHUI_CARDINAL),
            form: {
                hybrid_listing_type: '',
                FSM_property_completion_occupation_date: 0,
                FSM_property_facing_orientation_building_id: 0,
                FSM_property_facing_orientation_main_entrance_id: 0,
                FSM_property_facing_orientation_main_balcony_window_id: 0,
                FSM_space_sectors_main_door_entrance_sector_id: 0,
                FSM_space_sectors_main_living_hall_hall_sector_id: 0,
                FSM_space_sectors_kitchen_sector_id: 0,
                FSM_space_sectors_main_master_bedroom_sector_id: 0,

                AI_generative_language_Ids: [],
                AI_generative_target_audience_Ids: [],
                AI_generative_pr_look_and_feel_detail_Ids: [],
            },
            errors: {}
        }
    },
    computed: {
        ...mapState(['isAuthenticated', 'user']),
        hierarchicalOptions() {        
            return this.createHierarchicalOptions(this.options);
        },
        itemId() {
            return this.$route.params.id;
        },
        completionPercentage() {
            const fields = [
                this.form.hybrid_listing_type, 
                this.form.display_my_contact_information, 
                this.form.FSM_property_completion_occupation_date, 

                this.form.FSM_property_facing_orientation_building_id, 
                this.form.FSM_property_facing_orientation_main_entrance_id, 
                this.form.FSM_property_facing_orientation_main_balcony_window_id, 
                this.form.FSM_space_sectors_main_door_entrance_sector_id, 
                this.form.FSM_space_sectors_main_living_hall_hall_sector_id, 
                this.form.FSM_space_sectors_kitchen_sector_id, 
                this.form.FSM_space_sectors_main_master_bedroom_sector_id, 

                // AI_generative_content_count: 5, 
                // linkedin_link: 1, 

                // AI_generative_language_Ids: language_Ids,                        
                // AI_generative_target_audience_Ids: audience_Ids,                        
                // AI_generative_pr_look_and_feel_detail_Ids: feel_detail_Ids,                        

                // Group target platform fields into a single entity
                [
                this.form.AI_generative_target_platform_youtube,
                this.form.AI_generative_target_platform_linkedin,
                this.form.AI_generative_target_platform_twitter,
                this.form.AI_generative_target_platform_instagram,
                this.form.AI_generative_target_platform_tiktok,
                this.form.AI_generative_target_platform_facebook,
                ].some(platform => platform), // True if at least one platform is filled
            ];

            // Count fields that are true (considered "filled")
            const filledFields = fields.filter(Boolean).length;

            // Calculate and return the completion percentage
            return Math.round((filledFields / fields.length) * 100);
        }
    },
    created() {
        // toast.success('hhhh', 'Success', { timeOut: 3000 });
        // toast.error('Something went wrong in API')
        this.fetchOptions();
    },
    watch: {
        // Automatically save the completion percentage whenever it changes
        completionPercentage(newPercentage) {
            console.log('Completion percentage updated to:', newPercentage);
            this.saveCompletion();
        },
    },
    methods: {
        savePageCompletion(page, percentage) {
            // Save the page completion percentage to local storage
            const completionData = JSON.parse(localStorage.getItem('formCompletionData')) || {};
            completionData[page] = percentage;
            localStorage.setItem('formCompletionData', JSON.stringify(completionData));
        },

        saveCompletion() {
            // Save the current page's completion percentage
            const percentage = this.completionPercentage;
            this.savePageCompletion('page3', percentage);
        },
        validateForm() {
            this.errors = {};
            if (this.form.hybrid_listing_type.length == 0) {
                 this.errors.hybrid_listing_type = 'Choice Of Either 1 Selection, Not Allowed To Have Multiple Choices.';
             }          
            return Object.keys(this.errors).length === 0;
        }, 
        async submitPost() {
                  
            if (this.validateForm()) {
                //var qualify_for_foreigner = this.form.qualify_for_foreigner == true ? 1 : 0
                const token = localStorage.getItem('userToken');
                if (this.loading) return;
                this.loading = true;
                try {
                    
                    
                    var language_Ids = this.form.AI_generative_language_Ids.join(',');
                    var audience_Ids = this.form.AI_generative_target_audience_Ids.join(',');
                    var feel_detail_Ids = this.form.AI_generative_pr_look_and_feel_detail_Ids.join(',');
                    
                    var platform_facebook = this.form.AI_generative_target_platform_facebook == true ? 1 : 0
                    var platform_linkedin = this.form.AI_generative_target_platform_linkedin == true ? 1 : 0
                    var platform_twitter = this.form.AI_generative_target_platform_twitter == true ? 1 : 0
                    var platform_instagram = this.form.AI_generative_target_platform_instagram == true ? 1 : 0
                    var platform_tiktok = this.form.AI_generative_target_platform_tiktok == true ? 1 : 0
                    var platform_youtube = this.form.AI_generative_target_platform_youtube == true ? 1 : 0

                    const response = await this.$axios.post('api/update-property-hybrid-fsm-AI', {
                        user_id :this.user.id,
                        api_token: token,
                        property_listing_id:this.itemId,
                        
                        hybrid_listing_type: this.form.hybrid_listing_type, 
                        display_my_contact_information: this.form.display_my_contact_information, 
                        FSM_property_completion_occupation_date: this.form.FSM_property_completion_occupation_date, 

                        FSM_property_facing_orientation_building_id: this.form.FSM_property_facing_orientation_building_id, 
                        FSM_property_facing_orientation_main_entrance_id: this.form.FSM_property_facing_orientation_main_entrance_id, 
                        FSM_property_facing_orientation_main_balcony_window_id: this.form.FSM_property_facing_orientation_main_balcony_window_id, 
                        FSM_space_sectors_main_door_entrance_sector_id: this.form.FSM_space_sectors_main_door_entrance_sector_id, 
                        FSM_space_sectors_main_living_hall_hall_sector_id: this.form.FSM_space_sectors_main_living_hall_hall_sector_id, 
                        FSM_space_sectors_kitchen_sector_id: this.form.FSM_space_sectors_kitchen_sector_id, 
                        FSM_space_sectors_main_master_bedroom_sector_id: this.form.FSM_space_sectors_main_master_bedroom_sector_id, 

                        AI_generative_content_count: 5, 
                        linkedin_link: 1, 

                        AI_generative_language_Ids: language_Ids,                        
                        AI_generative_target_audience_Ids: audience_Ids,                        
                        AI_generative_pr_look_and_feel_detail_Ids: feel_detail_Ids,                        

                        AI_generative_target_platform_facebook: platform_facebook,                        
                        AI_generative_target_platform_linkedin: platform_linkedin,                        
                        AI_generative_target_platform_twitter: platform_twitter,                        
                        AI_generative_target_platform_instagram: platform_instagram,                        
                        AI_generative_target_platform_tiktok: platform_tiktok,                        
                        AI_generative_target_platform_youtube: platform_youtube


                       
                    });                    
                    var property_listing_id = response.data.output.id
                    toastr.success(response.data.message, 'Success', { timeOut: 3000 });
                    this.$router.push('/post-property-listing-4/'+property_listing_id);         

                } catch (error) {                    
                    toastr.error('Something went wrong in API')
                    if (error.response && error.response.data) {
                        this.errors = this.extractErrors(error.response.data.errors || {});
                    } else {
                        console.error('An unexpected error occurred:', error);
                    }
                } finally {
                    this.loading = false;
                }
            }
        },   
        extractErrors(errors) {
            // Extract the first error message from each field's errors array
            const formattedErrors = {};
            for (const [field, messages] of Object.entries(errors)) {
                if (Array.isArray(messages) && messages.length > 0) {
                formattedErrors[field] = messages[0]; // Get the first error message
                }
            }
            return formattedErrors;
        },      
        async fetchOptions() {
            

            const token = localStorage.getItem('userToken');
            // Target Audience
            try {
                const response = await this.$axios.post('api/target-audience-list',
                {                    
                    parent_id : "0",
                    keyword : "", 
                    show_in_project: 0,
                    show_in_property:1,
                    last_record_number : 0                     
                });
                this.checkboxTAlist = response.data.output.map(item => ({
                    id: item.id,
                    name: item.name
                }));
                console.log(this.checkboxFFlist);
            } catch (error) {
                console.error('Error fetching options:', error);
            }
            
            // Language list
            try {
                const response = await this.$axios.post('api/language-list',
                {                    
                    parent_id : "0",
                    keyword : "", 
                    show_in_project: 0,
                    show_in_property:1,
                    last_record_number : 0                     
                });
                this.checkboxLanguagelist = response.data.output.map(item => ({
                    id: item.id,
                    name: item.language_ISO_639_2_code == '' || item.language_ISO_639_2_code == null  ? item.name : item.language_ISO_639_2_code
                }));
            } catch (error) {
                console.error('Error fetching options:', error);
            }
            
            // Look and feed list
            try {
                const response = await this.$axios.post('api/pr-look-and-feel-list',
                {                    
                    parent_id : "0",
                    keyword : "", 
                    show_in_project: 0,
                    show_in_property:1,
                    last_record_number : 0                     
                });
                this.checkboxLFlist = response.data.output.map(item => ({
                    id: item.id,
                    name: item.name
                }));
            } catch (error) {
                console.error('Error fetching options:', error);
            }


            // If Edit
            if(this.itemId > 0){
                
                try {
                    const response = await this.$axios.post('api/property-detail',
                    {                    
                        user_id :this.user.id,
                        api_token: token,
                        property_listing_id:this.itemId,                    
                    });
                    this.editData = response.data.output[0];
                    console.log('editData', this.editData);
                    this.form.hybrid_listing_type = this.editData.hybrid_listing_type;
                    this.form.display_my_contact_information = this.editData.display_my_contact_information.toString();
                    
                    this.form.FSM_property_completion_occupation_date = this.editData.FSM_property_completion_occupation_date;
                    this.form.FSM_property_facing_orientation_building_id = this.editData.FSM_property_facing_orientation_building_id?.id;
                    this.form.FSM_property_facing_orientation_main_entrance_id = this.editData.FSM_property_facing_orientation_main_entrance_id?.id;
                    this.form.FSM_property_facing_orientation_main_balcony_window_id = this.editData.FSM_property_facing_orientation_main_balcony_window_id;

                    this.form.FSM_space_sectors_main_door_entrance_sector_id = this.editData.FSM_space_sectors_main_door_entrance_sector_id?.id;
                    this.form.FSM_space_sectors_main_living_hall_hall_sector_id = this.editData.FSM_space_sectors_main_living_hall_hall_sector_id;
                    this.form.FSM_space_sectors_kitchen_sector_id = this.editData.FSM_space_sectors_kitchen_sector_id;
                    this.form.FSM_space_sectors_main_master_bedroom_sector_id = this.editData.FSM_space_sectors_main_master_bedroom_sector_id;

                    if(this.editData.AI_generative_language_Ids){
                        this.form.AI_generative_language_Ids = this.editData.AI_generative_language_Ids.split(','); 
                    }
                    if(this.editData.AI_generative_target_audience_Ids){
                        this.form.AI_generative_target_audience_Ids = this.editData.AI_generative_target_audience_Ids.split(','); 
                    }
                    if(this.editData.AI_generative_pr_look_and_feel_detail_Ids){
                        this.form.AI_generative_pr_look_and_feel_detail_Ids = this.editData.AI_generative_pr_look_and_feel_detail_Ids.split(','); 
                    }

                    this.form.AI_generative_target_platform_youtube = this.editData.AI_generative_target_platform_youtube == 1 ? true : false;
                    this.form.AI_generative_target_platform_linkedin = this.editData.AI_generative_target_platform_linkedin == 1 ? true : false;
                    this.form.AI_generative_target_platform_twitter = this.editData.AI_generative_target_platform_twitter == 1 ? true : false;
                    this.form.AI_generative_target_platform_instagram = this.editData.AI_generative_target_platform_instagram == 1 ? true : false;
                    this.form.AI_generative_target_platform_tiktok = this.editData.AI_generative_target_platform_tiktok == 1 ? true : false;
                    this.form.AI_generative_target_platform_facebook = this.editData.AI_generative_target_platform_facebook == 1 ? true : false;
                    
                    

                } catch (error) {
                    console.error('Error fetching options:', error);
                }
            }
            // If Edit
            
        },
        transformData(data) {
            const map = {};
            const result = [];
    
            data.forEach(item => {
            map[item.id] = { ...item, children: [] };
            });
    
            data.forEach(item => {
            if (item.parent_id === 0) {
                result.push(map[item.id]);
            } else {
                map[item.parent_id].children.push(map[item.id]);
            }
            });
    
            return result;
        },
        createHierarchicalOptions(data) {
            return data.map(option => this.createOptionGroup(option));
        },
        createOptionGroup(option) {
                if (!option.children || option.children.length === 0) {
                    return {
                        type: 'option',
                        id: option.id,
                        label: option.name,
                        depth: option.depth,
                    };
                }
                return {
                    type: 'optgroup',
                    label: option.name,
                    depth: option.depth,
                    children: option.children.map(child => this.createOptionGroup(child))
                };
        },
    }
  }
  </script>
  
  <style scoped>
  /* Add styles specific to the About Us page here */
  </style>
  
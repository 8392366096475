import { CONSTANT } from "@/constants/constants";

export function formatDate(date, includeTime = false) {
    if (date == null || date == undefined) {
        return '-';
    }
    const options = {
        year: 'numeric',
        month: 'short',
        day: '2-digit',
        timeZone: 'Asia/Singapore',
    };
    if (includeTime) {
        options.hour = '2-digit';
        options.minute = '2-digit';
        options.second = '2-digit';
    }

    const formatter = new Intl.DateTimeFormat('en-US', options);
    return formatter.format(new Date(date));
}

export function formatPhoneNumber(phoneNumber) {
    if (!phoneNumber || !phoneNumber.length) {
        return "-";
    }
    const countryCode = phoneNumber.slice(0, 3);
    const maskedSection = "****";
    const lastDigits = phoneNumber.slice(-3);

    return `+${countryCode}${maskedSection}${lastDigits}`;
}

export function formatPrice(price) {
    if (price == null || isNaN(price)) {
        return '0';
    }
    return Number(price).toLocaleString('en-US');
}

export function formatTitleCase(str) {
    return str
        .replace(/_/g, ' ')
        .toLowerCase()
        .replace(/\b\w/g, char => char.toUpperCase());
}

export function yearsAgo(startYear) {
    const currentYear = new Date().getFullYear();
    const yearsDifference = currentYear - startYear;

    if (yearsDifference === 0) {
        return "this year";
    } else if (yearsDifference === 1) {
        return "1 year ago";
    } else {
        return `${yearsDifference} years ago`;
    }
}

export function getUserProfileImage(image) {
    return image
        ? `${CONSTANT.IMAGE_URL}/imagesProfile/${image}`
        : CONSTANT.DEFAULT_IMAGE;
}

export function getPropertyImage(image) {
    return image
        ? `${CONSTANT.IMAGE_URL}/imagesPropertyActualUnit/${image}`
        : CONSTANT.DEFAULT_NO_IMAGE;
}

export function getProjectImage(image) {
    return image
        ? `${CONSTANT.IMAGE_URL}/imagesProjectListing/${image}`
        : CONSTANT.DEFAULT_NO_IMAGE;
}

export function getPropertyOtherDocumentsAndPhoto(image) {
    return image
        ? `${CONSTANT.IMAGE_URL}/imagesPropertyDocumentAndOtherPhotos/${image}`
        : CONSTANT.DEFAULT_NO_IMAGE;
}

export function paginatedPages(currentPage, totalPages, startPage = 1) {
    let endPage = totalPages;

    if (totalPages > 10) {
        if (currentPage <= 6) {
            endPage = 10;
        } else if (currentPage + 4 >= totalPages) {
            startPage = totalPages - 9;
            endPage = totalPages;
        } else {
            startPage = currentPage - 5;
            endPage = currentPage + 4;
        }
    }

    let pages = [];
    for (let i = startPage; i <= endPage; i++) {
        pages.push(i);
    }
    return pages;
}

<template>
    <div><button class="btn comman-btn-2 w-100" data-bs-toggle="modal"
            data-bs-target="#messageModal"><b>Message</b></button></div>

    <div class="modal fade" id="messageModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
        aria-labelledby="messageModalLabel" aria-hidden="true">

        <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
            <div class="modal-content">
                <div class="modal-header">
                    <h1 class="modal-title fs-5" id="messageModalLabel">Send Message</h1>
                </div>
                <div class="modal-body">
                    <label>Message</label>
                    <input style="height:150px" type="text" class="form-control mb-3" v-model="message"
                        placeholder="Enter your message here">
                    <div class="form-check">
                        <input class="form-check-input" type="checkbox" v-model="includeProperty" id="flexCheckDefault">
                        <label class="form-check-label" for="flexCheckDefault">
                            Include Property
                        </label>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
                    <button type="button" class="btn btn-primary" @click="sendMessage">Send</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import toastr from 'toastr';
    import { mapState } from 'vuex';

    export default {
        name: 'SendMessageComponent',
        props: {
            recipient: {
                type: Object,
                required: true,
            },
            property: {
                type: Object,
                required: false,
                default: () => { }
            },
        },
        data() {
            return {
                message: '',
                includeProperty: false
            };
        },
        computed: {
            ...mapState(['isAuthenticated', 'user']),
        },
        methods: {
            async sendMessage() {
                try {
                    if (!this.message) {
                        toastr.error("Message cannot be empty");
                        return;
                    }

                    if (this.includeProperty && !this.property.id) {
                        toastr.error("No property found to be included in message");
                        return;
                    }

                    if (!this.user?.id || !this.recipient?.id) {
                        toastr.error("Invalid user");
                        return;
                    }

                    let params = {
                        api_token: localStorage.getItem('userToken'),
                        content: this.message,
                        include_property: this.includeProperty,
                        property_listing_id: this.property?.id,
                        sender_id: this.user.id,
                        recipient_id: this.recipient.id,
                    }

                    const response = await this.$axios.post(`api/users/${this.user.id}/messages`, params);

                    if (response.status === 200) {
                        // increment property analytic inquiries count
                        await this.$axios.post(`api/properties/${this.property.id}/analytics/inquiries`);

                        toastr.success('Message sent!')
                        this.message = null;
                        this.includeProperty = false;
                    }
                } catch (error) {
                    toastr.error('Failed to send message:', error);
                }
            }
        },
        mounted() {
            if (!this.isAuthenticated) {
                toastr.error("You are required to log in before sending message");
                return;
            }

            if (!this.user) {
                toastr.error("No user found. Please re-login");
                return;
            }

            if (!this.recipient) {
                toastr.error("Invalid recipient user");
                return;
            }

            if (this.recipient?.id === this.user?.id) {
                toastr.error("Invalid recipient user");
                return;
            }
        },
    };
</script>

<style>

</style>
<template>
    <section class="payment-se">
        <div class="container">
            <div class="payment-box">
                <div class="comman-title d-flex">
                    <h3>Current Balance</h3>
                    <span class="ms-auto payment-price">{{ user.available_credit_points }}</span>
                </div>
                <div class="form-group">
                    <label>Enter Preferred Amounts</label>
                    <input type="number" placeholder="Enter Preferred Amounts" class="form-control mb-2"
                        v-model.number="purchaseAmount" @input="handleInputChange" step="1">
                    <small class="pull-right">Min Reload Amount is $50</small>
                </div>
                <div class="btn-group-a">
                    <a href="#" v-for="amount in amounts" :key="amount" :class="{ active: purchaseAmount === amount }"
                        @click.prevent="setAmount(amount)">
                        ${{ amount }}
                    </a>
                </div>
            </div>
            <div class="comman-title">
                <h3>Payment Method</h3>
            </div>
            <div class="payment-box">
                <div class="text-end mb-2">
                    <img src="frontend/images/visa.jpg" alt=""><img src="frontend/images/master-card.png" alt="">
                </div>
                <div id="card-element" class="my-3"></div>
            </div>
            <div class="d-md-flex protect-payment">
                <div class="d-flex align-items-center">
                    <img src="frontend/images/lock.svg" class="me-3">
                    <p class="mb-0">We protect your payment information using<br> encryption to provide bank-level
                        security.</p>
                </div>
                <div class="ms-auto">

                    <a href="#" class="comman-btn-2" @click.prevent="submitTopUp">
                        <b>Confirm Payment</b>
                    </a>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
    import { mapState } from 'vuex';
    import toastr from "toastr";
    import { loadStripe } from '@stripe/stripe-js';

    export default {
        data() {
            return {
                currentBalance: 50,
                purchaseAmount: 50,
                amounts: [50, 100, 250, 350, 500],
                topUp: {},
                topUpPaymentUpdate: {},

                // stripe fields
                stripe: null,
                elements: null,
                card: null,
            };
        },
        computed: {
            ...mapState(['isAuthenticated', 'user']),
        },
        async created() {
            await this.fetchProfileDetails();
        },
        async mounted() {
            if (!window.STRIPE_KEY) {
                toastr.error('Stripe key not found!', 'Error', { timeOut: 3000 });
            }
            this.stripe = await loadStripe(window.STRIPE_KEY);
            this.elements = this.stripe.elements();

            const options = {
                hidePostalCode: true,
                iconStyle: "solid"
            }
            this.card = this.elements.create('card', options);
            this.card.mount('#card-element');
        },
        methods: {
            async fetchProfileDetails() {
                try {
                    const profileResponse = await this.$axios.post('api/my-profile-detail', {
                        user_id: JSON.parse(localStorage.getItem('user')).id,
                        api_token: localStorage.getItem('userToken'),
                    });

                    this.$store.commit('SET_USER', profileResponse.data.output);
                } catch (error) {
                    console.error('Failed to fetch profile details:', error);
                }
            },
            setAmount(amount) {
                this.purchaseAmount = amount;
            },
            handleInputChange() {
                clearTimeout(this.inputTimeout);

                this.inputTimeout = setTimeout(() => {
                    this.validateAmount();
                }, 750);
            },
            validateAmount() {
                if (this.purchaseAmount < 50) {
                    this.purchaseAmount = 50;
                }

                 // Round down to nearest integer if its decimal
                if (!Number.isInteger(this.purchaseAmount)) {
                    this.purchaseAmount = Math.floor(this.purchaseAmount);
                }
            },
            // with stripe
            async submitTopUp() {
                try {
                    const { paymentMethod, error } = await this.stripe.createPaymentMethod({
                        type: 'card',
                        card: this.card,
                    });

                    if (error) {
                        toastr.error(error.message, 'Error', { timeOut: 3000 });
                        return;
                    }
                    toastr.info('Processing top up', 'Info');
                    const response = await this.$axios.post(`api/users/${this.user.id}/credits/top-up`, {
                        api_token: localStorage.getItem('userToken'),
                        amount: this.purchaseAmount,
                        payment_method: paymentMethod.id,
                    });

                    if (response.status === 200) {
                        toastr.success('Credit points top up successfully!', 'Success', { timeOut: 3000 });
                    } else {
                        toastr.error(response.data.error, 'Error', { timeOut: 3000 });
                    }
                } catch (error) {
                    console.error('Error submitting top up:', error);
                } finally {
                    await this.fetchProfileDetails();
                    this.card.clear();
                    this.purchaseAmount = 50;
                }
            },

            /* without stripe
            async topUpCreditPoint() {
                const topUpResponse = await this.$axios.post('api/topup-credit-point', {
                    api_token: localStorage.getItem('userToken'),
                    user_id: this.user.id,
                    purchase_amount: this.purchaseAmount,
                });

                this.topUp = topUpResponse.data.output;
                console.log(this.topUp);
                // TODO: Create payment request
                // await this.topUpCreditPointPaymentRequest();
                await this.topUpCreditPointPaymentUpdate();
                await this.fetchProfileDetails();
            },
            async topUpCreditPointPaymentRequest() {
                try {
                    const response = await this.$axios.post('api/payment/request', {
                        user_id: 1,
                        package_id: 1,
                    });

                    if (response.data && response.data.url) {
                        window.location.href = response.data.url;
                    } else {
                        console.error('Payment URL not found in response:', response);
                    }
                } catch (error) {
                    console.error('Error creating payment request:', error);
                }
            },
            async topUpCreditPointPaymentUpdate() {
                const topUpPaymentUpdateResponse = await this.$axios.post('api/topup-credit-point-payment-update', {
                    api_token: localStorage.getItem('userToken'),
                    user_id: this.user.id,
                    order_id: this.topUp.id, // TODO: Add 900000 to order id to make it different from package orders
                    payment_amount: this.topUp.purchase_amount,
                    // TODO: Replace with actual payment response
                    payment_gateway_transaction_code: 'DummyCode123',
                    payment_status: "Success",
                    payment_gateway_entire_response: "TXN-success",
                });

                this.topUpPaymentUpdate = topUpPaymentUpdateResponse.data.output;

                toastr.success('Credit points updated!', 'Success', { timeOut: 3000 });
            }
            */
        }
    };
</script>
import { createRouter, createWebHistory } from "vue-router";
import MainLayout from "../layouts/MainLayout.vue";
import MostViewed from "@/views/MostViewed.vue";
import AboutUs from "../views/AboutUs.vue";
import ContactUs from "../views/ContactUs.vue";
import FeaturePage from "../views/FeaturePage.vue";
import FAQ from "@/views/FAQ.vue";
import PrivacyPolicy from "@/views/PrivacyPolicy.vue";
import TermCondition from "@/views/TermCondition.vue";
import HomePage from "../views/HomePage.vue";
import SignIn from "../views/SignIn.vue";
import ProfilePage from "../views/ProfilePage.vue";
import store from "../store";
import SignUp from "@/views/SignUp.vue";
import SignUpSocial from "@/views/SignUpSocial.vue";
import SignUpBusiness from "@/views/SignUpBusiness.vue";
import SignUpCorporate from "@/views/SignUpCorporate.vue";
import VerifyNumber from "@/views/VerifyNumber.vue";
import VerifyEmail from "@/views/VerifyEmail.vue";
import VerifiedEmail from '@/views/VerifiedEmail.vue';
import PostProperyListing from "@/views/listing/PostProperyListing.vue";
import PostProperyListingStep2 from "@/views/listing/PostProperyListingStep2.vue";
import PostProperyListingStep3 from "@/views/listing/PostProperyListingStep3.vue";
import PostProperyListingStep4 from "@/views/listing/PostProperyListingStep4.vue";
import PostProperyListingStep5 from "@/views/listing/PostProperyListingStep5.vue";
import MyProperty from "@/views/listing/MyProperty.vue";
import PropertyDetail from "@/views/listing/PropertyDetail.vue";
import ExploreMore from "@/views/property/ExploreMore.vue";
import PostProjectListing from "@/views/project/PostProjectListing.vue";
import PostProjectListingStep2 from "@/views/project/PostProjectListingStep2.vue";
import PostProjectListingStep4 from "@/views/project/PostProjectListingStep4.vue";
import PostProjectListingStep3 from "@/views/project/PostProjectListingStep3.vue";
import PostProjectListingStep5 from "@/views/project/PostProjectListingStep5.vue";
import MyProjects from "@/views/project/MyProjects.vue";
import ProjectDetail from "@/views/project/ProjectDetail.vue";

import PackagePage from "@/views/PackagePage";
import PaymentPage from "@/views/PaymentPage";
import PaymentSuccessPage from "@/views/PaymentSuccessPage";
import TopUpPage from "@/views/TopUpPage.vue";
import CreditsHistoryPage from "@/views/CreditsHistoryPage.vue";
import RewardPointsPage from "@/views/RewardPointsPage.vue";
import MessagesPage from "@/views/MessagesPage.vue";

import toastr from "toastr";
import AgentPage from "@/views/AgentPage.vue";
import CallBackPage from "@/views/CallBackPage.vue";
import AgentDetailPage from "@/views/AgentDetailPage.vue";
import Widget1Page from "@/views/widget/Widget1Page.vue";
import Widget2Page from "@/views/widget/Widget2Page.vue";
import Widget3Page from "@/views/widget/Widget3Page.vue";
import Widget0Page from "@/views/widget/Widget0Page.vue";
import WishlistPage from "@/views/wishlist/WishlistPage.vue";
import RequestedServicePage from "@/views/requested_service/RequestedServicePage.vue";
import ExploreMoreProject from "@/views/property/ExploreMoreProject.vue";
import ForgetPassword from "@/views/ForgetPassword.vue";
import ResetPassword from "@/views/ResetPassword.vue";
import VerifyServiceDetailPage from "@/views/VerifyServiceDetailPage.vue";
import PropertyComparison from "@/views/tool/PropertyComparison.vue";
import PropertyHistory from "@/views/listing/PropertyHistory.vue";
import PropertyDataAnalytic from "@/views/tool/PropertyDataAnalytic.vue";
import ProjectDataAnalytic from "@/views/tool/ProjectDataAnalytic.vue";

const routes = [
    {
        mode: "history",
        path: "/",
        component: MainLayout,
        children: [
            {
                path: "",
                name: "HomePage",
                component: HomePage,
            },
            {
                path: "explore-more",
                name: "ExploreMore",
                component: ExploreMore,
            },
            {
                path: "callback-page",
                name: "CallBackPage",
                component: CallBackPage,
            },
            {
                path: "most-viewed",
                name: "MostViewed",
                component: MostViewed,
            },
            {
                path: "faq",
                name: "FAQ",
                component: FAQ,
            },
            {
                path: "terms-and-conditions",
                name: "TermCondition",
                component: TermCondition,
            },
            {
                path: "privacy-policy",
                name: "PrivacyPolicy",
                component: PrivacyPolicy,
            },
            {
                path: "sign-up",
                name: "SignUp",
                component: SignUp,
                meta: { guest: true },
            },
            {
                path: "sign-up-social",
                name: "SignUpSocial",
                component: SignUpSocial,
                meta: { guest: true },
            },
            {
                path: "sign-up-business",
                name: "SignUpBusiness",
                component: SignUpBusiness,
                meta: { guest: true },
            },
            {
                path: "sign-up-corporate",
                name: "SignUpCorporate",
                component: SignUpCorporate,
                meta: { guest: true },
            },
            {
                path: "sign-in",
                name: "SignIn",
                component: SignIn,
                meta: { guest: true },
            },
            {
                path: "verify-number",
                name: "verifyNumber",
                component: VerifyNumber,
                meta: { requiresAuth: true },
                beforeEnter: (to, from, next) => {
                    if (store.state.user.is_mobile_verified) {
                        toastr.success("Your number is already verified!");
                        next({ name: "ProfilePage" });
                        return true;
                    }
                    next();
                },
            },
            {
                path: "verify-email",
                name: "verifyEmail",
                component: VerifyEmail,
                meta: { requiresAuth: true },
            },
            {
                path: '/verify-email/:token',
                name: 'verifiedEmail',
                component: VerifiedEmail,
                props: true, // Pass the token as a prop to the VerifyEmail component
            },
            {
                path: "post-property-listing/:id?",
                name: "postPropertyListing",
                component: PostProperyListing,
                meta: { requiresAuth: true },
            },
            {
                path: "/post-property-listing-2/:id",
                name: "postPropertyListingStep2",
                component: PostProperyListingStep2,
                props: true,
                meta: { requiresAuth: true },
            },
            {
                path: "post-property-listing-3/:id",
                name: "postPropertyListingStep3",
                component: PostProperyListingStep3,
                meta: { requiresAuth: true },
            },
            {
                path: "post-property-listing-4/:id",
                name: "postPropertyListingStep4",
                component: PostProperyListingStep4,
                meta: { requiresAuth: true },
            },
            {
                path: "post-property-listing-5/:id",
                name: "postPropertyListingStep5",
                component: PostProperyListingStep5,
                meta: { requiresAuth: true },
            },
            {
                path: "property-detail/:id",
                name: "propertyDetail",
                component: PropertyDetail,
            },
            {
                path: "widget-property-post/:id/:id_type",
                name: "Widget0Page",
                component: Widget0Page,
                meta: { hideHeaderFooter: true },
            },
            {
                path: "widget-property-post-1/:id/:id_type",
                name: "Widget1Page",
                component: Widget1Page,
                meta: { hideHeaderFooter: true },
            },
            {
                path: "widget-property-post-2/:id/:id_type/:post_listing_id",
                name: "Widget2Page",
                component: Widget2Page,
                meta: { hideHeaderFooter: true },
            },
            {
                path: "widget-property-post-3/:id/:id_type/:post_listing_id",
                name: "Widget3Page",
                component: Widget3Page,
                meta: { hideHeaderFooter: true },
            },
            {
                path: "project-detail/:id",
                name: "projectDetail",
                component: ProjectDetail,
            },
            {
                path: "my-property",
                name: "MyProperty",
                component: MyProperty,
                meta: { requiresAuth: true },
            },
            {
                path: "property-history",
                name: "PropertyHistory",
                component: PropertyHistory,
            },
            {
                path: "my-project",
                name: "MyProject",
                component: MyProjects,
                meta: { requiresAuth: true },
            },
            {
                path: "post-project-listing/:id?",
                name: "postProjectListing",
                component: PostProjectListing,
                meta: { requiresAuth: true, canCreateProject: true },
            },
            {
                path: "/post-project-listing-2/:id",
                name: "postProjectListingStep2",
                component: PostProjectListingStep2,
                props: true,
                meta: { requiresAuth: true, canCreateProject: true },
            },
            {
                path: "/post-project-listing-3/:id",
                name: "postProjectListingStep3",
                component: PostProjectListingStep3,
                props: true,
                meta: { requiresAuth: true, canCreateProject: true },
            },
            {
                path: "/post-project-listing-4/:id",
                name: "postProjectListingStep4",
                component: PostProjectListingStep4,
                props: true,
                meta: { requiresAuth: true, canCreateProject: true },
            },
            {
                path: "/post-project-listing-5/:id",
                name: "postProjectListingStep5",
                component: PostProjectListingStep5,
                props: true,
                meta: { requiresAuth: true, canCreateProject: true },
            },
            {
                path: "about-us",
                name: "AboutUs",
                component: AboutUs,
            },
            {
                path: "contact-us",
                name: "ContactUs",
                component: ContactUs,
            },
            {
                path: "feature",
                name: "FeaturePage",
                component: FeaturePage,
            },
            {
                path: "profile",
                name: "ProfilePage",
                component: ProfilePage,
                meta: { requiresAuth: true },
            },
            {
                path: "packages",
                name: "Packages",
                component: PackagePage,
                meta: { requiresAuth: true },
            },
            {
                path: "payments",
                name: "Payments",
                component: PaymentPage,
                meta: { requiresAuth: true },
                props: (route) => ({ packageId: route.params.packageId }),
            },
            {
                path: "payments/success",
                name: "PaymentSuccess",
                component: PaymentSuccessPage,
                meta: { requiresAuth: true },
            },
            {
                path: "top-up",
                name: "TopUp",
                component: TopUpPage,
                meta: { requiresAuth: true },
            },
            {
                path: "credits-history",
                name: "CreditsHistory",
                component: CreditsHistoryPage,
                meta: { requiresAuth: true },
            },
            {
                path: "reward-points",
                name: "RewardPoints",
                component: RewardPointsPage,
                meta: { requiresAuth: true },
            },
            {
                path: "agents",
                name: "Agents",
                component: AgentPage,
            },
            {
                path: "agents/:id",
                name: "AgentDetail",
                component: AgentDetailPage,
            },
            {
                path: "wishlist",
                name: "WishlistPage",
                component: WishlistPage,
                meta: { requiresAuth: true },
            },
            {
                path: "messages",
                name: "MessagesPage",
                component: MessagesPage,
                meta: { requiresAuth: true },
            },
            {
                path: "requested-service",
                name: "RequestedServicePage",
                component: RequestedServicePage,
            },
            {
                path: "explore-more-project",
                name: "ExploreMoreProject",
                component: ExploreMoreProject,
            },
            {
                path: "forget-password",
                name: "ForgetPassword",
                component: ForgetPassword,
            },
            {
                path: "reset-password/:token/:email",
                name: "ResetPassword",
                component: ResetPassword,
            },
            {
                path: "verify-service-detail-page",
                name: "VerifyServiceDetailPage",
                component: VerifyServiceDetailPage,
            },
            {
                path: "property-comparison-tool/:id",
                name: "PropertyComparison",
                component: PropertyComparison,
            },
            {
                path: "property-data-analytic",
                name: "PropertyDataAnalytic",
                component: PropertyDataAnalytic,
            },
            {
                path: "project-data-analytic",
                name: "ProjectDataAnalytic",
                component: ProjectDataAnalytic,
            },
        ],
    },
];

const router = createRouter({
    history: createWebHistory(), // Base URL is automatically handled by Vite
    routes,
});

// Route guard to check if the user is authenticated before accessing protected routes

router.beforeEach((to, from, next) => {
    const isAuthenticated = store.state.isAuthenticated;
    const isUser = store.state.user;

    var canCreateProject = false;
    if (isUser.is_business) {
        canCreateProject = isUser?.package?.title == 'Gold' || isUser?.package?.title == 'Platinum';
    } else if (isUser.is_corporate) {
        canCreateProject = true;
    }

    if (to.matched.some((record) => record.meta.guest) && isAuthenticated) {
        next("/profile"); // Redirect to profile or another appropriate page
    } else if (
        to.matched.some((record) => record.meta.requiresAuth) &&
        !isAuthenticated
    ) {
        next("/sign-in"); // Redirect to sign-in if not authenticated
    } else if (
        to.matched.some((record) => record.meta.canCreateProject) &&
        !canCreateProject
    ) {
        next("/");
    } else {
        // redirect to home if invalid url
        if (to.matched.length === 0) {
            next("/");
        }
        next(); // Allow navigation
    }
});

export default router;

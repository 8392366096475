<template>    
    
    <section class="property-se">
        <div class="container">
            <div class="property-title-bar">
                <div class="comman-title mb-0">
                    <h3 v-if="this.itemId>0"><a href="#"><i class="fa fa-angle-left"></i></a> Edit Property Listing</h3>
                    <h3 v-if="this.itemId==0"><a href="#"><i class="fa fa-angle-left"></i></a> Create Property Listing</h3>
                </div>
                <div class="progress-bar-box">
                    <div>5/5</div>
                    <div class="progress">
                        <div class="progress-bar" role="progressbar" style="width: 20%" aria-valuenow="15" aria-valuemin="0" aria-valuemax="100"></div>							 
                        <div class="progress-bar" role="progressbar" style="width: 20%" aria-valuenow="15" aria-valuemin="0" aria-valuemax="100"></div>							
                        <div class="progress-bar" role="progressbar" style="width: 20%" aria-valuenow="15" aria-valuemin="0" aria-valuemax="100"></div>							
                        <div class="progress-bar" role="progressbar" style="width: 20%" aria-valuenow="15" aria-valuemin="0" aria-valuemax="100"></div>							
                        <div class="progress-bar" role="progressbar" style="width: 20%" aria-valuenow="15" aria-valuemin="0" aria-valuemax="100"></div>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-md-2">
                    <div class="property-sidebar">
                        <ul>
                            <li><router-link :to="`/post-property-listing/${itemId}`">Step 1</router-link></li>
                            <li><router-link :to="`/post-property-listing-2/${itemId}`">Step 2</router-link></li>
                            <li><router-link :to="`/post-property-listing-3/${itemId}`">Step 3</router-link></li>
                            <li><router-link :to="`/post-property-listing-4/${itemId}`">Step 4</router-link></li>	 
                            <li><a class="active" href="javascript:;">Step 5</a></li>                            
                        </ul>
                    </div>
                </div>
                <div class="col-md-10">
                    <div class="property-box mb-4">							 
                        <div class="property-inner-box mb-0">
                            <div class="row ">
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <router-link :to="`/property-detail/${itemId}`"  class="comman-btn-2" target="_blank" ><img src="frontend/images/eye.svg" alt="view-icon">Preview</router-link>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <a href="javascript:;" @click="save(1)" class="comman-btn-2">Save & Publish</a>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <a href="javascript:;" @click="save(0)" class="comman-btn-1">Save As Draft</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div>
                            <h4 style="color: blue; font-weight: bold;">Overall Completion Status: {{ overallCompletionValue }}%</h4>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

</template>
  
  <script>
  import { mapState } from 'vuex';
  import toastr from 'toastr';
  import 'toastr/build/toastr.min.css';
  
  export default {
    name: 'PostPropertyListingStep5', 
    data() {
        return {     
            overallCompletionValue: 0, 
        }
    },
    computed: {
        ...mapState(['isAuthenticated', 'user']),
        itemId() {
            return this.$route.params.id;
        },
    },
    mounted() {
        this.overallCompletion();
    },
    methods: {
        overallCompletion() {
            const completionData = JSON.parse(localStorage.getItem('formCompletionData')) || {};
            const percentages = Object.values(completionData);

            const totalPercentage = percentages.reduce((sum, current) => sum + current, 0);

            const overallCompletionValue = Math.round(totalPercentage / 4); // Calculate the overall completion value

            console.log(completionData);
            console.log(percentages);
            console.log(totalPercentage);
            console.log(overallCompletionValue);

            // Now set the overallCompletionValue to the data property
            this.overallCompletionValue = overallCompletionValue;

            // Optionally, send the completion data to the backend
            this.sendCompletionData(overallCompletionValue);

            return overallCompletionValue; // Return the calculated value (though now not necessary)
        },

        async sendCompletionData(completionValue) {
            const token = localStorage.getItem('userToken');
            try {
                const response = await this.$axios.post('api/update-property-document-completion', {
                    user_id :this.user.id,
                    api_token: token,
                    property_listing_id:this.itemId,
                    completion: completionValue
                });                    
                toastr.success(response.data.message, 'Success', { timeOut: 3000 });
            } catch (error) {  
                if (error.response && error.response.data) {
                    this.errors = this.extractErrors(error.response.data.errors || {});
                } else {
                    console.error('An unexpected error occurred:', error);
                }
            } finally {
                this.loading = false;
            }
        },

        resetCompletionData() {
            const resetData = {
                page1: 0,
                page2: 0,
                page3: 0,
                page4: 0
            };

            // Save the reset data to localStorage
            localStorage.setItem('projectFormCompletionData', JSON.stringify(resetData));

            console.log('Completion data has been reset:', resetData);
            this.$router.push('/my-property'); 
        },
        async save(params) {
            const token = localStorage.getItem('userToken');
            try {
                const response = await this.$axios.post('api/update-property-publish-status', {
                    user_id :this.user.id,
                    api_token: token,
                    property_listing_id:this.itemId,
                    publish_status:params
                });                    
                toastr.success(response.data.message, 'Success', { timeOut: 3000 });
                // this.$router.push('/my-property');  
                
                // Call the resetCompletionData method after a successful save
                this.resetCompletionData();       

            } catch (error) {
                
                if (error.response && error.response.data) {
                    this.errors = this.extractErrors(error.response.data.errors || {});
                } else {
                    console.error('An unexpected error occurred:', error);
                }
            } finally {
                this.loading = false;
            }

        },
        extractErrors(errors) {
            // Extract the first error message from each field's errors array
            const formattedErrors = {};
            for (const [field, messages] of Object.entries(errors)) {
                if (Array.isArray(messages) && messages.length > 0) {
                formattedErrors[field] = messages[0]; // Get the first error message
                }
            }
            return formattedErrors;
        }, 
     
    }
  }
  </script>
  
  <style scoped>
  /* Add styles specific to the About Us page here */
  </style>
  
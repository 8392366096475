<template>
  <div></div>
</template>


<script>
export default {
  data() {
    return {
      email: '',
      password: '',
    };
  },

  async mounted() {
    // Extract email and password from URL
    const urlParams = new URLSearchParams(window.location.search);
    this.email = urlParams.get('email');
    this.password = urlParams.get('password');

    // Validate email and password before proceeding
    if (!this.email || !this.password) {
      console.error('Email or Password is missing from the URL');
      this.loading = false; // Stop loading if parameters are missing
      return;
    }

    try {
      // Send encrypted password to backend for decryption
      const response = await this.$axios.post('/api/decrypt-password', {
        password: this.password,
      });

      if (response.status !== 200) {
        throw new Error('Decryption failed');
      }

      const data = response.data; // Assuming the backend returns { status: 'success', password: 'decrypted_password' }
      console.log('Decrypted Password:', data.password); // For debugging only
      this.password = data.password; // Update the password with the decrypted version

      // Proceed to login
      const loginResponse = await this.$axios.post('/api/login', {
        email: this.email,
        password: this.password,
      });

      console.log('Login successful:', loginResponse.data);

      // Dispatch Vuex action for storing user data if needed
      await this.$store.dispatch('login', loginResponse.data);

      // Redirect to the homepage or desired route
      this.$router.push('/');
    } catch (error) {
      console.error('Error during decryption or login:', error.response?.data || error.message);
    } finally {
      // Stop loading regardless of success or failure
      this.loading = false;
    }
  }
};
</script>
<template>
    <div class="container my-4">
        <div class="top-tabs-design mb-0">
            <ul>
                <li><a href="/profile">My Profile</a>
                </li>
                <li v-if="!user.is_private_user">
                    <a href="/verify-service-detail-page">Verify Service Details</a>
                </li>
                <li><a href="/property-data-analytic">Analytics and Report</a></li>
                <li><a href="/wishlist">My Wishlist</a></li>
                <li><a href="/requested-service">Requested Service</a></li>
                <li><a href="javascript:;" class="active">Messages</a></li>
            </ul>
        </div>
    </div>
    <div class="mb-3 flex align-items-center text-center">
        <span>
            {{ counter > 0 ? `Refetching messages in ${counter} seconds` : 'Counter will start soon' }}
        </span>
        <a type="button" class="mx-3" @click="pauseCounter">
            <i v-if="!pause" class="fa fa-pause"></i>
            <i v-else-if="pause" class="fa fa-play"></i>
        </a>
    </div>
    <vue-advanced-chat id="chatBubbles" :current-user-id="user.id" :load-first-room="false" :rooms="JSON.stringify(rooms)"
        :rooms-loaded="roomsLoading" :messages="JSON.stringify(messages)" :messages-loaded="messagesLoaded"
        :message-actions="JSON.stringify(messageActions)" @fetch-messages="fetchMessages" @send-message="sendMessage"
        :show-emojis="false" :show-audio="false" :show-files="false" :show-add-room="false">
        <div v-for="message in messages" :key="message._id" :slot="'message_' + message._id" class="vac-message-wrapper">
            <div class="vac-message-box my-1" v-if="message.property">
                <div class="vac-message-container vac-message-container-offset">
                    <div class="vac-message-card" :class="{'vac-message-current': message.senderId == user.id}">
                        <div class="vac-format-message-wrapper">
                            <div class="markdown">
                                <p>Property: {{ message.property.property_name }}</p>
                                <p>{{ message.property.name }}</p>
                                <router-link target='_blank' :to="`/property-detail/${message.property.id}`">View
                                    Property</router-link>
                            </div>
                        </div>
                        <div class="vac-text-timestamp"><span>{{ message.timestamp }}</span></div>
                        <div class="vac-message-actions-wrapper">
                            <div class="vac-options-container" style="display: initial; width: 45px;"><span></span></div>
                        </div>
                    </div><span></span>
                </div>
            </div>
            <div class="vac-message-box my-1">
                <div class="vac-message-container vac-message-container-offset">
                    <div class="vac-message-card" :class="{'vac-message-current': message.senderId == user.id}">
                        <div class="vac-format-message-wrapper">
                            <div class="markdown">
                                <p class="mb-1">{{ message.content }}</p>
                            </div>
                        </div>
                        <div class="vac-text-timestamp"><span>{{ message.timestamp }}</span></div>
                        <div class="vac-message-actions-wrapper">
                            <div class="vac-options-container" style="display: initial; width: 45px;"><span></span></div>
                        </div>
                    </div><span></span>
                </div>
            </div>
        </div>
    </vue-advanced-chat>
</template>

<script>
    import { mapState } from 'vuex';
    import toastr from 'toastr';
    import { register } from 'vue-advanced-chat'
    export default {
        data() {
            return {
                rooms: [],
                roomsLoading: false,
                messages: [],
                messagesLoaded: false,
                messageActions: [],

                // for auto pull
                currentChatUser: null,
                timeoutSeconds: 10,
                counter: 0,
                interval: null,
                pause: false
            };
        },
        computed: {
            ...mapState(['user']),
        },
        mounted() {
            register();
            this.getMessagesList();
        },
        watch: {
            messagesLoaded: {
                handler(newValue) {
                    // auto fetch messages when user is in a chatroom
                    if (newValue === true && !this.pause && this.currentChatUser !== null) {
                        this.countdown();
                    }
                },
                immediate: true,
            }
        },
        methods: {
            pauseCounter() {
                this.pause = !this.pause;
                if (this.pause) {
                    clearInterval(this.interval);
                    this.interval = null;
                } else {
                    this.countdown();
                }
            },
            countdown() {
                if (this.interval) {
                    return;
                }

                this.interval = setInterval(() => {
                    if (this.pause) {
                        clearInterval(this.interval);
                        this.interval = null;
                        return;
                    }

                    if (this.counter > 0) {
                        this.counter--;
                    } else {
                        clearInterval(this.interval); // Stop when counter reaches 0
                        this.interval = null;
                        this.fetchMessages(null, this.currentChatUser); // Fetch messages
                    }
                }, 1000);
            },
            formatTime(datetime) {
                let date = new Date(datetime);
                return date.toLocaleTimeString("en-SG", {
                    hour: "2-digit",
                    minute: "2-digit",
                    second: "2-digit",
                    timeZone: "Asia/Singapore"
                });
            },
            async sendMessage(event) {
                let newMessage = event.detail[0].content;
                let chatUser = event.detail[0].roomId;
                this.currentChatUser = chatUser;

                try {
                    let params = {
                        api_token: localStorage.getItem('userToken'),
                        content: newMessage,
                        sender_id: this.user.id,
                        recipient_id: chatUser
                    }
                    await this.$axios.post(`api/users/${this.user.id}/messages`, params);
                } catch (error) {
                    toastr.error('Failed to send message', 'Error', { timeOut: 3000 });
                } finally {
                    this.fetchMessages(null, chatUser);
                }
            },
            async fetchMessages(event = null, paramChatUserId = null) {
                const token = localStorage.getItem('userToken');
                try {
                    this.messagesLoaded = false;
                    this.messages = [];
                    let chatUserId = paramChatUserId ? paramChatUserId : event.detail[0].room.roomId
                    this.currentChatUser = chatUserId;

                    const response = await this.$axios.get(`/api/users/${this.user.id}/messages/${chatUserId}`,
                        {
                            params: {
                                api_token: token
                            }
                        });

                    if (response.data.output) {
                        this.messages = response.data.output.map(message => ({
                            _id: message.id,
                            content: message.content,
                            senderId: '' + message.sender_id + '',
                            username: message.sender.name,
                            avatar: message.sender.profile_photo_name,
                            date: new Date(message.created_at).toJSON().slice(0, 10).replace(/-/g, '-'),
                            timestamp: this.formatTime(message.created_at),
                            seen: message.read_status,
                            system: false,
                            disableActions: true,
                            disableReactions: true,
                            property: message?.property_listing,
                        }))
                    } else {
                        toastr.error('Failed to fetch messages', 'Error', { timeOut: 3000 });
                    }
                } catch (error) {
                    toastr.error('Failed to fetch messages', 'Error', { timeOut: 3000 });
                } finally {
                    this.messagesLoaded = true;
                    this.counter = this.timeoutSeconds;
                }
            },
            async getMessagesList() {
                const token = localStorage.getItem('userToken');
                try {
                    this.roomsLoading = true;

                    const response = await this.$axios.get(`/api/users/${this.user.id}/messages`,
                        {
                            params: {
                                api_token: token
                            }
                        });

                    if (response.data.output?.messages) {
                        const rooms = []
                        let chatRooms = response.data.output.messages;
                        for (let i = 0; i < chatRooms.length; i++) {
                            let chatRoom = chatRooms[i];
                            let lastMessageObject = chatRoom['last_message'] || {};
                            let participants = [];

                            chatRoom['users'].forEach(chatRoomUser => {
                                participants.push({
                                    _id: chatRoomUser['id'],
                                    username: chatRoomUser['name'],
                                    avatar: chatRoomUser['profile_photo_name'],
                                })
                            });

                            rooms.push({
                                roomId: chatRoom['user_id'],
                                roomName: chatRoom['user_name'],
                                avatar: chatRoom['user_avatar'],
                                unreadCount: chatRoom['unread_count'],
                                lastMessage: {
                                    _id: lastMessageObject.id,
                                    content: lastMessageObject.content,
                                    senderId: lastMessageObject.sender_id,
                                    username: lastMessageObject.sender.name,
                                    timestamp: new Date(lastMessageObject.created_at).toJSON().slice(0, 10).replace(/-/g, '-') + ' ' + this.formatTime(lastMessageObject.created_at),
                                },
                                users: participants
                            })
                        }
                        this.rooms = rooms
                    } else {
                        toastr.error('Failed to fetch messages', 'Error', { timeOut: 3000 });
                    }
                } catch (error) {
                    toastr.error('Failed to fetch messages', 'Error', { timeOut: 3000 });
                } finally {
                    this.roomsLoading = false;
                }
            }
        }
    };
</script>
<template>
    <section class="profile-se">
        <div class="container">
            <div class="top-tabs-design">
                <ul>
                    <li><a href="javascript:;" class="active">My Profile  
                            <span v-if="userProfile.is_user_verified">
                                <img src="/frontend/images/verified_logo.png" alt="Verified" style="width: 16px; height: 16px; margin-left: 5px; margin-top: 5px;">
                            </span>
                        </a>
                    </li>
                    <li v-if="!user.is_private_user">
                        <a href="/verify-service-detail-page">Verify Service Details</a>
                    </li>
                    <li><a href="/property-data-analytic">Analytics and Report</a></li>
                    <li><a href="/wishlist">My Wishlist</a></li>
                    <li><a href="/requested-service">Requested Service</a></li>
                    <li><a href="/messages">Messages</a></li>
                </ul>
            </div>
            <div class="row">
                <div class="col-md-9">
                    <LoaderCust :isLoading="isLoading" />
                    <form @submit.prevent="updateProfileBasic">
                        <div class="profile-card-edit">
                            <div class="row">
                                <div class="comman-title col-6">
                                    <h3>Profile Picture</h3>
                                </div>
                                <label class="col-6 d-flex align-content-end justify-content-end">
                                    Verification Status:
                                    <span v-if="isVerified">
                                        <img src="/frontend/images/verified_logo.png" alt="Verified" style="width: 16px; height: 16px; margin-left: 5px; margin-top: 3px;">
                                        <span style="font-size: 10px; vertical-align: middle;"> Verified</span>
                                    </span>
                                        <span v-else>
                                        <img src="/frontend/images/unverified_logo.png" alt="Unverified" style="width: 16px; height: 16px; margin-left: 5px; margin-top: 3px;">
                                        <span style="font-size: 10px; vertical-align: middle; color: red;"> Unverified</span>
                                    </span>
                                </label>
                            </div>
                            <div class="profile-card-edit-box">
                                <figure v-if="imageUrl">
                                    <img :src="computedImageSrc" @error="handleImageError">
                                </figure>

                                <div class="profile-user-content w-100">
                                    <div class="form-group" @input="handleInputChangeUsername"
                                        v-if="((userProfile.is_corporate || userProfile.is_business) && userProfile.is_active )">
                                        <label>User Name</label>
                                        <input type="text" placeholder="Username" class="form-control"
                                            v-model="userProfile.user_name_url">
                                    </div>
                                    <div class="d-flex">
                                        <input type="file" ref="fileInput" @change="onFileChange"
                                            style="display: none;" />
                                        <a href="javascript:;" @click.prevent="triggerFileInput"
                                            class="comman-btn-2"><b>Upload Profile
                                                Picture</b></a>
                                        <a href="javascript:;" @click.prevent="removeProfilePhoto"
                                            class="comman-btn-4 ms-3"><b>Remove
                                                Picture</b></a>
                                    </div>
                                    <!-- <div class="d-flex">{{referal_current_Url}}</div> -->

                                </div>
                            </div>
                        </div>
                        <div class="profile-card-edit">
                            <div class="comman-title">
                                <h3>Your Referral Code</h3>
                            </div>
                            <div class="row">
                                <div class="form-group col">
                                    <label>Share your referral code with new users to earn rewards!</label>
                                    <!-- Display referral code -->
                                    <input type="text" placeholder="Referral Code" class="form-control"
                                        :value="referralCode" readonly>
                                </div>
                            </div>
                        </div>
                        <div class="profile-card-edit">
                            <div class="comman-title">
                                <h3>Your Profile</h3>
                            </div>
                            <div class="row">
                                <div class="form-group col-md-4">
                                    <label>Display Name </label>
                                    <input type="text" placeholder="Display Name" class="form-control"
                                        v-model="form.name">
                                </div>
                                <div class="form-group col-md-4">
                                    <label>First Name </label>
                                    <input type="text" placeholder="First Name" class="form-control"
                                        v-model="form.first_name">
                                </div>
                                <div class="form-group col-md-4">
                                    <label>Last Name</label>
                                    <input type="text" placeholder="Last Name" class="form-control"
                                        v-model="form.last_name">
                                </div>
                            </div>
                            <div class="row">
                                <div class="form-group col-md-4">
                                    <label>Birth Date</label>
                                    <input type="date" placeholder="Last Name" class="form-control"
                                        v-model="form.date_of_birth">
                                </div>
                                <div class="form-group col-md-4">
                                    <label>Gender</label>
                                    <select class="form-control" v-model="form.gender">
                                        <option value="" disabled>Gender</option>
                                        <option value="Male">Male</option>
                                        <option value="Female">Female</option>
                                        <option value="Other">Other</option>
                                    </select>
                                </div>
                            </div>
                            <div class="row">
                                <div class="form-group col-md-4">
                                    <label for="currentPassword">Current Password</label>
                                    <input class="form-control"
                                        type="password"
                                        id="currentPassword"
                                        v-model="form.currentPassword"
                                    />
                                    <span v-if="passwordError" class="error">{{ passwordError }}</span>
                                </div>
                                <div v-if="form.currentPassword" class="form-group col-md-4">
                                    <label for="newPassword">New Password</label>
                                    <input class="form-control"
                                        type="password"
                                        id="newPassword"
                                        v-model="form.newPassword"
                                    />
                                </div>
                                <div v-if="form.currentPassword" class="form-group col-md-4">
                                    <label for="confirmPassword">Confirm New Password</label>
                                    <input class="form-control"
                                        type="password"
                                        id="confirmPassword"
                                        v-model="form.confirmPassword"
                                    />
                                </div>
                            </div>
                            <div class="row">
                                <div class="form-group col-md-4">
                                <label>
                                    Phone Number 
                                    <span v-if="userProfile.is_mobile_verified">
                                        <img src="/frontend/images/verified_logo.png" alt="Verified" style="width: 16px; height: 16px; margin-left: 5px; margin-top: 3px;">
                                        <span style="font-size: 10px; vertical-align: middle;"> Verified</span>
                                    </span>
                                    <span v-else>
                                        <img src="/frontend/images/unverified_logo.png" alt="Unverified" style="width: 16px; height: 16px; margin-left: 5px; margin-top: 3px;">
                                        <span style="font-size: 10px; vertical-align: middle; color: red;"> Unverified</span>
                                    </span>
                                </label>
                                <input type="text" placeholder="Mobile Number" class="form-control" v-model="form.mobile_number">
                                </div>
                                <div class="form-group col-md-4">
                                    <label>
                                        Personal Email 
                                        <span v-if="userProfile.is_email_verified">
                                            <img src="/frontend/images/verified_logo.png" alt="Verified" style="width: 16px; height: 16px; margin-left: 5px; margin-top: 3px;"> 
                                            <span style="font-size: 10px; vertical-align: middle;"> Verified</span>
                                        </span>
                                        <span v-else>
                                            <img src="/frontend/images/unverified_logo.png" alt="Unverified" style="width: 16px; height: 16px; margin-left: 5px; margin-top: 3px;">
                                            <span style="font-size: 10px; vertical-align: middle; color: red;"> Unverified</span>
                                        </span>

                                    </label>
                                    <input type="text" placeholder="Email" class="form-control" v-model="userProfile.email" disabled>
                                </div>
                            </div>
                        </div>
                        <div class="profile-card-edit">
                            <div class="comman-title">
                                <h3>Business Contact</h3>
                            </div>
                            <div class="row">
                                <div class="form-group col-md-4">
                                    <label>Contact Number</label>
                                    <input type="text" placeholder="Phone Number" class="form-control"
                                        @blur="validatePhone" :class="{ 'is-invalid': verifyError }"
                                        v-model="form.contact_phone">
                                    <span v-if="phoneError" class="error">{{ phoneError }}</span>
                                </div>
                                <div class="form-group col-md-4">
                                    <label>Contact Email</label>
                                    <input type="email" placeholder="Email" class="form-control"
                                        v-model="this.form.contact_email">
                                </div>
                                <div class="form-group col-md-4">
                                    <label>Facebook</label>
                                    <input type="text" placeholder="Facebook" class="form-control"
                                        v-model="form.facebook_url">
                                </div>
                                <div class="form-group col-md-4">
                                    <label>Linkedin</label>
                                    <input type="text" placeholder="Linkedin" class="form-control"
                                        v-model="form.linkedin_url">
                                </div>
                                <div class="form-group col-md-4">
                                    <label>Twitter</label>
                                    <input type="text" placeholder="Twitter" class="form-control"
                                        v-model="form.twitter_url">
                                </div>
                                <div class="form-group col-md-4">
                                    <label>Instagram</label>
                                    <input type="text" placeholder="Instagram" class="form-control"
                                        v-model="form.instagram_url">
                                </div>
                                <div class="form-group col-md-4">
                                    <label>Tiktok</label>
                                    <input type="text" placeholder="Tiktok" class="form-control"
                                        v-model="form.tiktok_url">
                                </div>
                                <div class="form-group col-md-4">
                                    <label>YouTube</label>
                                    <input type="text" placeholder="YouTube" class="form-control"
                                        v-model="form.youtube_url">
                                </div>
                            </div>
                        </div>
                        <div class="profile-card-edit">
                            <div class="comman-title">
                                <h3>About You</h3>
                            </div>
                            <div class="form-group mb-0">
                                <label>About You</label>
                                <textarea class="form-control" placeholder="About You"
                                    v-model="form.about_you"></textarea>
                            </div>
                        </div>
                        <div class="profile-card-edit d-flex">
                            <button class="btn comman-btn-2" type="submit">Save</button>
                            <button class="btn comman-btn-4 ms-2">Cancel</button>
                        </div>
                    </form>
                </div>
                <div class="col-md-3">
                    <div class="profile-package-card">
                        <h3 v-if="package_details">Package</h3>
                        <h4 v-if="package_details">{{ package_details.title }}</h4>
                        <p v-if="package_details">Valid Until {{ package_details.paf_todate }}</p>
                        <a href="/packages" class="comman-btn-2"><img src="frontend/images/Choose-plan.svg" alt="">
                            {{ !package_details ? 'Choose package' : 'View package'}}</a>
                        <br>
                        <h4 v-if="canFeatureAgent">Feature</h4>
                        <button v-if="canFeatureAgent" class="comman-btn-2 w-100" data-bs-toggle="modal" data-bs-target="#feature_profile">
                            Feature Your Profile
                        </button>
                        <h4>Credits</h4>
                        <p class="mb-0"><b>{{ userProfile.available_credit_points ?? '0' }} Credits</b></p>
                        <p class="mb-0" v-if="userProfile.credit_points_expiry_date">Expire by {{ userProfile.credit_points_expiry_date }}</p>
                        <router-link class="comman-btn-2 my-3" :to="{ name: 'TopUp' }"><img
                                src="/frontend/images/top-up.svg" alt="">
                            Top-Up</router-link>
                        <router-link class="comman-btn-4" :to="{ name: 'CreditsHistory' }"><img
                                src="/frontend/images/credit-history.png" alt="">
                            Credits History</router-link>

                        <h4 class="mt-4">Reward Points</h4>
                        <p class="mb-0"><b>{{ userProfile.available_reward_points ?? '0' }} Points</b></p>
                        <router-link class="comman-btn-4" :to="{ name: 'RewardPoints' }"><img
                                src="/frontend/images/credit-history.png" alt="">
                            Reward Points</router-link>
                    </div>
                    <div class="profile-package-card">
                        <div v-if="isVerified">
                            <h3>Profile Status</h3>
                            <div class="d-flex mb-3">
                                <label class="form-check-label" for="flexSwitchCheckChecked"><b>{{ isActive ? 'Active' : 'Deactivated' }} :</b></label>
                                <div class="form-check form-switch">
                                    <input class="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckChecked"
                                           :checked="isActive" @click="toggleActive">
                                </div>
                                <div class="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                                    <div class="modal-dialog">
                                        <div class="modal-content">
                                            <div class="modal-header">
                                                <h5 class="modal-title" id="staticBackdropLabel">Modal title</h5>
                                                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                            </div>
                                            <div class="modal-body">
                                                ...
                                            </div>
                                            <div class="modal-footer">
                                                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                                                <button type="button" class="btn btn-primary">Understood</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <h4 class="mb-0">Availability Days</h4>
                        <p v-if="profilServicesData"> {{ profilServicesData.availability_days ?? 'N/A'}}</p>
                        <p v-else>{{ 'N/A' }}</p>
                        <h4 class="mb-0">Working Hours</h4>
                        <p v-if="profilServicesData">{{ profilServicesData.working_hours ?? 'N/A'}}</p>
                        <p v-else>{{ 'N/A' }}</p>
                        <h4 class="mb-0">Contacts</h4>
                        <p class="mb-0">Email: {{ userProfile.email ?? '-'}}</p>
                        <p class="mb-2">Phone: {{ userProfile.contact_phone ?? '-'}}</p>
                        <h4>My Social Medias</h4>
                        <div class="social">
                            <a :href="userProfile.facebook_url" v-if="userProfile.facebook_url"
                                target="_blank">
                                <img src="/frontend/images/fb-ico.svg">
                            </a>
                            <a :href="userProfile.linkedin_url" v-if="userProfile.linkedin_url"
                                target="_blank">
                                <img src="/frontend/images/linkedin-ico.svg">
                            </a>

                            <a :href="userProfile.twitter_url" v-if="userProfile.twitter_url" target="_blank">
                                <img src="/frontend/images/tweet-ico.svg">
                            </a>

                            <a :href="userProfile.instagram_url" v-if="userProfile.instagram_url"
                                target="_blank">
                                <img src="/frontend/images/instagram-icon.svg">
                            </a>

                            <a :href="userProfile.tiktok_url" v-if="userProfile.tiktok_url" target="_blank">
                                <img src="/frontend/images/tiktok-icon.svg">
                            </a>

                            <a :href="userProfile.youtube_url" v-if="userProfile.youtube_url" target="_blank">
                                <img src="/frontend/images/youtube-ico.svg">
                            </a>

                        </div>
                    </div>
                    <div class="modal fade" id="feature_profile" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                        <div class="modal-dialog modal-dialog-centered">
                            <div class="modal-content">
                                <div class="modal-header">
                                    <h5 class="modal-title" id="feature-profileLabel">Feature Your Profile Now</h5>
                                </div>
                                <div v-if="isFeatured" class="modal-body">
                                    <h4><b>Congrats, you are Featured.</b></h4>
                                    <br>
                                    <label><b>Feature From:</b> {{ featuredFrom }}</label>
                                    <label><b>Feature To:</b> {{ featuredTo }}</label>
                                    <div class="d-flex justify-content-end">
                                        <button type="button" class="comman-btn-2-danger" data-bs-dismiss="modal">Close</button>
                                    </div>
                                </div>
                                <div v-else>
                                    <div class="d-flex justify-content-start mt-3 ps-3" >
                                        <h4 class="mb-3">Propface now offer featuring service</h4>
                                    </div>
                                    <div class="px-3">
                                        <p>With just <b>10 credits</b>, you can get your profile featured up to <b>8 days</b></p>
                                        <label>Current Credit Balance</label>
                                        <p><b>{{ userProfile.available_credit_points ?? '0' }} Credits</b></p>
                                        <div class="modal-footer">
                                            <button type="button" class="comman-btn-2-danger" data-bs-dismiss="modal">Close</button>
                                            <a v-if="userProfile.available_credit_points < 10" class="comman-btn-2" href="/top-up" onclick="$('#feature_profile').modal('hide')">Top-up</a>
                                            <button type="button" class="comman-btn-2" data-bs-toggle="modal" data-bs-dismiss="modal" data-bs-target="#confirmationFeature">Feature Now</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="modal fade" id="confirmationFeature" data-bs-backdrop="static" data-bs-keyboard="false" aria-hidden="true" aria-labelledby="exampleModalToggleLabel2" tabindex="-1">
                        <div class="modal-dialog modal-dialog-centered">
                            <div class="modal-content">
                                <div class="modal-header">
                                    <h5 class="modal-title" id="confirmationFeatureLabel">Confirmation</h5>
                                </div>
                                <div class="modal-body">
                                    <p class="mb-3">10 Credits will be deducted from your Account.</p>
                                    <label>Credit Balance after deduction</label>
                                    <p><b>{{ userProfile.available_credit_points - 10 ?? '0' }} Credits</b></p>
                                </div>
                                <div class="modal-footer">
                                    <button type="button" class="comman-btn-2-danger" data-bs-dismiss="modal">Close</button>
                                    <button type="button" class="comman-btn-2" data-bs-dismiss="modal" @click="featureAgent">Yes, I'm Sure</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
    import LoaderCust from '@/components/LoaderCust.vue';
    import { CONSTANT } from '@/constants/constants';
    import { mapState } from 'vuex';
    import toastr from 'toastr';
    import 'toastr/build/toastr.min.css';
    export default {
        components: {
            LoaderCust,
        },
        data() {
            return {
                isVerified: '',
                referralCode: '',
                referal_current_Url: '',
                copySuccess: false,
                isLoading: false,
                selectedYear: new Date().getFullYear(), // Default to current year
                years: [],
                form: {
                    name: '',
                    first_name: '',
                    last_name: '',
                    gender: '',
                    date_of_birth: '',
                    mobile_phone: '',
                    email: '',
                    is_mobile_verified: '',
                    is_email_verified: '',
                    contact_phone: '',
                    contact_email: '',
                    facebook_url: '',
                    linkedin_url: '',
                    twitter_url: '',
                    instagram_url: '',
                    tiktok_url: '',
                    youtube_url: '',
                    about_you: '',
                    agency_name: '',
                    agency_license: '',
                    agency_registration_no: '',
                    position_held: '',
                    personal_service_type_id: 0,
                    personal_working_since: '',
                    service_type_id: 0,
                    show_as_agent: 0,
                    is_available_mon: 0,
                    is_available_tue: 0,
                    is_available_wed: 0,
                    is_available_thu: 0,
                    is_available_fri: 0,
                    is_available_sat: 0,
                    is_available_sun: 0,
                    newPassword: '',
                    currentPassword: '',
                    confirmPassword: '',

                },
                service_typeList: [],
                package_details: [],
                myprofileData: [],
                profilServicesData: [],
                selectedFile: null,
                imageUrl: '/frontend/images/profile.svg',
                errors: {},
                phoneError: '',
                verifyError: '',
                passwordError: '',
                isAgent: null,
                isFeatured: null,
                featuredTo: null,
                featuredFrom: null,
                isActive: null,
                originalIsActive: null,
            };
        },
        computed: {
            ...mapState(['isAuthenticated', 'user', 'userProfile']),
            computedImageSrc() {
                return this.userProfile?.profile_photo_name ? `${CONSTANT.IMAGE_URL}/imagesProfile/${this.userProfile.profile_photo_name}` : CONSTANT.DEFAULT_IMAGE;
            },
            canFeatureAgent() {
                return (this.userProfile.is_business && this.userProfile?.package_details?.title == 'Platinum') || this.userProfile.is_corporate;
            },
        },
        mounted() {
            if (this.userProfile) {
                this.initializeForm();
            }
            this.fetchReferralCode()
           
        },
        watch: {
            userProfile: {
                handler(newValue) {
                    if (newValue) {
                        this.initializeForm();
                    }
                },
                immediate: true,
            },
        },
        async created() {
            this.fetchOptions();
            this.generateYears();
        },
        methods: {
            async toggleActiveStatus() {
                const token = localStorage.getItem('userToken');
                const response = await this.$axios.post('api/change-profile-status', {
                    api_token: token,
                    is_active: this.isActive,
                });

                if (response.data.status == 1) {
                    toastr.success('Profile Status Change Successfully');

                    await this.fetchOptions();
                } else {
                    toastr.error('Profile Status Change Failed');
                }
            },
            toggleActive(event) {
                event.preventDefault();
                window.Swal.fire({
                    title: "Are you sure you want to " + (this.isActive ? 'deactivate' : 'activate') + "?",
                    showCancelButton: true,
                    confirmButtonText: "Save",
                    confirmButtonColor: "var(--primary-F6B034)",
                    cancelButtonColor: "var(--danger-color, #FF0000)",
                    denyButtonText: `Don't save`
                }).then((result) => {
                    /* Read more about isConfirmed, isDenied below */
                    if (result.isConfirmed) {
                        this.isActive = !this.isActive;
                        this.toggleActiveStatus();
                    }
                });
            },
            initializeForm() {
                this.form.name = this.userProfile.name || '';
                this.form.first_name = this.userProfile.first_name || '';
                this.form.last_name = this.userProfile.last_name || '';
                this.form.gender = this.userProfile.gender || '';
                this.form.date_of_birth = this.userProfile.date_of_birth || '';
                this.form.mobile_number= this.userProfile.mobile_number || '';
                this.form.email = this.userProfile.email || '';
                this.form.is_mobile_verified = this.userProfile.is_mobile_verified || '';
                this.form.is_email_verified = this.userProfile.is_email_verified || '';
            },
            async onFileChange(event) {
                this.selectedFile = event.target.files[0];
                if (this.selectedFile) {
                    // Generate a preview URL
                    this.imageUrl = URL.createObjectURL(this.selectedFile);
                    this.computedImageSrc = URL.createObjectURL(this.selectedFile);

                    const token = localStorage.getItem('userToken');
                    // Create a FormData object
                    const formData = new FormData();
                    formData.append('profile_photo_name', this.selectedFile);
                    formData.append('dataJson', JSON.stringify({
                        user_id: this.user.id,
                        api_token: token,
                    }));
                    try {
                        const response = await this.$axios.post('api/update-profile-photo', formData, {
                            headers: {
                                'Content-Type': 'multipart/form-data',
                            },
                        });
                        toastr.success(response.data.message, 'Success', { timeOut: 3000 });

                        const response_profile = await this.$axios.post('/api/profile', {
                            api_token: token,
                            user_id: this.user.id,
                        });
                        // this.form.verify_phone = response_profile.data.output.contact_phone
                        await this.$store.dispatch('profileUpdate', response_profile.data.output);

                        //await this.$store.dispatch('profileUpdate', response.data);
                    } catch (error) {
                        if (error.response && error.response.data) {
                            this.errors = this.extractErrors(error.response.data.errors || {});
                        } else {
                            console.error('An unexpected error occurred:', error);
                        }
                    } finally {
                        this.isLoading = false;
                    }
                }
            },
            triggerFileInput() {
                // Trigger the file input click event
                this.$refs.fileInput.click();
            },
            async removeProfilePhoto() {

                const token = localStorage.getItem('userToken');
                const response_remove_photo = await this.$axios.post('/api/remove-profile-photo', {
                    user_id: this.user.id,
                    api_token: token
                });
                if (response_remove_photo.data)  {
                    toastr.success(response_remove_photo.data.message, 'Success', { timeOut: 3000 });
                    await this.$store.dispatch('profileUpdate', response_remove_photo.data);
                }
            },
            async fetchReferralCode() {
                const token = localStorage.getItem('userToken');
                this.isLoading = true;
                try {
                    // Fetch the referral code from the API
                    const response = await this.$axios.get(`/api/users/${this.user.id}/referral-code`,
                    {
                        params: {
                            api_token: token,
                        }
                    });
                    if (response.data.referral_code) {
                    this.referralCode = response.data.referral_code; // Store the referral code
                    } else {
                    toastr.warning('Referral code not found for the user', 'Warning', { timeOut: 3000 });
                    }
                } catch (error) {
                    toastr.error('Failed to fetch referral code', 'Error', { timeOut: 3000 });
                    console.error('Error fetching referral code:', error);
                } finally {
                    this.isLoading = false;
                }
            },
            validatePhone() {
                const phonePattern = /^[0-9]{9,15}$/; // Adjust pattern as needed
                this.phoneError = phonePattern.test(this.form.contact_phone)
                    ? ''
                    : 'Invalid phone number. Must be between 9 to 15 digits including country code.';
            },
            validatePassword() {
                if (this.form.newPassword !== this.form.confirmPassword) {
                    this.passwordError = 'New passwords do not match!';
                    return false;
                } else {
                    if (!this.form.newPassword) {
                        this.passwordError = 'New Password is required.';
                        return false;
                    } else if (this.form.newPassword.length < 8) {
                        this.passwordError = 'New Password must be at least 8 characters long.';
                        return false;
                    } else if (!/[A-Z]/.test(this.form.newPassword)) {
                        this.passwordError = 'New Password must include one uppercase letter';
                        return false;
                    } else if (!/[a-z]/.test(this.form.newPassword)) {
                        this.passwordError = 'New Password must include one lowercase letter';
                        return false;
                    } else if (!/\d/.test(this.form.newPassword)) {
                        this.passwordError = 'New Password must include at least one number';
                        return false;
                    } else if (!/[!@#$%^&*(),.?":{}|<>]/.test(this.form.newPassword)) {
                        this.passwordError = 'New Password must include at least one special character.';
                        return false;
                    }
                }
                return true;
            },
            async updateProfileBasic() {
                // Validate password first if not empty
                if (this.form.confirmPassword) {
                    const isPasswordValid = this.validatePassword();
                    if (!isPasswordValid) {
                        return;
                    }
                }

                this.validatePhone();

                if (this.isLoading) return;
                this.isLoading = true;
                const token = localStorage.getItem('userToken');

                try {
                    const response = await this.$axios.post('api/update-basic-profile', {
                        user_id: this.user.id,
                        api_token: token,
                        name: this.form.name,
                        first_name: this.form.first_name,
                        last_name: this.form.last_name,
                        gender: this.form.gender,
                        date_of_birth: this.form.date_of_birth,
                        mobile_number: this.form.mobile_number,
                        email: this.form.email,
                        is_mobile_verified: this.form.is_mobile_verified,
                        is_email_verified: this.form.is_email_verified,
                        contact_phone: this.form.contact_phone,
                        contact_email: this.form.contact_email,
                        facebook_url: this.form.facebook_url,
                        linkedin_url: this.form.linkedin_url,
                        twitter_url: this.form.twitter_url,
                        instagram_url: this.form.instagram_url,
                        tiktok_url: this.form.tiktok_url,
                        youtube_url: this.form.youtube_url,
                        about_you: this.form.about_you,
                        current_password: this.form.currentPassword,
                        new_password: this.form.newPassword,

                    });
                    //this.updateServicedData();
                    toastr.success(response.data.message, 'Success', { timeOut: 3000 });

                } catch (error) {
                    if (error.response && error.response.data) {
                        this.errors = this.extractErrors(error.response.data.errors || error.response.data.message);
                    } else {
                        console.error('An unexpected error occurred:', error);
                    }
                } finally {
                    this.isLoading = false;
                }
            },
            async handleInputChangeUsername() {

                try {
                    const token = localStorage.getItem('userToken');
                    await this.$axios.post('api/update-user-name-url', {
                        user_id: this.user.id,
                        api_token: token,
                        user_name_url: this.userProfile.user_name_url
                    });

                } catch (error) {

                    if (error.response && error.response.data) {
                        this.errors = this.extractErrors(error.response.data.errors || {});
                    } else {
                        console.error('An unexpected error occurred:', error);
                    }
                } finally {
                    this.isLoading = false;
                }
            },
            extractErrors(errors) {
                // Extract the first error message from each field's errors array
                const formattedErrors = {};
                for (const [field, messages] of Object.entries(errors)) {
                    if (Array.isArray(messages) && messages.length > 0) {
                        formattedErrors[field] = messages[0]; // Get the first error message
                    }
                }
                return formattedErrors;
            },
            generateYears() {
                const currentYear = new Date().getFullYear();
                for (let year = 1970; year <= currentYear; year++) {
                    this.years.push(year);
                }
            },
            async fetchOptions() {
                const token = localStorage.getItem('userToken');
                // Get Features list
                try {
                    const response = await this.$axios.post('api/services-type-list',
                        {
                            user_id: this.user.id,
                            api_token: token
                        });
                    this.service_typeList = response.data.output.map(item => ({
                        value: item.id,
                        label: item.name
                    }));
                } catch (error) {
                    console.error('Error fetching options:', error);
                }
                // Get Srviced data
                // try {
                //   const response = await this.$axios.post('api/my-profile-detail',
                //     {
                //       user_id : this.user.id,
                //       api_token : token
                //     });
                //   this.myprofileData = response.data.output
                //   this.profilServicesData = this.myprofileData.service_details
                // } catch (error) {
                //   console.error('Error fetching options:', error);
                // }
                // If Profile data
                if (this.isAuthenticated) {
                    try {
                        const response = await this.$axios.post('/api/my-profile-detail', {
                            api_token: token,
                            user_id: this.user.id
                        });

                        var userData = response.data.output;
                        await this.$store.dispatch('profileUpdate', response.data.output);
                        var userServiceData = userData.service_details[0];
                        this.package_details = userData.package_details;
                        this.isVerified = userData?.is_admin_verified;
                        this.isFeatured = userServiceData?.is_featured;
                        this.featuredTo = userServiceData?.featured_to;
                        this.featuredFrom = userServiceData?.featured_from;
                        this.profilServicesData = userServiceData;
                        this.referal_current_Url = `${window.location.protocol}//${window.location.host}/` + 'widget-property-post/' + userData.id + '/' + userData.referral_user_type;
                        this.form = userData,
                        this.form.agency_name = userServiceData?.agency_name,
                        this.form.agency_license = userServiceData?.agency_license,
                        this.form.agency_registration_no = userServiceData?.agency_registration_no,
                        this.form.position_held = userServiceData?.position_held,
                        this.form.service_type_id = userServiceData?.service_type_id ?? 0,
                        this.form.company_name = userServiceData?.company_name,
                        this.form.availability_days = userServiceData?.availability_days,
                        this.form.is_available_mon = userServiceData?.is_available_mon == 1 ? true : false,
                        this.form.is_available_tue = userServiceData?.is_available_tue == 1 ? true : false,
                        this.form.is_available_wed = userServiceData?.is_available_wed == 1 ? true : false,
                        this.form.is_available_thu = userServiceData?.is_available_thu == 1 ? true : false,
                        this.form.is_available_fri = userServiceData?.is_available_fri == 1 ? true : false,
                        this.form.is_available_sat = userServiceData?.is_available_sat == 1 ? true : false,
                        this.form.is_available_sun = userServiceData?.is_available_sun == 1 ? true : false,
                        this.form.working_hours = userServiceData?.working_hours,
                        this.form.show_as_agent = userServiceData?.show_as_agent == 1 ? true : false,
                        this.form.personal_service_type_id = userServiceData?.personal_service_type_id ?? 0,
                        this.form.personal_working_since = userServiceData?.personal_working_since ?? '',
                        this.isActive = userData.is_active,
                        // This is not executed, as some error has thrown before
                        await this.$store.dispatch('profileUpdate', response.data.output);
                    } catch (error) {
                        console.error('Failed to fetch profile:', error);
                    }

                } else {
                    console.log('User is not authenticated');
                }
            },
            async featureAgent() {
                const token = localStorage.getItem('userToken');
                try {
                    const response = await this.$axios.post('/api/feature-agent', {
                        user_id: this.user.id,
                        api_token: token,
                    });

                    if (response.data.status == 1) {
                        alert('Agent has been successfully featured!');
                    } else {
                        alert(response.data.message);
                    }
                } catch (error) {
                    console.error("Error featuring agent:", error);
                    alert('Something went wrong. Please try again.');
                }
            },
        }
    };
</script>

<style scoped>

</style>
<template>
    <section class="hero-banner-sec">
        <picture>
            <img src="frontend/images/banner.jpg">
        </picture>
        <div class="container">
            <div class="banner-outer">
                <div class="row">``
                    <div class="col-md-8">
                        <div class="caption-heading">
                            <div style="display: inline-block; position: relative;">
                                <h1>
                                  PROPFACE<br>Your Place, Your Space!
                                </h1>
                                <h1 style="text-align: right; font-size: 25px;">Beyond Property Listings...</h1>
                            </div>
                              
                            <ul class="nav nav-tabs" id="myTab" role="tablist">
                                <li class="nav-item" role="presentation">
                                    <button class="nav-link" :class="{ active: activeTab === 'Buy' }" id="Buy-tab"
                                        data-bs-toggle="tab" data-bs-target="#Buy" type="button" role="tab"
                                        aria-controls="Buy" aria-selected="true"
                                        @click="setActiveTab('Buy')">Buy</button>
                                </li>
                                <li class="nav-item" role="presentation">
                                    <button class="nav-link" :class="{ active: activeTab === 'Rent' }" id="Rent-tab"
                                        data-bs-toggle="tab" data-bs-target="#Rent" type="button" role="tab"
                                        aria-controls="Rent" aria-selected="false"
                                        @click="setActiveTab('Rent')">Rent</button>
                                </li>
                            </ul>
                            <div class="tab-content" id="myTabContent">
                                <div class="tab-pane fade show active" id="Buy" role="tabpanel"
                                    aria-labelledby="Buy-tab">
                                    <PropertySearch type="Buy"></PropertySearch>
                                </div>
                                <div class="tab-pane fade" id="Rent" role="tabpanel" aria-labelledby="Rent-tab">
                                    <PropertySearch type="Rent"></PropertySearch>
                                </div>
                            </div>


                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <section class="browse-property-sec">
        <div class="container">
            <div class="row">
                <div class="col-md-8 col-lg-9">
                    <div class="property-slider-outer">
                        <div class="comman-title">
                            <h3>Browse Property Type</h3>
                        </div>
                        <LoaderCust :isLoading="loading"/>
                        <div v-if="!loading" class="pro-type-carousel owl-carousel owl-theme h-100">
                            <div class="item" v-for="(property, index) in response.property_type_details" :key="index">
                                <div class="card bpt-box" @click="browseProperty(property)" style="cursor: pointer;">
                                    <picture>
                                        <img src="frontend/images/property-ico1.png" class="card-img-top"
                                            alt="Property Icon">
                                    </picture>
                                    <div class="card-body">
                                        <h3 class="card-title mb-0">{{ property.name }}</h3>
                                    </div>
                                    <div class="card-footer border border-0">
                                        {{ property.propery_count }} properties
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-4 col-lg-3">
                    <div class="own-property-bx">
                        <h3>Create & Own Your Property Listing Now!</h3>
                        <a href="/post-property-listing" class="comman-btn-2">Start Free <i
                                class="ms-2 align-middle d-inline-block"><img
                                    src="frontend/images/start-ico.svg"></i></a>
                    </div>
                </div>
            </div>
            <div class="desc-info-box">
                <p><b>71% Of Owners</b> Wished They've More Control Over Listings Of Their Property Marketed By Estate
                    Agents
                    (Source: NAR 2023, Seller & Buyer Profile).
                    <b>PROP</b>FACE enables you to craft unique details highlighting your property's charm, with
                    flexibility to
                    decide on the level of Estate Agent's involvement that suits you, because you know your property
                    best after
                    all.
                </p>
            </div>
        </div>
    </section>

    <section class="h-it-work-sec">
        <div class="container">
            <div class="comman-title text-center">
                <h3>How It Works, <span>Differently!?</span></h3>
            </div>
            <div class="desc-info-box text-center">
                <p>
                    <b>82%</b> of <b>Buyers & Renters</b> felt frustrated by property listings description that lack
                    details from
                    Sellers.
                    <br><br>
                    <b>84%</b> of <b>Estate Agents</b> acknowledged that generic listings don't showcase the property's
                    true
                    potential by <b>Owners</b>.
                    <br><small><i>(Source: 2023 Consumer Property Search Habits Report)</i></small>
                </p>
            </div>
            <div class="row">
                <div class="col-md-3 col-6">
                    <div class="h-it-work-box text-center">
                        <div class="h-it-work-box-title">
                            <span>1st In the Market!</span>
                            <h5>Hybrid-Listings</h5>
                        </div>
                        <div class="h-it-work-box-content">
                            <img src="frontend/images/1.png" alt="">
                        </div>
                    </div>
                </div>
                <div class="col-md-3 col-6">
                    <div class="h-it-work-box text-center">
                        <div class="h-it-work-box-title">
                            <span>Community Scoring</span>
                            <h5>Crowd Source</h5>
                        </div>
                        <div class="h-it-work-box-content">
                            <img src="frontend/images/3.png" alt="">
                        </div>
                    </div>
                </div>
                <div class="col-md-3 col-6">
                    <div class="h-it-work-box text-center">
                        <div class="h-it-work-box-title">
                            <span>Accuracy Comparison</span>
                            <h5>A.I & Database</h5>
                        </div>
                        <div class="h-it-work-box-content">
                            <img src="frontend/images/2.png" alt="">
                        </div>
                    </div>
                </div>

                <div class="col-md-3 col-6">
                    <div class="h-it-work-box text-center">
                        <div class="h-it-work-box-title">
                            <span><b>BONUS FEATURE</b></span>
                            <h5>Feng Shui Mapping</h5>
                        </div>
                        <div class="h-it-work-box-content">
                            <img src="frontend/images/4.png" alt="">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <section class="property-features-sec">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-md-6 col-lg-7">
                    <div class="feature-tabslist">
                        <ul class="nav nav-tabs" id="myTab-2" role="tablist">
                            <li class="nav-item" role="presentation">
                                <button class="nav-link" id="Hybrid-tab" data-bs-toggle="tab" data-bs-target="#Hybrid"
                                    type="button" role="tab" aria-controls="Hybrid" aria-selected="true">
                                    <small>1st In the Market!</small>
                                    <span>Hybrid-Listings</span>
                                </button>
                            </li>
                            <li class="nav-item" role="presentation">
                                <button class="nav-link active" id="Crowd-tab" data-bs-toggle="tab"
                                    data-bs-target="#Crowd" type="button" role="tab" aria-controls="Crowd"
                                    aria-selected="false">
                                    <small>Community Scoring</small>
                                    <span>Crowd Source</span>
                                </button>
                            </li>
                            <li class="nav-item" role="presentation">
                                <button class="nav-link" id="ai-tab" data-bs-toggle="tab" data-bs-target="#ai"
                                    type="button" role="tab" aria-controls="ai" aria-selected="false">
                                    <small>Accuracy Comparison</small>
                                    <span>A.I & Database</span>
                                </button>
                            </li>
                            <li class="nav-item w-100-lg" role="presentation">
                                <button class="nav-link" id="Feng-tab" data-bs-toggle="tab" data-bs-target="#Feng"
                                    type="button" role="tab" aria-controls="Feng" aria-selected="false">
                                    <small>BONUS FEATURE</small>
                                    <span>Feng Shui Mapping</span>
                                </button>
                            </li>
                        </ul>

                        <div class="tab-content" id="myTab-2Content">
                            <div class="tab-pane " id="Hybrid" role="tabpanel" aria-labelledby="Hybrid-tab">
                                <div class="comman-title">
                                    <h4>Hybrid-Listings</h4>
                                </div>
                                <div class="feature-list-bx">
                                    <ol>
                                        <li>Post Your Properties, You Own & Control The Listings</li>
                                        <li>DIY – Privacy Mode To Hide Contact No. & Filter For E-Mail Enquiries ONLY
                                        </li>
                                        <li>Choose Your Flexibility, Option To Assign Listings Directly To Estate
                                            Agents, Accept Or Decline
                                            Requests</li>
                                        <li>Track Response Rate & Performance Transparency Of Assigned Listings</li>
                                    </ol>
                                    <!--                  <div class="readmore">-->
                                    <!--                    <a href="/hybrid-read-me">Read more <i><img src="/frontend/images/rgt-arrow.svg"></i></a>-->
                                    <!--                  </div>-->
                                </div>
                            </div>

                            <div class="tab-pane fade show active" id="Crowd" role="tabpanel"
                                aria-labelledby="Crowd-tab">
                                <div class="comman-title">
                                    <h4>Crowd Source</h4>
                                </div>
                                <div class="feature-list-bx">
                                    <ol>
                                        <li>Earn Trust Badge To Combat Fake Listings & Shadow Postings</li>
                                        <li>Participate As Local/House Resident In PropertyRatings &/Or Reviews</li>
                                        <li>Build Profile & Goodwill, Personalize It As Your Own Brand</li>
                                        <li>Property Verification By Estate Agents; Genuine Leads & Anti-Hijack Listings
                                        </li>
                                    </ol>
                                    <!--                  <div class="readmore">-->
                                    <!--                    <a href="/crowdsource-read-me">Read more <i><img src="/frontend/images/rgt-arrow.svg"></i></a>-->
                                    <!--                  </div>-->
                                </div>
                            </div>

                            <div class="tab-pane" id="ai" role="tabpanel" aria-labelledby="ai-tab">
                                <div class="comman-title">
                                    <h4>A.I & Database</h4>
                                </div>
                                <div class="feature-list-bx">
                                    <ol>
                                        <li>AI-Assisted & Database- Linked Seamless Listing Creation & Processing Steps
                                        </li>
                                        <li>Accuracy Check With Comparison Analysis In Unique Description Content</li>
                                        <li>Enhance Completeness Of Listing With Guided Field Inputs</li>
                                        <li>Power-Up AI Creatives & Autonomous Predictive Responses In Social Medias
                                        </li>
                                    </ol>
                                    <!--                  <div class="readmore">-->
                                    <!--                    <a href="/ai-read-me">Read more <i><img src="/frontend/images/rgt-arrow.svg"></i></a>-->
                                    <!--                  </div>-->
                                </div>
                            </div>

                            <div class="tab-pane" id="Feng" role="tabpanel" aria-labelledby="Feng-tab">
                                <div class="comman-title">
                                    <h4>Feng Shui Mapping</h4>
                                </div>
                                <div class="feature-list-bx">
                                    <ol>
                                        <li>Just Enter Your Property’s Facing Direction To Generate Feng Sui Map</li>
                                        <li>Unlock Hidden-Value That Resonates With Your Buyer’s / Tenant’s Innate
                                            Personality At Deeper
                                            Level</li>
                                        <li>Get Sticky Leads, Show Characteristics That Stand- Out From Generic Listings
                                        </li>
                                        <li>Show That Your Property Is ‘Unique On Its OWN’</li>
                                    </ol>
                                    <!--                  <div class="readmore">-->
                                    <!--                    <a href="/fengshui-read-me">Read more <i><img src="/frontend/images/rgt-arrow.svg"></i></a>-->
                                    <!--                  </div>-->
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                <div class="col-md-6 col-lg-5 d-md-flex align-items-center">
                    <div class="fea-pic">
                        <figure>
                            <img src="frontend/images/feature-img.jpg">
                        </figure>
                        <div class="mark-logo">
                            <img src="frontend/images/logo.png" alt="">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <!--enable after project pages are done-->
    <section class="offer-pro-sec" v-if="response.developer_projects && response.developer_projects.length > 0">
        <LoaderCust :isLoading="loading"/>
        <div class="container" v-if="!loading">
            <div class="comman-title d-md-flex align-items-center justify-content-between">
                <div class="offer-heading">
                    <span>Browse more offers</span>
                    <h3>{{ getSectionName('developer_projects') }}</h3>
                </div>
                <div class="readmore ms-md-auto">
                    <a @click.prevent="redirectToExploreMore('developer_projects')">
                        Explore more
                        <i><img src="frontend/images/rgt-arrow.svg" alt="Right arrow" /></i>
                    </a>
                </div>
            </div>
            <div class="row row-cols-lg-4 row-cols-md-2 row-cols-sm-2">
                <div class="col" v-for="item in response.developer_projects.slice(0,4)" :key="item.id">
                    <ProjectBox :item="item"></ProjectBox>
                </div>
            </div>
        </div>
    </section>

    <section class="recnet-list-sec" v-if="response.latest_properties && response.latest_properties.length > 0">
        <LoaderCust :isLoading="loading"/>
        <div class="container" v-if="!loading">
            <div class="comman-title d-md-flex align-items-center justify-content-between">
                <div class="offer-heading">
                    <span>Browse more offers</span>
                    <h3>{{ getSectionName('latest_properties') }}</h3>
                </div>
                <div class="readmore ms-md-auto">
                    <a @click.prevent="redirectToExploreMore('latest_properties')">
                        Explore more
                        <i><img src="frontend/images/rgt-arrow.svg" alt="Right arrow" /></i>
                    </a>
                </div>
            </div>
            <div class="row row-cols-lg-4 row-cols-md-2 row-cols-sm-2">
                <div class="col" v-for="item in response.latest_properties.slice(0,4)" :key="item.id">
                    <PropertyBox :item="item"></PropertyBox>
                </div>
            </div>
        </div>
    </section>

    <section class="offer-pro-sec" v-if="response.featured_properties && response.featured_properties.length > 0">
        <LoaderCust :isLoading="loading"/>
        <div class="container" v-if="!loading">
            <div class="comman-title d-md-flex align-items-center justify-content-between">
                <div class="offer-heading">
                    <span>Browse more offers</span>
                    <h3>{{ getSectionName('featured_properties') }}</h3>
                </div>
                <div class="readmore ms-md-auto">
                    <a @click.prevent="redirectToExploreMore('featured_properties')">
                        Explore more
                        <i><img src="frontend/images/rgt-arrow.svg" alt="Right arrow" /></i>
                    </a>
                </div>
            </div>
            <div class="row row-cols-lg-4 row-cols-md-2 row-cols-sm-2">
                <div class="col" v-for="item in response.featured_properties.slice(0,4)" :key="item.id">
                    <PropertyBox :item="item"></PropertyBox>
                </div>
            </div>
        </div>
    </section>

    <section class="recnet-list-sec" v-if="response.fsm_properties && response.fsm_properties.length > 0">
        <LoaderCust :isLoading="loading"/>
        <div class="container" v-if="!loading">
            <div class="comman-title d-md-flex align-items-center justify-content-between">
                <div class="offer-heading">
                    <span>Browse more offers</span>
                    <h3>{{ getSectionName('fsm_properties') }}</h3>
                </div>
                <div class="readmore ms-md-auto">
                    <a @click.prevent="redirectToExploreMore('fsm_properties')">
                        Explore more
                        <i><img src="frontend/images/rgt-arrow.svg" alt="Right arrow" /></i>
                    </a>
                </div>
            </div>
            <div class="row row-cols-lg-4 row-cols-md-2 row-cols-sm-2">
                <div class="col" v-for="item in response.fsm_properties.slice(0,4)" :key="item.id">
                    <PropertyBox :item="item"></PropertyBox>
                </div>
            </div>
        </div>
    </section>

    <section class="offer-pro-sec" v-if="response.classified_properties && response.classified_properties.length > 0">
        <LoaderCust :isLoading="loading"/>
        <div class="container" v-if="!loading">
            <div class="comman-title d-md-flex align-items-center justify-content-between">
                <div class="offer-heading">
                    <span>Browse more offers</span>
                    <h3>{{ getSectionName('classified_properties') }}</h3>
                </div>
                <div class="readmore ms-md-auto">
                    <a @click.prevent="redirectToExploreMore('classified_properties')">
                        Explore more
                        <i><img src="frontend/images/rgt-arrow.svg" alt="Right arrow" /></i>
                    </a>
                </div>
            </div>
            <div class="row row-cols-lg-4 row-cols-md-2 row-cols-sm-2">
                <div class="col" v-for="item in response.classified_properties.slice(0,4)" :key="item.id">
                    <PropertyBox :item="item"></PropertyBox>
                </div>
            </div>
        </div>
    </section>

    <section class="recnet-list-sec" v-if="response.curated_properties && response.curated_properties.length > 0">
        <LoaderCust :isLoading="loading"/>
        <div class="container" v-if="!loading">
            <div class="comman-title d-md-flex align-items-center justify-content-between">
                <div class="offer-heading">
                    <span>Browse more offers</span>
                    <h3>{{ getSectionName('curated_properties') }}</h3>
                </div>
                <div class="readmore ms-md-auto">
                    <a @click.prevent="redirectToExploreMore('curated_properties')">
                        Explore more
                        <i><img src="frontend/images/rgt-arrow.svg" alt="Right arrow" /></i>
                    </a>
                </div>
            </div>
            <div class="row row-cols-lg-4 row-cols-md-2 row-cols-sm-2">
                <div class="col" v-for="item in response.curated_properties.slice(0,4)" :key="item.id">
                    <PropertyBox :item="item"></PropertyBox>
                </div>
            </div>
        </div>
    </section>

    <section class="explore-loc-sec" v-if="response.locations_details && response.locations_details.length > 0">
        <LoaderCust :isLoading="loading"/>
        <div class="container" v-if="!loading">
            <div class="row d-md-flex align-items-center">
                <div class="col-md-12 col-lg-5">
                    <div class="comman-title mb-0">
                        <h3>Explore Property Listings by Location</h3>
                    </div>
                </div>
            </div>
            <div class="row row-cols-lg-3 row-cols-md-2  row-cols-d-md-flex align-items-stretch">
                <div v-for="(location, index) in response.locations_details" :key="index">
                    <div class="location-exp-bx" @click="browsePropertyByLocation(location?.country_id)" style="cursor: pointer;">
                        <div class="mark-logo top-lft">
                            <img src="frontend/images/logo.png">
                        </div>
                        <picture>
                            <img :src="getImageUrl(location?.country?.image)" />
                        </picture>
                        <h5>{{ location?.country?.name }}</h5>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <section class="why-sec">
        <div class="container">
            <div class="row">
                <div class="col-md-6 col-lg-7">
                    <div class="why-ser-bx">
                        <div class="comman-title">
                            <h3>Why PROPverse?</h3>
                        </div>
                        <ul>
                            <li>
                                <figure>
                                    <img src="frontend/images/why-ico1.svg">
                                </figure>
                                <div class="">
                                    <p class="mb-0"><b>Focus On Quality Listings & Qualified Leads, Not Search
                                            Ranking</b>: Forget
                                        expensive listing boosters with zer0-sum game of endless credit top-ups.
                                        PROPverse prioritizes
                                        properties based on relevant features, not who pays the most, and this means
                                        your listings reach
                                        serious buyers looking for exactly what you have.</p>
                                </div>
                            </li>
                            <li>
                                <figure>
                                    <img src="frontend/images/why-ico2.svg">
                                </figure>
                                <div class="">
                                    <p class="mb-0"><b>Tailored Property Matching, Every Listing Is Unique On Its
                                            Own</b>: PROPverse goes
                                        beyond basic property searches, it’s believed to be the 1stproperty listing
                                        platform in the market
                                        that incorporates the option of FengShui Mapping to unlock hidden-values of your
                                        property, and
                                        connect you with purchasers/renters seeking properties that resonate with their
                                        innate personality
                                        or needs at a deeper level - Compete on Value, not Price!</p>
                                </div>
                            </li>
                            <li>
                                <figure>
                                    <img src="frontend/images/why-ico3.svg">
                                </figure>
                                <div class="">
                                    <p class="mb-0"><b>Tech-Powered Auto-Sourcing & Enabler Tools For Success</b>:
                                        PROPverse isn't just a
                                        property listing board, it offers tools that introduces another 1st-in-market
                                        concept of
                                        Hybrid-Listing, empowering property community-inclusion and participation,
                                        collaborating with Estate
                                        Agents and breeding property bird-doggers through digital hand-shake to
                                        streamline the buying,
                                        selling and renting engagements with increasing productivity.</p>
                                </div>
                            </li>
                        </ul>
                        <div class="d-flex">
                            <a href="/about-us" class="comman-btn-2">Learn more</a>
                        </div>
                    </div>
                </div>
                <div class="col-md-6 col-lg-5">
                    <div class="why-pic">
                        <figure>
                            <img src="frontend/images/why-img.jpg">
                        </figure>
                        <div class="mark-logo">
                            <img src="frontend/images/logo.png">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <section class="home-counter-sec">
        <LoaderCust :isLoading="loading"/>
        <div class="container" v-if="!loading && response.stats && response.stats.length > 0">
            <ul class="justify-content-center d-flex">
                <li v-for="(item, index) in response.stats" :key="index">
                    <h3>{{ item.count_val }}</h3>
                    <span>{{ item.name }}</span>
                </li>
            </ul>
        </div>
    </section>

    <section class="meet-agent-sec" v-if="response.agent_details && response.agent_details.length > 0">
        <div class="container">
            <div class="comman-title text-center">
                <span>Our registered agents</span>
            </div>
        
            <LoaderCust :isLoading="loading"/>
            <div v-if="!loading" class="row">
                <div class="comman-title text-center">
                    <h3>Meet our agents</h3>
                </div>
                <div v-for="(agent, index) in response.agent_details" :key="index" class="col-md-4">
                    <div>
                        <div class="meet-agent-bx">
                            <div class="meet-pic">
                                <a :href="'agents/' + response.agent_details[index]?.id">
                                    <picture>
                                        <img :src="getAgentImage(agent.profile_photo_name)">
                                    </picture>
                                </a>
                                <div class="mark-logo">
                                    <img src="frontend/images/logo.png">
                                </div>
                            </div>
                            <div class="agent-info">
                                <h3>{{ agent.name }}</h3>
                                <span>{{ agent.user_service_details.service_type_id.name }}</span>
                                <p v-if="agent.about_you">{{ agent.about_you }}</p>
                            </div>
                            <div class="agent-profile">
                                <a v-bind:href="'/agents/' +  agent.id " class="comman-btn-2">View Profile</a>
                                <div class="profile-call ms-md-auto">
                                    <a href="tel:0121231234">
                                        <img src="frontend/images/phone-ico.svg">
                                    </a>
                                    <a href="mailto:agent@example.com">
                                        <img src="frontend/images/email-ico.svg">
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

<!--    <section class="testimonial-sec">-->
<!--        <div class="container">-->
<!--            <div class="comman-title text-center">-->
<!--                <span>Testimonials</span>-->
<!--                <h3>what our customer say about us</h3>-->
<!--            </div>-->
<!--            <div class="testimonail-carousel owl-carousel owl-theme">-->
<!--                <div class="item">-->
<!--                    <div class="testimoanil-bx">-->
<!--                        <div class="client-header">-->
<!--                            <figure>-->
<!--                                <img src="frontend/images/agent1.png">-->
<!--                            </figure>-->
<!--                            <div class="client-nm">-->
<!--                                <h4>Jane Cooper</h4>-->
<!--                                <div class="rating-show">-->
<!--                                    <i class="fa fa-star"></i>-->
<!--                                    <i class="fa fa-star"></i>-->
<!--                                    <i class="fa fa-star"></i>-->
<!--                                    <i class="fa fa-star"></i>-->
<!--                                </div>-->
<!--                            </div>-->
<!--                        </div>-->
<!--                        <div class="testimoanil-content">-->
<!--                            <p>"We love our new Ang Mo Kio home! It's spacious with stunning skyline views and-->
<!--                                convenient MRT access.-->
<!--                                The neighborhood is vibrant, perfect for families. Property management is attentive and-->
<!--                                responsive.-->
<!--                                Highly recommended!"</p>-->
<!--                        </div>-->
<!--                    </div>-->
<!--                </div>-->
<!--            </div>-->
<!--        </div>-->
<!--    </section>-->
</template>

<script>

    // import $ from 'jquery'; // Import jQuery
    import LoaderCust from '@/components/LoaderCust.vue';
    import ProjectBox from './home/ProjectBox.vue';
    import PropertyBox from './home/PropertyBox.vue';
    import PropertySearch from './home/PropertySearch.vue';
    import { CONSTANT } from '@/constants/constants';
    import { mapState } from 'vuex';

    export default {
        name: 'HomePage',

        components: { PropertySearch, PropertyBox, ProjectBox, LoaderCust },

        data() {
            return {
                activeTab: 'Buy',
                response: {},
                loading: false,
                SectionTitleMap: {
                    property_type_details: 'Browse Property Type',
                    developer_projects: 'Developer Projects',
                    latest_properties: 'Most Recent Property Listings',
                    featured_properties: 'Featured Properties (For Sale / For Rent)',
                    fsm_properties: 'Enriched Property Listings (FengShui Map)',
                    classified_properties: 'Classified Property Listings',
                    curated_properties: 'Curated Property Collections (Malaysia & Others)',
                    locations_details: 'Explore Property Listings by Location',
                    stats: 'Status',
                    agent_details: 'Meet our agents'
                }
            }
        },

        mounted() {
            window.$(document).ready(function () {
                window.$('.property-carousel').owlCarousel({
                    loop: false,
                    margin: 0,
                    nav: true,
                    dots: true,
                    navText: ['<i class="fa fa-angle-left"></i>', '<i class="fa fa-angle-right"></i>'],
                    responsive: {
                        0: {
                            items: 1,
                            margin: 0
                        },
                        600: {
                            items: 1
                        },
                        1000: {
                            items: 1
                        }
                    }
                });

                // Initialize testimonial carousel
                window.$('.testimonail-carousel').owlCarousel({
                    loop: false,
                    margin: 25,
                    nav: false,
                    dots: true,
                    responsive: {
                        0: {
                            items: 1
                        },
                        600: {
                            items: 2
                        },
                        1000: {
                            items: 3
                        }
                    }
                });
            });

            this.loading = true;
            this.$axios.post('api/home-page-details', {
                "user_id": this.user?.id,
                "country_id": 93,
                "location_type_id": 0,
                "property_type_id": 0
            }).then((response) => {
                this.response = response.data.output;
                setTimeout(() => {

                    var $carousel = window.$('.pro-type-carousel');
                    $carousel.owlCarousel({
                        loop: $carousel.find('.item').length > 1, // Only loop if more than one item
                        margin: 20,
                        nav: true,
                        dots: false,
                        navText: ['<i class="fa fa-angle-left"></i>', '<i class="fa fa-angle-right"></i>'],
                        responsive: {
                            0: {
                                items: 1,
                                margin: 10
                            },
                            600: {
                                items: 2
                            },
                            1000: {
                                items: 4
                            }
                        }
                    });

                }, 1000)
            }).catch((error) => {
                console.log(error);
            }).finally(() => {
                this.loading = false;
            });
        },

        computed: {
            ...mapState(['user'])
        },

        methods: {
            browseProperty(propertyType) {
                let data = { selectedType: '', selectedCategories: { "Commercial & Industrial": [], "Residential": [] } };
                let type = '';

                const commercials = ['Office', 'Retail'];
                if (commercials.includes(propertyType.name)) {
                    type = "Commercial & Industrial";
                } else {
                    type = "Residential";
                }
                data.selectedType = type;
                data.selectedCategories[type].push(propertyType.property_type);
                this.$router.push({ name: 'ExploreMore', query: { formData: JSON.stringify(data) } }).then(() => {
                    window.scrollTo(0, 0);
                });
            },

            browsePropertyByLocation(countryId) {
                if (!countryId || countryId == null || countryId == undefined) {
                    return;
                }

                let data = { selectedCountry: countryId };

                this.$router.push({ name: 'ExploreMore', query: { formData: JSON.stringify(data) } }).then(() => {
                    window.scrollTo(0, 0);
                });
            },

            setActiveTab(tab) {
                this.activeTab = tab;
            },

            getSectionName(apiArrayName) {
                return this.SectionTitleMap[apiArrayName] || null;
            },

            redirectToExploreMore(sectionName) {
                let pageToRedirect = 'ExploreMore';
                let params = {};
                if (sectionName == 'featured_properties' || sectionName == 'fsm_properties' || sectionName == 'classified_properties' || sectionName == 'developer_projects') {
                    if (sectionName == 'fsm_properties') {
                        params.is_FSM_linked = true;
                    } else if (sectionName == 'classified_properties') {
                        params.is_widget_property = true;
                    } else if (sectionName == 'developer_projects') {
                        pageToRedirect = 'ExploreMoreProject';
                    }
                } else if (sectionName == 'curated_properties') {
                    params.is_curated_property = true;
                } else if (sectionName == 'latest_properties') {
                    params.sort_by_field = 'Newest';
                }

                this.$router.push({ name: pageToRedirect, query: { formData: JSON.stringify(params) } }).then(() => {
                    window.scrollTo(0, 0);
                });
            },

            getImageUrl(path) {
                return path ? `${CONSTANT.IMAGE_URL}/imagesLocation/${path}` : CONSTANT.DEFAULT_NO_IMAGE;
            },

            getAgentImage(path) {
                return path ? `${CONSTANT.IMAGE_URL}/imagesProfile/${path}` : CONSTANT.DEFAULT_NO_IMAGE;
            },
        }
    }
</script>

<style scoped>
    /* Add styles specific to the Home page here */
</style>
import axios from '../axios'; // axios.js

export const sendCode = async (axios, mobileNumber) => {
    try {
        const response = await axios.post("api/code/send", {
            mobile_number: mobileNumber,
        });
        console.log("Code sent:", response.data);
        return response.data;
    } catch (error) {
        console.error("Error sending code:", error);
        throw error;
    }
};

export async function getConstListingTypes() {
    try {
        const response = await axios.get("api/const-listing-types");
        return response.data;
    } catch (error) {
        console.error("Error fetching const listing types:", error);
        return [];
    }
}

export async function getConstPropertyTypes() {
    try {
        const response = await axios.get("api/const-property-types");
        return response.data;
    } catch (error) {
        console.error("Error fetching const property types:", error);
        return [];
    }
}

<template>
    <section class="payment-se">
        <div class="container">
            <div class="comman-title">
                <h3>Payment Method</h3>
            </div>
            <div class="payment-box">
                <div class="text-end mb-2">
                    <img src="frontend/images/visa.jpg" alt=""><img src="frontend/images/master-card.png" alt="">
                </div>
                <div id="card-element" class="my-3"></div>
                <p class="mb-0">You will not be charged until admin approves your package purchase request.</p>
            </div>
            <div class="form-group">
                    <input v-model="form.referral_code" id="referralCode" type="text" class="form-control" placeholder="Referral Code"/>
                    <span class="error" v-if="errors.referral_code">{{ errors.referral_code }}</span>
                </div>
            <div class="d-md-flex protect-payment">
                <div class="d-flex align-items-center">
                    <img src="frontend/images/lock.svg" class="me-3">
                    <p class="mb-0">We protect your payment information using<br> encryption to provide bank-level
                        security.</p>
                </div>
                <div class="ms-auto">
                    <a href="#" class="comman-btn-2" @click.prevent="createPaymentRequest">
                        <b>
                            Pay S${{
                            parseFloat(packageDetails.current_annual_subscription).toFixed(packageDetails.current_annual_subscription %
                            1 !==
                            0 ? 2 : 0) }}
                        </b>
                    </a>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
    import { mapState } from 'vuex';
    import toastr from "toastr";
    import { loadStripe } from '@stripe/stripe-js';

    export default {
        data() {
            return {
                packageDetails: {},
                packageId: '',
                form: {
                    referral_code: '',
                },
                errors: {},
                // stripe fields
                stripe: null,
                elements: null,
                card: null,
            };
        },
        computed: {
            ...mapState(['isAuthenticated', 'user']),
        },
        async created() {
            this.packageId = this.$route.query.packageId;

            if (!this.packageId) {
                this.$router.push('/packages');
            }

            await this.fetchPackage();
        },
        async mounted() {
            if (!window.STRIPE_KEY) {
                toastr.error('Stripe key not found!', 'Error', { timeOut: 3000 });
            }
            this.stripe = await loadStripe(window.STRIPE_KEY);
            this.elements = this.stripe.elements();

            const options = {
                hidePostalCode: true,
                iconStyle: "solid"
            }
            this.card = this.elements.create('card', options);
            this.card.mount('#card-element');
        },
        methods: {
            async fetchPackage() {
                try {
                    const packagesResponse = await this.$axios.post('/api/package-list-based-on-id', {
                        api_token: localStorage.getItem('userToken'),
                        paf_product_id: this.packageId,
                    });

                    this.packageDetails = packagesResponse.data.output[0];
                } catch (error) {
                    console.error('Error fetching package:', error);
                }
            },
            async createPaymentRequest() {
                try {
                    const { paymentMethod, error } = await this.stripe.createPaymentMethod({
                        type: 'card',
                        card: this.card,
                    });

                    if (error) {
                        toastr.error(error.message, 'Error', { timeOut: 3000 });
                        return;
                    }
                    const response = await this.$axios.post(`api/users/${this.user.id}/packages/payment`, {
                        api_token: localStorage.getItem('userToken'),
                        package_id: this.packageId,
                        referral_code: this.referral_code,
                        payment_method: paymentMethod.id,
                    });

                    if (response.status === 200) {
                        toastr.success('Package purchased successfully! Waiting for approval', 'Success', { timeOut: 3000 });
                        this.$router.push('/packages');
                    } else {
                        toastr.error('Error purchasing package!' + response?.data?.error, 'Error', { timeOut: 3000 });
                    }
                } catch (error) {
                    let message = error?.response?.data?.error || error;
                    toastr.error('Error purchasing package:' + message, 'Error', { timeOut: 3000 });
                    console.error('Error purchasing package:', error);
                } finally {
                    this.card.clear();
                }
            },
            //async createPaymentRequest() {
            //  try {
            //    // Call your Laravel API
            //    const response = await this.$axios.post('http://lbp.test/api/payment/request', {
            //      user_id: 1,
            //      package_id: 1,
            //    });

            //    if (response.data && response.data.url) {
            //      // Redirect to the payment page
            //      window.location.href = response.data.url;
            //    } else {
            //      console.error('Payment URL not found in response:', response);
            //    }
            //  } catch (error) {
            //    console.error('Error creating payment request:', error);
            //  }
            //}
        }
    };
</script>
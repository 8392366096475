<template>
    <section class="signin-sec">
        <div class="container">
            <div class="signin-bx m-auto">
                <h1>Verify Email</h1>
                <p class="text-center">
                    We have sent a verification link to <b>{{ user.email }}</b>. <br>Please check your inbox and click the link to verify your account.
                </p>
            </div>
        </div>
    </section>
</template>


<style>
    .disabled {
        pointer-events: none;
        color: grey;
    }
</style>

<script>
import { mapState } from "vuex";

export default {
    data() {
        return {
            errors: {},
        };
    },
    computed: {
        ...mapState(['isAuthenticated', 'user']),
    },
};
</script>

<template>
    <div v-if="propertyDetail.is_FSM_linked" class="accordion custom-accordion" id="accordion-one">
        <div class="accordion-item">
            <div class="accordion-header" id="heading12">
                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                    data-bs-target="#Disclaimer" aria-expanded="true" aria-controls="Disclaimer">
                    Disclaimer
                </button>
            </div>
            <div id="Disclaimer" class="accordion-collapse collapse show" aria-labelledby="heading12">
                <div class="accordion-body">
                    <p>This property listing may include features and content enhanced by Feng Shui
                        insights, Hybrid listings, and AI-generated content. These elements are
                        provided for general informational purposes only and should not be taken as
                        expert advice. Property details and recommendations based on Feng Shui are
                        not to be construed as a guarantee of increased value, prosperity, or
                        suitability. Users are encouraged to conduct their own due diligence and
                        consult with professionals where appropriate.”</p>
                </div>
            </div>
        </div>
        <div class="accordion-item">
            <div class="accordion-header" id="heading1">
                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                    data-bs-target="#property-gua" aria-expanded="true" aria-controls="property-gua">
                    Property Gua
                </button>
            </div>
            <div id="property-gua" class="accordion-collapse collapse show" aria-labelledby="heading1">
                <div :class="['accordion-body', { 'mb-3': !isAuthenticated }]">
                    <div v-if="!isAuthenticated" class="login-signup-overlay text-center">
                        <a href="/sign-in" class="comman-btn-2">Login / Sign Up</a>
                    </div>
                    <div :class="{'blurred': !isAuthenticated}">
                        <div class="property-slider">
                            <div class="property-slider-left">
                                <div class="property-slider-f-slider owl-carousel owl-theme" v-if="unitLayoutImages.length > 0">
                                    <div class="item" v-for="(pimage, index) in unitLayoutImages" :key="pimage.property_listing_id">
                                        <div class="property-slider-images">
                                            <img :src="`${CONSTANT.IMAGE_URL}/imagesPropertyDocumentAndOtherPhotos/${pimage.photo_name}`"
                                                :alt="pimage.photo_name">
                                        </div>
                                        <div class="text-center">{{ index + 1 }} / {{ unitLayoutImages.length }} photos</div>
                                    </div>
                                </div>
                                <div v-else>
                                    <div class="item">
                                        <div class="property-slider-images">
                                            <img :src="CONSTANT.DEFAULT_NO_IMAGE">
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="property-slider-right">
                                <div class="row">
                                    <div class="col">
                                        <div class="light-info-box mt-0">
                                            <span>Property Facing</span>
                                            <h5>{{ propertyDetail.FSM_property_facing_orientation_building_id?.sub_cardinal || '-' }}</h5>
                                        </div>
                                    </div>
                                    <div class="col">
                                        <div class="light-info-box mt-0">
                                        <span>Main Door Facing</span>
                                            <h5>{{ propertyDetail.FSM_space_sectors_main_door_entrance_sector_id?.sub_cardinal || '-' }}</h5>
                                        </div>
                                    </div>
                                </div>

                                <div>
                                    <div class="list-box">
                                        <ul>
                                            <li><b>Property Gua</b> <span><b>Sectors/Direction</b></span></li>
                                            <template v-if="Object.keys(property_gua).length > 0">
                                                <li v-for="(guaDirection, guaName) in property_gua" :key="guaName">
                                                    <span>{{ formatTitleCase(guaName) }}</span><span>{{ guaDirection }}</span>
                                                </li>
                                            </template>
                                            <template v-else>
                                                <li>
                                                    <span>No property gua found.</span>
                                                </li>
                                            </template>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="accordion-item">
            <div class="accordion-header" id="heading1">
                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                    data-bs-target="#flying-stars-combo" aria-expanded="true" aria-controls="flying-stars-combo">
                    Flying Stars Combo
                </button>
            </div>
            <div id="flying-stars-combo" class="accordion-collapse collapse show" aria-labelledby="heading1">
                <div :class="['accordion-body', { 'mb-3': !isAuthenticated }]">
                    <div v-if="!isAuthenticated" class="login-signup-overlay text-center">
                        <a href="/sign-in" class="comman-btn-2">Login / Sign Up</a>
                    </div>
                    <div :class="{'blurred': !isAuthenticated}">
                        <div v-if="Object.keys(computedFlyingStars).length > 0">
                            <div class="row row-cols-1 row-cols-sm-4" v-for="(flying_star, direction) in computedFlyingStars" :key="'flying-stars-'+direction">
                                <div class="col">
                                    <div class="light-info-box">
                                        <span>Direction</span>
                                        <h5>{{ direction || '-' }}</h5>
                                    </div>
                                </div>
                                <div class="col flex-grow-1">
                                    <div class="light-info-box">
                                        <span>Description of Combination</span>
                                        <h5>{{ flyingStarsComboDescription(flying_star['combo']) }}</h5>
                                    </div>
                                </div>
                                <div class="col">
                                    <div class="light-info-box">
                                        <span>Rating</span>
                                        <h5>{{ flying_star['combo']['rating'] || '-' }}</h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div v-else>
                            <span> No flying stars combo found.</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="accordion-item">
            <div class="accordion-header" id="heading2">
                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                    data-bs-target="#auspicious-stars" aria-expanded="true" aria-controls="auspicious-stars">
                    Auspicious Stars
                </button>
            </div>
            <div id="auspicious-stars" class="accordion-collapse collapse" aria-labelledby="heading2">
                <div :class="['accordion-body', { 'mb-3': !isAuthenticated }]">
                    <div v-if="!isAuthenticated" class="login-signup-overlay text-center">
                        <a href="/sign-in" class="comman-btn-2">Login / Sign Up</a>
                    </div>
                    <div :class="{'blurred': !isAuthenticated}">
                        <div v-if="auspicious_stars.length > 0">
                            <div class="row row-cols-1 row-cols-sm-3">
                                <div class="col">
                                    <div class="light-info-box">
                                        <span>House Direction</span>
                                        <h5>{{propertyDetail.FSM_property_facing_orientation_main_entrance_sc}}</h5>
                                    </div>
                                </div>
                                <div class="col">
                                    <div class="light-info-box">
                                        <span>Main Door Direction</span>
                                        <h5>{{propertyDetail.FSM_space_sectors_main_door_entrance_sector_sc}}</h5>
                                    </div>
                                </div>
                                <div class="col">
                                    <div class="light-info-box">
                                        <span>Completion Date</span>
                                        <h5>{{ propertyDetail.FSM_property_completion_occupation_date?.toString().slice(0, 4) || '-' }}</h5>
                                    </div>
                                </div>
                            </div>
                            <hr>
                            <div v-for="(auspicious_star, index) in auspicious_stars" :key="'auspicious-stars-'+index">
                                <div class="row row-cols-1 row-cols-sm-6">
                                    <div class="col">
                                        <div class="light-info-box">
                                            <span>Rating</span>
                                            <h5>{{ auspicious_star.rating }}</h5>
                                        </div>
                                    </div>
                                    <div class="col">
                                        <div class="light-info-box">
                                            <span>Nobleman 1</span>
                                            <h5>{{ auspicious_star.nobleman1 || '-' }}</h5>
                                        </div>
                                    </div>
                                    <div class="col">
                                        <div class="light-info-box">
                                            <span>Nobleman 2</span>
                                            <h5>{{ auspicious_star.nobleman2 || '-' }}</h5>
                                        </div>
                                    </div>
                                    <div class="col">
                                        <div class="light-info-box">
                                            <span>Peach Blossom</span>
                                            <h5>{{ auspicious_star.peach_blossom || '-' }}</h5>
                                        </div>
                                    </div>
                                    <div class="col">
                                        <div class="light-info-box">
                                            <span>Knowledge</span>
                                            <h5>{{ auspicious_star.knowledg || '-' }}</h5>
                                        </div>
                                    </div>
                                    <div class="col">
                                        <div class="light-info-box">
                                            <span>Sky Horse</span>
                                            <h5>{{ auspicious_star.sky_horse || '-' }}</h5>
                                        </div>
                                    </div>
                                </div>
                                <hr v-if="auspicious_stars.length !== (index + 1)">
                            </div>
                        </div>
                        <div v-else>
                            <span>No auspicious stars found.</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div v-else>
        <div class="card">
            <div class="card-body">
                Feng Shui Map not linked.
            </div>
        </div>
    </div>
</template>

<script>
    import { CONSTANT } from '@/constants/constants';
    import { mapState } from 'vuex';
    import { formatTitleCase } from "@/utils/helpers";

    export default {
        name: 'PropertyFengShui',
        props: {
            propertyDetail: {
                type: Object,
                required: true,
            }
        },
        data() {
            return {
                flying_stars: [],
                auspicious_stars: [],
                property_gua: {},
                unitLayoutImages: [],
                CONSTANT
            }
        },
        computed: {
            ...mapState(['isAuthenticated']),
            computedFlyingStars() {
                // sort by combo['rating']
                return Object.entries(this.flying_stars)
                    .sort((a, b) => {
                        const ratingA = a[1]?.combo?.rating ?? Infinity;
                        const ratingB = b[1]?.combo?.rating ?? Infinity;
                        return ratingA - ratingB;
                    })
                    .reduce((acc, [key, value]) => {
                        acc[key] = value;
                        return acc;
                    }, {});
            }
        },
        methods: {
            formatTitleCase,
            getUnitLayoutImages() {
                this.unitLayoutImages = (this.propertyDetail.doc_other_photos?.filter(photo => photo.doc_type === 'Unit Layout & Site Plan')) || [];
                // destroy and reinitialize owlcarousal
                const sliderClass = `.property-slider-f-slider`;
                if (window.$(sliderClass).data('owl.carousel')) {
                    window.$(sliderClass).trigger('destroy.owl.carousel');
                }

                setTimeout(() => {
                    const newSliderClass = `.property-slider-f-slider`;
                    window.$(newSliderClass).owlCarousel({
                        loop: false,
                        margin: 0,
                        nav: true,
                        dots: false,
                        navText: ['<i class="fa fa-angle-left"></i>', '<i class="fa fa-angle-right"></i>'],
                        responsive: {
                            0: {
                                items: 1,
                            },
                            600: {
                                items: 1,
                            },
                            1000: {
                                items: 1,
                            },
                        },
                    });
                }, 1000);
            },

            async getPropertyGua() {
                try {
                    const response = await this.$axios.post(`api/properties/${this.propertyDetail.id}/property-gua`, {
                        api_token: localStorage.getItem('userToken'),
                    });
                    this.property_gua = response.data.output;
                } catch (error) {
                    console.error("Error retrieving property gua:", error);
                    throw error;
                }
            },

            flyingStarsComboDescription(combo) {
                let description = [];
                for (let index = 1; index <= 5; index++) {
                    let string = combo['attributes_' + index];
                    if (string) {
                        description.push(string);
                    }
                }

                return description.join(', ') || '-';
            },

            async getFlyingStarsCombo() {
                try {
                    const response = await this.$axios.post(`api/properties/${this.propertyDetail.id}/flying-stars`, {
                        api_token: localStorage.getItem('userToken'),
                    });
                    this.flying_stars = response.data.output;
                } catch (error) {
                    console.error("Error retrieving flying stars:", error);
                    throw error;
                }
            },
            async getAuspiciousStars() {
                try {
                    const response = await this.$axios.post(`api/properties/${this.propertyDetail.id}/auspicious-stars`, {
                        api_token: localStorage.getItem('userToken'),
                    });
                    this.auspicious_stars = response.data.output;
                } catch (error) {
                    console.error("Error retrieving auspicious stars:", error);
                    throw error;
                }
            },
        },

        watch: {
            propertyDetail: {
                immediate: true,
                handler(newVal) {
                    this.getUnitLayoutImages();
                    if (newVal && newVal.is_FSM_linked && this.isAuthenticated) {
                        this.getPropertyGua();
                        this.getFlyingStarsCombo();
                        this.getAuspiciousStars();
                    }
                },
            },
        }

    }
</script>

<style scoped>
.blurred {
  filter: blur(5px);
}

.accordion-body {
  position: relative;
}

.login-signup-overlay {
  position: absolute;
  top: 50%; /* Center vertically */
  left: 50%; /* Center horizontally */
  transform: translate(-50%, -50%);
  z-index: 10;
}
</style>
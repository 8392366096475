<template>    
    <section class="create-property-listing-page">
			<div class="container">
				<div class="text-center">
					<div class="comman-title">
						<h2>Create your property listing in minutes!</h2>
						<p>Add quick details and sign up with your mobile number to Add your property.</p>
					</div>
					<div class="d-flex justify-content-center">
						<router-link :to="`/widget-property-post-1/${id}/${idType}`"><a href="javascript:;" class="comman-btn-2">Get Started</a></router-link>
					</div>
				</div>
			</div>
		</section> 
</template>
  
<script> 
import { mapState } from 'vuex';
import {ref} from "vue";
import {useHead} from "@unhead/vue";

    export default {
        name: 'Widget0Page',
        data() {
            return {
                widget: {},
            }
        },
        computed: {
            ...mapState(['isAuthenticated', 'user']),
            id() {
                return this.$route.params.id;
            },
            idType() {
                return this.$route.params.id_type;
            },
        },
        methods: {
            async fetchOptions() {
                try {
                    const response = await this.$axios.get(`api/users/${this.id}/widgets`);
                    this.widget = response.data.widget;
                } catch (error) {
                    console.error('Error fetching widget:', error.response?.data?.message || error.message);
                }
            },
            storeAgentId() {
                const agentId = this.id;
                if (agentId) {
                    localStorage.setItem('agentId', agentId); // Store agent ID in local storage
                }
            },
        },
        mounted() {
            this.storeAgentId(); // Call storeAgentId when the component is mounted
        },
        beforeMount() {
            this.fetchOptions().then(() => {
                const title = ref(this.widget.title || 'Welcome To PropFace');
                const description = ref(this.widget.description || 'Default Description');

                useHead({
                    title: title.value,
                    meta: [
                        { property: 'og:description', content: description.value },
                        { property: 'og:title', content: title.value || 'Welcome to PropFace' },
                        { property: 'og:image', content: this.widget.image },
                        { property: 'og:url', content: window.location.href },
                        { property: 'og:type', content: 'website' },
                    ],
                })
            });
        },
        head() {
            return {
                title: 'hello'
            }
        }

    }
</script>
  
<style scoped>
</style>
  